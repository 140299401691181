import { FC, useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
//import { CartInfo } from './CartInfo'
import { CartPrices  } from 'components/Cart/CartPrices';
import { NavLink } from 'react-router-dom';

import { CartCard } from './cart-card';

import { useAppSelector, useAppDispatch } from 'store';
import { fetchCarts } from 'store/carts/actions';

import { routesPath } from 'routes';

import staticData from 'common/static.json';

export const CartPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isOrdersExists, setOrders] = useState(false);

  const {
    list
  } = useAppSelector(state => state.carts);

  const {
    token
  } = useAppSelector(state => state.auth);

  const {
    role
  } = useAppSelector(state => state.profile);

  useEffect(() => {
    if (role === 'ADMIN') {
      //navigate(routesPath.ordersList);
    }
  }, [role]);

  useEffect(() => {
    dispatch(fetchCarts());
  }, [token]);

  useEffect(() => {
    if (list.length) {
      setOrders(true);
    } else {
      setOrders(false);
    }
  }, [list]);

  return (
    <div className='cart'>
      <div className="cart__wrapper">
        <h1 className='cart__title'>{ staticData.cart.title }</h1>
        { !isOrdersExists && 
          <div className='cart-empty_block'>
            <div className='cart-empty_block__notification'>
              <span>{ staticData.cart.emptyBlock.notification }</span>
            </div>
            <div className='cart-empty_block__button'>
              <NavLink
                to={ routesPath.markets }
              >
                <Button
                  label={ staticData.cart.emptyBlock.button }
                />
              </NavLink>
            </div>
          </div>
        }
        <div className='cart__main cart-main'>
          <div className='cart-main__items cart-items'>
            { isOrdersExists &&
              list.map((cart, key) => {
                return <CartCard key={ key } { ...cart } />
              })
            }
          </div>
          { list.length !== 0 &&
            <CartPrices />
          }
        </div>
      </div>
    </div>
  )
};