import { FC, useState, useEffect } from 'react';

import { getOrdersTemplateDocuments, addTemplatesForOrder } from 'store/documents/actions';
import { useAppDispatch, useAppSelector } from 'store';
import { DropDownWithCheckBox } from 'components/common/DropDownWithCheckBox';
import {
  PdfDocumentIcon,
  AddDocument
} from 'assets/icons';

import { addDocuments } from 'store/orders/actions';

export const OrderDocumentsForm: FC<{currentDocuments, submitAction}> = ({ currentDocuments, submitAction }) => {
  const [documents, setDocuments] = useState(currentDocuments.map(item => { return { name: item.name, link: item.link, type: 'old', id: item.id }}));
  const dispatch = useAppDispatch();

  const { customerDocuments } = useAppSelector(state => state.documents);
  const { order } = useAppSelector(state => state.orders);

  const [allDocuments, setAllDocuments] = useState([{name: '', value: ''}]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedFile, setSelectedFile] = useState(null);
  const [newDocumentName, setNewDocumentName] = useState('');

  const [removedDocuments, setRemovedDocuments] = useState([]);

  useEffect(() => {
    dispatch(getOrdersTemplateDocuments({pageNum: 0, pageSize: 100 }));

    const tempDocuments = currentDocuments.map((doc) => {
      return {
        name: doc?.name,
        value: doc?.fileId,
        type: 'old'
      }
    })

    setSelectedDocuments(tempDocuments);
  }, []);

  useEffect(() => {
    const tempDocuments = customerDocuments.map((doc) => {
      return {
        name: doc?.name,
        value: doc?.fileId,
        type: 'new'
      }
    })
    setAllDocuments(tempDocuments);
  }, [customerDocuments]);

  const addNewDocument = () => {
    const sendData = new FormData();
    const json = JSON.stringify({
      name: newDocumentName
    });
    sendData.append('data', json)
    sendData.append('file', selectedFile)

    dispatch(addDocuments({id: order?.order?.id, params: sendData}));
  }

  const saveDocuments = () => {
    if (selectedFile) {
      addNewDocument();
    }

    const newDoc = selectedDocuments.filter(doc => doc?.type === 'new');

    if (newDoc.length) {
      dispatch(addTemplatesForOrder({
        id: order?.order?.id,
        params: {
          templateFilesIds: newDoc.map(doc => doc.value)
        }
      }))
    }

    const temp = removedDocuments.map((doc) => {
      return {
        type: 'removed',
        id: doc?.value
      }
    });

    submitAction(temp);
  };

  const updateFieldChanged = (index, value) => e => {
    let newArr = [...documents];
    newArr[index][value] = e.target.value;
    if (newArr[index].type === 'old') {
      newArr[index].type = 'edit';
    }
    setDocuments(newArr);
  }

  const removeDocument = (index) => {
    let tempDocuments = selectedDocuments;
    const spliceElem = tempDocuments.splice(index, 1);
    let tempRemoved = removedDocuments;

    tempRemoved = tempRemoved.concat(spliceElem)

    setRemovedDocuments([...tempRemoved]);
    setSelectedDocuments([...tempDocuments]);
  }

  const addNewDocumentTemplate = (values) => {
    const documentsLength = values.length;
    let newDocuments = values;
    newDocuments[documentsLength - 1] = {
      ...newDocuments[documentsLength - 1],
    }
    setSelectedDocuments([...newDocuments])
  }

  return (
    <div className='order--inputs'>
      <div className='order--inputs-title flex justify-content-center flex-wrap'>
        { currentStep === 1 &&
          <span>Редактировать документы</span>
        }

        { currentStep === 2 &&
          <span>Добавить документы</span>
        }
      </div>
      <div className='order--inputs-documents flex justify-content-center flex-wrap'>
        { currentStep === 2 &&
          <>
            <DropDownWithCheckBox
              initialValue={ selectedDocuments }
              setSelectedValues={ values => { addNewDocumentTemplate(values) } }
              options={ allDocuments }
            />


            <span className='order-inputs-documents__or'>И/или</span>

            <div className="order-inputs-documents__alternative order-inputs-documents-alternative">
              <p className="order-inputs-documents-alternative__title">
                Загрузите документ
              </p>

              <p className="order-inputs-documents-alternative__suptitle">
                * Для загрузки доступны документы, не требующие электронной подписи
              </p>

              <input
                type="text"
                value={ newDocumentName }
                className={ /^[a-zA-Zа-яА-ЯёЁ0-9\\?!№%.,/ ]+$/.test(newDocumentName) ? 'order-inputs-documents-alternative__input' : 'order-inputs-documents-alternative__input order-inputs-documents-alternative__input--invalid' }
                placeholder='Наименование документа'
                onChange={ (e) => { setNewDocumentName(e?.target?.value) } }
              />

            { !selectedFile &&
              <>
                <span className='order-inputs-documents-alternative__added'>
                <input
                type="file"
                name='file'
                className="documents-added-modal-form__input documents-added-modal-form__input--file"
                onChange={(event)=> {
                  setSelectedFile(event?.target?.files[0]);
                }}
              />
                Добавить файл <AddDocument />
                </span>

                <p className="order-inputs-documents-alternative__suptitle">
                  * Допустимые форматы: DOCX<br></br>
                    Максимальный размер файла: 2 MB
                </p>
                </>
              }
              {
                selectedFile &&
                <p className="order-inputs-documents-alternative__suptitle">
                  Файл: <br />
                  { selectedFile?.name }
                </p>
              }
            </div>

          </>
        }
        { currentStep === 1 &&
          <ul>
          {
            selectedDocuments.map((document, key) => {
              return <li key={key} className='order--inputs-documents-item'> 
                {
                  <>
                    <PdfDocumentIcon />
                    <span className='order--inputs-documents-item_name'> { document?.name } </span>
                    <i className='order--inputs-documents-item-del pi pi-times' onClick={ () => { removeDocument(key) } }></i>
                  </>
                }
              </li>
            })
          }
          </ul>
        }
      </div>
      <div className='order--inputs-documents-add_button flex justify-content-center flex-wrap'>
        { currentStep !== 2 &&
          <span onClick={() => { setCurrentStep(2) }}>Добавить документ</span>
        }
      </div>
      {/* { currentStep === 1 &&
        <div className='order--inputs-documents-save_button flex justify-content-center flex-wrap'>
          <button onClick={ () => { setCurrentStep(2) } }>Далее</button>
        </div>
      } */}
      <div className='order--inputs-documents-save_button flex justify-content-center flex-wrap'>
        <button onClick={ () => { saveDocuments() } }>Сохранить изменения</button>
      </div>
    </div>
  );
};
