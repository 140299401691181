import { FC } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { useController, useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import staticData from 'common/static.json';
import { useAppSelector } from 'store';
import { IRegion } from 'store/partners/types';

const text = staticData.partnersRequest;

const RegionSelect: FC = () => {
  const { regionList } = useAppSelector(state => state.ui);
  const { field } = useController({
    name: 'regions',
    rules: { required: true },
  });
  const { setValue } = useFormContext();
  const [data, setData] = useState<string[]>([]);
  const handleCheck = (value: IRegion) => {
    if (data.includes(value.region)) {
      setData(data.filter(item => item !== value.region));
    } else {
      setData([...data, value.region]);
    }
  };

  useEffect(() => {
    setValue('regions', data);
  }, [data]);

  return (
    <div className='flex flex-column gap-3'>
      <span className='text-center font-medium text-2xl'>{text.city}</span>
      <div className='flex flex-column gap-2'>
        {regionList.map(item => (
          <label key={item.region}>
            <Checkbox
              {...field}
              checked={data.includes(item.region)}
              onChange={() => {
                handleCheck(item);
              }}
              className='partners-checkbox mr-2'
            />
            {item.value}
          </label>
        ))}
      </div>
    </div>
  );
};

export default RegionSelect;
