export const API_BASE_URL = ' https://api.kalitka.moscow/api/v1';
export const API_ROUTES = {
  auth: {
    registration: '/user',
    login: '/user/login',
    passwordRestore: '/user/passwordRestoration',
    password: '/user/password',
    sendEmailConfirmation: '/user/{authUserId}/emailConfirmation',
    emailConfirmation: '/user/emailConfirmation',
    refreshToken: '/user/refresh'
  },
  user: {
    fetch: '/user',
    update: '/user/{userId}',
    updatePassword: '/user/{userId}/password',
    byId: '/user/{userId}',
    all: '/user/all',
    createLegal: '/user/legal/{userId}',
  },
  partners: {
    fetchList: '/partners/issues',
    updateStatus: '/partners/issues/{issuePartnerId}',
    createIssue: '/partners/issues',
    updateIssue: '/partners/issues',
    getOrders: '/partners/issues/executors/orders',
    getExecutors: '/partners/issues/executors',
    getPartnerIssue: '/partners/issues/executors/orders/{orderExecutionIssueId}',
    getPartnerInfo: 'partners/{partnerId}'
  },
  projects: {
    getList: '/store/market',
    getById: '/projects/{projectId}',
    getByIdWithOptions: 'projects/{projectId}/with-options',
    getTree: '/projects/options',
    getImages: '/projects/images/{imageId}',
    getVillageHouse: '/store/township/building/{name}'
  },
  orders: {
    list: '/orders',
    item: '/orders/{orderId}',
    edit: '/orders/{orderId}',
    archive: '/orders/archive',
    usersOrder: '/orders/users/{userId}',
    editLandData: '/orders/{orderId}/land',
    addDocuments: '/orders/{orderId}/documents',
    editDocuments: '/orders/{orderId}/documents',
    removeDocuments: '/orders/{orderId}/documents/{documentId}',
    create: '/orders',
    assignExecutors: '/orders/{orderId}/assign'
  },
  commercial: {
    getCategory: '/commercial/category',
    createCategory: '/commercial/category',
    deleteCategory: '/commercial/category/{categoryId}',
    getOffers: '/commercial/category/report',
    getTemplate: '/commercial/template/{categoryId}',
    createOffer: '/commercial/offer',
    getOffer: '/commercial/offer',
    updateOffer: '/commercial/offer',
    getUserOffer: '/commercial/offer/{partnerId}/{categoryId}',
    fetchNomenclature: '/commercial/nomenclature',
    addNomenclature: '/commercial/nomenclature',
    getAllColumns: '/commercial/nomenclature/columns-definitions'
  },
  carts: {
    list: '/carts',
    putInCart: '/carts',
    changeCount: '/carts/change',
    remove: '/carts/{cartElemntId}'
  },
  administration: {
    regions: '/configs/regions',
  },
  referral: {
    fetch: '/referral',
    saveBankDetails: '/referral/bank-details',
    userReferral: '/referral/{userId}'
  },
  documents: {
    getDocuments: 'documents/templates',
    saveDocuments: 'documents/templates',
    getDocumentsFile: 'documents/templates/files/{fileId}',
    removeDocumentTemplate: 'documents/templates/files/{fileId}',
    getFilledDocuments: '/partners/issues/documents',
    getFilledDocumentId: '/partners/issues/documents/{fileId}',
    getAllUserDocuments: '/orders/documents',
    getOrdersDocumentsById: '/orders/documents/{fileId}',
    getOrdersTemplateDocuments: '/documents/templates/for-customer',
    getOrdersTemplateDocumentsForPartners: '/documents/templates/for-partner/{authUserId}',
    addTemplatesForOrder: '/orders/{orderId}/templates',
    changeVisibilityForCustomer: '/documents/customer/{fileId}/visibility',
    changeVisibilityForPartner: '/documents/partner/{fileId}/visibility'
  }
};
