import { partnersApi } from "services/api/partnersApi";

export const PARTNERS_GET_PARTNERS = 'partners/getPartners';
export const PARTNERS_UPDATE_STATUS = 'partners/updateStatus';
export const PARTNERS_CREATE_ISSUE = 'partners/createIssue';
export const PARTNERS_GET_EXECUTORS = 'partners/getExecutors';
export const PARTNERS_GET_INFO = 'partners/getPartnersInfo';

export interface IPartners {
  list: Array<IPartnerIssue>;
  status: Status;
  executors: IExecutorData[]
}

export interface IPartnerIssue {
  id: string;
  partnerType: string;
  workType?: string;
  city: Array<IRegion>;
  issuePartnerStatus: Status;
  number: string;
  orgName: string;
  ogrn: string;
  kpp: string;
  inn: string;
  phoneNumber: string;
}

export interface IRegion {
  region: string;
  value: string;
}

export interface IStatus {
  issuePartnerStatus: string;
}

export interface IExecutorData {
  executorType: string;
  executorsData: {
    id: string;
    organizationName: string;
    regions: string[];
  }
}

export enum REPRESENTATIVE_ISSUE_ORDER {
  NEW = 'Новый',
  ORDER_CONFIRMATION = 'Подтверждение заказа',
  SIGNING_OF_DOCUMENTS = 'Подписание документов',
  PREPAYMENT = 'Внесение предоплаты',
  CHECKING_THE_LAND_PLOT = 'Проверка земельного участка',
  GETTING_A_MORTGAGE = 'Выдача ипотеки',
  PAYMENT = 'Оплата',
  BUILDING = 'Строительство',
  REPAIRING = 'Ремонт',
  FURNITURE_INSTALLATION = 'Установка мебели',
  DELIVERY = 'Cдача объекта',
  COMPLETED = 'Завершен',
  CANCELLED = 'Отменен'
}

export enum Status {
  inactive = 'INACTIVE',
  active = 'ACTIVE',
  disabled = 'DISABLED',
}

export enum ExecutorsTypes {
  REPRESENTATIVE = 'Представитель',
  BUILDER = 'Строитель',
  LOGISTIC = 'Логист',
  FURNITURE_MAKER = 'Мебель',
  REPAIRMAN = 'Ремонт',
  REALTOR = 'Риелтор',
  GEOLOGIST = 'Геологи',
  TECHNICAL_SUPERVISION = 'Технадзор'
}

export enum PartnerTypes {
  REPRESENTATIVE = 'Представитель',
  BUILDER = 'Строитель',
  LOGISTIC = 'Логист',
  FURNITURE_MAKER = 'Мебель',
  REPAIRMAN = 'Ремонт',
  REALTOR = 'Риелтор',
  GEOLOGIST = 'Геологи',
  TECHNICAL_SUPERVISION = 'Технадзор',
  PROVIDER = 'Поставщик',
  CONTRACTOR = 'Подрядчик',
}

export enum WorkType {
  GEOLOGY = 'Геология',
  CONSTRUCTION = 'Строительство',
  FURNITURE = 'Мебель',
  REPAIR = 'Ремонт',
  TECHNICAL_SUPERVISION = 'Технадзор',
  SERVICE = 'Сервисное обслуживание'
}

export const partnersTypes = Object.keys(PartnerTypes).map((partnerKey) => {
  return {
    value: PartnerTypes[partnerKey],
    key: partnerKey
  }
})