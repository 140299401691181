import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getPartners, updateStatus, getExecutors, getPartnerInfo } from './actions';
import { IPartners, Status, IExecutorData } from './types';

const initialState: IPartners = {
  list: [],
  executors: [],
  status: Status.inactive,
  partner: {}
};

export const partnersSlice = createSlice({
  name: 'partners',
  initialState,
  reducers: {
    setStatus(state, action: PayloadAction<string>) {
      state.status = action.payload as Status;
    },
  },
  extraReducers: builder => {
    builder.addCase(
      getPartners.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.list = action.payload.data;
      }
    ).addCase(
      getExecutors.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.executors = action.payload.data; 
      }
    ).addCase(
      getPartnerInfo.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.partner = action.payload;
      }
    );
  },
});

export default partnersSlice.reducer;
