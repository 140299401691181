import { FC, useState } from 'react';
import { TabPanel, TabView } from 'primereact/tabview';
import { useAppSelector } from 'store';
import staticData from 'common/static.json';
import InputTest from 'components/common/InputTest';
import ChangePassword from './ChangePassword';
import { useFormContext } from 'react-hook-form';
import { validations } from 'helpers/validations';
import { useController } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import { DropDownWithCheckBox } from 'components/common/DropDownWithCheckBox';

const text = staticData.profile;

const LegalData: FC = () => {
  const { isEditing, passwordEditing, legalUser } = useAppSelector(state => state.profile);

  const {
    formState: { errors },
  } = useFormContext();

  const [selectAuthorityGround, setSelectAuthorityGround] = useState([]);

  const { field } = useController({ name: 'authorityGround' });

  const authorityGround = [{
    name: 'Устав',
    value: 'ARTICLES_OF_ASSOCIATION'
  }, {
    name: 'Доверенность',
    value: 'POWER_OF_ATTORNEY'
  }]

  return (
    <div>
      <div className='profile-desktop'>
        <TabView renderActiveOnly={false} className='profile-tabs max-w-full'>
          <TabPanel
            header={text.orgDetails}
            disabled={passwordEditing}
            headerClassName={`${
              errors.ogrn || errors.inn || errors.kpp
                ? 'profile-tabs-header__invalid'
                : 'profile-tabs-header'
            }`}
          >
            <div className='grid align-items-end mt-1'>
              <InputTest
                name='ogrn'
                validations={validations.ogrn}
                classNameInput='auth-form-input profile-form-input'
                classNameValid='input-valid-auth'
                classNameLabel='flex flex-column gap-2 col-4'
                classNameError='input-error-auth'
                label={text.ogrn}
                disabled={!isEditing || (legalUser.ogrn !== null && legalUser.ogrn !== '')}
              />
              <InputTest
                name='inn'
                mask={'9999999999'}
                validations={validations.mask}
                classNameInput='auth-form-input profile-form-input'
                classNameValid='input-valid-auth'
                classNameLabel='flex flex-column gap-2 col-4'
                classNameError='input-error-auth'
                label={text.inn}
                disabled={!isEditing || (legalUser.inn !== null && legalUser.inn !== '')}
              />
              <InputTest
                name='kpp'
                validations={validations.kpp}
                classNameInput='auth-form-input profile-form-input'
                classNameValid='input-valid-auth'
                classNameLabel='flex flex-column gap-2 col-4'
                classNameError='input-error-auth'
                label={text.kpp}
                disabled={!isEditing || (legalUser.kpp !== null && legalUser.kpp !== '')}
              />
            </div>

            <div className='grid align-items-end mt-1'>
              <InputTest
                name='responsiblePersonName'
                validations={validations.name}
                classNameInput='auth-form-input profile-form-input'
                classNameValid='input-valid-auth'
                classNameLabel='flex flex-column gap-2 col-4'
                classNameError='input-error-auth'
                label={'ФИО ответственного лица'}
                disabled={!isEditing || (legalUser.responsiblePersonName !== null && legalUser.responsiblePersonName !== '')}
              />
              <label className='flex flex-column gap-2 col-4'>
                Основание полномочий
                <Dropdown
                  {...field}
                  options={authorityGround}
                  optionLabel='name'
                  optionValue='value'
                  value={field.value}
                  onChange={e => field.onChange(e.value)}
                  panelClassName='profile-info-select'
                  className={`auth-form-input pr-0 flex align-items-center`}
                />
              </label>
              {/* <DropDownWithCheckBox
                {...field}
                setSelectedValues={ values => { field.onChange(values.map(values => values.value)); setSelectAuthorityGround(values) } }
                options={ authorityGround }
              /> */}
              <InputTest
                name='legalAddress'
                validations={validations.bankName}
                classNameInput='auth-form-input profile-form-input'
                classNameValid='input-valid-auth'
                classNameLabel='flex flex-column gap-2 col-4'
                classNameError='input-error-auth'
                label={'Юридический адрес'}
                disabled={!isEditing || (legalUser.legalAddress !== null && legalUser.legalAddress !== '')}
              />
            </div>
          </TabPanel>
          <TabPanel
            header={text.bankDetails}
            disabled={passwordEditing}
            headerClassName={`${
              errors.bankAccount ||
              errors.bankName ||
              errors.bic ||
              errors.correspondentAccount
                ? 'profile-tabs-header__invalid'
                : 'profile-tabs-header'
            }`}
          >
            <div className='grid'>
              <InputTest
                name='bankName'
                validations={validations.bankName}
                classNameInput='auth-form-input profile-form-input'
                classNameValid='input-valid-auth'
                classNameLabel='flex flex-column gap-2 col-4'
                classNameError='input-error-auth'
                label={text.bankName}
                disabled={!isEditing}
              />
              <InputTest
                name='bankAccount'
                mask='99999999999999999999'
                validations={validations.mask}
                classNameInput='auth-form-input profile-form-input'
                classNameValid='input-valid-auth'
                classNameLabel='flex flex-column gap-2 col-4'
                classNameError='input-error-auth'
                label={text.bankAccount}
                disabled={!isEditing}
              />
              <InputTest
                name='bic'
                validations={validations.mask}
                mask='999999999'
                classNameInput='auth-form-input profile-form-input'
                classNameValid='input-valid-auth'
                classNameLabel='flex flex-column gap-2 col-4'
                classNameError='input-error-auth'
                label={text.bic}
                disabled={!isEditing}
              />
              <InputTest
                name='correspondentAccount'
                validations={validations.mask}
                mask='30101999999999999999'
                classNameInput='auth-form-input profile-form-input'
                classNameValid='input-valid-auth'
                classNameLabel='flex flex-column gap-2 col-4'
                classNameError='input-error-auth'
                label={text.correspondentAccount}
                disabled={!isEditing}
              />
            </div>
          </TabPanel>
          <TabPanel
            header={text.password}
            disabled={isEditing}
            headerClassName='profile-tabs-header'
          >
            { !isEditing &&
              <ChangePassword />
            }
          </TabPanel>
        </TabView>
      </div>

      <div className='profile-mobile'>
        <TabView className='profile-tabs max-w-full'>
          <TabPanel
            header={text.orgDetails}
            disabled={passwordEditing}
            className='flex flex-column gap-4'
            headerClassName={`${
              errors.ogrn || errors.inn || errors.kpp
                ? 'profile-tabs-header__invalid'
                : 'profile-tabs-header'
            }`}
          >
            <InputTest
              name='ogrn'
              validations={validations.ogrn}
              classNameInput='auth-form-input profile-form-input'
              classNameValid='input-valid-auth'
              classNameLabel='flex flex-column gap-2'
              classNameError='input-error-auth'
              label={text.ogrn}
              disabled={!isEditing}
            />
            <InputTest
              name='inn'
              mask='9999999999'
              validations={validations.mask}
              classNameInput='auth-form-input profile-form-input'
              classNameValid='input-valid-auth'
              classNameLabel='flex flex-column gap-2'
              classNameError='input-error-auth'
              label={text.inn}
              disabled={!isEditing}
            />
            <InputTest
              name='kpp'
              validations={validations.kpp}
              classNameInput='auth-form-input profile-form-input'
              classNameValid='input-valid-auth'
              classNameLabel='flex flex-column gap-2'
              classNameError='input-error-auth'
              label={text.kpp}
              disabled={!isEditing}
            />
          </TabPanel>

          <TabPanel
            header={text.orgDetails}
            disabled={passwordEditing}
            className='flex flex-column gap-4'
            headerClassName={`${
              errors.ogrn || errors.inn || errors.kpp
                ? 'profile-tabs-header__invalid'
                : 'profile-tabs-header'
            }`}
          >
            <InputTest
              name='ogrn'
              validations={validations.ogrn}
              classNameInput='auth-form-input profile-form-input'
              classNameValid='input-valid-auth'
              classNameLabel='flex flex-column gap-2'
              classNameError='input-error-auth'
              label={text.ogrn}
              disabled={!isEditing}
            />
            <InputTest
              name='inn'
              mask='9999999999'
              validations={validations.mask}
              classNameInput='auth-form-input profile-form-input'
              classNameValid='input-valid-auth'
              classNameLabel='flex flex-column gap-2'
              classNameError='input-error-auth'
              label={text.inn}
              disabled={!isEditing}
            />
            <InputTest
              name='kpp'
              validations={validations.kpp}
              classNameInput='auth-form-input profile-form-input'
              classNameValid='input-valid-auth'
              classNameLabel='flex flex-column gap-2'
              classNameError='input-error-auth'
              label={text.kpp}
              disabled={!isEditing}
            />
          </TabPanel>

          <TabPanel
            header={text.bankDetails}
            disabled={passwordEditing}
            className='flex flex-column gap-4'
            headerClassName={`${
              errors.bankAccount ||
              errors.bankNAme ||
              errors.bic ||
              errors.correspondentAccount
                ? 'profile-tabs-header__invalid'
                : 'profile-tabs-header'
            }`}
          >
            <InputTest
              name='bankName'
              validations={validations.bankName}
              classNameInput='auth-form-input profile-form-input'
              classNameValid='input-valid-auth'
              classNameLabel='flex flex-column gap-2'
              classNameError='input-error-auth'
              label={text.bankName}
              disabled={!isEditing}
            />
            <InputTest
              name='bankAccount'
              mask='99999999999999999999'
              validations={validations.mask}
              classNameInput='auth-form-input profile-form-input'
              classNameValid='input-valid-auth'
              classNameLabel='flex flex-column gap-2'
              classNameError='input-error-auth'
              label={text.bankAccount}
              disabled={!isEditing}
            />
            <InputTest
              name='bic'
              validations={validations.mask}
              mask='999999999'
              classNameInput='auth-form-input profile-form-input'
              classNameValid='input-valid-auth'
              classNameLabel='flex flex-column gap-2'
              classNameError='input-error-auth'
              label={text.bic}
              disabled={!isEditing}
            />
            <InputTest
              name='correspondentAccount'
              validations={validations.mask}
              mask='30101999999999999999'
              classNameInput='auth-form-input profile-form-input'
              classNameValid='input-valid-auth'
              classNameLabel='flex flex-column gap-2'
              classNameError='input-error-auth'
              label={text.correspondentAccount}
              disabled={!isEditing}
            />
          </TabPanel>

          <TabPanel
            header={text.password}
            disabled={isEditing}
            headerClassName='profile-tabs-header'
          >
            <ChangePassword />
          </TabPanel>
        </TabView>
      </div>
    </div>
  );
};

export default LegalData;
