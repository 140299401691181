import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { FC, useState, useEffect } from 'react';
import { routesPath } from 'routes';
import { LogisticStatus } from 'store/orders/types';

import {
  IOrderItemData
} from 'store/orders/types';

export const OrderLogistic: FC<{ order:IOrderItemData }> = ({ order }) => {
  const [orderData, setOrderData] = useState('{}');
  const navigate = useNavigate();
  //let orderData = {};

  const options = {
    LOGISTIC: 'Логист',
    PROVIDER: 'Поставщик',
    CONTRACTOR: 'Подрядчик',
    REALTOR: 'Риелтор',
    REPRESENTATIVE: 'Представитель',
  };

  useEffect(() => {
    if (order?.payloadData) {
      setOrderData(order?.payloadData);
    }
  }, [order]);

  const getDate = (date) => {
    if (date === null) {
      return '';
    }
    return moment(date).format('L').replaceAll('/', '.');
  }

  const openOrder = () => {
    navigate(`${routesPath.orderDetail.replace(':id',order?.orderId )}`, { state: {
      isPartnerOrder: true,
      partnerType: 'logistic'
    }});
  }

  return (
    <div className='orders-list--list_item orders-list--list_item-role-logistic w-full'>
      <div className='flex justify-content-between flex-wrap w-full'>
        <div className='orders-list--list_item--title'>
          <span>Заказ № { order?.publicId } </span><span>{ getDate(order?.timeCreated) }</span>
        </div>
        <div className='flex justify-content-end flex-wrap'>
          <div className='orders-list--list_item--button'>
            <span>{ LogisticStatus[order?.status] }</span>
          </div>
        </div>
      </div>

      <div className='orders-list--list_item--info w-full flex justify-content-start flex-wrap'>
        <div className='ml-2'>
          <div className='flex justify-content-start flex-wrap'>
            <div>
              <span>Пункт А: </span>
              <span>{ JSON.parse(JSON.parse(JSON.stringify(orderData)))?.pickUpPoint?.address }</span>
            </div>
            <div className='ml-2'>
              <span>Пункт Б: </span>
              <span>{ JSON.parse(JSON.parse(JSON.stringify(orderData)))?.departurePoint?.address }</span>
            </div>
          </div>
          <div className='flex justify-content-start flex-wrap'>
            <div>
              <span>Дата и время прибытия:</span>
            </div>
            <div>
              <div className='ml-2'>
                <span>в пункте А: </span>
                <span>{ getDate(JSON.parse(JSON.parse(JSON.stringify(orderData)))?.pickUpPoint?.arrivalDate) }</span>
                <span> с { JSON.parse(JSON.parse(JSON.stringify(orderData)))?.pickUpPoint?.arrivalHourFrom } до &nbsp; 
                { JSON.parse(JSON.parse(JSON.stringify(orderData)))?.pickUpPoint?.arrivalHourTo }</span>
              </div>
              <div className='ml-2'>
                <span>в пункте Б: </span>
                <span>{ getDate(JSON.parse(JSON.parse(JSON.stringify(orderData)))?.departurePoint?.arrivalDate) }</span>
                <span> с { JSON.parse(JSON.parse(JSON.stringify(orderData)))?.departurePoint?.arrivalHourFrom } до &nbsp; 
                { JSON.parse(JSON.parse(JSON.stringify(orderData)))?.departurePoint?.arrivalHourTo }</span>
              </div>
            </div>
          </div>
        </div>
        <div className='flex justify-content-between flex-wrap w-full'>
          <div>
          </div>
          <div>
            <button onClick={ () => { openOrder() } }>
              Перейти
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}