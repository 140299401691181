const defaultMessage = 'Это поле обязательно для заполнения';

export const validations = {
  orgName: {
    required: defaultMessage,
    minLength: {
      value: 5,
      message: 'Поле должно содержать как минимум 5 символов',
    },
    pattern: {
      value: /^(?=.*[а-яё])[а-яё\d\p{P}\s]+$/iu,
      message: 'Это поле может содержать только кириллицу, цифры и спецсимволы',
    },
  },
  ogrn: {
    required: defaultMessage,
    pattern: {
      value: /^\d{13}$|^\d{15}$/,
      message: 'Поле может содержать только цифры, 13 либо 15 штук',
    },
  },
  partnerType: {
    required: defaultMessage,
  },
  workType: {
    required: defaultMessage,
  },
  kpp: {
    required: defaultMessage,
    pattern: {
      value: /^\d{9}$|^0$/,
      message: 'Поле должно содержать 9 цифр (для ИП введите 0)',
    },
  },
  mask: {
    required: defaultMessage,
  },
};
