import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router';
import { Button } from 'primereact/button';
import { useMultiStepForm } from 'hooks/useMultiStepForm';
import CompleteLegalDataFirstStep from './CompleteLegalDataFirstStep';
import CompleteLegalDataSecondStep from './CompleteLegalDataSecondStep';
import staticData from 'common/static.json';
import { useAppDispatch, useAppSelector } from 'store';
import { useEffect, useState } from 'react';
import WarningDialogTest from 'components/common/WarningDialogTest';
import CompleteDataFinish from '../CompleteDataFinish';
import { updateUser } from 'store/profile/actions';
import { saveBankDetails } from 'store/referral/actions';
import { uiSlice } from 'store/ui/reducer';
import { routesPath } from 'routes';
import { checkNullProperties } from 'helpers/services';

const text = staticData;
const buttons = staticData.buttons;
const notificationText = staticData.notifications.referral;

const CompleteLegalData: React.FC = () => {
  const { state } = useLocation();
  const partnerCheck = state?.partnerCheck;
  const { legalUser, common } = useAppSelector(state => state.profile);
  const { id } = useAppSelector(state => state.auth);
  const { setNotification } = uiSlice.actions;
  const dispatch = useAppDispatch();
  const methods = useForm({ mode: 'onChange' });
  const {
    formState: { isDirty },
  } = methods;
  const { Step, next, back, isFirstStep, isLastStep } = useMultiStepForm([
    CompleteLegalDataFirstStep,
    CompleteLegalDataSecondStep,
  ], 1);
  const navigate = useNavigate();

  if (partnerCheck && !checkNullProperties(legalUser)) {
    navigate(routesPath.ordersList);
  }

  const notification = {
    id: Math.random(),
    header: notificationText.header,
    message: notificationText.message,
    type: 'notification',
  };

  const onSubmit = (formData: any) => {
    const bankData = {
      bankAccount: formData.bankAccount,
      correspondentAccount: formData.correspondentAccount,
      bankName: formData.bankName,
      bic: formData.bic,
      inn: formData.inn,
    };

    if (partnerCheck) {
      let fullData = {}
      fullData.commonUserData = common;
      fullData.individualUserData = null;

      fullData.legalEntityUserData = {
        ...legalUser,
        ...formData
      };

      dispatch(updateUser({ id: id, data: fullData }));
      navigate('/profile');
    } else {
      dispatch(saveBankDetails(bankData));
      setDialog(false);
      navigate('/profile');
      dispatch(setNotification(notification));
    }
  };
  const handleFinish = (e: any) => {
    e.preventDefault();
  };

  const handleBack = (e: any) => {
    e.preventDefault();
    back();
  };

  const handleWarning = (formData: any) => {
    const userData = {
      legalEntityUserData: {
        orgName: formData.orgName,
        ogrn: formData.ogrn,
        inn: formData.inn,
        kpp: formData.kpp,
      },
      commonUserData: {
        email: common.email,
        phoneNumber: common.phoneNumber,
        roles: common.roles,
        name: common.name,
        accountType: common.accountType,
        region: common.region,
      },
    };

    if (isFirstStep) {
      if (isDirty) {
        dispatch(updateUser({ id: id, data: userData }));
      }
      next();
    }
    next();
    if (isLastStep) {
      setDialog(true);
    }
  };

  const defaultValues = {
    orgName: legalUser.orgName || '',
    ogrn: legalUser.ogrn || '',
    inn: legalUser.inn || '',
    kpp: legalUser.kpp || '',
    bankName: legalUser.bankName || '',
    bankAccount: legalUser.bankAccount || '',
    bic: legalUser.bic || '',
    correspondentAccount: legalUser.correspondentAccount || '',
  };

  useEffect(() => {
    methods.reset({ ...defaultValues });
  }, [legalUser]);

  const [dialog, setDialog] = useState(false);

  const isBlockBackButton = () => {
    if (partnerCheck) {
      return true;
    }

    if (isFirstStep) {
      return true;
    }

    return false;
  }

  return (
    <div className='referral-form '>
      <WarningDialogTest
        isVisible={dialog}
        onClose={() => {
          setDialog(false);
        }}
        onConfirm={methods.handleSubmit(onSubmit)}
        confirmLabel={buttons.save}
        declineLabel={buttons.back}
        declineButtonClassName={'button-white referral-form-button'}
        confirmButtonClassName={'button-green referral-form-button'}
        wrapperClassName='referral-form'
      >
        <CompleteDataFinish />
      </WarningDialogTest>
      <FormProvider {...methods}>
        <form
          className='auth-form flex flex-column gap-3 justify-content-center mt-5 mb-0 grid'
          onSubmit={methods.handleSubmit(handleWarning)}
        >
          <span className='font-medium text-2xl text-center'>
            { !partnerCheck &&
              text.referral.referral
            }
            { partnerCheck &&
              'Заполните или проверьте банковские реквизиты'
            }
          </span>
          {<Step />}
          <div className='flex flex-wrap gap-3 justify-content-between mt-3'>
            <Button
              className='button-white referral-form-button'
              label='Назад'
              disabled={isBlockBackButton}
              onClick={handleBack}
            />
            <Button
              className='button-green referral-form-button'
              onClick={() => (isLastStep ? handleFinish : null)}
              label={isLastStep ? buttons.done : buttons.forward}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default CompleteLegalData;
