import { FC, useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';

import { CrossPlusIcon } from 'assets/icons';
import { DocumentsSearch } from 'assets/icons';
import { DocumentsFilter } from 'assets/icons';
import { DropdownFilter } from 'components/common/Filters/DropdownFilter';
import { useAppDispatch } from 'store';
import { DocumentsType, INewDocument } from 'store/documents/types';
import { Arrow } from 'assets/icons';
import { Check } from 'assets/icons';
import { DropDownWithCheckBox } from 'components/common/DropDownWithCheckBox';

import { FileUpload } from 'primereact/fileupload';
import { saveDocuments } from 'store/documents/actions';

import { signerType } from 'store/documents/types';
import { documentsType } from 'store/documents/types';

export const DocumentsNewUpload: FC<{documentCategory:string, onClose:any, postAction:any}> = ({documentCategory, onClose, postAction}) => {
  const dispatch = useAppDispatch();

  const [selectedFirstSigner, setSelectedfirstSigner] = useState([]);
  const [selectedSecondSigner, setSelectedSecondSigner] = useState([]);
  const [documentName, setDocumentName] = useState('');
  const [newDocumentType, setDocumentType] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);

  const documentType = () => {
    switch(documentCategory) {
      case 'SYSTEM':
        return [];
      case 'EMPLOYMENT':
        return [{
          value: 'EMPLOYMENT',
          name: 'Трудоустройство'
        }, {
          value: 'OTHERS',
          name: 'Текущие'
        }, {
          value: 'FINANCE',
          name: 'Финансы'
        }];
      case 'PARTICIPANTS':
        return [{
          value: 'TERMS_AND_AGREEMENTS',
          name: 'Условия и соглашения'
        }, {
          value: 'SUPPORTING',
          name: 'Сопроводительные'
        }, {
          value: 'FINANCE',
          name: 'Финансы'
        }];
      case 'PROGRAMS':
        return [{
          value: 'TERMS_AND_AGREEMENTS',
          name: 'Условия и соглашения'
        }, {
          value: 'FINANCE',
          name: 'Финансы'
        }];
      case 'MORTGAGE':
        return [{
          value: 'TERMS_AND_AGREEMENTS',
          name: 'Условия и соглашения'
        }, {
          value: 'FINANCE',
          name: 'Финансы'
        }];
      default:
        return []
    }
  }

  const saveDocument = async (document: INewDocument) => {
    const sendData = new FormData();
    let jsonPrev = {
      name: documentName,
      category: documentCategory,
      firstSigners: selectedFirstSigner.map(value => value?.value),
      secondSigners: selectedSecondSigner.map(value => value?.value)
    }

    if (documentCategory !== 'SYSTEM') {
      jsonPrev.type = newDocumentType;
    }

    const json = JSON.stringify(jsonPrev);
    sendData.append('data', json)
    sendData.append('file', selectedFile)

    await dispatch(saveDocuments(sendData));
    postAction();
    onClose();
  }

  const signeres = [{
    name: 'Представитель',
    value: 'REPRESENTATIVE'
  }, {
    name: 'Риелтор',
    value: 'REALTOR'
  }, {
    name: 'Логист',
    value: 'LOGISTIC'
  }, {
    name: 'Поставщик',
    value: 'PROVIDER'
  }, {
    name: 'Подрядчик',
    value: 'CONTRACTOR'
  }, {
    name: 'Калитка',
    value: 'SYSTEM'
  }, {
    name: 'Заказчик (юр. лицо)',
    value: 'LEGAL_ENTITY_CUSTOMER'
  }, {
    name: 'Заказчик (индивидуальное лицо)',
    value: 'INDIVIDUAL_CUSTOMER'
  },]

  return (
    <>
      <div className="documents-added-modal__wrapper">
        <h2 className="documents-added-modal__title">Добавить документ</h2>
        <form action="#" className="documents-added-modal__form documents-added-modal-form">
          <div className="documents-added-modal-input__wrapper">
            <label className='documents-added-modal-input__lable'>
              Наименование документа
            </label>
            <input
              className='documents-added-modal-input__item input--new'
              type="text"
              placeholder='ПДКП'
              value={ documentName }
              onChange={ (e) => { setDocumentName(e.target.value) } }
            />
          </div>

          { documentType().length > 0 && 
            <div className="documents-added-modal-input__wrapper">
              <span className="documents-added-modal-input__lable">Тип документа</span>
              <DropdownFilter
                fieldName='direction'
                options={documentType()}
                selectAction={(value: string) => { setDocumentType(value)  }}
                className='documents-added-modal-input__item documents-added-modal-input__item--dropdown'
              />
            </div>
          }

          <div className="documents-added-modal-input__wrapper">
            <span className="documents-added-modal-input__lable">Подписант 1</span>
            <DropDownWithCheckBox
              setSelectedValues={ (values) => { setSelectedfirstSigner(values) } }
              options={ signeres }
            />
          </div>

          <div className="documents-added-modal-input__wrapper">
            <span className="documents-added-modal-input__lable">Подписант 2</span>
            <DropDownWithCheckBox
              setSelectedValues={ (values) => { setSelectedSecondSigner(values) } }
              options={ signeres }
            />
          </div>
          { !selectedFile &&
          <>
            <label className="documents-added-modal-form__lable">
              <input
                type="file"
                name='file'
                className="documents-added-modal-form__input documents-added-modal-form__input--file"
                onChange={(event)=> { 
                  setSelectedFile(event?.target?.files[0]);
                }}
              />
              <span className='documents-added-modal-form__text'>Добавить файл</span>
            </label>
            <p className="documents-added-modal-form__explanation">
              * Допустимые форматы: PDF, DOC<br></br>
              &nbsp;&nbsp;Максимальный размер файла: 2 MB
            </p>
            </>
            }
            {
              selectedFile && 
              <p className="documents-added-modal-form__explanation">
                Файл: <br></br>
                { selectedFile?.name }
              </p>
            }

          <div className="documents-added-modal-form__buttons documents-added-modal-form-buttons">
            <button
              type="button"
              className="documents-added-modal-form-buttons__item"
              onClick={ () => { onClose() } }
            >Отменить</button>
            <button
              type="button"
              className="documents-added-modal-form-buttons__item documents-added-modal-form-buttons__item--active"
              onClick={ () => { saveDocument() }}
            >
              Загрузить
            </button>
          </div>
        </form>
      </div>
    </>
  )
};