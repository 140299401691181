import { FC, useState, useEffect } from 'react';
import { useSearchParams, NavLink } from 'react-router-dom';

import { PasswordRestoreIcon  } from 'assets/icons';
import { requestEmailConfirmation, emailConfirmation } from 'store/auth/actions';
import { getUser } from 'store/profile/actions';
import { useAppDispatch, useAppSelector } from 'store';
import { useNavigate } from 'react-router-dom';

import { EmailType } from 'store/profile/types';

import { routesPath } from 'routes';

import { CartConfirmation } from 'assets/icons';

import staticData from 'common/static.json';
const staticText = staticData.auth.passwordRestore;

export const PartnerConfirmationPage: FC = () => {
  return (
    <div className='flex justify-content-center flex-wrap'>
      <form
        className='auth-form cart--confirmation auth-form--restore p-d-flex p-jc-center p-mt-2 w-8 auth-form'
      >
        <div className='auth-form--restore-pending'>
          <div className='auth-form--restore-icon'>
            <CartConfirmation />
          </div>
          <div className='auth-form--restore-info'>
            <span>Заявка на парнерство успешно отправлена </span>
          </div>
          <div className='auth-form--restore-description'>
            <div>Мы рассматриваем вашу заявку. Письмо с одобрением придет на почту, а пока наслаждайтесь покупками</div>
          </div>
        </div>
      </form>
    </div>
  );
};
