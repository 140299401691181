import { TabPanel, TabView } from 'primereact/tabview';
import { useAppSelector } from 'store';
import InputTest from 'components/common/InputTest';
import ChangePassword from './ChangePassword';
import staticData from 'common/static.json';
import { useFormContext } from 'react-hook-form';
import { validations } from 'helpers/validations';

const text = staticData.profile;
const placeholder = staticData.profile.placeholders;

const IndividualData: React.FC = () => {
  const { isEditing, passwordEditing } = useAppSelector(state => state.profile);

  const {
    formState: { errors },
  } = useFormContext();

  return (
    <div>
      <div className='profile-desktop'>
        <TabView className='profile-tabs max-w-full'>
          <TabPanel
            className='flex flex-column gap-4'
            header={text.personalData}
            disabled={passwordEditing}
            headerClassName={`${
              errors.passport ||
              errors.passportIssuedBy ||
              errors.passportIssueDate ||
              errors.residenceAddress
                ? 'profile-tabs-header__invalid'
                : 'profile-tabs-header'
            }`}
          >
            <div className='grid align-items-end'>
              <InputTest
                name='passport'
                mask={'9999 999999'}
                validations={validations.mask}
                classNameInput='auth-form-input profile-form-input'
                classNameValid='input-valid-auth'
                classNameLabel='flex flex-column gap-2 col-3'
                classNameError='input-error-auth'
                label={text.passport}
                disabled={!isEditing}
                placeholder={placeholder.passport}
              />
              <InputTest
                name='passportIssuedBy'
                validations={validations.passportIsssuedBy}
                classNameInput='auth-form-input profile-form-input'
                classNameValid='input-valid-auth'
                classNameLabel='flex flex-column gap-2 col-5'
                classNameError='input-error-auth'
                label={text.passportIssuedBy}
                disabled={!isEditing}
                placeholder={placeholder.passportIssuedBy}
              />
              <InputTest
                name='passportIssueDate'
                mask={'99.99.9999'}
                validations={validations.mask}
                classNameInput='auth-form-input profile-form-input'
                classNameValid='input-valid-auth'
                classNameLabel='flex flex-column gap-2 col-4'
                classNameError='input-error-auth'
                label={text.passportIssueDate}
                disabled={!isEditing}
                placeholder={placeholder.passportIssueDate}
              />
            </div>
            <InputTest
              name='residenceAddress'
              validations={validations.registrationAddr}
              classNameInput='auth-form-input profile-form-input'
              classNameValid='input-valid-auth'
              classNameLabel='flex flex-column gap-2'
              classNameError='input-error-auth'
              label={text.residenceAddress}
              disabled={!isEditing}
              placeholder={placeholder.residenceAddress}
            />
          </TabPanel>
          <TabPanel
            header={text.password}
            disabled={isEditing}
            headerClassName='profile-tabs-header'
          >
            <ChangePassword />
          </TabPanel>
        </TabView>
      </div>

      <div className='profile-mobile'>
        <TabView className='profile-tabs max-w-full'>
          <TabPanel
            className='flex flex-column gap-4'
            header={text.personalData}
            disabled={passwordEditing}
            headerClassName={`${
              errors.passport ||
              errors.passportIssuedBy ||
              errors.passportIssueDate ||
              errors.residenceAddress
                ? 'profile-tabs-header__invalid'
                : 'profile-tabs-header'
            }`}
          >
            <InputTest
              name='passport'
              mask={'9999 999999'}
              validations={validations.mask}
              classNameInput='auth-form-input profile-form-input'
              classNameValid='input-valid-auth'
              classNameLabel='flex flex-column gap-2'
              classNameError='input-error-auth'
              label={text.passport}
              disabled={!isEditing}
              placeholder={placeholder.passport}
            />
            <InputTest
              name='passportIssuedBy'
              validations={validations.passportIsssuedBy}
              classNameInput='auth-form-input profile-form-input'
              classNameValid='input-valid-auth'
              classNameLabel='flex flex-column gap-2'
              classNameError='input-error-auth'
              label={text.passportIssuedBy}
              disabled={!isEditing}
              placeholder={placeholder.passportIssuedBy}
            />
            <InputTest
              name='passportIssueDate'
              mask={'99.99.9999'}
              validations={validations.mask}
              classNameInput='auth-form-input profile-form-input'
              classNameValid='input-valid-auth'
              classNameLabel='flex flex-column gap-2'
              classNameError='input-error-auth'
              label={text.passportIssueDate}
              disabled={!isEditing}
              placeholder={placeholder.passportIssueDate}
            />
            <InputTest
              name='residenceAddress'
              validations={validations.registrationAddr}
              classNameInput='auth-form-input profile-form-input'
              classNameValid='input-valid-auth'
              classNameLabel='flex flex-column gap-2'
              classNameError='input-error-auth'
              label={text.residenceAddress}
              disabled={!isEditing}
              placeholder={placeholder.residenceAddress}
            />
          </TabPanel>
          <TabPanel
            header={text.password}
            disabled={isEditing}
            headerClassName='profile-tabs-header'
          >
            <ChangePassword />
          </TabPanel>
        </TabView>
      </div>
    </div>
  );
};

export default IndividualData;
