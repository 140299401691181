import { FC } from 'react';

import {ProjectProgramFamily} from 'assets/icons';
import {ProjectProgramIt} from 'assets/icons';
import {ProjectProgramMilitary} from 'assets/icons';

import React, { useRef, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/scrollbar';

export const MarketProgramSlider: FC = () => {
  return (
    <div className='project-program'>
      <h2 className="project-program__title">Ипотечные программы</h2>

      <Swiper
        loop={true}
        breakpoints={{
          320: {
            slidesPerView: 2,
          },
          744: {
            slidesPerView: 3,
          }
        }}
        spaceBetween={24}
        className="project-program__slider project-program-slider"
      >
        <SwiperSlide className='project-program-slider__item project-program-slider-item'>
          <span className='project-program-slider-item__text'>Семейная ипотека от&nbsp;5%</span>
          <div className="project-program-slider-item__img"><ProjectProgramFamily/></div>
        </SwiperSlide>
        
        <SwiperSlide className='project-program-slider__item project-program-slider-item'>
          <span className='project-program-slider-item__text'>IT Ипотека</span>
          <div className="project-program-slider-item__img"><ProjectProgramIt/></div>
        </SwiperSlide>

        <SwiperSlide className='project-program-slider__item project-program-slider-item'>
          <span className='project-program-slider-item__text'>Военная ипотека</span>
          <div className="project-program-slider-item__img"><ProjectProgramMilitary/></div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};
