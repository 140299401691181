export const USER_GET = 'user/USER_GET';
export const USER_GET_ALL = 'user/USER_GET_ALL';
export const USER_CREATE_LEGAL = 'user/CREATE_LEGAL';
export const USER_FETCH_DOCUMENTS = 'user/USER_FETCH_DOCUMENTS';
export const USER_FETCH_DOCUMENTS_PARTNERS = 'user/USER_FETCH_DOCUMENTS_PARTNERS';

export enum AccountType {
  individualUser = 'INDIVIDUAL_USER',
  legalUser = 'LEGAL_USER',
  blankUser = '',
}

export enum Roles {
  GUEST = 'GUEST',
  CUSTOMER = 'CUSTOMER',
  ADMIN = 'ADMIN',
  PARTNER = 'PARTNER',
}

export interface IUserData {
  id: string;
  name: string;
  role: string;
  email: string;
  regions: string[] 
}

export interface ICommonUser {
  id: string;
  email: string;
  phoneNumber: string;
  roles: Roles;
  name: string;
  referralLink: string;
  accountType: AccountType;
  region: string;
}

export interface IIndividualUser {
  passportSeries: string;
  passportNumber: string;
  passportIssueDate: string;
  passportIssuedBy: string;
  residenceAddress: string;
}

export interface ILegalUser {
  orgName: string;
  inn: string;
  kpp: string;
  ogrn: string;
  bankName: string;
  bankAccount: string;
  correspondentAccount: string;
  bic: string;
}

export interface IUserProfile {
  commonUserData: ICommonUser;
  individualUserData: IIndividualUser;
  legalEntityUserData: ILegalUser;
}

export interface IUsersState {
  user: IUserProfile | null,
  list: {
    data: IUserData[],
    size: Number
  },
  documents: {
    data: [],
    totalPages: Number,
    totalElements: Number,
    pageSize: Number, 
    pageNum: Number
  },
  listSize: 0
}
