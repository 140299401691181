import { FC, useState } from 'react';

import {
  ContractorIcon,
  CustomerIcon,
  LogisticIcon,
  RealtorIcon,
  RepresentativeIcon,
  ProviderIcon
} from 'assets/icons';
import { Action } from '@reduxjs/toolkit';

import { Slider } from 'components/common/Slider';

export const RoleSwitcher: FC<{ changeRole: Action }> = ({ changeRole }) => {

  const [selected, setSelect] = useState(0);

  const rolesList = [{
    label: 'Заказчик',
    value: 'customer',
    element: <CustomerIcon />
  }, {
    label: 'Представитель',
    value: 'representative',
    element: <RepresentativeIcon /> 
  }, {
    label: 'Подрядчик',
    value: 'contractor',
    element: <ContractorIcon />
  }, {
    label: 'Поставщик',
    value: 'provider',
    element: <ProviderIcon />
  }, {
    label: 'Риелтор',
    value: 'realtor',
    element: <RealtorIcon />
  }, {
    label: 'Логист',
    value: 'logistic',
    element: <LogisticIcon />
  }];

  const selRole = (index: number) => {
    setSelect(index);
    changeRole(index);
  }

  return (
    <div className='orders--role-switcher'>
      {
        // rolesList.map((role, key) => {
        //   return <div key={ key } onClick={ () => { selRole(key) } } className='orders--role-switcher_item'>
        //           <div>
        //             <div className='orders--role-switcher_item-label'>
        //               <span>{ role.label }</span>
        //             </div>
        //             <div className={ key === selected ? 'orders--role-switcher_item-img active' : 'orders--role-switcher_item-img' }>
        //               { role.element }
        //             </div>
        //           </div>
        //         </div>
        // })
        <Slider
        sliderAction={ (value) => { selRole(value) } }
        slides={ rolesList }
        customClass={ 'orders-role' }
      />
      }
    </div>
  )
}

