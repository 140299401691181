import { FC } from 'react';

const CompleteDataFinish: FC = () => {
  return (
    <div className='flex flex-column gap-4 justify-content-center'>
      <span className='font-medium text-xl text-center'>Вы уверены?</span>
      <p className='text-center'>
        Вы действительно хотите сохранить <br /> введенные изменения?
      </p>
    </div>
  );
};

export default CompleteDataFinish;
