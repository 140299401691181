import { FC, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';

export const DropdownFilter: FC<{
  fieldName:string,
  options: any,
  selectAction: any,
  className: string
}> = ({ fieldName, options, selectAction, className }) => {

  const [dropdownValue, setDropdownValue] = useState(null);

  const optionTemplate = (option) => {
    if (option?.elem) {
      return (
        <div className="documents-filters-filter-item-dropdown__item">
            {
              option?.elem &&
              <span>{ option?.name }</span>
            }
            { option?.elem }
        </div>
    );
    } else {
      return option?.name;
    }
};

  return (
    <Dropdown
      name={ fieldName }
      options={ options }
      optionLabel='name'
      optionValue='value'
      itemTemplate={optionTemplate}
      value={ dropdownValue }
      placeholder='Все'
      onChange={ e => { setDropdownValue(e.value); selectAction(e.value) } }
      className={`${className} input--new`}
      showClear
      // todo убрать костыль как можно скорее
    />
  )
};