import { createSlice } from '@reduxjs/toolkit';

import {
  fetchOrders,
  getById,
  getArchive,
  getUsersOrder,
  fetchPartnerOrders,
  editLandData,
  edit,
  createOrder,
  getPartnerIssue,
  updatePartnerIssue
} from './actions';

import {
  IOrderState,
  OrderType,
  PaymentStatus,
  OrderStatus
} from './types';

const initialOrders:IOrderState = {
  orders: {
    data: [],
    size: 0
  },
  order: {},
  partnerOrders: {
    data: [],
    size: 0
  },
  partnerIssue: {},
  filters: {
    order: '',
    payment: '',
    date: ''
  },
  type: OrderType.ACTIVE
}

const ordersSlice = createSlice({
  name: 'orders',
  initialState: initialOrders,
  reducers: {
    changeType(state, { payload }) {
      state.type = payload;
    },
    setFilters(state, { payload }) {
      state.filters = {
        ...state.filters,
        ...payload
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrders.fulfilled, (state,  { payload }) => {
        const { data, pageNum, totalElements } = payload;
        if (pageNum === 0) {
          state.orders.data = data;
        } else {
          state.orders.data = state.orders.data.concat(data);
        }

        state.orders.size = totalElements;
      })
      .addCase(getById.fulfilled, (state,  { payload }) => {
        state.order = payload;
      })
      .addCase(getArchive.fulfilled, (state,  { payload }) => {
        const { data, pageNum, totalElements } = payload;
        if (pageNum === 0) {
          state.orders.data = data;
        } else {
          state.orders.data = state.orders.data.concat(data);
        }

        state.orders.size = totalElements;
      })
      .addCase(getUsersOrder.fulfilled, (state,  { payload }) => {
        //state.order = payload;
      })
      .addCase(fetchPartnerOrders.fulfilled, (state,  { payload }) => {
        state.partnerOrders = payload;
        state.order = [];
        const { data, pageNum, totalElements } = payload;
        if (pageNum === 0) {
          state.partnerOrders.data = data;
        } else {
          state.partnerOrders.data = state.partnerOrders.data.concat(data);
        }

        state.partnerOrders.size = totalElements;
        state.orders = {
          data: [],
          size: 0
        };
      })
      .addCase(editLandData.fulfilled, (state,  { payload }) => {
        state.order = payload;
      })
      .addCase(edit.fulfilled, (state,  { payload }) => {
        state.order = payload;
      })
      .addCase(getPartnerIssue.fulfilled, (state,  { payload }) => {
        state.partnerIssue = payload;
      })
      .addCase(updatePartnerIssue.fulfilled, (state,  { payload }) => {
        state.partnerIssue = payload;
      })
  },
});

export const {
  setFilters,
  changeType,
} = ordersSlice.actions;

export default ordersSlice.reducer;