import { createSlice } from '@reduxjs/toolkit';

import { ICommercialState, IMapOffer } from './types';

import {
  getCategory,
  createCategory,
  getTemplate,
  createOffer,
  getOffers,
  getOffer,
  getUserOffer,
  updateOffer,
  fetchNomenclature,
  addNomenclature,
  getAllColumns
} from './actions';

const initialOffers:ICommercialState = {
  list: {
    data: [],
    size: 0
  },
  categories: [],
  selectedCategories: [],
  templates: [],
  offers: [],
  mapOffer: [],
  userOffers: [],
  nomenclature: {},
  nomenclatureColumns: []
}

const mappingOffer = (data) => {
  let tempOffer:IMapOffer = [];

  data.map((offer) => {
    const index = tempOffer.findIndex(temp => temp.categoryId === offer.categoryId);
    if (index === -1) {
      tempOffer.push({
        categoryId: offer.categoryId,
        data: [].concat(offer) 
      })
    } else {
      tempOffer[index].data.push(offer);
    }
  })

  return tempOffer;
}

const commercialSlice = createSlice({
  name: 'commercial',
  initialState: initialOffers,
  reducers: {
    setCategory(state, { payload }) {
      state.selectedCategories = payload;
    },
    clearTemplates(state, { payload }) {
      state.templates = state.templates.filter(template => template.categoryId !== payload);
    },
    clearNomenclature(state) {
      state.nomenclature = {};
    },
    clearCommercial(state) {
      return initialOffers;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategory.fulfilled, (state,  { payload }) => {
        state.categories = payload.data;
      })
      .addCase(createCategory.fulfilled, (state,  { payload }) => {
      })
      .addCase(getTemplate.fulfilled, (state,  { payload }) => {
        state.templates.push(payload);
      })
      .addCase(createOffer.fulfilled, (state,  { payload }) => {
      })
      .addCase(getUserOffer.fulfilled, (state,  { payload }) => {
        state.mapOffer = mappingOffer(payload.data);
        state.userOffers = payload.data;
      })
      .addCase(getOffers.fulfilled, (state,  { payload }) => {
        const { data, pageNum, totalElements } = payload;
        if (!pageNum) {
          state.list.data = payload.data;  
        } else {
          state.list.data = state.list.data.concat(data);
        }

        state.list.size = totalElements;
      })
      .addCase(getOffer.fulfilled, (state,  { payload }) => {
        state.mapOffer = mappingOffer(payload.data);
        state.offers = payload.data;
      })
      .addCase(updateOffer.fulfilled, (state,  { payload }) => {
        state.mapOffer = mappingOffer(payload.data);
        state.offers = payload.data;
      })
      .addCase(fetchNomenclature.fulfilled, (state,  { payload }) => {
        state.nomenclature = payload;
      })
      .addCase(addNomenclature.fulfilled, (state,  { payload }) => {
      })
      .addCase(getAllColumns.fulfilled, (state,  { payload }) => {
        state.nomenclatureColumns = payload?.data;
      })
    }
});

export const { setCategory, clearTemplates, clearCommercial, clearNomenclature } = commercialSlice.actions;

export default commercialSlice.reducer;