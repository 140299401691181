import { request } from "./api";
import { IUser } from 'models/user-auth.model';
import { API_ROUTES } from 'config'

import { ILoginData } from 'store/auth/types';

export const authApi = {
  login: (data: ILoginData) => request.post<void>(API_ROUTES.auth.login, data),
  registration: (data: IUser) => request.post<void>(API_ROUTES.auth.registration, data),
  passwordRestore: (data: any) => request.post<void>(API_ROUTES.auth.passwordRestore, data),
  setPassword: (data: any) => request.put<void>(API_ROUTES.auth.password, data),
  requestEmailConfirmation: (id) => request.post<void>(API_ROUTES.auth.sendEmailConfirmation.replace('{authUserId}', id), {}),
  emailConfirmation: (params) => request.get<void>(API_ROUTES.auth.emailConfirmation, params),
  refreshToken: (token: any) => request.post<void>(API_ROUTES.auth.refreshToken, {refreshToken: token }),
};