import { FC, useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from 'store';
import { IProjectItem } from 'store/projects/types';
import { useParams, useNavigate } from 'react-router-dom';
import { ProjectArea, ProjectFloor, ProjectFront } from 'assets/icons';

import { fetchProjectTree, fetchProjectImages } from 'store/projects/actions';

import { putInCart } from 'store/carts/actions';

import { routesPath } from 'routes';

export const MarketOptions: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isShowOptions, setShowOptions] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [sendingImgId, setSendingImgId] = useState('');

  const { token } = useAppSelector(state => state.auth);

  const { project, projectData } = useAppSelector(state => state.projects);

  const params = useParams();
  const { id } = params;

  useEffect(() => {
    const projectImageId = project?.images?.filter((image) => {
      if (image.type === 'EXTERIOR') {
        return image?.fileInfo?.fileId
      }
    })
    const id = projectImageId?.length ? projectImageId[0]?.fileInfo?.fileId: '';
    setSendingImgId(id)
  }, [project.images]);

  const changeSelection = (title, option) => {
    let tempSelect = structuredClone(option);
    tempSelect.selected = true;
    let newSelected = selectedOptions;
    newSelected[title] = tempSelect;

    setSelectedOptions(newSelected);

    dispatch(fetchProjectTree({
      projectId: id,
      selectedOptions: Object.values(newSelected)
    }))
  }

  const inCart = async () => {
    const optionsToRequest = project?.options.map((option) => {
      return {
        option: option.title,
        variant: option?.options?.filter((item) => {
          return item?.selected === true;
        })[0]?.title
      }
    });

    const sendData = {
      count: 1,
      projectId: id,
      pricePerSingleProject: project?.price,
      options: optionsToRequest,
      projectConfigImageFileId: sendingImgId
    };

    if (token) {
      await dispatch(putInCart(sendData))
      navigate(routesPath.cart);
    } else {
      window.localStorage.setItem('CART_DATA', JSON.stringify(sendData));
      navigate(routesPath.cart);
    }
  }

  return (
    <div className='project-options'>
      <div className="project-options__wrapper">
        <div className='project-options__header project-options-header'>
          <h1 className='project-options-header__title'>{ projectData?.model } </h1>
          <span className='project-options-header__amount'>{ project?.price ? (project?.price?.toFixed()).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 '): '' } ₽ </span>
        </div>
        <h2 className='project-options__subtitle'>Выбор комплектации</h2>


        <div className='project-options__list project-options-list'>
          {
            project?.options?.map((option, key) => {
              return <div className='project-options-list__item list-item' key={ key }>
                      { !isShowOptions && key < 2 &&
                        <>
                          <span className='list-item__name'>{ option.title }</span>
                          <div className='list-item__options list-item-options'>
                            {
                              option?.options?.map((opt, index) => {
                                return <button
                                  onClick={ () => { changeSelection(option.title, opt) } }
                                  disabled={ !opt?.available }
                                  className={ opt.selected === true ? 'list-item-options__button list-item-options__button--active': 'list-item-options__button' }
                                  >{ opt?.title }</button>
                              })
                            }
                          </div>
                        </>
                      }
                      {
                        !isShowOptions && key === 2 &&
                          <span className='list-item__showmore' onClick={ () => { setShowOptions(true) } }>Развернуть</span>
                      }
                      {
                        isShowOptions &&
                          <>
                          <span className='list-item__name'>{ option.title }</span>
                          <div className='list-item__options list-item-options'>
                            {
                              option?.options?.map((opt, index) => {
                                return <button
                                  onClick={ () => { changeSelection(option.title, opt) } }
                                  disabled={ !opt?.available }
                                  className={ opt.selected === true ? 'list-item-options__button list-item-options__button--active': 'list-item-options__button' }
                                >{ opt?.title }</button>
                              })
                            }
                          </div>
                          </>
                      }
                      {
                        isShowOptions && key === project?.options.length - 1 &&
                          <span className='list-item__showmore list-item__showmore--close' onClick={ () => { setShowOptions(false) } }>Свернуть</span>
                      }
                    </div>
            })
          }
            
        </div>

        <div className='project-options__summary project-options-summary'>
          <h3 className='project-options-summary__title'>Краткие характеристики</h3>
          <div className='project-options-summary__item project-options-summary-item'>
            <span className='project-options-summary-item__label'>Площадь дома, включая террасы и балконы</span>
            <span className='project-options-summary-item__value'>{ project?.specs?.totalArea }&nbsp;м²</span>
          </div>
          <div className='project-options-summary__item project-options-summary-item'>
            <span className='project-options-summary-item__label'>Жилая площадь</span>
            <span className='project-options-summary-item__value'>{ project?.specs?.livingArea }&nbsp;м²</span>
          </div>
          <div className='project-options-summary__item project-options-summary-item'>
            <span className='project-options-summary-item__label'>Количество комнат (в зависимости от выбранной планировки)</span>
            <span className='project-options-summary-item__value'>{ project?.specs?.roomsCount }</span>
          </div>
          <div className='project-options-summary__item project-options-summary-item'>
            <span className='project-options-summary-item__label'>Этажей</span>
            <span className='project-options-summary-item__value'>{ projectData?.floorsCount }</span>
          </div>
        </div>
      </div>
      <div className="project-options__button" onClick={ () => { inCart() } }>Купить</div>
    </div>
  );
};