import { FC, useState, useEffect } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import moment from 'moment';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';

import InputTest from 'components/common/InputTest';
import { validations } from 'helpers/validations';

export const LogisticForm: FC<{logistic, submitAction}> = ({ logistic, submitAction }) => {
  const [isShowCalendar, setShowCalendar] = useState(false);
  const [date, setDate] = useState(false);
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      fullName: logistic?.fullName,
      brand: logistic?.carInfo?.brand,
      model: logistic?.carInfo?.model,
      number: logistic?.carInfo?.number
    },
  });
  const { setValue } = useForm();

  useEffect(() => {
    setValue('arrivalDate', moment(date).format('L').replaceAll('/', '.'));
  }, [date]);

  const onSubmit = (data: any) => {
    submitAction(data);
  };

  return (
    <div className='flex justify-content-center flex-wrap'>
      <FormProvider {...methods}>
        <form
          className='order--form auth-form auth-form--login p-d-flex p-jc-center p-mt-2 w-8'
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <div className='flex justify-content-center flex-wrap gap-2'>
            <span className='auth-form-title'>
              Редактировать логиста
            </span>
          </div>
          <div className='flex flex-column gap-2'>
            <InputTest
              name='fullName'
              type='text'
              validations={validations.name}
              classNameInput='auth-form-input'
              classNameLabel='auth-form-label'
              classNameValid='input-valid-auth'
              classNameError='input-error-auth'
              placeholder='Свинтицкий Станислав Ст|'
              label='ФИО водителя'
            />
          </div>
          <div className='flex flex-column gap-2'>
            <InputTest
              name='brand'
              type='text'
              validations={validations.name}
              classNameInput='auth-form-input auth-form-login'
              classNameLabel='auth-form-label'
              classNameValid='input-valid-auth'
              classNameError='input-error-auth'
              placeholder='ГАЗ ГАЗель next 20'
              label='Марка машины'
            />
          </div>
          <div className='flex flex-column gap-2'>
            <InputTest
              name='model'
              type='text'
              validations={validations.name}
              classNameInput='auth-form-input auth-form-login'
              classNameLabel='auth-form-label'
              classNameValid='input-valid-auth'
              classNameError='input-error-auth'
              placeholder='ГАЗ ГАЗель next 20'
              label='Модель машины'
            />
          </div>
          <div className='flex flex-column gap-2'>
            <InputTest
              name='number'
              type='text'
              validations={validations.required}
              classNameInput='auth-form-input auth-form-login'
              classNameLabel='auth-form-label'
              classNameValid='input-valid-auth'
              classNameError='input-error-auth'
              placeholder='О123'
              label='Номер машины'
            />
          </div>
          <div className='flex justify-content-center flex-wrap align-items-stretch'>
            <Button
              className='auth-form-login-button flex align-items-center justify-content-center'
              label='Сохранить изменения'
              type='submit'
            />
          </div>
          
        </form>
      </FormProvider>
    </div>
  );
};
