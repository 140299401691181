import { FC, useState, useEffect } from 'react';

export const MarketCalculator: FC = () => {

  const [amount, setAmount] = useState(500000);
  const [contribution, setContribution] = useState(0);
  const [creditTerm, setCreditTerm] = useState(30);

  const [monthCredit, setMonthCredit] = useState(0);
  const [monthCredit2, setMonthCredit2] = useState(0);

  const [overPayment, setOverPayment] = useState(0);

  useEffect(() => {
    calculate();
  }, [amount]);

  useEffect(() => {
    calculate();
  }, [contribution]);

  useEffect(() => {
    calculate();
  }, [creditTerm]);

  const calculate = () => {
    // let summa = +$('input[name="summa"]').val();//Стоимость недвижимости
    // let vznos = +$('input[name="vznos"]').val();//Первоначальный взнос
    // let srok = +$('input[name="term"]').val();//Срок кредита

    let percent = 16; // Фиксированная процентная ставка
    let percent2 = 5; // Новая процентная ставка
    let creditSumm = amount - contribution; //Сумма кредита
    if (creditSumm < 0) {
      creditSumm = 0;
    }

    let monthPercent = percent/12/100; //ЕЖЕМЕСЯЧНАЯ_СТАВКА
    let monthPercent2 = percent2/12/100; //Новая ЕЖЕМЕСЯЧНАЯ_СТАВКА

    let srock = creditTerm * 12;

    let degree = Math.pow( 1+monthPercent , -srock);  //ОБЩАЯ_СТАВКА
	  let degree2 = Math.pow( 1+monthPercent2 , -srock);  //Новая ОБЩАЯ_СТАВКА


    let monthCredit = creditSumm*(monthPercent/(1-degree));//ЕЖЕМЕСЯЧНЫЙ_ПЛАТЕЖ
		let monthCredit2 = creditSumm*(monthPercent2/(1-degree2));

    let finalSumm = monthCredit*srock;//Итоговая сумма
    let finalSumm2 = monthCredit2*srock;//Итоговая сумма

    let percentSumm = finalSumm - creditSumm;//Сумма по Процентам
    let percentSumm2 = finalSumm2 - creditSumm;//Сумма по Процентам

    let income = monthCredit*1.6667; //Необходимый доход

    let pereplata = percentSumm - creditSumm; // Вычисляем переплату
    let eco = percentSumm - percentSumm2; // Вычисляем переплату

    monthCredit = (monthCredit.toFixed()).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
    monthCredit2 = (monthCredit2.toFixed()).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
    creditSumm = (creditSumm.toFixed()).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
    finalSumm = (finalSumm.toFixed()).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
    percentSumm = (percentSumm.toFixed()).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
    percentSumm2 = (percentSumm2.toFixed()).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
    income = (income.toFixed()).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
    pereplata = (pereplata.toFixed()).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
    eco = (eco.toFixed()).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');

    setMonthCredit(monthCredit);
    setMonthCredit2(monthCredit2);

    setOverPayment(pereplata)
  }
  return (
    <div className='project-calculator'>
      <h2 className="project-calculator__title">Ипотечный калькулятор *</h2>
      <div className="project-calculator__wrapper">

        <div className='project-calculator__bar project-calculator-bar'>
          <div className="project-calculator-bar__price project-calculator-bar-price">
            <label className="project-calculator-bar-price__lable">
              Стоимость квартиры
              <input
                value={ amount }
                onChange={ (e) => { setAmount(e.target.value) } }
                type="text"
                placeholder='4 532 521 ₽'
                className="project-calculator-bar-price__input input--new"
              />
            </label>
          </div>
          <div className="project-calculator-bar__initial project-calculator-bar-initial">
            <label className="project-calculator-bar-initial__lable">
              Первоначальный взнос
              <input
                value={ contribution }
                onChange={ (e) => { setContribution(e.target.value) } }
                type="text"
                placeholder='1 359 756 ₽'
                className="project-calculator-bar-initial__input input--new"
              />
            </label>
            <p className="project-calculator-bar-initial__text">
              Максимальная сумма кредита - 6 000 000 Р, в случае превышения первоначальный взнос будет автоматически увеличен.
            </p>
          </div>
          <div className="project-calculator-bar__time project-calculator-bar-time">
            <label className="project-calculator-bar-time__lable">
              Срок кредита
              <input
                value={ creditTerm }
                onChange={ (e) => { setCreditTerm(e.target.value) } }
                type="text"
                placeholder='30 лет'
                className="project-calculator-bar-time__input input--new"
              />
            </label>
          </div>
        </div>

        <div className='project-calculator__standard project-calculator-standard'>
          <div className="project-calculator-standard__wrapper">
            <h3 className="project-calculator-standard__title">Стандартная ипотека</h3>

            <div className="project-calculator-standard__percent project-calculator-standard-percent">
              <span className="project-calculator-standard-percent__title">Процентная ставка</span>
              <span className="project-calculator-standard-percent__value">16%</span>
            </div>

            <div className="project-calculator-standard__payment project-calculator-standard-payment">
              <span className="project-calculator-standard-payment__title">Ежемесячный платеж</span>
              <span className="project-calculator-standard-payment__value">{ monthCredit } ₽ </span>
              <div className="project-calculator-standard-payment__subtitle project-calculator-standard-payment__subtitle--check">Больше на 1 828 ₽</div>
            </div>

            <div className="project-calculator-standard__overpayment project-calculator-standard-overpayment">
              <span className="project-calculator-standard-overpayment__title">Переплата</span>
              <span className="project-calculator-standard-overpayment__value">{ overPayment } ₽ </span>
              <div className="project-calculator-standard-overpayment__subtitle project-calculator-standard-payment__subtitle--check">Больше на 1 102 267 ₽ </div>
            </div>

            <div className="project-calculator-standard__saving project-calculator-standard-saving">
              <span className="project-calculator-standard-saving__value">0 ₽</span>
              <span className="project-calculator-standard-saving__text">Без экономии</span>
            </div>

          </div>
        </div>

        <div className='project-calculator__reduced project-calculator-reduced'>
          <div className="project-calculator-reduced__wrapper">
            <h3 className="project-calculator-reduced__title">Ипотека 3%</h3>

            <div className="project-calculator-reduced__percent project-calculator-reduced-percent">
              <span className="project-calculator-reduced-percent__title">Процентная ставка</span>
              <span className="project-calculator-reduced-percent__value">5%</span>
            </div>

            <div className="project-calculator-reduced__payment project-calculator-reduced-payment">
              <span className="project-calculator-reduced-payment__title">Ежемесячный платеж</span>
              <span className="project-calculator-reduced-payment__value">{ monthCredit2 } ₽ </span>
              <div className="project-calculator-reduced-payment__subtitle project-calculator-reduced-payment__subtitle--check">Больше на 1 828 ₽</div>
            </div>

            <div className="project-calculator-reduced__overpayment project-calculator-reduced-overpayment">
              <span className="project-calculator-reduced-overpayment__title">Переплата</span>
              <span className="project-calculator-reduced-overpayment__value">{ overPayment } ₽ </span>
              <div className="project-calculator-reduced-overpayment__subtitle project-calculator-reduced-payment__subtitle--check">Больше на 1 102 267 ₽ </div>
            </div>

            <div className="project-calculator-reduced__saving project-calculator-reduced-saving">
              <span className="project-calculator-reduced-saving__value">467 714 ₽</span>
              <span className="project-calculator-reduced-saving__text">Экономия</span>
            </div>

          </div>
        </div>
      </div>
      <p className="project-calculator__description">
        * Все расчеты приведены исключительно в аналитических целях, не являются индивидуальным кредитным предложением.
      </p>
    </div>
  );
};