import { Button } from 'primereact/button';
import { useRef } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import staticData from 'common/static.json';
import { useAppSelector } from 'store';

const text = staticData.referral;

const FirstStep: React.FC = () => {
  const { info } = useAppSelector(state => state.referral);
  const link = info.referralLink;
  const op = useRef<any>(null);

  const copyToClipBoard = (e: React.MouseEvent<HTMLElement>) => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        op.current.toggle(e);
        setTimeout(() => {
          op.current.hide(e);
        }, 1500);
      })
      .catch(error => {
        console.log(error.message);
      });
  };

  return (
    <div className='referral-wrapper flex flex-column gap-3'>
      <div className='text-2xl font-bold'>
        Получайте <span className='referral-percent vertical-align-text-top'>{`${info.percent}%`}</span> c каждой покупки
      </div>
      <span>{text.info}</span>
      <span>{text.firstStep}</span>
      <span className='text-xl font-medium'>{text.shareLink}</span>
      <div className='referral-block max-w-max flex flex-wrap align-items-center gap-3'>
        <span>{link}</span>
        <Button
          className='button-green'
          label={text.copy}
          onClick={copyToClipBoard}
        />
        <OverlayPanel ref={op}>
          <span>{text.copied}</span>
        </OverlayPanel>
      </div>
      <span className='text-sm'>{text.agreement}</span>
    </div>
  );
};

export default FirstStep;
