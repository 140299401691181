import { FC, useState } from 'react';
import { Dialog } from 'primereact/dialog';

import { DocumentsItem } from './DocumentsItem';
import { DocumentsPreview } from './DocumentsPreview';

import { useAppSelector, useAppDispatch } from 'store';

import { getDocumentFile, getFilledDocumentId } from 'store/documents/actions';

export const DocumentsList: FC<{ removeFile: any, documentStatus:string }> = ({ removeFile, documentStatus }) => {
  const dispatch = useAppDispatch();
  const { documents } = useAppSelector(state => state.documents);
  const [isShowDocument, setShowDocument] = useState(false);
  const [currentDocument, setCurrentDocument] = useState();

  const showDocument = async (document) => {
    setCurrentDocument(document);

    if (documentStatus === 'TYPICAL') {
      await dispatch(getDocumentFile(document?.fileId));  
    } else if (documentStatus === 'FILLED') {
      await dispatch(getFilledDocumentId(document?.fileId));
    }
    setShowDocument(true);
  }

  return (
    <>
      <Dialog
        visible={ isShowDocument }
        onHide={ () => setShowDocument(false) }
        header=""
        className='documents-modal documents__modal'
      >
        <DocumentsPreview />
        {
            currentDocument?.status === 'WAITING_FOR_SENDING' &&
            <div className="documents-modal__button">Ожидает отправки</div>
          }
          {
            currentDocument?.status === 'ONLY_FOR_READING' &&
            <div className="documents-modal__button">Только для чтения</div>
          }
          {
            currentDocument?.status === 'WAITING_FOR_SIGNING' &&
            <div className="documents-modal__button">Ожидает подписания</div>
          }
          {
            currentDocument?.status === 'FIRST_PARTICIPANT_SIGNED' &&
            <div className="documents-modal__button">1 подписант</div>
          }
          {
            currentDocument?.status === 'SECOND_PARTICIPANT_SIGNED' &&
            <div className="documents-modal__button">2 подписанта</div>
          }
        {/* <div className="documents-modal__button">Документ подписан</div> */}
      </Dialog>
      <div className='documents-list documents__list'>
        {
          documents.data.map((document, key) => {
            return <DocumentsItem
                      showDocument={ (id: string) => { showDocument(document) } }
                      removeFile={ removeFile }
                      key={ key }
                      document={ document }
                      documentStatus={ documentStatus }
                    />
          })
        }
      </div>
    </>
  )
};