import { FC } from 'react';


export const TermsPartnership: FC = () => {
  return (
    <div className='terms-partnership'>
      <h1 className="terms-partnership__title">Партнерское соглашение</h1>
      <ol className="terms-partnership__list terms-partnership-list">
        <li className='terms-partnership-list__item terms-partnership-list__item--title'>
          <span className='underline'>Определения</span>
          <ol className="terms-partnership__list terms-partnership-list terms-partnership-list--2">
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
                <span className='bold'>Авторизация в Личном кабинете</span> – ввод Партнером Логина и Пароля от Личного кабинета Партнера
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              <span className="bold">Авторизованный Партнер</span> – Партнер, прошедший авторизацию на Сайте с помощью Логина и Пароля, предоставленных ему Владельцем сайта.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              <span className="bold">Владелец сайта</span> – юридическое лицо, Общества с ограниченной ответственностью «КАЛИТКА» (ОГРН 1245400015190; ИНН 5406840176), осуществляющее администрирование Сайта, являющееся стороной настоящего Соглашения. Место нахождения: 630004, Новосибирская область, г. Новосибирск, пр-кт Димитрова, д. 4/1.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              <span className="bold">Дилерский договор</span> – договор, заключенный между Партнером и одним из ____________________ на реализацию товаров, производимых и реализуемых одним из ______________.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              <span className="bold">Заказ</span> – действия Пользователя по оформлению заявки на приобретение Товара (Товаров) на Сайте у соответствующего Партнера, включающие в себя выбор Товара, его параметров (при наличии) и количества.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              <span className="bold">Карточка Товара</span> – страница Сайта, содержащая информацию о конкретном Товаре, а также о Товарном предложении, в случае его размещения Партнером.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              <span className="bold">Корзина</span> – Сервис Сайта, позволяющий сохранять выбранные Пользователем Товары до момента формирования Заказа.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              <span className="bold">Личный кабинет Партнера</span> – персональный раздел Партнера, в виде защищенных страниц Сайта, доступ к которым возможен только при вводе Партнером Учетных данных, в котором Партнеру доступно управление отдельными Сервисами Сайта на предложенных Владельцем сайта условиях. Предоставление доступа к персональному разделу Партнера регулируется настоящим Соглашением.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              <span className="bold">Личный кабинет Пользователя</span> – персональный раздел Пользователя, представленный в виде защищенных страниц Сайта, доступ к которым возможен только при вводе Учетных данных и после завершения Регистрации Пользователя, в котором Пользователю доступно управление отдельными Сервисами Сайта на предложенных Владельцем сайта условиях.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              <span className="bold">Логин</span> – уникальное сочетание символов, используемое Партнером на Сайте в целях доступа к Личному кабинету Партнера. Логин предоставляется Партнеру ответственным лицом Владельца сайта.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              <span className="bold">Пароль</span> – уникальное сочетание символов, позволяющее при одновременном вводе с Логином войти в Личный кабинет Партнера. Пароль предоставляется Партнеру ответственным лицом Владельца сайта.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              <span className="bold">Партнер</span> – лицо (индивидуальный предприниматель или юридическое лицо), заключившее дилерский договор с _____________________ в России и осуществляющее деятельность по продаже Товаров Пользователям дистанционным способом с использованием Сайта от своего имени и за свой счет.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              <span className="bold">Персональные данные</span> – любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              <span className="bold">Пользователь</span> – лицо, не имеющее законодательных ограничений для акцепта Пользовательского Соглашения, осуществившее доступ к Сайту и/или использующее Сайт, любые его Сервисы, функции и/или возможности, независимо от факта Регистрации на Сайте.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              <span className="bold">Регистрационные данные Партнера</span> – информация о Партнере, используемая для Регистрации на Сайте в соответствии с п. 3.3 настоящего соглашения, включая Учетные данные, адрес электронной почты и пр., а также все изменения и дополнения такой информации, указанные Партнером непосредственно в Личном кабинете.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              <span className="bold">Регистрация Партнера</span> – совокупность действий Владельца сайта в соответствии с указанными на Сайте инструкциями, включая предоставление Регистрационных данных, а также иной информации, совершаемых с использованием специальной формы пользовательского интерфейса Сайта в целях формирования Личного кабинета Партнера и получения доступа к отдельным сервисам Сайта, доступным Партнерам.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              <span className="bold">Сайт</span> – совокупность программных и аппаратных средств для ЭВМ, обеспечивающих публикацию для обозрения информации и данных, объединенных общим целевым назначением, посредством технических средств, применяемых для связи между ЭВМ в сети Интернет, расположенный по адресу: _______________. Сайт предоставляет доступ Пользователям к информации о товарах и услугах Партнера, а также функциональные возможности Сайта (Сервисы Сайта), направленные на обеспечение эффективности использования Сайта / подбора лучшего предложения товаров и услуг от Партнеров, включая, но не ограничиваясь: средства поиска Товаров, Корзина, Регистрация и Авторизация на Сайте, Личный кабинет.          
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              <span className="bold">Соглашение</span> – настоящее Партнерское соглашение об использовании Сайта, заключаемое между Владельцем сайта и Партнером.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              <span className="bold">Стороны</span> – Владелец сайта и Партнер при их совместном упоминании, а Сторона – любой из них при упоминании по отдельности.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              <span className="bold">Страница Партнера</span> – страница Сайта, содержащая информацию о Партнере, в том числе информацию о способах оплаты и условиях доставки, условиях возврата Товара, а также иную информацию, являющуюся существенной для формирования Заказа и приобретения Пользователем Товаров у Партнера. Страница Партнера доступна по ссылке, размещенной на странице «Точки продаж» или в Корзине при выборе Товарных предложений.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              <span className="bold">Товар (Продукт)</span> – строительно-отделочные материалы, информация о которых представлена Владельцем сайта на Сайте.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              <span className="bold">Товарное предложение</span> – адресованное Пользователям предложение Товара, реализуемого Партнером, размещаемое на Сайте в Карточке Товара.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              <span className="bold">Учётная запись Партнера</span> – хранимая в компьютерной системе совокупность данных о Партнере, необходимая для его опознавания (аутентификации) и предоставления доступа к данным и параметрам Личного кабинета Партнера.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              <span className="bold">Уникальный идентификатор (ID)</span> – уникальный идентификационный номер, который присваивается Партнеру Владельцем Сайта при создании Учетной записи Партнера и закрепляется за Партнером в соответствии с Дополнительным соглашением к Дилерскому договору.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              <span className="bold">Услуги</span> – услуги, предоставленные на Сайте и оказываемые Партнерами, включая, ноне ограничиваясь: услуги по доставке Товара, услуги по разгрузке Товара, услуги по подъему Товара на этаж, переносу Товара.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              <span className="bold">Учетные данные</span> – уникальный Логин и Пароль Партнера, используемые для доступа Партнера в Личный кабинет Партнера, а также для идентификации Партнера в качестве стороны настоящего Соглашения при предоставлении функциональных возможностей (Сервисов Сайта). Учетные данные в совокупности признаются Сторонами аналогом собственноручной подписи Партнера при заключении настоящего Соглашения и оформлении уведомлений, передачи информации, а также совершении иных действий.
            </li>
          </ol>
        </li>
        
        <li className='terms-partnership-list__item terms-partnership-list__item--title'>
          <span className='underline bold'>Предмет соглашения</span>
          <ol className="terms-partnership__list terms-partnership-list terms-partnership-list--2">
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              По настоящему Партнерскому соглашению (далее по тексту – «Соглашение») Владелец сайта предоставляет Партнеру возможность использовать Сайт с целью реализации Товаров, производимых и реализуемых предприятиями _______________. Принимая условия настоящего Соглашения, Партнер уведомлен и согласен с тем, что:
              <ul className='terms-partnership-list terms-partnership-list--marker'>
                <li className='terms-partnership-list__item'>
                  Сайт создан с целью предоставления доступа Пользователям к информации о Партнерах, в том числе к контактной информации Партнера, информации о Товарах, предлагаемых Партнерами, условиях доставки и оплаты, установленных Партнерами, а также с целью помочь Пользователям в поиске максимально выгодного предложения, соответствующего запросу Пользователя по качеству, количеству, характеристикам и иных опциям.
                </li>
                <li className='terms-partnership-list__item'>
                  Сайт является площадкой, позволяющей Партнерам самостоятельно размещать предложения о продаже Товара в виде Товарных предложений, адресованных неопределенному кругу лиц на совершение сделки купли-продажи дистанционным способом в отношении Товара, а Пользователям - принимать такие предложения, размещенные на Сайте Партнерами, направляя Заказ Партнеру. При этом Владелец сайта не является стороной сделки, заключаемой между Партнёром и Пользователем, агентом или представителем Партнера и/или представителем какого-либо Пользователя и/или иным заинтересованным лицом в отношении заключаемой между Пользователем и Партнером сделки.
                </li>
                <li className='terms-partnership-list__item'>
                  Функционал Сайта предоставляет Партнерам возможность предлагать Товары Пользователям, принимать и обрабатывать Заказы, поступающие от Пользователей.
                </li>
                <li className='terms-partnership-list__item'>
                  Владелец сайта не определяет условия, по которым Партнеры осуществляют продажу Товаров, не дает Партнеру никаких гарантий относительно объемов продаж Товаров через Сайт, а также не несет ответственности за действия/бездействия Пользователей при заказе / оплате Товаров.
                </li>
                <li className='terms-partnership-list__item'>
                  Владелец сайта не участвует в процессе оплаты Товаров и Услуг, предоставляемых Партнерами, не предоставляет возможность проведения оплаты за Товары и услуги с использованием Сайта, а также не принимает никакие платежи от Пользователей сайта и не осуществляет перевод платежей от Пользователей Партнерам.
                </li>
                <li className='terms-partnership-list__item'>
                  Все взаимоотношения, связанные с приобретением Товаров и Услуг, возникают непосредственно между Партнером и Пользователем.
                </li>
              </ul>
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Владелец сайта вправе устанавливать условия, правила и ограничения использования функциональных возможностей Сайта (Сервисов Сайта). Владелец сайта вправе в любое время пересматривать или изменять условия предоставления Сервисов Сайта, дополнять, изменять, ограничивать и расширять их, в том числе условия доступа Партнера к Сервисам Сайта.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Владелец сайта вправе привлекать иных лиц для выполнения своих обязательств по настоящему Соглашению.
            </li>
          </ol>
        </li>

        <li className='terms-partnership-list__item terms-partnership-list__item--title'>
          <span className='underline'>Регистрация Партнера. Личный кабинет Партнера</span>
          <ol className="terms-partnership__list terms-partnership-list terms-partnership-list--2">
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Регистрация Партнера осуществляется Владельцем сайта путем создания  Учетной записи Партнера и предоставления Партнеру Логина и Пароля для входа в Личный кабинет Партнера. Регистрация Партнера и предоставление ему Логина/Пароля производится по исключительному усмотрению Владельца сайта после обращения Партнера по электронной почте или иными способами к Владельцу сайта с просьбой о регистрации на Сайте. Владелец Сайта не обязан уведомлять Партнера об отказе в регистрации на Сайте, если Владельцем Сайта по итогам рассмотрения просьбы Партнера будет принято соответствующее решение.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
            Каждой Учетной записи Партнера присваивается Уникальный идентификатор (ID), который отражается в дополнительном соглашении к дилерскому договору с Партнером.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Для использования Сайта Партнер обязан внести всю необходимую информацию в Личный кабинет, включая:


              <ol className="terms-partnership__list terms-partnership-list terms-partnership-list--3">
                <li className="terms-partnership-list__item terms-partnership-list__item--3">
                  Информацию о Партнере: фирменное наименование (наименование), местонахождение (адрес), телефон, электронная почта, государственный регистрационный номер записи о создании юридического лица, государственный регистрационный номер записи о государственной регистрации физического лица в качестве индивидуального предпринимателя, ИНН, ОГРН/ОГРНИП.
                </li>
                <li className="terms-partnership-list__item terms-partnership-list__item--3">
                  Информацию о контактном лице Партнера, в том числе: фамилия, имя, отчество, телефон, населенный пункт.
                </li>
                <li className="terms-partnership-list__item terms-partnership-list__item--3">
                  Дополнительную информацию: адрес сайта, логотип (при наличии), иная информация.
                </li>
                <li className="terms-partnership-list__item terms-partnership-list__item--3">
                  Информацию о точках продаж (складах, пунктах выдачи), в том числе: название, адрес, режим работы.
                </li>
                <li className="terms-partnership-list__item terms-partnership-list__item--3">
                  Информацию о возможных способах оплаты Заказа.
                </li>
                <li className="terms-partnership-list__item terms-partnership-list__item--3">
                  Информацию о способах, сроках и стоимости доставки и возврата Товара.
                </li>
              </ol>
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
            Инструкция по работе с Личным кабинетом Партнера доступна в Личном кабинете Партнера по адресу: ___________________.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
            Партнер обязан поддерживать актуальность и достоверность информации, указанной в Личном кабинете.
            </li>
          </ol>
        </li>







        <li className='terms-partnership-list__item terms-partnership-list__item--title'>
          <span className='underline bold'>Предмет соглашения</span>
          <ol className="terms-partnership__list terms-partnership-list terms-partnership-list--2">
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Партнеру предоставляются функциональные возможности Сайта для размещения Товарных предложений Партнера на следующих условиях:
              <ol className="terms-partnership__list terms-partnership-list terms-partnership-list--3">
                <li className="terms-partnership-list__item terms-partnership-list__item--3">
                  Функциональные возможности Сайта применимы только в отношении Товаров, размещенных Владельцем сайта на Сайте.
                </li>
                <li className="terms-partnership-list__item terms-partnership-list__item--3">
                  Размещение Товарных предложений на Сайте осуществляется Партнером с использованием Личного кабинета Партнера двумя способами:
                  <ul className='terms-partnership-list terms-partnership-list--marker'>
                    <li className='terms-partnership-list__item'>
                      Партнер выбирает Товары из списка Товаров Владельца сайта, представленных на Сайте, устанавливает цену Товара и количество Товара в наличии.
                    </li>
                    <li className='terms-partnership-list__item'>
                      Партнер загружает файл в указанном Владельцем Сайта на Сайте формате с перечнем Товарных предложений в Личном кабинете Партнера.
                    </li>
                  </ul>
                </li>
              </ol>
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Отбор Товарных предложений для показа в Корзине Пользователя на Сайте осуществляется в соответствии с установленными Владельцем сайта критериями релевантности, в том числе:
              <ul className='terms-partnership-list terms-partnership-list--marker'>
                <li className='terms-partnership-list__item'>
                  Регион
                </li>
                <li className='terms-partnership-list__item'>
                  Стоимость всех Товаров в Корзине
                </li>
                <li className='terms-partnership-list__item'>
                  Срок доставки
                </li>
                <li className='terms-partnership-list__item'>
                  Стоимость доставки
                </li>
              </ul>
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Пользователь выбирает Товарное предложение самостоятельно из списка Товарных предложений всех Партнеров, которые соответствуют установленным Пользователем фильтрам и имеют в наличии все Товары из Корзины. Если Товарные предложения, соответствующие заданным Пользователем фильтрам отсутствуют, в списке Товарных предложений всех Партнеров отображаются Партнеры, у которых имеется в наличии часть Товаров добавленных Пользователем в Корзины (в порядке убывания количества Товаров в наличии у Партнеров). 
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Цена товара, реализуемого на Сайте Партнером, указывается в рублях Российской Федерации и включает в себя налог на добавленную стоимость в случае, если соответствующий Партнер применяет общую систему налогообложения, либо не включает НДС, если соответствующий Партнер использует упрощенную систему налогообложения.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Цена Товара не включает в себя стоимость доставки.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Если Партнером не установлена цена Товарного предложения или товар отсутствует (количество в наличии равно нулю), такое Товарное предложение не отображается в списке Товарных предложений в Корзине Пользователя.
            </li>
          </ol>
        </li>

        <li className='terms-partnership-list__item terms-partnership-list__item--title'>
          <span className='underline bold'>Формирование Заказа</span><br></br>
          Заказ может осуществляться следующими способами:
          <ul className='terms-partnership-list terms-partnership-list--marker'>
            <li className='terms-partnership-list__item'>
              Заказ через Корзину
            </li>
            <li className='terms-partnership-list__item'>
              Быстрый заказ
            </li>
            <li className='terms-partnership-list__item'>
              Заявка «Под заказ»
            </li>
          </ul> 
          <ol className="terms-partnership__list terms-partnership-list terms-partnership-list--2">
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              <span className='bold'>Заказ через Корзину</span>
              <ol className="terms-partnership__list terms-partnership-list terms-partnership-list--3">
                <li className="terms-partnership-list__item terms-partnership-list__item--3">
                  В целях формирования Заказа Пользователь выбирает Товар, добавляя его в Корзину. После заполнения Корзины Пользователь переходит к формированию Заказа на Сайте.
                </li>
                <li className="terms-partnership-list__item terms-partnership-list__item--3">
                  Состав сведений, указываемых Пользователем в соответствующем Заказе, включает в себя:
                </li>
                <li className="terms-partnership-list__item terms-partnership-list__item--3">
                  Для авторизованного Пользователя:
                  <ul className='terms-partnership-list terms-partnership-list--marker'>
                    <li className='terms-partnership-list__item'>
                      Фамилия;
                    </li>
                    <li className='terms-partnership-list__item'>
                      Имя;
                    </li>
                    <li className='terms-partnership-list__item'>
                      Контактный телефон;
                    </li>
                    <li className='terms-partnership-list__item'>
                      Адрес электронной почты;
                    </li>
                    <li className='terms-partnership-list__item'>
                      Дата рождения, пол;
                    </li>
                    <li className='terms-partnership-list__item'>
                      Адрес доставки (в том числе: город, улица, дом, подъезд, этаж, квартира/офис, наличие лифта);
                    </li>
                    <li className='terms-partnership-list__item'>
                      Дата и время доставки (отгрузки) Товара;
                    </li>
                    <li className='terms-partnership-list__item'>
                      Наименование Товара, количество, цена Товара;
                    </li>
                    <li className='terms-partnership-list__item'>
                      Выбранный способ оплаты;
                    </li>
                    <li className='terms-partnership-list__item'>
                      Выбранный способ доставки;
                    </li>
                    <li className='terms-partnership-list__item'>
                      Дополнительная информация на усмотрение Пользователя.
                    </li>
                  </ul> 
                </li>
                <li className="terms-partnership-list__item terms-partnership-list__item--3">
                  Для неавторизованного Пользователя, не прошедшего процедуру Регистрации на Сайте:
                  <ul className='terms-partnership-list terms-partnership-list--marker'>
                    <li className='terms-partnership-list__item'>
                      Фамилия;
                    </li>
                    <li className='terms-partnership-list__item'>
                      Имя;
                    </li>
                    <li className='terms-partnership-list__item'>
                      Контактный телефон;
                    </li>
                    <li className='terms-partnership-list__item'>
                      Адрес электронной почты;
                    </li>
                    <li className='terms-partnership-list__item'>
                      Адрес доставки (в том числе: город, улица, дом, подъезд, этаж, квартира/офис, наличие лифта);
                    </li>
                    <li className='terms-partnership-list__item'>
                      Дата и время доставки (отгрузки) Товара;
                    </li>
                    <li className='terms-partnership-list__item'>
                      Наименование Товара, количество, цена Товара;
                    </li>
                    <li className='terms-partnership-list__item'>
                      Выбранный способ оплаты;
                    </li>
                    <li className='terms-partnership-list__item'>
                      Выбранный способ доставки;
                    </li>
                    <li className='terms-partnership-list__item'>
                      Дополнительная информация на усмотрение Пользователя.
                    </li>
                  </ul> 
                </li>
                <li className="terms-partnership-list__item terms-partnership-list__item--3">
                  При формировании Заказа Пользователь выбирает один из предложенных ему способов оплаты Заказа,  согласно п. 7.1 настоящего Соглашения.
                </li>
                <li className="terms-partnership-list__item terms-partnership-list__item--3">
                  При формировании Заказа Пользователь выбирает один из предложенных ему способов доставки Заказа:
                  <ul className='terms-partnership-list terms-partnership-list--marker'>
                    <li className='terms-partnership-list__item'>
                      Доставка курьером;
                    </li>
                    <li className='terms-partnership-list__item'>
                      Самовывоз.
                    </li>
                  </ul> 
                  Условия доставки (отгрузки) Товара, сроки, стоимость отражаются в Корзине при формировании Заказа и на соответствующих Страницах Партнеров на Сайте.
                </li>
                <li className="terms-partnership-list__item terms-partnership-list__item--3">
                  Заказ считается сформированным Пользователем после присвоения ему индивидуального номера. Информация о присвоенном индивидуальном номере Заказа направляется Пользователю по электронной почте, указанной Пользователем при формировании Заказа
                </li>
                <li className="terms-partnership-list__item terms-partnership-list__item--3">
                  Сформированный Пользователем Заказ отображается в Личном кабинете Партнера во вкладке «Заказы», а также автоматически перенаправляется Партнеру на его адрес электронной почты, указанный в Личном кабинете Партнера.
                </li>
                <li className="terms-partnership-list__item terms-partnership-list__item--3">
                  После оповещения о новом Заказе на Сайте Партнер для подтверждения или отмены Заказа, а также для уточнения дополнительной информации обязан в срок не более 2 часов в рабочее время Партнёра связаться с Пользователем и установить соответствующий статус в Личном кабинете во вкладке «Заказы».
                </li>
                <li className="terms-partnership-list__item terms-partnership-list__item--3">
                  Договор купли-продажи считается заключенным, а заказ Пользователя считается подтвержденным Партнером с момента присвоения ему статуса «Заказ принят» в личном кабинете Партнера во вкладке «Заказы». Пользователю автоматически направляется уведомление «Ваш заказ № __________ принят. Отправку Пользователям автоматических уведомлений о присвоении статуса «Заказ принят» организовывает Владелец сайта.
                </li>
                <li className="terms-partnership-list__item terms-partnership-list__item--3">
                  Партнер обязан выполнить подтвержденный, согласно пп. 5.1.10 настоящего Соглашения Заказ в соответствии с условиями, указанными Партнером на Сайте.
                </li>
              </ol>
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              <span className="bold">Быстрый заказ</span><br></br>
              В целях формирования Быстрого заказа Пользователь выбирает Товар (одно SKU) и формирует Быстрый заказ с помощью соответствующей формы на сайте в карточке товара (доступна для всех товаров, у которых есть Товарные предложения в регионе Пользователя).
              <ol className="terms-partnership__list terms-partnership-list terms-partnership-list--3">
                <li className="terms-partnership-list__item terms-partnership-list__item--3">
                  <ul className='terms-partnership-list terms-partnership-list--marker'>
                    <li className='terms-partnership-list__item'>
                      Фамилия;
                    </li>
                    <li className='terms-partnership-list__item'>
                      Имя;
                    </li>
                    <li className='terms-partnership-list__item'>
                      Отчество;
                    </li>
                    <li className='terms-partnership-list__item'>
                      Контактный телефон;
                    </li>
                    <li className='terms-partnership-list__item'>
                      Адрес электронной почты;
                    </li>
                    <li className='terms-partnership-list__item'>
                      Адрес доставки (в том числе: город, улица, дом, подъезд, этаж, квартира/офис);
                    </li>
                    <li className='terms-partnership-list__item'>
                      Количество Товара (в штуках или квадратных / погонных метрах (для определенных групп товаров);
                    </li>
                  </ul> 
                </li>
                <li className="terms-partnership-list__item terms-partnership-list__item--3">
                  <ul className='terms-partnership-list terms-partnership-list--marker'>
                    <li className='terms-partnership-list__item'>
                      Наименование Партнера;
                    </li>
                    <li className='terms-partnership-list__item'>
                      Рейтинг;
                    </li>
                    <li className='terms-partnership-list__item'>
                      Варианты оплаты (согласно п. 7.1 настоящего Соглашения);
                    </li>
                    <li className='terms-partnership-list__item'>
                      Минимальный срок доставки;
                    </li>
                    <li className='terms-partnership-list__item'>
                      Возможность бесплатной доставки;
                    </li>
                    <li className='terms-partnership-list__item'>
                      Варианты доставки (Доставка курьером, Самовывоз);
                    </li>
                    <li className='terms-partnership-list__item'>
                      Возможность разгрузки, переноса, подъема Товара.
                    </li>
                  </ul>
                </li>
                <li className="terms-partnership-list__item terms-partnership-list__item--3">
                  Процесс обработки Быстрого заказа аналогичен процессу обработки Заказа через корзину, описанному в пп. 5.1.7 – 5.1.11 настоящего Соглашения.
                </li>
              </ol>
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              <span className="bold">Заявка «Под заказ»</span>
              <ol className="terms-partnership__list terms-partnership-list terms-partnership-list--3">
                <li className="terms-partnership-list__item terms-partnership-list__item--3">
                  В целях формирования Заявки «Под заказ» Пользователь выбирает Товар (одно SKU) и формирует Заявку с помощью соответствующей формы на сайте в списке товаров или карточке. Форма доступна для всех Товаров, по которым нет ни одного Товарного предложения в регионе Пользователя.
                </li>
                <li className="terms-partnership-list__item terms-partnership-list__item--3">
                  Состав сведений, указываемых Пользователем в форме Заявки «Под заказ», включает в себя:<br></br>
                  Для авторизованного и неавторизованного Пользователей:
                  <ul className='terms-partnership-list terms-partnership-list--marker'>
                    <li className='terms-partnership-list__item'>
                      Фамилия;
                    </li>
                    <li className='terms-partnership-list__item'>
                      Имя;
                    </li>
                    <li className='terms-partnership-list__item'>
                      Отчество;
                    </li>
                    <li className='terms-partnership-list__item'>
                      Контактный телефон;
                    </li>
                    <li className='terms-partnership-list__item'>
                      Адрес электронной почты;
                    </li>
                    <li className='terms-partnership-list__item'>
                      Адрес доставки (в том числе: город, улица, дом, подъезд, этаж, квартира/офис, наличие лифта);
                    </li>
                    <li className='terms-partnership-list__item'>
                      Количество Товара (штук);
                    </li>
                  </ul> 
                </li>
                <li className="terms-partnership-list__item terms-partnership-list__item--3">
                  При формировании Заявки «Под заказ» Пользователь уведомляется о том, что Заявка может быть не выполнена Партнером, если ни у одного из них не будет Товара, заказанного Пользователем.
                </li>
                <li className="terms-partnership-list__item terms-partnership-list__item--3">
                  Заявка считается сформированной Пользователем после присвоения ей индивидуального номера. Информация о присвоенном индивидуальном номере Заявки направляется Пользователю по электронной почте, указанной Пользователем при формировании Заявки.
                </li>
                <li className="terms-partnership-list__item terms-partnership-list__item--3">
                  Сформированная Пользователем Заявка «Под заказ» отображается в Личном кабинете Партнера во вкладке «Биржа», а также автоматически перенаправляется всем Партнерам в регионе, где оформлена Заявка на их адрес электронной почты, указанной в Личном кабинете Партнера.
                </li>
                <li className="terms-partnership-list__item terms-partnership-list__item--3">
                  После оповещения о новой Заявке на Сайте Партнер может принять ее в обработку, нажав на кнопку «Исполнить» в списке на вкладке «Биржа» в Личном кабинете Партнера.
                </li>
                <li className="terms-partnership-list__item terms-partnership-list__item--3">
                  В срок не более 2 часов после принятия Заявки на обработку Партнер для подтверждения или отмены Заявки, а также для уточнения дополнительной информации, обязан связаться с Пользователем в рабочее время и установить соответствующий статус в Личном кабинете во вкладке «Заказы».
                </li>
                <li className="terms-partnership-list__item terms-partnership-list__item--3">
                  Заявка Пользователя считается подтвержденной Партнером после присвоения ей статуса «Принята» в личном кабинете Партнера во вкладке «Заказы».
                </li>
                <li className="terms-partnership-list__item terms-partnership-list__item--3">
                  Партнер обязан выполнить подтвержденную, согласно п. 5.3.8 настоящего Соглашения Заявку в соответствии с условиями, указанными Партнером на Сайте.
                </li>
                <li className="terms-partnership-list__item terms-partnership-list__item--3">
                   В случае отмены Заявки Партнер обязан указать причину отмены Заявки в соответствующем поле.
                </li>
              </ol>
            </li>
          </ol>
        </li>

        <li className='terms-partnership-list__item terms-partnership-list__item--title'>
          <span className='underline bold'>Доставка товара</span>
          <ol className="terms-partnership__list terms-partnership-list terms-partnership-list--2">
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Условия осуществления доставки (отгрузки) Товара в соответствии с подтвержденным Партнером Заказом согласно п. 5.3.8 настоящего Соглашения, а именно срок доставки, стоимость и порядок оплаты, устанавливаются Партнером в Личном кабинете во вкладке «Доставка».
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Доставка Товара Пользователю должна строго осуществляться в сроки, установленные Партнером в его Личном кабинете.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Доставку Товаров, заказ которых осуществляет Пользователь на Сайте, организует и осуществляет Партнер от своего имени. При этом Владелец сайта не несет ответственность перед Пользователем за неисполнение/ненадлежащее исполнение обязательств по организации доставки Товаров Партнера в рамках заключенного между Пользователем и Партнером договора купли-продажи/поставки Товаров.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Владелец сайта не участвует в доставке Товара, в том числе и в оплате его стоимости, а также в размещении и актуализации информации, представленной в личном кабинете Партнера/Пользователя.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              После отгрузки Товара Пользователю Партнер обязан сообщить о выполнении Заказа Владельцу сайта в срок не позднее одного календарного дня с момента доставки Товара Пользователю, установив соответствующий статус Заказу в Личном кабинете во вкладке «Заказы».
            </li>
          </ol>
        </li>

        <li className='terms-partnership-list__item terms-partnership-list__item--title'>
          <span className='underline bold'>Оплата заказа</span>
          <ol className="terms-partnership__list terms-partnership-list terms-partnership-list--2">
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Возможные способы оплаты Заказа устанавливаются Партнером в Личном кабинете во вкладке «Оплата»:<br></br>
              <span className="bold">Предоплата (оплата до получения Заказа)*:</span>
              <ul className='terms-partnership-list terms-partnership-list--marker'>
                <li className='terms-partnership-list__item'>
                  наличный расчет;
                </li>
                <li className='terms-partnership-list__item'>
                  оплата банковской картой;
                </li>
                <li className='terms-partnership-list__item'>
                  оплата электронными деньгами.
                </li>
              </ul> 
              *предоплата осуществляется Пользователем по предоставленной Партнером ссылке на сайт третьего лица, предоставляющего услугу безналичного расчета, ссылка отправляется на адрес электронной почты Пользователя, указанный Пользователем при формировании Заказа или при Регистрации Пользователя на Сайте.<br></br>
              <span className="bold">Постоплата (оплата при получении Заказа):</span><br></br>
              <ul className='terms-partnership-list terms-partnership-list--marker'>
                <li className='terms-partnership-list__item'>
                  наличный расчет;
                </li>
                <li className='terms-partnership-list__item'>
                  оплата банковской картой.
                </li>
              </ul> 
              <li className="terms-partnership-list__item terms-partnership-list__item--2">
                Способ оплаты выбирается Пользователем из доступных в момент Заказа Товара, указывается Пользователем непосредственно в Заказе и согласовывается Партнером и Пользователем при подтверждении Заказа Партнером.
              </li>
            </li>
          </ol>
        </li>
        <li className='terms-partnership-list__item terms-partnership-list__item--title'>
          <span className='underline bold'>Возврат (обмен) Товара</span>
          <ol className="terms-partnership__list terms-partnership-list terms-partnership-list--2">
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Правила возврата (обмена) Товара устанавливаются каждым соответствующим Партнером самостоятельно. Владелец сайта не несет обязательств в отношении возврата (обмена) Товара, реализуемого Партнерами.
            </li>
          </ol>
        </li>
        <li className='terms-partnership-list__item terms-partnership-list__item--title'>
          <span className='underline bold'>Ответственность</span>
          <ol className="terms-partnership__list terms-partnership-list terms-partnership-list--2">
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Сайт предоставляется по принципу «как есть», то есть без гарантий качества и пригодности для каких-либо явных или подразумеваемых целей Партнера. Владелец сайта вправе при необходимости по собственному усмотрению прекратить (временно или окончательно) предоставление Сервисов (или каких-либо отдельных функций в рамках Сервисов) всем Партнерам в целом или отдельному Партнеру, в частности, без предварительного уведомления.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Владелец сайта не гарантирует, что Сайт будет работать беспрерывно и безошибочно. В случае наличия ошибок и неисправностей Владелец сайта обязуется устранить их в кратчайшие сроки.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              За неисполнение или ненадлежащее исполнение обязательств по настоящему Соглашению Стороны несут ответственность в соответствии с действующим законодательством Российской Федерации.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Все споры и разногласия, возникающие между Сторонами в процессе исполнения настоящего Соглашения, решаются путем переговоров.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Все споры и разногласия, возникающие из настоящего Соглашения или в связи с ним, в том числе касающиеся его выполнения, нарушения, прекращения или действительности, если они не урегулированы путем двусторонних переговоров, подлежат разрешению в Арбитражном суде по месту нахождения Владельца сайта. Применимым правом является право Российской Федерации.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              До обращения в соответствующий суд заинтересованная сторона направляет другой стороне претензию в письменном виде заказным письмом, срок ответа на которую устанавливается 20 (двадцать) календарных дней с даты её получения.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Партнер самостоятельно несет ответственность за содержание и достоверность данных, предоставленных Владельцу сайта, а также информации, размещенной Партнером в своем Личном кабинете.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Партнер самостоятельно несет ответственность за сохранность и конфиденциальность Регистрационных данных (Логин и Пароль). Все действия, осуществленные с использованием Логина и Пароля Партнера (представителя Партнера) в Личном кабинете, считаются осуществленными Партнером. Партнер самостоятельно несет ответственность перед третьими лицами за все действия, совершенные с использованием Логина и Пароля Партнера (представителя Партнера). Владелец сайта не несет ответственности за несанкционированное использование Регистрационных данных Партнера третьими лицами.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Партнер несет ответственность за подлинность реализуемого Товара.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Партнер несет ответственность перед Пользователем за качество и сохранность доставляемых Товаров.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Партнер обязуется своими силами и за свой счет, без привлечения Владельца сайта разрешать все споры и жалобы, поступившие от Пользователей Сайта в отношении оплаты, доставки, качества и количества поставленного Пользователю Товара, а также иные вопросы, связанные с неисполнением Партнером взятых на себя обязательств.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Партнер соглашается с тем, что любая передача любой информации по информационно-телекоммуникационной сети «Интернет», в том числе по защищенным каналам связи в зашифрованном виде, не может быть гарантированно защищена от несанкционированного доступа к ней третьими лицами. В связи с этим Владелец сайта не несет ответственности за любой ущерб, причиненный Партнеру вследствие несанкционированного доступа третьих лиц к информации Партнера.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Владелец сайта не несет ответственность по сделкам (договорам купли-продажи/договорам оказания услуг иным договорам и соглашениям), заключенным Партнером с Пользователем.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Владелец Сайта вправе без предварительного уведомления Партнера в любое время заблокировать доступ Партнера к Личному кабинету на неопределенное время в следующих случаях:
              <ul className='terms-partnership-list terms-partnership-list--marker'>
                <li className='terms-partnership-list__item'>
                  Нарушение одного или нескольких пунктов настоящего Соглашения;
                </li>
                <li className='terms-partnership-list__item'>
                  Оскорбление, то есть унижение чести и достоинства другого лица, выраженное в неприличной форме;
                </li>
                <li className='terms-partnership-list__item'>
                  Использование на Сайте ненормативной лексики;
                </li>
                <li className='terms-partnership-list__item'>
                  Действия, направленные на возбуждение ненависти либо вражды, а также на унижение достоинства человека либо группы лиц по признакам пола, расы, национальности, языка, происхождения, отношения к религии, а равно принадлежности к какой-либо социальной группе, совершенные публично, в том числе с использованием средств массовой информации либо информационно-телекоммуникационных сетей, включая сеть «Интернет»:
                  <ul className="terms-partnership__list terms-partnership-list terms-partnership-list--type-a">
                    <li className="terms-partnership-list__item">
                      с применением насилия или с угрозой его применения;
                    </li>
                    <li className="terms-partnership-list__item">
                      лицом с использованием своего служебного положения;
                    </li>
                    <li className="terms-partnership-list__item">
                      организованной группой.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ol>
        </li>
        <li className='terms-partnership-list__item terms-partnership-list__item--title'>
          <span className='underline bold'>Конфиденциальность</span>
          <ol className="terms-partnership__list terms-partnership-list terms-partnership-list--2">
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Под конфиденциальной информацией в Соглашении понимается любая информация, передаваемая одной Стороной другой Стороне в любой возможной форме (устной, письменной, электронной, иной), которая имеет действительную или потенциальную коммерческую ценность в силу неизвестности ее третьим лицам, к которой нет свободного доступа на законном основании и обозначенная Передающей стороной как конфиденциальная (далее – «Конфиденциальная Информация»). К конфиденциальной информации относятся, в том числе передаваемые Партнеру Персональные данные физических лиц. Стороны предпринимают меры по недопущению раскрытия конфиденциальной информации третьим лицам или распространению конфиденциальной информации без согласия раскрывающей стороны, за исключением случаев, предусмотренных действующим законодательством Российской Федерации.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Каждая из Сторон предпримет все необходимые меры для защиты Конфиденциальной Информации как минимум с такой же степенью тщательности, с какой она защищает собственную конфиденциальную информацию. Доступ к Конфиденциальной Информации будет предоставлен только тем сотрудникам каждой из Сторон, которым он обоснованно необходим для выполнения служебных обязанностей, связанных с исполнением настоящего Соглашения.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Конфиденциальная Информация всегда остается собственностью передающей Стороны и не должна копироваться или иным способом воспроизводиться без предварительного письменного согласия передающей Стороны.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Обязательство сохранять в тайне Конфиденциальную Информацию передающей Стороны не распространяется на информацию, которая:
              <ol className="terms-partnership__list terms-partnership-list terms-partnership-list--3">
                <li className="terms-partnership-list__item terms-partnership-list__item--3">
                  на момент раскрытия являлась или стала всеобщим достоянием; или
                </li>
                <li className="terms-partnership-list__item terms-partnership-list__item--3">
                  становится известной принимающей Стороне из источника, иного, чем передающая Сторона, без нарушения принимающей Стороной условий Соглашения, что может быть удостоверено документами, достаточными для подтверждения того, что источником получения Конфиденциальной Информации является третья сторона; или
                </li>
                <li className="terms-partnership-list__item terms-partnership-list__item--3">
                  была известна принимающей Стороне до ее раскрытия по настоящему Соглашению, что подтверждается документами, достаточными для установления факта обладания Конфиденциальной Информацией; или
                </li>
                <li className="terms-partnership-list__item terms-partnership-list__item--3">
                  была раскрыта с письменного разрешения передающей Стороны;
                </li>
                <li className="terms-partnership-list__item terms-partnership-list__item--3">
                  была раскрыта Стороной в связи с требованием государственного органа.
                </li>
              </ol>
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Стороны, получившие в целях исполнения своих обязательств по настоящему Соглашению конфиденциальную информацию, сведения, составляющие коммерческую тайну, не вправе сообщать эти сведения третьим лицам без письменного разрешения другой Стороны настоящего Соглашения, за исключением случаев, установленных действующим законодательством Российской Федерации.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              При нарушении обязанности, предусмотренной в п 10.5 настоящего Соглашения, Стороны несут ответственность в соответствии с нормами законодательства Российской Федерации.
            </li>
          </ol>
        </li>
        <li className='terms-partnership-list__item terms-partnership-list__item--title'>
          <span className='underline bold'>Обработка Персональных данных Пользователя</span>
          <ol className="terms-partnership__list terms-partnership-list terms-partnership-list--2">
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Во исполнение настоящего Соглашения на основании ст. 6 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных», Владелец сайта, являясь Оператором персональных данных (далее «ПДн»), поручает Партнеру обработку персональных данных Пользователей на условиях, установленных данной статьей. Партнер гарантирует ограничение обработки ПДн достижением целей, определенных в настоящем Соглашении, и недопущение обработки ПДн, несовместимой с целями их сбора. Партнер обязуется обеспечить конфиденциальность и безопасность полученных в связи с настоящим Соглашением от Владельца сайта ПДн физических лиц (Пользователей) при их обработке, хранить эти ПДнне дольше, чем этого требуют цели их обработки и уничтожить их по достижении целей обработки или в случае утраты необходимости в их достижении, при необходимости по требованию Владельца сайта подтверждать, что указанные ПДн уничтожены.<br></br>
              Перечень действий (операций) с ПДн, которые будут совершаться Партнером: систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передача (предоставление, доступ), обезличивание, блокирование, удаление и уничтожение, действия (операции), совершаемые с использованием средств автоматизации и без использования средств (неавтоматизированная обработка).
              <br></br>
              В связи с поручением Партнеру обработки ПДн, Партнер принимает на себя следующие обязательства:
              <ul className='terms-partnership-list terms-partnership-list--marker'>
                <li className='terms-partnership-list__item'>
                  соблюдать принципы и правила обработки ПДн, предусмотренные Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных» (далее – Закон №152-ФЗ);
                </li>
                <li className='terms-partnership-list__item'>
                  ограничить обработку персональных данных субъектов ПДн достижением целей, определенных в настоящем Соглашении и не допускать обработку ПДн, несовместимой с целями обработки;
                </li>
                <li className='terms-partnership-list__item'>
                  не допускать объединение баз данных, содержащих ПДн, обработка которых осуществляется в целях, несовместимых между собой;
                </li>
                <li className='terms-partnership-list__item'>
                  обеспечить неизменность ПДн, принятие необходимых мер по удалению или уточнению неполных или неточных данных при исполнении обязательств по Соглашению;
                </li>
                <li className='terms-partnership-list__item'>
                  осуществлять хранение ПДн в форме, позволяющей определить субъекта ПДн, не дольше, чем этого требуют цели обработки;
                </li>
                <li className='terms-partnership-list__item'>
                  принимать все необходимые организационные и технические меры по обеспечению конфиденциальности и безопасности ПДн, по защите их от несанкционированного, в том числе, случайного доступа, уничтожения, изменения, блокирования, копирования, распространения и иных неправомерных действий.
                </li>
              </ul> 
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Партнер вправе использовать ПДн Пользователя, сформировавшего Заказ на Сайте, и переданные ему Сайтом, исключительно для целей исполнения Партнером договора с Пользователем, заключенного Партнером с Пользователем при формировании последним Заказа на Сайте. 
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Любое иное использование ПДн Пользователя Партнером запрещается, за исключением случаев, когда Партнером получено согласие Пользователя на такое использование в соответствии с требованиями действующего законодательства российской Федерации. При невыполнении Партнером данного требования Владелец сайта вправе заблокировать Личный кабинет Партнера с даты направления соответствующего уведомления Партнеру. 
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Партнер обязуется обеспечить обработку ПДн Пользователей в полном соответствии с соответствующими документами Владельца сайта, размещенными в информационно-телекоммуникационной сети «Интернет» по адресу: _________________.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              В случае нарушения Партнером порядка обработки ПДн Пользователей, Партнер обязуется своими силами и за свой счет, без привлечения Владельца сайта, урегулировать все претензии, иски, жалобы, связанные с таким нарушением, а также возместить все убытки Владельцу сайта, связанные с таким нарушением.
            </li>
          </ol>
        </li>
        <li className='terms-partnership-list__item terms-partnership-list__item--title'>
          <span className='underline bold'>Срок действия соглашения</span>
          <ol className="terms-partnership__list terms-partnership-list terms-partnership-list--2">
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Настоящее Соглашение вступает в силу с даты подписания Партнером Дополнительного соглашения к Дилерскому договору и действует в течении срока действия Дилерского договора, за исключением случаев предусмотренных в пункте 9.13 Соглашения. 
            </li>
          </ol>
        </li>
        <li className='terms-partnership-list__item terms-partnership-list__item--title'>
          <span className='underline bold'>Заключительные положения</span>
          <ol className="terms-partnership__list terms-partnership-list terms-partnership-list--2">
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Владелец сайта сохраняет за собой право в любое время изменять оформление Сайта, его содержание, функционал, изменять или дополнять используемые скрипты, программное обеспечение и другие объекты, используемые или хранящиеся на Сайте, любые серверные приложения в любое время.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">.
              В случае возникновения разногласий между Сторонами сведения, которые зафиксированы при помощи технических средств Сайта, имеют преимущественное значение при разрешении таких разногласий.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Партнер гарантирует, что пользуется Сайтом в соответствии с условиями и правилами его использования, информация о нем в Личном кабинете Партнера отражена правильным и полным образом, он действует под собственным наименованием. Стороны обязуются сообщать друг другу о любом факте несанкционированного разглашения информации третьим лицам. В случае завладения третьим лицом Логином и Паролем Партнера и совершения им от имени Партнера действий в Личном кабинете Партнера, которые привели или могут привести к причинению ему убытков, Владелец сайта за такие убытки не несет ответственности.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Стороны пришли к соглашению, что электронная переписка Сторон с использованием адресов электронных почт, указанных на Сайте  является официальной и направление любой информации, уведомлений и документов, в рамках исполнения настоящего Соглашения может осуществляться с использованием электронной переписки. Стороны подтверждают, что лица, осуществляющие коммуникацию с указанных электронных адресов, являются уполномоченными с каждой из Сторон в полном объеме для совершения любых действий в рамках настоящего Соглашения, а также для подписания любых документов, связанных с исполнением настоящего Соглашения. Стороны гарантируют, что любые документы, предоставляемые в виде сканированных копий или посредством факсимильной и/или электронной связи, имеют юридическую силу.
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Все изменения к настоящему Соглашению, переписка между Сторонами, уведомления и обращения осуществляются исключительно на русском языке. 
            </li>
            <li className="terms-partnership-list__item terms-partnership-list__item--2">
              Действующая редакция настоящего Соглашения доступна по адресу: _______________________.
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
};