import staticData from 'common/static.json';
import InputTest from 'components/common/InputTest';
import { validations } from '../validations';

const text = staticData.profile;
const placeholder = staticData.profile.placeholders;

const CompleteIndividualDataSecondStep: React.FC = () => {
  return (
    <div className='flex flex-column gap-3'>
      <span className='text-center'>{text.bankDetails}</span>
      <div className='flex flex-column gap-3 justify-content-center'>
        <InputTest
          name='bankName'
          validations={validations.bankName}
          classNameInput='auth-form-input'
          classNameValid='input-valid-auth'
          classNameError='input-error-auth'
          classNameLabel='flex flex-column gap-2'
          placeholder={placeholder.bankName}
          label={text.bankName}
        />
        <div className='flex gap-4 justify-content-between align-items-end'>
          <InputTest
            name='bic'
            validations={validations.mask}
            mask='999999999'
            classNameInput='auth-form-input'
            classNameValid='input-valid-auth'
            classNameError='input-error-auth'
            classNameLabel='flex flex-column gap-2'
            placeholder={placeholder.bic}
            label={text.bic}
          />
          <InputTest
            name='bankInn'
            validations={validations.mask}
            mask='999999999999'
            classNameInput='auth-form-input'
            classNameValid='input-valid-auth'
            classNameError='input-error-auth'
            classNameLabel='flex flex-column gap-2'
            placeholder={placeholder.bankInn}
            label={text.inn}
          />
        </div>

        <InputTest
          name='bankAccount'
          validations={validations.mask}
          mask='99999999999999999999'
          classNameInput='auth-form-input'
          classNameValid='input-valid-auth'
          classNameError='input-error-auth'
          classNameLabel='flex flex-column gap-2'
          placeholder={placeholder.bankAccount}
          label={text.bankAccount}
        />
        <InputTest
          name='correspondentAccount'
          validations={validations.mask}
          mask='30101999999999999999'
          classNameInput='auth-form-input'
          classNameValid='input-valid-auth'
          classNameError='input-error-auth'
          classNameLabel='flex flex-column gap-2'
          placeholder={placeholder.correspondentAccount}
          label={text.correspondentAccount}
        />
      </div>
    </div>
  );
};

export default CompleteIndividualDataSecondStep;
