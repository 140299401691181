export const CARTS_LIST = 'carts/fetchCarts';
export const CARTS_CHANGE = 'carts/changeCountCarts';
export const CARTS_REMOVE = 'carts/removeCarts';
export const CARTS_ADD = 'carts/cartsAdd';

export enum ProjectType {
  MAIN = 'MAIN',
  ADDITIONAL = 'ADDITIONAL',
}

export interface IOptionData {
  option: string;
  variant: string;
}

export interface IProjectData {
  id: string;
  model: string;
  projectType: ProjectType;
}

export interface ICartData {
  id: string;
  count: number;
  projectData: IProjectData;
  pricePerSingleProject: number;
  options: IOptionData[]
}

export interface ICartsState {
  list: ICartData[],
  count: number
}