import { NavLink } from 'react-router-dom';
import { FC, useState, useEffect } from 'react';
import moment from 'moment';

import {
  IOrderItemData
} from 'store/orders/types';

export const OrderProvide: FC<{ order:IOrderItemData }> = ({ order }) => {
  const [orderData, setOrderData] = useState({});

    const options = {
      LOGISTIC: 'Логист',
      PROVIDER: 'Поставщик',
      CONTRACTOR: 'Подрядчик',
      REALTOR: 'Риелтор',
      REPRESENTATIVE: 'Представитель',
    };

    useEffect(() => {
      if (order?.payloadData) {
        setOrderData(JSON.parse(order?.payloadData));
      }
    }, [order]);

  return (
    <div className='orders-list--list_item orders-list--list_item-role-provide w-full'>
      <div className='flex justify-content-between flex-wrap w-full'>
        <div className='orders-list--list_item--title'>
          <span>Заказ № { order.publicId } </span><span>{ moment(order.timeCreated).format('L').replaceAll('/', '.') }</span>
        </div>
        <div className='flex justify-content-end flex-wrap'>
          <div className='orders-list--list_item--price'>
            <span> ₽ </span>
          </div>
          <div className='orders-list--list_item--button'>
            <span>Материалы в пути</span>
          </div>
        </div>
      </div>

      <div className='orders-list--list_item--info w-full flex justify-content-start flex-wrap'>
        <div>
          <div>
            <span>Поставщик: </span>
            <span>{ orderData?.customerOrganizationName }</span>
          </div>
          <div>
            <span>Номер телефона поставщика: </span>
            <span>{ orderData?.customerPhoneNumber }</span>
          </div>
          <div>
            <span>Категория материалов: </span>
            <span></span>
          </div>
          <div>
            <span>Область: </span>
            <span>{ orderData?.location }</span>
          </div>
        </div>
        <div className='m-2'>
          <div>
            <span>Представитель: </span>
            <span></span>
          </div>
          <div>
            <span>Номер телефона представителя: </span>
            <span></span>
          </div>
          <div>
            <span>Наименование транспортной компании: </span>
            <span></span>
          </div>
        </div>
        <div className='flex justify-content-between flex-wrap w-full'>
          <div>
          </div>
          <div>
            {/* <NavLink
                to={`/order/${order.id}`}
              >
              <button>
                Перейти
              </button>
            </NavLink> */}
            <button>
              Перейти
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}