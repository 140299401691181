export const TOKEN_STORAGE_KEY = 'accessToken';
export const REFRESH_TOKEN_STORAGE_KEY = 'refreshToken';

export const ROUTES = {
  ROOT: '/',
  LOGIN: '/login',
  REGISTRATION: '/registration',
  PASSWORD_RESTORE: '/password-restore',
  EMAIL_CONFIRMATION: '/email-confirmation',
  ORDERS_LIST: '/orders',
  ORDER_DETAIL: '/order/:id',
  PROFILE: '/profile',
  CART: '/cart',
  CART_CONFIRMATION: '/cart-confirmation',
  PARTNER_CONFIRMATION: '/partner-confirmation',
  PARTNERS: '/partners',
  REFERRAL: '/profile/referral',
  COMPLETE_DATA: '/complete-data',
  PARTNER_ISSUE: '/partner-issue',
  USERS: '/users',
  USER: '/user/:id',
  OFFERS: '/offers',
  NOMENCLATURE: '/nomenclature',
  OFFER: '/offer/:categoryId/:partnerId',
  NOT_FOUND: '/not-found',
  DOCUMENTS: '/documents',
  DOCUMENTS_USER: '/profile/documents',
  MARKETS: '/markets',
  MARKET: '/market/:id',
  VILLAGE: '/village/:name',
  TERMS: '/terms/:type'
};
