import { FC } from 'react';

import {
  EmailConfirmIcon,
  DocumentsSystem,
  DocumentsEmployment,
  DocumentsParticipants,
  DocumentsPrograms,
  DocumentsMortgage
} from 'assets/icons';

import { Slider } from 'components/common/Slider';
import { documentsCategory, DocumentsCategory } from 'store/documents/types';
import { ProfilePersonal, ProfileMoneyBag, ProfileDocuments } from 'assets/icons';

const сategoryList = [{
  label: documentsCategory.SYSTEM,
  value: DocumentsCategory.SYSTEM,
  element: <DocumentsSystem />
}, {
  label: documentsCategory.EMPLOYMENT,
  value: DocumentsCategory.EMPLOYMENT,
  element: <DocumentsEmployment /> 
}, {
  label: documentsCategory.PARTICIPANTS,
  value: DocumentsCategory.PARTICIPANTS,
  element: <DocumentsParticipants />
}, {
  label: documentsCategory.PROGRAMS,
  value: DocumentsCategory.PROGRAMS,
  element: <DocumentsPrograms />
}, {
  label: documentsCategory.MORTGAGE,
  value: DocumentsCategory.MORTGAGE,
  element: <DocumentsMortgage />
}];

export const DocumentsHeader: FC<{ changeTab: (category:string) => void }> = ({ changeTab }) => {
  return (
    <>
      <div className="documents-header">
        <div className="documents-header__wrapper">
          <h1 className="documents-header__title">
            Документы
          </h1>

          <div className='documents-header__info documents-header-info'>
            <div className="documents-header-info__icon">
              <EmailConfirmIcon />
            </div>
            <span className="documents-header-info__text">
              Работа с документами осуществляется в Системе Контур.Диадок или Контур.Сайн
            </span>
          </div>
        </div>
      </div>

      <Slider
        sliderAction={ (value) => { changeTab(value) } }
        slides={ сategoryList }
        customClass={ 'documents' }
      />
    </>
  )
}