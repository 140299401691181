import { FC, useState, useEffect } from 'react';
import { useController } from 'react-hook-form';

import staticData from 'common/static.json';
import InputTest from 'components/common/InputTest';
import { validations } from 'helpers/validations';
import { Dropdown } from 'primereact/dropdown';

export const LandSelection: FC<{ projectName: string, landIndex: number }> = ({ projectName, landIndex }) => {
  const [paymentMethod, setPaymentMethod] = useState(null);
  const paymentMethods = [
      { name: 'Ипотечные средства', value: 'MORTGAGE' },
      { name: 'Собственные средства', value: 'OWN_FUNDS' },
  ];
  const { field } = useController({ name: `paymentMethod${landIndex}` });

  return (
    <div className='flex flex-column gap-3'>
      <div className='cart--completion-title'>
        <span>{ projectName }</span>
      </div>
      <div className='cart--completion-title'>
        <span className='text-center'>Укажите местоположение участка</span>
      </div>
      <div className='flex flex-column gap-3 justify-content-center'>
        <InputTest
          name={`region${landIndex}`}
          validations={validations.regionName}
          classNameInput='auth-form-input'
          classNameValid='input-valid-auth'
          classNameError='input-error-auth'
          classNameLabel='flex flex-column gap-2'
          placeholder='Область'
          label='Область'
        />
        <InputTest
          name={`district${landIndex}`}
          validations={validations.regionName}
          classNameInput='auth-form-input'
          classNameValid='input-valid-auth'
          classNameError='input-error-auth'
          classNameLabel='flex flex-column gap-2'
          placeholder='Район'
          label='Район'
        />
        <InputTest
          name={`locality${landIndex}`}
          validations={validations.regionName}
          classNameInput='auth-form-input'
          classNameValid='input-valid-auth'
          classNameError='input-error-auth'
          classNameLabel='flex flex-column gap-2'
          placeholder='Населенный пункт'
          label='Населенный пункт'
        />
        <InputTest
          name={`cadastralNumber${landIndex}`}
          mask={'99.99.999999.99'}
          validations={''}
          classNameInput='auth-form-input'
          classNameValid='input-valid-auth'
          classNameError='input-error-auth'
          classNameLabel='flex flex-column gap-2'
          placeholder='Кадастровый номер'
          label='Кадастровый номер'
        />
      </div>
      <div className='profile-desktop'>
        <div className=''>
          <span className='text-left'>Выберите способ оплаты</span>
        </div>
        <Dropdown
          {...field}
          name={`paymentMethod${landIndex}`}
          options={ paymentMethods }
          optionLabel='name'
          optionValue='value'
          value={ paymentMethod }
          onChange={ e => { field.onChange(e.value); setPaymentMethod(e.value) } }
          className='auth-form-input cart--completion_land-selection--dropdown w-full pr-0 flex align-items-center h-3rem'
        />
      </div>
    </div>
  );
};

