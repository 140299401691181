export const PROJECTS_LIST = 'projects/fetchProjects';
export const PROJECTS_WITH_OPTIONS = 'project/fetchProjectWithOption'
export const PROJECT_FETCH_LIST = 'project/fetchList';
export const PROJECTS_FETCH_MARKET_LIST = 'project/fetchMarketsList';
export const PROJECTS_GET_TREE = 'project/fetchProjectTree';
export const PROJECTS_IMAGES = 'project/fetchProjectImages';
export const PROJECT_BY_ID = 'project/fetchProjectById';
export const PROJECTS_FETCH_VILLAGE_HOUSE = 'project/fetchVillageHouse';

export enum ProjectType {
  MAIN = 'MAIN',
  ADDITIONAL = 'ADDITIONAL'
}

export interface IProjectItem {
  id: string;
  model: string;
  projectType: ProjectType;
  // amount: number;
  // front: string;
  // area: string;
  // floors: number;
  // img: string;
}

export interface IPojects {
  data: IProjectItem[],
  size: number;
}

export interface IOptionData {
  title: string;
  priority: number;
  availableOptions: string[]
}

export interface IProjectsData {
  ceilingHeight: number;
  houseArea: number;
  id: string;
  model: string;
  numberOfRoofs: number;
  numberOfRooms: number;
  optionGroups: IOptionData[];
  price: number;
}

export interface IAvailableOptions {
  id: number;
  available: boolean;
  selected: boolean;
  title: string;
}

export interface IProjectOption {
  priority: number;
  title: string;
  availableOptions: IAvailableOptions[]
}

export interface IProjectWithOptions extends IProjectItem {
  options: IProjectOption[]
}

export interface IProjectsState {
  project: {
    options: IProjectOption[];
    price: number;
  },
  projects: IPojects,
  villageHouse: {}
}