import React from 'react';
import { Button } from 'primereact/button';
import { NavLink } from 'react-router-dom';
import staticData from 'common/static.json';

import {
  LogoIcon,
  InstagramIcon,
  PinterestIcon,
  TGIcon,
  VKIcon,
  YoutubeIcon,
  DisclaimerIcon
} from 'assets/icons';

enum SocialNetworks {
  VK = 'vk',
  TELEGRAM = 'telegram',
  YOUTUBE = 'youtube',
  PINTEREST = 'pinterest',
  INSTAGRAM = 'instagram',
}

export const MainFooter: React.FC = () => {

  const getSocialIcon = (socialName: string) => {
    switch (socialName) {
      case SocialNetworks.VK:
        return <VKIcon />;
      case SocialNetworks.TELEGRAM:
        return <TGIcon />;
      case SocialNetworks.YOUTUBE:
        return <YoutubeIcon />;
      case SocialNetworks.PINTEREST:
        return <PinterestIcon />;
      case SocialNetworks.INSTAGRAM:
        return <InstagramIcon />;
      default:
        return;
    }
  }

  const logoBlock = () => {
    return (
      <div>
        <NavLink
          to={ 'https://kalitka.moscow' }
        >
          <LogoIcon className='logo' />
        </NavLink>
        <ul className='main-footer--social-network-list'>
          {
            staticData.footer.socialLinks.map((social, key) => {
              return <li key={key}>{ getSocialIcon(social.name) }</li>;  
            })
          }
          <li className='main-footer--social-network-list_disclaimer'><DisclaimerIcon /></li>
        </ul>
      </div>
    )
  }

  return (
    <div className='main-footer'>
      <div className='main-footer--wrapper grid'>
        <div className='col-12 main-footer--address-list mobile'>
          <ul className='main-footer--address-list'>
            <li>{ staticData.footer.addressList.name }</li>
            <li>
              <span>{ staticData.footer.addressList.inn.label } </span>
              <span className='font-light'>{ staticData.footer.addressList.inn.value }, </span>
              <span>{ staticData.footer.addressList.ogrn.label } </span>
              <span className='font-light'>{ staticData.footer.addressList.ogrn.value }</span>
            </li>
            <li><span className='font-light'>{ staticData.footer.addressList.address }</span></li>
            <li className='main-footer--address-list_email'>{ staticData.footer.addressList.email }</li>
            <li>
              <Button
                className='main-footer--button flex align-items-center justify-content-center'
                label={ staticData.footer.help }
              />
            </li>
          </ul>
        </div>
        <div className='desktop col-4'>
          { logoBlock() }
        </div>
        <div className='mobile col-12'>
          { logoBlock() }
        </div>

        <div className='col-2'>
          <ul className='main-footer--social-info-list'>
            <li><span>{ staticData.footer.infoList.title }</span></li>
            {
              staticData.footer.infoList.data.map((link, key) => {
                return <li key={key}><span><a href={link.link}>{ link.label }</a></span></li>;
            })}
          </ul>
        </div>

        <div className='col-3'>
          <ul className='main-footer--social-calc-list'>
            <li><span>{ staticData.footer.calcList.title }</span></li>
            {
              staticData.footer.calcList.data.map((link, key) => {
                return <li key={key}><span><a href={link.link}>{ link.label }</a></span></li>;
            })}
          </ul>
        </div>

        <div className='col-3'>
          <ul className='main-footer--address-list'>
            <li><span className='main-footer--address-list_name'>{ staticData.footer.addressList.name }</span></li>
            <li>
              <span>{ staticData.footer.addressList.inn.label } </span>
              <span className='font-light'>{ staticData.footer.addressList.inn.value }, </span>
              <span>{ staticData.footer.addressList.ogrn.label } </span>
              <span className='font-light'>{ staticData.footer.addressList.ogrn.value }</span>
            </li>
            <li><span className='font-light'>{ staticData.footer.addressList.address }</span></li>
            <li className='main-footer--address-list_email'>{ staticData.footer.addressList.email }</li>
            <li>
            <NavLink
              to={ 'https://kalitka.moscow/voprosi' }
              className='help-button'
            >
              <Button
                className='main-footer--button flex align-items-center justify-content-center'
                label={ staticData.footer.help }
              />
            </NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div className='main-footer--bottom grid desktop'>
        <div className='main-footer--disclaimer col-3'>
          <span>
            { staticData.footer.disclaimers.instagram }
          </span>
        </div> 
        <div className='main-footer--tos col-9'>
          <div dangerouslySetInnerHTML={{__html: staticData.footer.disclaimers.tos}}></div>
        </div>    
      </div>
      <div className='main-footer--bottom flex justify-content-between flex-wrap mobile'>
        <div className='main-footer--disclaimer flex align-items-center'>
          <span>
            { staticData.footer.disclaimers.instagram }
          </span>
        </div> 
        <div className='main-footer--tos flex align-items-center'>
          <div dangerouslySetInnerHTML={{__html: staticData.footer.disclaimers.tos}}></div>
        </div>    
      </div>
    </div>
  );
};