import { FC, useState } from 'react';
import { SelectButton } from 'primereact/selectbutton';
import { orderStatus } from 'store/orders/types';

export const OrderStatusForm: FC<{currentStatus, submitAction}> = ({ currentStatus, submitAction }) => {
  const options = Object.keys(orderStatus).map((value) => {
    return {
      label: orderStatus[value],
      value
    };
  });
  const [status, setStatus] = useState(options.filter(item => item.value === currentStatus)[0]);

  const saveStatus = () => {
    submitAction(status);
  };

  return (
    <div className='order--select flex flex-column gap-3'>
      <span className='text-center font-medium text-2xl'>
        Редактировать статус заказа
      </span>
      <SelectButton options={options} value={status} onChange={(e) => setStatus(e.value)} className='orders-select partners-select' />
      <div className='order--select-buttons'>
        <button onClick={ saveStatus }>
          Сохранить изменения
        </button>
      </div>
    </div>
  );
};
