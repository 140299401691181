import { FC, useState, useEffect } from 'react';
import { useAppSelector } from 'store';
import { NavLink } from 'react-router-dom';

import { Button } from 'primereact/button';
import 'react-circular-progressbar/dist/styles.css';

import { useAppDispatch } from 'store';
import { OrderType, IOrderItemData } from 'store/orders/types';
import { routesPath } from 'routes';
import { OrderCustomer } from 'components/Orders/orders-type/OrderCustomer';
import { OrderRepresentative } from 'components/Orders/orders-type/OrderRepresentative';
import { OrderContractor } from 'components/Orders/orders-type/OrderContractor';
import { OrderProvide } from 'components/Orders/orders-type/OrderProvide';
import { OrderRealtor } from 'components/Orders/orders-type/OrderRealtor';
import { OrderLogistic } from 'components/Orders/orders-type/OrderLogistic';

import {
  fetchOrders,
  getArchive,
  fetchPartnerOrders
} from 'store/orders/actions';

import { Filters } from './Filters';
import { RoleSwitcher } from './RoleSwitcher';
import { LoadMoreButton } from 'components/common/LoadMoreButton';

import staticData from 'common/static.json';
const staticText = staticData.orders;

const pageSize = 5;

export const OrdersPage: FC = () => {
  const dispatch = useAppDispatch();

  const [orderType, setOrderType] = useState(OrderType.ACTIVE);
  const [isEmpty, setEmpty] = useState(true);
  const [selectedRole, setSelectedRole] = useState('customer');
  const [currentOrders, setCurrentOrders] = useState({data:[], size: 0});
  const { token } = useAppSelector(state => state.auth);
  const { isProToogler } = useAppSelector(state => state.ui);
  const { role, legalUser, common } = useAppSelector(state => state.profile);
  const { orders, partnerOrders } = useAppSelector(state => state.orders);

  const isAdmin = role === 'ADMIN';

  const setType = (type: string) => {
    setOrderType(type as OrderType);

    if (type === 'ARCHIVE') {
      dispatch(getArchive({ pageNum: 0, pageSize }));
    } else {
      if (selectedRole !== 'customer') {
        dispatch(fetchPartnerOrders({
          pageSize,
          pageNum: 0,
          partnerType: selectedRole.toUpperCase()
        }));
      } else {
        dispatch(fetchOrders({ pageNum: 0, pageSize }));
      }
    }

    changeOrdersCards();
  }

  useEffect(() => {
    if (orders.size) {
      setEmpty(false);
    } else {
      setEmpty(true);
    }
    setCurrentOrders(orders);
  }, [orders]);

  const changeOrdersCards = () => {
    if (common?.roles === 'PARTNER') {
      if (isProToogler) {
        setSelectedRole('customer');
      } else {
        setSelectedRole(role.toLowerCase());
      }
    }
  }

  useEffect(() => {
    changeOrdersCards();
  }, [role]);

  useEffect(() => {
    changeOrdersCards();
  }, [isProToogler]);

  useEffect(() => {
    if (partnerOrders.size) {
      setEmpty(false);
    } else {
      setEmpty(true);
    }
    setCurrentOrders(partnerOrders);
  }, [partnerOrders]);

  useEffect(() => {
    if (selectedRole !== 'customer') {
      dispatch(fetchPartnerOrders({
        pageSize,
        pageNum: 0,
        partnerType: selectedRole.toUpperCase()
      }));
    } else {
      dispatch(fetchOrders({ pageNum: 0, pageSize }));
    }
  }, [selectedRole, token]);

  const loadMore = (params) => {
    if (token) {
      //dispatch(fetchOrders(params));
    }
  }

  const currentCards = (order:IOrderItemData, key:number) => {
    switch (selectedRole) {
      case 'customer':
        return <OrderCustomer order={order} key={key} />
      case 'representative':
        return <OrderRepresentative order={order} key={key} />
      case 'contractor':
        return <OrderContractor order={order} key={key} />
      case 'provider':
        return <OrderProvide order={order} key={key} />
      case 'realtor':
        return <OrderRealtor order={order} key={key} />
      case 'logistic':
        return <OrderLogistic order={order} key={key} />
      default:
        return <></>;
    }
  };

  return (
    <div>
      <div className="orders-list">
        <h1 className="orders-list__title">
          Заказы
        </h1>
        { isAdmin &&
          <RoleSwitcher changeRole={ (value: string) => { setSelectedRole(value) } } />
        }
        <div className="flex justify-content-start flex-wrap orders-list--select-type">
          <div>
            <Button
              className={
                `flex align-items-center justify-content-center orders-list--select-type__button orders-list--${OrderType.ACTIVE.toLowerCase()}-status ` + (orderType === OrderType.ACTIVE && 'orders-list--active') }
              label={staticText.orderType.active}
              onClick={() => { setType(OrderType.ACTIVE) }}
            />
          </div>
          <div>
            <Button
              className={
                `flex align-items-center justify-content-center orders-list--select-type__button orders-list--${OrderType.ARCHIVE.toLowerCase()}-status ` + (orderType === OrderType.ARCHIVE && 'orders-list--active') }
              label={staticText.orderType.archive}
              onClick={() => { setType(OrderType.ARCHIVE)} }
            />
          </div>
        </div>
        { isAdmin &&
          <div className='orders-list--filters'>
            <Filters orderType={orderType} />
          </div>
        }
        { isEmpty &&
          <div className="orders-list--empty_list">
            <div className='flex justify-content-center flex-wrap'>
              <span> { staticText.empty.title }</span>
            </div>
            { role === 'CUSTOMER' && 
              <div className='flex justify-content-center flex-wrap'>
                <NavLink
                  to={ routesPath.markets }
                >
                  <button>{ staticText.empty.button }</button>
                </NavLink>
              </div>
            }
          </div>
        }

          <div className="orders-list--list">
            {
              currentOrders?.data.map((order, key) => {
                return currentCards(order, key);
              })
            }
          </div>
        
        <LoadMoreButton
          loadAction={ loadMore }
          start={ 0 }
          step={ pageSize }
          size={ currentOrders.size }
        />
      </div>
    </div>
  );
};