import { useMultiStepForm } from 'hooks/useMultiStepForm';
import { Button } from 'primereact/button';
import { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { regions } from 'store/ui/actions';
import { PartnerType } from 'store/ui/types';
import { useAppDispatch, useAppSelector } from 'store';
import WorkType from './WorkType';
import PartnerData from './PartnerData';
import PartnerTypeSelect from './PartnerTypeSelect';
import RegionSelect from './RegionSelect';
import { createLegal } from 'store/users/actions';
import { createIssue } from 'store/partners/actions';
import { useNavigate } from 'react-router';
import { ROUTES } from 'common/constants';
import { setNotification } from 'store/ui/reducer';

const PartnerIssue: FC = () => {
  const dispatch = useAppDispatch();
  const { partnerType } = useAppSelector(state => state.ui);
  const { legalUser, common } = useAppSelector(state => state.profile);
  const { id } = useAppSelector(state => state.auth);
  const { phoneNumber } = common;
  const steps = () => {
    switch (partnerType) {
      case PartnerType.CONTRACTOR:
        return [PartnerData, PartnerTypeSelect, WorkType, RegionSelect];
      default:
        return [PartnerData, PartnerTypeSelect, RegionSelect];
    }
  };
  const methods = useForm({ mode: 'onChange' });

  const { Step, next, back, isFirstStep, isLastStep } = useMultiStepForm(
    steps()
  );
  const navigate = useNavigate();

  const handleFinish = (e: any) => {
    e.preventDefault();
  };

  const onSubmit = (formData: any) => {
    const userData = {
      orgName: formData.orgName,
      ogrn: formData.ogrn,
      inn: formData.inn,
      kpp: formData.kpp,
      responsiblePersonName: formData.responsiblePersonName,
      authorityGround: formData.authorityGround[0]
    };

    const issueData = {
      partnerType: formData.partnerType,
      workType: formData.workType,
      regions: formData.regions,
    };

    if (!isFirstStep && !isLastStep) {
      next();
    }

    if (isFirstStep) {
      !!!legalUser.orgName ||
      !!!legalUser.ogrn ||
      !!!legalUser.inn ||
      !!!legalUser.kpp ||
      !!!legalUser.responsiblePersonName ||
      !!!legalUser.authorityGround 
        ? dispatch(createLegal({ id, data: userData })).then(() => {
            next();
          })
        : next();
    }

    if (isLastStep) {
      dispatch(createIssue(issueData));
      navigate(ROUTES.PARTNER_CONFIRMATION);
    }
  };

  const legalValues = {
    orgName: legalUser.orgName || '',
    ogrn: legalUser.ogrn || '',
    inn: legalUser.inn || '',
    kpp: legalUser.kpp || '',
    bankName: legalUser.bankName || '',
    bankAccount: legalUser.bankAccount || '',
    bic: legalUser.bic || '',
    correspondentAccount: legalUser.correspondentAccount || '',
    responsiblePersonName: legalUser?.responsiblePersonName || '',
    authorityGround: legalUser?.authorityGround || '',
  };

  useEffect(() => {
    methods.reset({ ...legalValues, phoneNumber });
  }, [legalUser, common]);

  useEffect(() => {
    if (common?.id === '') {
      return;
    }
    if (common?.emailConfirmationStatus === 'UNCONFIRMED') {
      const notification = {
        id: Math.random(),
        header: '',
        message: 'Необходимо подтвердить почту',
        type: 'notification',
      };
      dispatch(setNotification(notification));
      navigate('/profile');
    }

    if (common?.roles === 'CUSTOMER') {
      const notification = {
        id: Math.random(),
        header: '',
        message: 'Пожалуйста, зарегистрируйте аккаунт для юр. лица',
        type: 'notification',
      };
      dispatch(setNotification(notification));
      navigate('/profile');
    }
  }, [common]);

  useEffect(() => {
    dispatch(regions());
  }, []);

  return (
    <div className='flex justify-content-center'>
      <FormProvider {...methods}>
        <form
          className='auth-form partners-issue flex flex-column gap-3 justify-content-center mt-5 mb-0'
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          {<Step />}
          <div className='flex justify-content-between gap-3 mt-3 flex-wrap'>
            <Button
              className='button-white'
              label='Назад'
              disabled={isFirstStep}
              onClick={(e) => { e.preventDefault(); back(); }}
            />
            <Button
              className='button-green'
              onClick={() => (isLastStep || isFirstStep ? handleFinish : null)}
              label={isLastStep ? 'Отправить' : 'Далее'}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default PartnerIssue;
