export const AUTH_REGISTRATION = 'auth/registration';
export const AUTH_LOGIN = 'auth/login';
export const AUTH_USER = 'auth/getUser';
export const AUTH_PASSWORD_RESTORE = 'auth/passwordRestore';
export const AUTH_SET_PASSWORD = 'auth/setPassword';
export const AUTH_REQUEST_EMAIL_CONFIRMATION = 'auth/requestEmailConfirmation';
export const AUTH_EMAIL_CONFIRMATION = 'auth/emailConfirmation';
export const AUTH_REFRESH_TOKEN = 'auth/refreshToken';

export enum Roles {
  GUEST = 'GUEST',
  CUSTOMER = 'CUSTOMER',
  ADMIN = 'ADMIN',
  PARTNER = 'PARTNER',
}

export const userRoles = {
  GUEST: 'GUEST',
  CUSTOMER: 'CUSTOMER',
  ADMIN: 'ADMIN',
  PARTNER: 'PARTNER',
}

export interface IAuthState {
  role: Roles;
  id: string | null;
  token: string;
  refreshToken: string;
  emailToken: string | null;
}

export interface ILoginData {
  email: string;
  password: string;
}

export type AuthAction = {
  type: string;
  payload: any;
};

export type AuthDispatchType = (args: AuthAction) => AuthAction;
