import { FC } from 'react';
import { SelectButton } from 'primereact/selectbutton';
import { useController } from 'react-hook-form';
import { validations } from './validations';
import staticData from 'common/static.json';

const text = staticData.partnersRequest;

const WorkType: FC = () => {
  const { field } = useController({
    name: 'workType',
    rules: validations.workType,
  });
  const options = [
    { label: 'Геология/Геодезия', value: 'GEOLOGY' },
    { label: 'Строительство', value: 'CONSTRUCTION' },
    { label: 'Ремонт', value: 'REPAIR' },
    { label: 'Мебель', value: 'FURNITURE' },
    { label: 'Технадзор', value: 'TECHNICAL_SUPERVISION' },
    { label: 'Сервисное обслуживание', value: 'SERVICE' },
  ];
  return (
    <div className='flex flex-column gap-3'>
      <span className='text-center font-medium text-2xl'>{text.workType}</span>
      <SelectButton options={options} {...field} className='partners-select' />
    </div>
  );
};

export default WorkType;
