import { FC, useState } from 'react';
import { FormProvider, useForm, useFormContext, useController } from 'react-hook-form';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import InputTest from 'components/common/InputTest';
import { validations } from 'helpers/validations';
import { Button } from 'primereact/button';
import { CrossIcon } from 'assets/icons';

export const DeadlineForm: FC<{deadline, submitAction}> = ({ deadline, submitAction }) => {
  const [date, setDate] = useState(moment(deadline));

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      orderExecutionDeadline: deadline
    }
  });
  const { setValue } = useForm();

  const saveStatus = () => {
    submitAction(moment(date).format('YYYY-MM-DD'));
  };

  const onSubmit = (data: any) => {
    // submitAction({
    //   address: data?.address,
    //   arrivalDate: moment(date).format('L').replaceAll('/', '.'),
    //   arrivalHourFrom: arrivalTime[0],
    //   arrivalHourTo:arrivalTime[1]
    // });
  };

  return (
    <div className='flex justify-content-center flex-wrap'>
      <FormProvider {...methods}>
        <form
          className='order--form auth-form auth-form--login p-d-flex p-jc-center p-mt-2 w-8'
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <div className='flex justify-content-center flex-wrap gap-2'>
            <span className='auth-form-title'>
              Редактировать крайний срок исполнения заказа
            </span>
          </div>
          <div className='flex flex-column gap-2'>
            <InputTest
              name='orderExecutionDeadline'
              type='text'
              mask={'99.99.9999'}
              validations={validations.mask}
              classNameInput='auth-form-input auth-form-login'
              classNameLabel='auth-form-label'
              classNameValid='input-valid-auth'
              classNameError='input-error-auth'
              placeholder='04.09.2020'
              label='Крайний срок исполнения заказа'
              disabled={ true }
            />
          </div>
          <div className='flex flex-column gap-2'>
            <Calendar value={date} onChange={(e) => setDate(e.value)} inline showWeek />
          </div>
          <div className='flex justify-content-center flex-wrap align-items-stretch'>
            <Button
              className='auth-form-login-button flex align-items-center justify-content-center'
              label='Сохранить изменения'
              type='submit'
            />
          </div>
          
        </form>
      </FormProvider>
    </div>
  );
};
