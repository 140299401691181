import { useEffect } from 'react';
import { SelectButton } from 'primereact/selectbutton';
import { useController } from 'react-hook-form';
import { useAppDispatch } from 'store';
import { AccountType } from 'store/profile/types';
import { setAccountType } from 'store/ui/reducer';

export const AccountTypeSelection: React.FC = () => {
  const dispatch = useAppDispatch();

  const { field } = useController({
    name: 'accountType',
  });

  const options = [
    { label: 'Физ. лицо', value: AccountType.individualUser },
    { label: 'Юр. лицо/ИП', value: AccountType.legalUser },
  ];

  useEffect(() => {
    field.value === AccountType.individualUser
      ? dispatch(setAccountType(AccountType.individualUser))
      : dispatch(setAccountType(AccountType.legalUser));
  }, [field]);

  return (
    <div className='w-full flex flex-column gap-6 cart--completion_account-type'>
      <div className='flex justify-content-center flex-wrap gap-2 cart--completion-title auth-form-title'>
        <span>Заполнение данных</span>
      </div>
      <div className='p-d-flex p-jc-center gap-2'>
        <div className='cart--completion_account-type_button flex justify-content-center flex-wrap align-items-stretch flex-wrap auth-form-description'>
          <span>Выберите тип аккаунта</span>
        </div>
        <div className='cart--completion_account-type_button flex justify-content-around flex-wrap align-items-stretch flex-wrap gap-3'>
          <SelectButton options={options} {...field} className='account-type-select partners-select ' />
        </div>
      </div>
    </div>
  );
};
