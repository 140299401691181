import { FC, useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { Button } from 'primereact/button';

import InputTest from 'components/common/InputTest';

import { validations } from 'helpers/validations';

import { routesPath } from 'routes';

import { PasswordRestoreIcon  } from 'assets/icons';
import { restorePassword, setPassword } from 'store/auth/actions';
import { useAppDispatch } from 'store';

import staticData from 'common/static.json';
const staticText = staticData.auth.passwordRestore;

enum RestoreSteps { EMAIL, PENDING, RETRY, RESTORE };

export const PasswordRestorePage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(RestoreSteps.EMAIL);
  const [requestToken, setRequestToken] = useState('');
  const [counter, setCounter] = useState(59);
  const [userEmail, setUserEmail] = useState('');
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const tempToken = searchParams.get('token');
    if (tempToken) {
      setRequestToken(tempToken);
      setCurrentStep(RestoreSteps.RESTORE);
    }
  }, [searchParams]); 

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
      passwordRepeat: ''
    }
  });

  const secondsFormat = () => {
    if (counter > 9) {
      return `00:${counter}`;
    }

    return `00:0${counter}`;
  };

  const startCountdown = () => {
    let timerValue = 59;
    const intervalId = setInterval(() => {
      if (timerValue > 1) {
        timerValue -= 1;
        setCounter(timerValue);
      } else {
        clearInterval(intervalId);
        setCurrentStep(RestoreSteps.RETRY);
      }
    }, 1000);
  };

  const onSubmit = (data: any) => {
    methods.reset();
    setUserEmail(data.email);
    if (currentStep === RestoreSteps.EMAIL) {
      dispatch(
        restorePassword({
          email: data.email,
        })
      );
      setCurrentStep(RestoreSteps.PENDING);
      startCountdown();
    }

    if (currentStep === RestoreSteps.RESTORE) {
      dispatch(setPassword({
        token: requestToken,
        password: data.password
      }));
      navigate(routesPath.login);
    }
  };

  const requestNewEmail = () => {
    if (currentStep === RestoreSteps.RETRY) {
      dispatch(
        restorePassword({
          email: userEmail,
        })
      );
      setCounter(59);
      startCountdown();
      setCurrentStep(RestoreSteps.PENDING);
    }
  };

  return (
    <div className='flex justify-content-center flex-wrap'>
      <FormProvider {...methods}>
        <form
          className='auth-form auth-form--restore p-d-flex p-jc-center p-mt-2 w-8 auth-form'
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          { currentStep === RestoreSteps.EMAIL &&
            <>
              <div className='flex justify-content-center flex-wrap gap-2'>
                <span className='auth-form-title'>
                  {staticData.auth.passwordRestore.title}
                </span>
              </div>
              <div className='flex flex-column gap-2'>
                <InputTest
                  name='email'
                  type='email'
                  validations={validations.email}
                  classNameInput='auth-form-input auth-form-login'
                  classNameLabel='auth-form-label'
                  classNameValid='input-valid-auth'
                  classNameError='input-error-auth'
                  placeholder={
                    staticData.auth.passwordRestore.placeholders.email
                  }
                  label={staticData.auth.passwordRestore.labels.email}
                />
              </div>
              <div className='flex justify-content-center flex-wrap align-items-stretch'>
                <Button
                  className='auth-form-restore-button flex align-items-center justify-content-center'
                  label={staticData.auth.passwordRestore.button}
                  type='submit'
                />
              </div>
            </>
          }
          { (currentStep !== RestoreSteps.EMAIL && currentStep !== RestoreSteps.RESTORE) &&
            <div className='auth-form--restore-pending'>
              <div className='auth-form--restore-icon'>
                <PasswordRestoreIcon />
              </div>
              <div className='auth-form--restore-info'>
                <span>{staticText.info}</span>
              </div>
              <div className='auth-form--restore-description'>
                <div>{staticText.description}</div>
              </div>
              <div className='auth-form--restore-timer'>
                {currentStep === RestoreSteps.PENDING && (
                  <span>
                    {staticData.auth.passwordRestore.timer} {secondsFormat()}
                  </span>
                )}
                {currentStep === RestoreSteps.RETRY && (
                  <span
                    onClick={requestNewEmail}
                    className='auth-form--restore-timer--link'
                  >
                    {staticData.auth.passwordRestore.timerRetry}{' '}
                  </span>
                )}
              </div>
            </div>
          }
          { currentStep === RestoreSteps.RESTORE &&
            <>
              <div className="flex justify-content-center flex-wrap gap-2">
                <span className='auth-form-title'>{staticText.title}</span>
              </div>
              <div className="flex flex-column gap-2">
                <InputTest
                  name='password'
                  type='password'
                  validations={validations.password}
                  classNameInput='auth-form-input'
                  classNameLabel='auth-form-label'
                  classNameValid='input-valid-auth'
                  classNameError='input-error-auth'
                  placeholder={staticText.passwordInput}
                  label={staticText.passwordLabel}
                />
              </div>
              <div className="flex flex-column gap-2">
                <InputTest
                  name='passwordRepeat'
                  type='password'
                  validations={validations.password}
                  classNameInput='auth-form-input'
                  classNameLabel='auth-form-label'
                  classNameValid='input-valid-auth'
                  classNameError='input-error-auth'
                  placeholder={staticText.passwordInput}
                  label={staticText.passwordRepeatLabel}
                />
              </div>
              <div className="flex justify-content-center flex-wrap align-items-stretch">
                <Button
                  className="auth-form-restore-button flex align-items-center justify-content-center"
                  label={staticText.requestButton}
                  type="submit"
                />
              </div>
            </>
          }
        </form>
      </FormProvider>
    </div>
  );
};
