import { FC } from 'react';

interface IProps {
  number: string;
  name: string;
  date: string;
}

export const ProjectDetail: FC<IProps> = ({ number, name, date }) => {
  return (
    <div>
      <div className="col-12 orders-detail-order_number"><span>{ number }</span></div>
      <div className="col-12 orders-detail-order_namedate">
          <span className="orders-detail-order_name">{ name }</span>
          <span className="orders-detail-order_date">{ date }</span>
      </div>
    </div>
  )
};