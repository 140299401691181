import { FC, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'primereact/button';

import { toggleOverflow } from 'store/ui/reducer';
import { useAppDispatch, useAppSelector } from 'store';
import { AccountType } from 'store/profile/types';

import { routesPath } from 'routes';

import staticData from 'common/static.json';

import {
  TabBarStoreIcon,
  TabBarOrdersIcon,
  TabBarCartIcon,
  TabBarMenuIcon
} from 'assets/icons';

export const TabBar: FC = () => {
  const dispatch = useAppDispatch();
  const [isMenuOpen, toogleMenuValue] = useState(false);

  const { common, role } = useAppSelector(state => state.profile);

  const showMenu = () => {
    dispatch(toggleOverflow());
    toogleMenuValue(!isMenuOpen);
  }

  return (
    <div className='tab-bar w-full'>
      { isMenuOpen && 
        <div className='tab-bar--menu_open w-full'>
          <div className='w-full'>
            <ul className='tab-bar--social-info-list'>
              <li><span className='font-semibold'>{ staticData.footer.infoList.title }</span></li>
              {
                staticData.footer.infoList.data.map((link, key) => {
                  return <li key={key}><span className='font-normal'>{ link.label }</span></li>;
              })}
            </ul>
          </div>

          <div className='w-full'>
            <ul className='tab-bar--social-calc-list'>
              <li><span className='font-semibold'>{ staticData.footer.calcList.title }</span></li>
              {
                staticData.footer.calcList.data.map((link, key) => {
                  return <li key={key}><span className='font-normal'>{ link.label }</span></li>;
              })}
            </ul>
          </div>

          <div className='w-full'>
            <ul className='tab-bar--social-calc-list'>
              <li><span className='font-semibold'>{ staticData.footer.addList.title }</span></li>
              { (common.accountType !== AccountType.uncertainUser && role !== 'PROVIDER') &&
                <li><span className='font-custom-green'>Реферальная программа</span></li>
              }
            </ul>
          </div>
          
        </div>
      }
      <div className='tab-bar--menu w-full'>
        <ul className='w-full'>
          <li className='w-3'>
            <TabBarStoreIcon />
            <div><NavLink to={ routesPath.markets }><span>{ staticData.footer.tabBar.store }</span></NavLink></div>
          </li>
          <li className='w-3'>
            <TabBarOrdersIcon />
            <div><NavLink to={ routesPath.ordersList }><span>{ staticData.footer.tabBar.orders }</span></NavLink></div>
          </li>
          <li className='w-3'>
            <TabBarCartIcon />
            <div><NavLink to={ routesPath.cart }><span>{ staticData.footer.tabBar.cart }</span></NavLink></div>
          </li>
          <li className='w-3' onClick={ showMenu }>
            <TabBarMenuIcon />
            <div><span>{ staticData.footer.tabBar.menu }</span></div>
          </li>
        </ul>
      </div>
    </div>
  );
};