import { FC, useState, useEffect } from 'react';

import { Dialog } from 'primereact/dialog';

import { DocumentsHeader } from './DocumentsHeader';
import { DocumentsFilters } from 'components/Documents/DocumentsFilters';
import { DocumentsList } from './DocumentsList';

import { useAppDispatch } from 'store';
import { getDocuments, removeDocumentTemplate, getFilledDocuments } from 'store/documents/actions';
import { DocumentsCategory } from 'store/documents/types';
import { ConfirmDocumentsModal } from './DocumentsConfirmModal';
import { DocumentsType, INewDocument } from 'store/documents/types';

export const DocumentsPage: FC = () => {
  const dispatch = useAppDispatch();
  const [documentCategory, setDocumentCategory] = useState(DocumentsCategory.SYSTEM);
  const [currentFilters, setCurrentFilters] = useState({});
  const [documentStatus, setDocumentStatus] = useState(Object.keys(DocumentsType)[0]);

  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [currentConfirmAction, setCurrentConfirmAction] = useState(false);
  const [currentRemoveFile, setCurrentRemoveFile] = useState();
  const [currentUserType, setCurrentUserType] = useState(null);

  useEffect(() => {
    dispatch(getDocuments({
      category: DocumentsCategory.SYSTEM,
      pageNum: 0,
      pageSize: 100
    }));
  }, []);

  useEffect(() => {
    if (documentStatus === 'TYPICAL') {
      dispatch(getDocuments({
        category: DocumentsCategory.SYSTEM,
        pageNum: 0,
        pageSize: 100
      }));
    }

    if (documentStatus === 'FILLED') {
      dispatch(getFilledDocuments({
        category: DocumentsCategory.SYSTEM,
        pageNum: 0,
        pageSize: 100,
        proActive: true
      }))
    }
  }, [documentStatus]);

  const changeCategory = (category: string) => {
    setDocumentCategory(category as DocumentsCategory);
    setCurrentFilters({})
    setDocumentStatus('TYPICAL');
    if (documentStatus === 'TYPICAL') {
      dispatch(getDocuments({
        category: category as DocumentsCategory,
        pageNum: 0,
        pageSize: 100
      }))
    }

    if (documentStatus === 'FILLED') {
      dispatch(getFilledDocuments({
        category: category as DocumentsCategory,
        pageNum: 0,
        pageSize: 100,
        proActive: true
      }))
    }
  }

  const applyFilters = (filters: any) => {
    setCurrentFilters(filters);

    let params = {
      pageSize: 100,
      pageNum: 0,
      category: documentCategory as DocumentsCategory
    };

    if (filters?.direction) {
      params.direction = filters?.direction
    }

    if (filters?.signerTypes) {
      //params.signerTypes = filters?.signerTypes
      setCurrentUserType(filters?.signerTypes);
    }

    if (filters?.type) {
      params.type = filters?.type
    }

    if (filters?.order) {
      params.order = filters?.order
    }

    if (filters?.documentName) {
      params.documentName = filters?.documentName
    }

    if (documentStatus === 'TYPICAL') {
      dispatch(getDocuments({
        category: documentCategory as DocumentsCategory,
        ...params
      }));
    }

    if (documentStatus === 'FILLED') {
      dispatch(getFilledDocuments({
        proActive: true,
        ...params
      }));
    }
  }

  const removeDocumentItem = async(fileId: string) => {
    setIsConfirmModal(true)
    setCurrentRemoveFile(fileId);
  }

  const removeFileAction = async () => {
    await dispatch(removeDocumentTemplate(currentRemoveFile));
    dispatch(getDocuments({
      category: documentCategory as DocumentsCategory,
      pageNum: 0,
      pageSize: 100,
      ...currentFilters
    }));
    setIsConfirmModal(false)
  }

  return (
    <div className='documents'>
      <Dialog
        visible={ isConfirmModal }
        onHide={() => setIsConfirmModal(false)}
        className='categories-modal-changes categories-modal__changes'>
          <ConfirmDocumentsModal
            onClose={ () => setIsConfirmModal(false) }
            confirmAction={ removeFileAction }
          />
      </Dialog>
      <DocumentsHeader
        changeTab={ (category:string) => { changeCategory(category) }}
      />
      <DocumentsFilters
        applyFilters={ (filters) => { applyFilters(filters) } }
        documentCategory={ documentCategory }
        changeDocumentStatus={ (status) => setDocumentStatus(status) }
        documentStatus={ documentStatus }
      />
      <DocumentsList
        documentStatus={ documentStatus }
        removeFile={
          removeDocumentItem
        }
      />
    </div>
  )
};