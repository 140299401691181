export const REFERRAL_GET_INFO = 'referral/getInfo';
export const REFERRAL_SAVE_BANK_DETAILS = 'referral/saveBankDetails';
export const REFERRAL_USER = 'referral/getUserReferal';

export interface IReferral {
  info: {
    percent: number;
    referralLink: string;
    referralsCount: number;
    withdrawnAmount: number;
    availableAmount: number;
  };
}
