import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { FC, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  isVisible: boolean;
  onClose: () => void;
  onConfirm: () => void;
  confirmLabel: string;
  declineLabel: string;
  confirmButtonClassName?: string;
  declineButtonClassName?: string;
  wrapperClassName?: string;
}

const WarningDialogTest: FC<Props> = ({
  children,
  isVisible,
  onClose,
  onConfirm,
  confirmLabel,
  declineLabel,
  declineButtonClassName,
  confirmButtonClassName,
  wrapperClassName,
}) => {
  return (
    <Dialog
      onHide={() => {}}
      visible={isVisible}
      showHeader={false}
      className={`profile-dialog ${wrapperClassName}`}
      blockScroll={true}
    >
      <div className='p-5 flex flex-column gap-4'>
        {children}
        <div className='flex gap-6 justify-content-between flex-wrap'>
          <Button
            className={declineButtonClassName}
            label={declineLabel}
            onClick={onClose}
          />
          <Button
            className={confirmButtonClassName}
            label={confirmLabel}
            onClick={onConfirm}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default WarningDialogTest;
