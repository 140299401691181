import { InvalidIcon } from 'assets/icons';
import { Tooltip } from 'primereact/tooltip';
import { memo } from 'react';
import { FieldError } from 'react-hook-form';

interface Props {
  error: FieldError;
  iconClassName?: string;
}

const ErrorTooltip: React.FC<Props> = ({ error, iconClassName }) => {
  return (
    <>
      <InvalidIcon
        className={`tooltip-icon ${iconClassName}`}
        data-pr-tooltip={error.message}
        data-pr-position='bottom'
        data-pr-at='center bottom+15'
      />
      <Tooltip className='input-tooltip' target={'.tooltip-icon'} />
    </>
  );
};

export default ErrorTooltip;
