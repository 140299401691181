import { FC } from 'react';
import { useAppSelector } from 'store';
import NotificationItem from './NotificationItem';

const Notifications: FC = () => {
  const { notifications } = useAppSelector(state => state.ui);

  return (
    <ul className='notifications flex flex-column gap-3'>
      {notifications
        .filter((i, index) => index < 5)
        .map(item => (
          <NotificationItem
            key={item.id}
            id={item.id}
            header={item.header}
            message={item.message}
          />
        ))}
    </ul>
  );
};

export default Notifications;
