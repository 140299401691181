import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';

import { guestToggle } from 'store/ui/reducer';

import {
  useAppSelector,
  useAppDispatch
} from 'store';

export const ProtectedRoute:FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const { isGuest } = useAppSelector(state => state.ui);
  
  const { userToken } = useAuth();

  if (!userToken) {
    return <Navigate to='/login' replace={ true } />
  }

  if (isGuest) {
    dispatch(guestToggle());
  }

  return children;
};