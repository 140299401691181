import { FC, useState } from 'react';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';

export const DeadlineForm: FC<{currentDate, submitAction}> = ({ currentStatus, submitAction }) => {
  const [date, setDate] = useState(false);

  const saveStatus = () => {
    submitAction(moment(date).format('YYYY-MM-DD'));
  };

  return (
    <div className='order--select flex flex-column gap-3'>
      <span className='text-center font-medium text-2xl'>
        Редактировать статус
      </span>
      <Calendar value={date} onChange={(e) => setDate(e.value)} inline showWeek />
      <div className='order--select-buttons'>
        <button onClick={ saveStatus }>
          Сохранить изменения
        </button>
      </div>
    </div>
  );
};
