import { createAsyncThunk } from '@reduxjs/toolkit';
import { userApi } from 'services/api/userApi';
import {
  PROFILE_GET_USER,
  PROFILE_UPDATE_PASSWORD,
  PROFILE_UPDATE_USER,
} from './types';

export const getUser = createAsyncThunk(
  PROFILE_GET_USER,
  async () => {
    const response = await userApi.getUser();
    return response;
  }
);

export const updateUser = createAsyncThunk(
  PROFILE_UPDATE_USER,
  async ({ id, data }: { id: any; data: any }) => {
    const response = await userApi.updateUser(id, data);
    return response;
  }
);

export const updatePassword = createAsyncThunk(
  PROFILE_UPDATE_PASSWORD,
  async ({ id, data }: { id: string; data: any }) => {
    const response = await userApi.updatePassword(id, data);
    return response;
  }
);
