export const ORDERS_LIST = 'orders/list';
export const ORDERS_ITEM = 'orders/item';
export const ORDERS_ARCHIVE = 'orders/archive';
export const ORDERS_USERS_ORDER = 'orders/usersOrder';
export const ORDERS_PARTNER_ORDERS = 'orders/getPartnerOrders';
export const ORDERS_EDIT_LAND = 'orders/editLandData';
export const ORDERS_EDIT = 'orders/edit';
export const ORDERS_ADD_DOCUMENTS = 'orders/addDocuments';
export const ORDERS_EDIT_DOCUMENTS = 'orders/editDocuments';
export const ORDERS_REMOVE_DOCUMENTS = 'orders/removeDocuments';
export const ORDERS_CREATE_ORDER = 'orders/createOrder';
export const ASSIGN_EXECUTORS = 'orders/assignExecutors';
export const ORDERS_GET_PARTNER_ISSUE = 'orders/getPartnerIssue';
export const ORDERS_UPDTE_PARTNER_ISSUE = 'orders/updatePartnerIssue';

export enum OrderType {
  ACTIVE = 'ACTIVE',
  ARCHIVE = 'ARCHIVE'
}

export const paymentStatus = {
  NOT_PAID: 'Не оплачен',
  WAITING_FOR_UPFRONT_PAYMENT: 'Ожидает предоплату',
  UPFRONT_PAYMENT_CONFIRM: 'Предоплата принята',
  WAITING_FOR_PAYMENT: 'Ожидает основной оплаты',
  PAID: 'Оплачен'
};

export const orderStatus = {
  CREATED: 'Создан',
  ORDER_CONFIRMATION: 'Подтверждение заказа',
  SIGNING_OF_DOCUMENTS: 'Подписание документов',
  PREPAYMENT: 'Внесение предоплаты',
  CHECKING_THE_LAND_PLOT: 'Проверка земельного участка',
  GETTING_A_MORTGAGE: 'Выдача ипотеки',
  PAYMENT: 'Оплата',
  BUILDING: 'Строительство',
  REPAIRING: 'Ремонт',
  FURNITURE_INSTALLATION: 'Установка мебели',
  TECHNICAL_SUPERVISION: 'Тех. надзор',
  DELIVERY: 'Cдача объекта',
  COMPLETED: 'Завершен',
  CANCELLED: 'Отменен'
};

export enum PaymentStatus {
  NOT_PAID = 'Не оплачен',
  WAITING_FOR_UPFRONT_PAYMENT = 'Ожидает предоплату',
  UPFRONT_PAYMENT_CONFIRM = 'Предоплата принята',
  WAITING_FOR_PAYMENT = 'Ожидает основной оплаты',
  PAID = 'Оплачен'
}

export enum OrderStatus {
  CREATED = 'Создан',
  ORDER_CONFIRMATION = 'Подтверждение заказа',
  SIGNING_OF_DOCUMENTS = 'Подписание документов',
  PREPAYMENT = 'Внесение предоплаты',
  CHECKING_THE_LAND_PLOT = 'Проверка земельного участка',
  GETTING_A_MORTGAGE = 'Выдача ипотеки',
  PAYMENT = 'Оплата',
  TECHNICAL_SUPERVISION = 'Тех. надзор',
  BUILDING = 'Строительство',
  REPAIRING = 'Ремонт',
  FURNITURE_INSTALLATION = 'Установка мебели',
  DELIVERY = 'Cдача объекта',
  COMPLETED = 'Завершен',
  CANCELLED = 'Отменен'
}

export enum OrderStatusActive {
  CREATED = 'Создан',
  ORDER_CONFIRMATION = 'Подтверждение заказа',
  SIGNING_OF_DOCUMENTS = 'Подписание документов',
  PREPAYMENT = 'Внесение предоплаты',
  CHECKING_THE_LAND_PLOT = 'Проверка земельного участка',
  GETTING_A_MORTGAGE = 'Выдача ипотеки',
  TECHNICAL_SUPERVISION = 'Тех. надзор',
  PAYMENT = 'Оплата',
  BUILDING = 'Строительство',
  REPAIRING = 'Ремонт',
  FURNITURE_INSTALLATION = 'Установка мебели',
  DELIVERY = 'Cдача объекта',
}


//Риелтор
export const RealtorStatus = {
  NEW: 'Новый',
  SIGNING_OF_DOCUMENTS: 'Подписание документов',
  COLLECTION_OF_DOCUMENTS: 'Сбор документов',
  APPLYING_FOR_MORTGAGE: 'Подача заявки на ипотеку',
  APPLICATION_APPROVED: 'Заявка одобрена',
  APPLICATION_DECLINED: 'Заявка отклонена',
  SIGNING_OF_DOCUMENTS_BY_CUSTOMER: 'Подписание документов Заказчиком',
  GETTING_A_MORTGAGE: 'Получение ипотеки',
  PAYMENT_FOR_ORDER: 'Оплата заказа',
  WAITING_FOR_HOUSE_COMPLETING: 'Ожидание сдачи объекта',
  HOUSE_COMPLETED: 'Сдача объекта',
  COMPLETED: 'Выполнен',
  CANCELLED: 'Отменен'
}

//Риелтор
export const LogisticStatus = {
  CREATED: 'Создан',
  NEW: 'Новый',
  SIGNING_OF_DOCUMENTS: 'Подписание документов',
  COORDINATION_OF_DATE_AND_TIME: 'Согласование даты и времени',
  ON_WAY_TO_POINT_A: 'В пути в п.А',
  CAR_LOADING: 'Загрузка машины',
  ON_WAY_TO_POINT_B: 'В пути в п.Б',
  UNLOADING_AT_POINT_B: 'Отгрузка в п. Б',
  WAITING_FOR_NEXT_STAGE: 'Ожидание следующего этапа',
  COMPLETED: 'Выполнен',
  CANCELLED: 'Отменен'
}

export enum OrderStatusArchive {
  COMPLETED = 'Завершен',
  CANCELLED = 'Отменен'
}

export enum paymentMethod {
  OWN_FUNDS = 'OWN_FUNDS',
  MORTGAGE = 'MORTGAGE'
}

export const paymentMethods = {
  MORTGAGE: 'Ипотечные средства',
  OWN_FUNDS: 'Собственные средства'
};

export interface IFiltersFields {
  payment: string;
  order: string;
  date: string;
}

export interface ISubOrderData {
  id: string;
  amount: number;
  options: string[];
  projectName: string;
}

export interface IOrderOptionsData {
  option: string;
  variant: string;
}

export interface IOrderItemData {
  id: string;
  projectId: string;
  projectName: string;
  totalAmount: number;
  options: IOrderOptionsData[];
  paymentMethod: paymentMethod;
  paymentStatus: PaymentStatus;
  status: OrderStatus;
  number: number;
  timeCreated: string;
  subOrderData: ISubOrderData[]
}

export interface ILandData {
  id: number;
  region: string;
  district: string;
  locality: string;
  cadastralNumber: string;
}

export interface IOrderData {
  land: ILandData,
  order: IOrderItemData,
  manager: {
    name: string;
    phoneNumber: string;
  },
  executors: Array<any>,
  documents: Array<any>
}

export interface IOrderPartnerData {
  id: string;
  organizationName: string;
  ogrn: string;
  kpp: string;
  inn: string;
  phoneNumber: string;
  partnerType: string;
  workType: string;
  regions: string[];
  partnerStatus: string;
}

export interface IOrderState {
  orders: {
    data: IOrderItemData[],
    size: Number
  },
  partnerOrders: {
    data: IOrderPartnerData[],
    size: Number
  }
  order: IOrderData,
  filters: IFiltersFields,
  type: OrderType
}