export const defaultMessage = 'Это поле обязательно для заполнения';

export const validations = {
  name: {
    minLength: {
      value: 5,
      message: 'Поле должно содержать как минимум 5 символов',
    },
    pattern: {
      value: /^[а-яё\s\-]+$/iu,
      message: 'Это поле может содержать только кириллицу, тире и пробелы',
    },
  },
  orgName: {
    minLength: {
      value: 5,
      message: 'Поле должно содержать как минимум 5 символов',
    },
    pattern: {
      value: /^(?=.*[а-яё])[а-яё\d\p{P}\s]+$/iu,
      message: 'Это поле может содержать только кириллицу, цифры и спецсимволы',
    },
  },
  email: {
    pattern: {
      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,9}$/g,
      message: 'Некорректный формат адреса электронной почты',
    },
  },
  city: {
    required: 'Ни один город не выбран',
  },
  password: {
    pattern: {
      value:
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!-_%{}`><|*?&]{8,}$/,
      message:
        'Пароль должен содержать минимум 8 символов: латинские буквы от a-z верхнего и нижнего регистра, а также хотя бы одну цифру и спец. символ',
    },
  },
  passportIsssuedBy: {
    pattern: {
      value: /^[А-Яа-я0-9\s\-.,/"']+$/i,
      message:
        'Это поле может содержать только кириллицу, цифры и символы "".,-/',
    },
    minLength: {
      value: 10,
      message: 'Поле должно содержать как минимум 10 символов',
    },
  },
  registrationAddr: {
    pattern: {
      value: /^[А-Яа-я0-9\s\-.,/"']+$/i,
      message:
        'Это поле может содержать только кириллицу, цифры и символы "".,-/',
    },
    minLength: {
      value: 10,
      message: 'Поле должно содержать как минимум 10 символов',
    },
  },
  district: {
    pattern: {
      value: /^[А-Яа-я0-9\s\-.,/"']+$/i,
      message:
        'Это поле может содержать только кириллицу, цифры и символы "".,-/',
    },
    minLength: {
      value: 5,
      message: 'Поле должно содержать как минимум 5 символов',
    },
  },
  locality: {
    pattern: {
      value: /^[А-Яа-я0-9\s\-.,/"']+$/i,
      message:
        'Это поле может содержать только кириллицу, цифры и символы "".,-/',
    },
    minLength: {
      value: 2,
      message: 'Поле должно содержать как минимум 2 символов',
    },
  },
  ogrn: {
    pattern: {
      value: /^\d{13}$|^\d{15}$/,
      message: 'Поле может содержать только цифры, 13 либо 15 штук',
    },
  },
  bankName: {
    minLength: {
      value: 5,
      message: 'Поле должно содержать как минимум 5 символов',
    },
    pattern: {
      value: /^(?=.*[а-яА-Я])([0-9-\-'"\sа-яА-Я]+)$/,
      message: 'Это поле может содержать только кириллицу, цифры и символы ""-',
    },
  },
  kpp: {
    pattern: {
      value: /^\d{9}$|^0$/,
      message: 'Поле должно содержать 9 цифр (для ИП введите 0)',
    },
  },
  regionName: {
    minLength: {
      value: 4,
      message: 'Поле должно содержать как минимум 4 символов',
    },
    pattern: {
      value: /^(?=.*[а-яё])[а-яё\d\p{P}\s]+$/iu,
      message: 'Это поле может содержать только кириллицу, цифры и спецсимволы',
    },
  },
  mask: {
    required: defaultMessage,
  },
};
