import { FC } from 'react';

import { NavLink } from 'react-router-dom';

import {
  NotFoundIcon
} from 'assets/icons';

import { ROUTES } from 'common/constants';

import staticData from 'common/static.json';
const pageStaticText = {
  ...staticData.notFound
};

export const NotFoundPage: FC = () => {
  return (
    <div className='not-found'>
      <div className='not-found--title flex justify-content-center flex-wrap'>
        <span>{ pageStaticText.title }</span>
      </div>
      <div className='not-found--img flex justify-content-center flex-wrap'>
        <NotFoundIcon />
      </div>
      <div className='not-found--description flex justify-content-center flex-wrap'>
        <div>
          <span>{ pageStaticText.descsription }</span>
        </div>
      </div>
      <div className='not-found--btn flex justify-content-center flex-wrap'>
        <NavLink
          to={ ROUTES.LOGIN }
        >
          <button>
            { pageStaticText.buttonText }
          </button>
        </NavLink>
      </div>
    </div>
  )
};