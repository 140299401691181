import { Button } from 'primereact/button';
import { FC } from 'react';

interface Props {
  confirm: () => void;
  close: () => void;
}

const WarningDialog: FC<Props> = ({ confirm, close }) => {
  return (
    <div className='profile w-full p-6 flex flex-column gap-6'>
      <span className='font-medium text-xl text-center'>Вы уверены?</span>
      <div className='text-center'>
        Все введенные изменения отобразятся в заказах со статусом
        <br />
        "Подтверждение заказа" и "Подписание документов"
      </div>
      <div className='flex gap-6 justify-content-between flex-wrap'>
        <Button className='button-white' label='Назад' onClick={close} />
        <Button
          className='button-green'
          label='Подтвердить'
          onClick={confirm}
        />
      </div>
    </div>
  );
};

export default WarningDialog;
