import { useState, FC } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import staticData from 'common/static.json';
import { setNotification } from 'store/ui/reducer';
import { CartCompletion } from 'components/Cart/cart-completion';
import { useAppSelector, useAppDispatch } from 'store';

export const CartPrices: FC = () => {
  const [isShowDialog, showDialog] = useState(false);
  const dispatch = useAppDispatch();

  const {
    list
  } = useAppSelector(state => state.carts);

  const { common } = useAppSelector(state => state.profile);

  const initialValue = 0;
  const finalPrice = list.reduce((acc, currentValue) => acc + currentValue.pricePerSingleProject * currentValue.count, initialValue);

  const makeOrder = () => {
    if (common?.emailConfirmationStatus !== 'CONFIRMED') {
      dispatch(setNotification({
        id: Math.random(),
        message: 'Пожалуйста, подтвердите электронную почту',
        type: 'error',
        header: '',
      }));
    } else {
      showDialog(true);
    }
  }

  return (
    <>
      <Dialog visible={isShowDialog} style={{ width: '50vw' }} onHide={() => showDialog(false)} className='card-dialog order--dialog'>
        <CartCompletion />
      </Dialog>
      <div className='cart-main__prices cart-prices'>
        <div className="cart-prices__wrapper">
          <div className='cart-prices__total'>
            <div className='cart-prices__header cart-prices-header'>
              <span className='cart-prices-header__text'>Итого</span>
              <span className='cart-prices-header__summ'>{ finalPrice ? (finalPrice.toFixed()).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ') : '' } ₽</span>
            </div>
            <div className="cart-prices__counts cart-prices-counts">
              { list.map((order, key) => {
                return <div key={ key } className='cart-prices-counts__item'>
                  <span className='cart-prices-counts__text'>{ order.count } товар на сумму</span>
                  <span className='cart-prices-counts__price'>{ order.pricePerSingleProject ? (order.pricePerSingleProject.toFixed()).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ') : '' } ₽</span>
                </div>
              })}
            </div>
          </div>

          <Button
            onClick={() => { makeOrder() }}
            label='Оформить заказ'
            className='cart-prices__button'
          />
        </div>
      </div>
    </>
  )
};