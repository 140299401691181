import { FC, useEffect, useState } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';

import { useAppSelector, useAppDispatch } from 'store';
import { changeCountCarts, removeCarts } from 'store/carts/actions';

import { QuantityButton } from 'components/common/QuantityButton'

import { ICartData } from 'store/carts/types';

import TempImageDetail from 'assets/images/temp-order-detail.png'

export const CartInfo: FC<ICartData> = (cart) => {
  const dispatch = useAppDispatch();
  const [orderCount, setCount] = useState(cart.count);
  const [isShowAllOption, setShowAllOption] = useState(false);

  useEffect(() => {
    if (orderCount !== cart.count) {
      dispatch(changeCountCarts({
        cartElementId: cart.id,
        count: orderCount
      }));
    }
  }, [orderCount]);

  const removeCart = () => {
    dispatch(removeCarts(cart.id));
  }
  
  return (
    <div className='cart-item-wrapper'>
        
        <img src={ cart?.projectConfigImage?.fileUrl } className='cart-item-wrapper__img'/>


        <div className='cart-item-wrapper__info cart-item-info'>

          <div className='cart-item-info__header'>

            <h2 className="cart-item-info__title">{ cart?.projectData?.model }</h2>

            <div className='cart-item-info__deleted' onClick={ () => { removeCart() }}>Удалить</div>

          </div>

          <div className='cart-item-info__materials cart-item-materials'>
            {
              cart?.options?.map((option, key) => {
                return <div key={ key } className='cart-item-materials__item cart-item-materials-item'>
                  {
                    key < 5 && 
                    <span className='cart-item-materials-item__text'>{ option.option }: { option.variant }</span>
                  }
                  { (key === 5 && !isShowAllOption) &&
                    <span className='cart-item-materials-item__more' onClick={ () => { setShowAllOption(true) } }>Все характеристики</span>
                  }
                  {
                    key >= 5 &&
                    <>
                      { isShowAllOption &&
                        <div className='hidden-options'>
                          <span className='cart-item-materials-item__text'>{ option.option }: { option.variant }</span>
                        </div>
                      }
                    </>
                  }
                  { (key + 1 === cart.options.length && isShowAllOption) &&
                    <span className='cart-item-materials-item__more cart-item-materials-item__more--hidden' onClick={ () => { setShowAllOption(false) } }>Свернуть характеристики</span>
                  }
                </div>
              })
            }
          </div>


          <div className='cart-item-info__calculator cart-item-info-calculator'>
            <div className='cart-item-info-calculator__button'>
              <QuantityButton
                count={ orderCount }
                buttonAction={ setCount }
              />
            </div>
            <span className='cart-item-info-calculator__price'>
              { orderCount * cart.pricePerSingleProject ? ((orderCount * cart.pricePerSingleProject).toFixed()).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ') : '' } ₽
            </span>
          </div>
        </div>
    </div>
  )
};