import { FC, useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';

import { DropdownFilter } from 'components/common/Filters/DropdownFilter';
import { DocumentsNewUpload } from './DocumentsNewUpload';
import { useAppDispatch } from 'store';
import { DocumentsType, INewDocument } from 'store/documents/types';
import { saveDocuments } from 'store/documents/actions';
import { SortDirection } from 'services/api/api';
import { DocumentsCategory } from 'store/documents/types';
import { useAppSelector } from 'store';

import {
  DESCIcon,
  ASCIcon,
  CrossPlusIcon,
  DocumentsSearch,
  DocumentsFilter
} from 'assets/icons';

export const DocumentsFilters: FC<{ applyFilters: ( params:any ) => void, documentCategory:DocumentsCategory, changeDocumentStatus: any, documentStatus:any } > = ({ applyFilters, documentCategory, changeDocumentStatus, documentStatus }) => {
  const dispatch = useAppDispatch();
  //const [documentStatus, setDocumentStatus] = useState(Object.keys(DocumentsType)[0]);
  const [isAddDocumentMode, setDocumentMode] = useState(false);
  const [isFilterMode, seFilterMode] = useState(false);
  const [filters, setFilter] = useState({});
  const [searchName, setSearchName] = useState('');

  const { role } = useAppSelector(
    state => state.profile
  );

  const orderFiltersOprions = [{
    name: 'Заказ #2',
    value: 'id'
  }, {
    name: 'Заказ #3',
    value: 'id'
  }, {
    name: 'Заказ #4',
    value: 'id'
  }, {
    name: 'Заказ #5',
    value: 'id'
  }];

  const sortDirectionFiltersOprions = [{
    name: 'По алфавиту',
    elem: <ASCIcon />,
    value: {
      order: 'NAME',
      direction: 'ASC'
    }
  }, {
    name: 'По алфавиту',
    elem: <DESCIcon />,
    value: {
      order: 'NAME',
      direction: 'DESC'
    }
  }, {
    name: 'По дате',
    elem: <ASCIcon />,
    value: {
      order: 'TIME_CREATED',
      direction: 'ASC'
    }
  }, {
    name: 'По дате',
    elem: <DESCIcon />,
    value: {
      order: 'TIME_CREATED',
      direction: 'DESC'
    }
  }]

  // const sortTypeFiltersOprions = [{
  //   name: 'Трудоустройство',
  //   value: 'EMPLOYMENT'
  // }, {
  //   name: 'Условия и соглашения',
  //   value: 'TERMS_AND_AGREEMENTS'
  // }, {
  //   name: 'Сопроводительные',
  //   value: 'SUPPORTING'
  // }, {
  //   name: 'Финансы',
  //   value: 'FINANCE'
  // }, {
  //   name: 'Текущие',
  //   value: 'OTHERS'
  // }]

  const sortUserFiltersOprions = [{
    name: 'Заказчик',
    value: 'CUSTOMER'
  }, {
    name: 'Представитель',
    value: 'REPRESENTATIVE'
  }, {
    name: 'Риелтор',
    value: 'REALTOR'
  }, {
    name: 'Логист',
    value: 'LOGISTIC'
  }, {
    name: 'Поставщик',
    value: 'PROVIDER'
  }, {
    name: 'Подрядчик',
    value: 'CONTRACTOR'
  }, {
    name: 'Калитка',
    value: 'SYSTEM'
  }, {
    name: 'Все',
    value: 'ALL'
  }];

  const sortTypeFiltersOprions = () => {
    switch(documentCategory) {
      case 'SYSTEM':
        return [];
      case 'EMPLOYMENT':
        return [{
          value: 'EMPLOYMENT',
          name: 'Трудоустройство'
        }, {
          value: 'OTHERS',
          name: 'Текущие'
        }, {
          value: 'FINANCE',
          name: 'Финансы'
        }];
      case 'PARTICIPANTS':
        return [{
          value: 'TERMS_AND_AGREEMENTS',
          name: 'Условия и соглашения'
        }, {
          value: 'SUPPORTING',
          name: 'Сопроводительные'
        }, {
          value: 'FINANCE',
          name: 'Финансы'
        }];
      case 'PROGRAMS':
        return [{
          value: 'TERMS_AND_AGREEMENTS',
          name: 'Условия и соглашения'
        }, {
          value: 'FINANCE',
          name: 'Финансы'
        }];
      case 'MORTGAGE':
        return [{
          value: 'TERMS_AND_AGREEMENTS',
          name: 'Условия и соглашения'
        }, {
          value: 'FINANCE',
          name: 'Финансы'
        }];
      default:
        return []
    }
  }

  const applyDocumentsFilters = (filter: string, value: any) => {
    let filterParams:any = {};
    if (filter === 'direction') {
      filterParams = {
        ...value
      }
    } else {
      filterParams[filter] = value;
    }

    setFilter(prevState => {
      return {
        ...prevState,
        ...filterParams
      }
    });
  }

  useEffect(() => {
    applyFilters(filters);
  }, [filters]);

  return (
    <>
      { role === 'ADMIN' &&
      <div className='documents-filters'>
        <div className="documents-filters__wrapper">
          { documentCategory === 'PARTICIPANTS' &&
          <div className='documents-filters__status documents-filters-status'>
            {
              Object.keys(DocumentsType).map((status, key) => {
                return <button
                  className={ documentStatus === status ? 'documents-filters-status__button documents-filters-status__button--active': 'documents-filters-status__button' }
                  onClick={() => { changeDocumentStatus(status) }}>{ DocumentsType[status] }
                </button>
              })
            }
          </div>
          }
          <button onClick={() => setDocumentMode(true)} className='documents-filters-added__button'>
            Добавить документ
            <div className="documents-filters-added__icon">
              <CrossPlusIcon />
            </div> 
          </button>
        </div>

        <Dialog visible={isAddDocumentMode} onHide={() => setDocumentMode(false)} className='documents-added-modal'>
          <DocumentsNewUpload
            documentCategory={ documentCategory }
            postAction={ () => applyFilters(filters) }
            onClose={ () => setDocumentMode(false) }
          />
        </Dialog>

    </div>
  }

    <div className="documents-filters-filter">
      <div className="documents-filters-filter__wrapper">
        { 
          documentCategory !== 'SYSTEM' &&
          <>
            <div className="documents-filters-filter-item">
              <span className="documents-filters-filter-item__name">Тип документа</span>
              <DropdownFilter
                fieldName='direction'
                options={sortTypeFiltersOprions()}
                selectAction={(value: string) => { applyDocumentsFilters('type', value) }}
                className='documents-filters-filter-item__dropdown'
              />
            </div>

            {/* <div className="documents-filters-filter-item">
              <span className="documents-filters-filter-item__name">Тип пользователя</span>
              <DropdownFilter
                fieldName='direction'
                options={sortUserFiltersOprions}
                selectAction={(value: string) => { applyDocumentsFilters('signerTypes', value) }}
                className='documents-filters-filter-item__dropdown'
              />
            </div> */}
          </>
        }
        {/* <div className="documents-filters-filter-item">
          <span className="documents-filters-filter-item__name">Номен заказа</span>
          <DropdownFilter
            fieldName='orderNumbers'
            options={orderFiltersOprions}
            selectAction={(value: string) => { applyDocumentsFilters('order', value) }}
            className='documents-filters-filter-item__dropdown'
          />
        </div> */}

        <div className="documents-filters-filter-item">
          <span className="documents-filters-filter-item__name">Сортировка</span>
          <DropdownFilter
            fieldName='direction'
            options={sortDirectionFiltersOprions}
            selectAction={(value: string) => { applyDocumentsFilters('direction', value) }}
            className='documents-filters-filter-item__dropdown'
          />
        </div>

        <div className="documents-filters-filter-item documents-filters-filter-item--search">
          <div className="documents-filters-filter-item__form">
            <input
              id='search'
              className='documents-filters-filter-item__search input--new'
              type="text"
              placeholder='Поиск'
              value={searchName}
              onChange={ e => setSearchName(e.target.value) }
              onKeyDown={(e) => {
                if (e.key === "Enter")
                  applyDocumentsFilters('documentName', searchName)
                }}
            />
            <label htmlFor="search" className='documents-filters-filter-item__icon' onClick={ () => { 
              applyDocumentsFilters('documentName', searchName)
             }}>
              <DocumentsSearch />
            </label>
          </div>
        </div>

        <div className="documents-filters-filter__search documents-filters-filter-search input--new">
          <span className="documents-filters-filter-search__text" onClick={ () => seFilterMode(true) }>Фильтр</span>
           <div className="documents-filters-filter-search__icon">
            <DocumentsFilter />
           </div>
        </div>

        <Dialog visible={isFilterMode} onHide={() => seFilterMode(false)} header="Фильтр" className='documents-filter-modal'>
          <div className="documents-filter-modal__wrapper">

            <div className="documents-filter-modal-input__wrapper">
              <span className="documents-filter-modal-input__lable">Тип документа</span>
              <DropdownFilter
                fieldName='direction'
                options={sortDirectionFiltersOprions}
                selectAction={(value: string) => { applyDocumentsFilters('direction', value) }}
                className='documents-filter-modal-input__item documents-filter-modal-input__item--dropdown'
              />
            </div>

            <div className="documents-filter-modal-input__wrapper">
              <span className="documents-filter-modal-input__lable">Сортировка</span>
              <DropdownFilter
                fieldName='direction'
                options={sortDirectionFiltersOprions}
                selectAction={(value: string) => { applyDocumentsFilters('direction', value) }}
                className='documents-filter-modal-input__item documents-filter-modal-input__item--dropdown'
              />
            </div>

            <div className="documents-filter-modal-input__wrapper">
              <form action="#" className="documents-filter-modal-input__form">
                <input id='search' className='documents-filter-modal-input__search input--new' type="text"  placeholder='Поиск'/>
                <label htmlFor="search" className='documents-filter-modal-input__icon'>
                  <DocumentsSearch />
                </label>
              </form>
            </div>

            

            <button className="documents-filter-modal__button">Применить</button>
          </div>
        </Dialog>

      </div>
    </div>
    </>
  )
};