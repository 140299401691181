import { FC } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { routesPath } from 'routes';
import { Button } from 'primereact/button';
import { REPRESENTATIVE_ISSUE_ORDER } from 'store/partners/types';
import { RealtorStatus } from 'store/orders/types';
import { paymentMethods } from 'store/orders/types';
import {
  IOrderItemData
} from 'store/orders/types';


export const OrderRealtor: FC<{ order:IOrderItemData }> = ({ order }) => {
  const navigate = useNavigate();
  const [orderData, setOrderData] = useState({});

  useEffect(() => {
    if (order?.payloadData) {
      setOrderData(JSON.parse(order?.payloadData));
    }
  }, [order]);

  const openOrder = () => {
    navigate(`${routesPath.orderDetail.replace(':id',order?.orderId )}`, { state: {
      isPartnerOrder: true,
      partnerType: 'realtor'
    }});
  }

  console.info(orderData?.incomeFrom);
  return (
    <div className='orders-list--list_item orders-list--list_item-role-realtor w-full'>
      <div className='flex justify-content-between flex-wrap w-full'>
        <div className='orders-list--list_item--title'>
          <span>Заказ { order?.publicId }</span><span>{ moment(order.timeCreated).format('L').replaceAll('/', '.') }</span>
        </div>
        <div className='flex justify-content-end flex-wrap'>
          <div className='orders-list--list_item--price'>
            <span> от  { orderData?.incomeFrom ? (orderData?.incomeFrom?.toFixed()).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ') : ''} ₽ до { orderData?.incomeTo ? (orderData?.incomeTo?.toFixed()).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ') : '' } ₽ </span>
          </div>
          <div className='orders-list--list_item--button'>
            <span>{ RealtorStatus[order?.status] }</span>
          </div>
        </div>
      </div>

      <div className='orders-list--list_item--info orders-list__wrapper orders-list-wrapper'>
        <div className='orders-list-wrapper__item'>
          <div>
            <span>Заказчик: </span>
            <span>{ orderData?.customerFullName }</span>
          </div>
          <div>
            <span>Номер телефона заказчика: </span>
            <span>{ orderData?.customerPhoneNumber }</span>
          </div>
          <div>
            <span>Область: </span>
            <span>{ orderData?.region }</span>
          </div>
        </div>
        <div className='orders-list-wrapper__item'>
          <div>
            <span>Способ оплаты: </span>
            <span>{ paymentMethods[orderData?.paymentMethod] }</span>
          </div>
          <div>
            <span>Наименование товара: </span>
            <span>{ orderData?.projectName }</span>
          </div>
          <div>
            <span>Продавец: </span>
            <span>{ orderData?.salesmanFullName }</span>
          </div>
        </div>
      </div>
      <button onClick={ () => { openOrder() } } className='orders-list__button'>
        Перейти
      </button>
    </div>
  )
}