import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { FormProvider, useForm } from 'react-hook-form';
import { useMultiStepForm } from 'hooks/useMultiStepForm';
import CompleteIndividualDataFirstStep from './CompleteIndividualDataFirstStep';
import CompleteIndividualDataSecondStep from './CompleteIndividualDataSecondStep';
import { useAppDispatch, useAppSelector } from 'store';
import { FormatTo, formatDate } from 'utils/formatDate';
import staticData from 'common/static.json';
import WarningDialogTest from 'components/common/WarningDialogTest';
import CompleteDataFinish from '../CompleteDataFinish';
import { updateUser } from 'store/profile/actions';
import { saveBankDetails } from 'store/referral/actions';
import { uiSlice } from 'store/ui/reducer';

const text = staticData.referral;
const buttons = staticData.buttons;
const notificationText = staticData.notifications.referral;

const CompleteIndividualData: React.FC = () => {
  const { common, individualUser } = useAppSelector(state => state.profile);
  const { id } = useAppSelector(state => state.auth);
  const { setNotification } = uiSlice.actions;
  const dispatch = useAppDispatch();
  const methods = useForm({ mode: 'all' });
  const {
    formState: { isDirty },
  } = methods;
  const { Step, next, back, isFirstStep, isLastStep } = useMultiStepForm([
    CompleteIndividualDataFirstStep,
    CompleteIndividualDataSecondStep,
  ]);
  const navigate = useNavigate();

  const notification = {
    id: Math.random(),
    header: notificationText.header,
    message: notificationText.message,
    type: 'notification',
  };

  const onSubmit = (formData: any) => {
    const bankData = {
      bankAccount: formData.bankAccount,
      correspondentAccount: formData.correspondentAccount,
      bankName: formData.bankName,
      bic: formData.bic,
      inn: formData.bankInn,
    };
    dispatch(saveBankDetails(bankData));
    setDialog(false);
    navigate('/profile');
    dispatch(setNotification(notification));
  };
  const handleFinish = (e: any) => {
    e.preventDefault();
  };

  const handleBack = (e: any) => {
    e.preventDefault();
    back();
  };

  const handleWarning = (formData: any) => {
    const userData = {
      individualUserData: {
        passportSeries:
          (formData.passport && formData.passport.split(' ')[0]) || '',
        passportNumber:
          (formData.passport && formData.passport.split(' ')[1]) || '',
        passportIssuedBy: formData.passportIssuedBy,
        passportIssueDate: formatDate(
          formData.passportIssueDate,
          FormatTo.server
        ),
        iian: formData.iian,
        inn: formData.inn,
        residenceAddress: individualUser.residenceAddress,
      },
      commonUserData: {
        name: common.name,
        accountType: common.accountType,
        region: common.region,
      },
    };
    if (isFirstStep) {
      if (isDirty) {
        dispatch(updateUser({ id: id, data: userData }));
      }
      next();
    }
    if (!isFirstStep && !isLastStep) {
      next();
    }
    if (isLastStep) {
      setDialog(true);
    }
  };

  const defaultValues = {
    name: common.name,
    passport:
      `${individualUser.passportSeries} ${individualUser.passportNumber}` || '',
    passportIssuedBy: individualUser.passportIssuedBy || '',
    passportIssueDate:
      formatDate(individualUser.passportIssueDate, FormatTo.client) || '',
    iian: individualUser.iian || '',
    inn: individualUser.inn || '',
  };

  useEffect(() => {
    methods.reset({ ...defaultValues });
  }, [individualUser]);

  const [dialog, setDialog] = useState(false);

  return (
    <div className='referral-form'>
      <WarningDialogTest
        isVisible={dialog}
        onClose={() => {
          setDialog(false);
        }}
        onConfirm={methods.handleSubmit(onSubmit)}
        confirmLabel={buttons.save}
        declineLabel={buttons.back}
        declineButtonClassName={'button-white referral-form-button'}
        confirmButtonClassName={'button-green referral-form-button'}
        wrapperClassName='referral-form'
      >
        <CompleteDataFinish />
      </WarningDialogTest>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(handleWarning)}
          className='auth-form flex flex-column gap-3 justify-content-center mt-5 mb-0 grid'
        >
          <span className='font-medium text-2xl text-center'>
            {text.referral}
          </span>
          <Step />
          <div className='flex flex-wrap gap-3 justify-content-between mt-3'>
            <Button
              className='button-white referral-form-button'
              label='Назад'
              disabled={isFirstStep}
              onClick={handleBack}
            />
            <Button
              className='button-green referral-form-button'
              onClick={() => (isLastStep ? handleFinish : null)}
              label={isLastStep ? buttons.done : buttons.forward}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default CompleteIndividualData;
