import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'store';
import { getCategory, getTemplate, createOffer, getOffer } from 'store/commercial/actions';
import { setCategory, clearTemplates } from 'store/commercial/reducer';

import { Accordion, AccordionTab } from 'primereact/accordion';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { routesPath } from 'routes';

import {
  PdfDocumentIcon
} from 'assets/icons';

import staticData from 'common/static.json';
const pageStaticText = {
  ...staticData.offer
};

export const OffersCategory: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);

  const {
    categories,
    selectedCategories,
    templates,
    offers
  } = useAppSelector(state => state.commercial);

  useEffect(() => {
    dispatch(getOffer());
    dispatch(getCategory());
  }, []);

  useEffect(() => {
    const newTemplates = templates.map(template => {
      return {
        id: template.categoryId,
        name: selectedCategories.find(elem => elem.id === template.categoryId)?.name,
        list: template.data.map((data, index) => {
          return {
            index: index + 1,
            ...data,
            productionTime: '',
            unitPrice: ''
          }
        })
      }
    }) || [];

    setTableData(newTemplates);
  }, [templates]);

  useEffect(() => {
    if (offers.length) {
      navigate(routesPath.profile);
    }
  }, [offers]);

  const checkCategory = (category) => {
    let categoriesTemp = structuredClone(selectedCategories);
    if (isCategorySelected(category)) {
      categoriesTemp.push(category);
      dispatch(setCategory(categoriesTemp))
      dispatch(getTemplate(category.id));
    } else {
      categoriesTemp = categoriesTemp.filter(item => item.id !== category.id);
      dispatch(setCategory(categoriesTemp));
      dispatch(clearTemplates(category.id));
    }
  }
  
  const isCategorySelected = (category) => {
    const { id } = category;
    const isContains = selectedCategories.find(elem => elem.id === id);
    return isContains === undefined;
  }

  const tableDocumentIcon = () => {
    return <div className='offer--table-documents' ><PdfDocumentIcon /></div>
  }

  const tableNumberChangeHTML = (options, minValue, maxValue) => {
    return <div className='offers--category_table-input'><input value={options.value} onChange={(e) => options.editorCallback(e.target.value)}  type='number' min={minValue} max={maxValue} /></div>
  }

  const sendOfferData = () => {
    const sendData = tableData.map(data => {
      const temp = data.list.map(item => {
        return {
          productionTime: item.productionTime,
          unitPrice: item.minimumOrderQuantity,
          nomenclatureId: item.id,
          categoryId: data.id,
        }
      });

      return temp;
    })

    dispatch(createOffer(sendData[0]));
    navigate(routesPath.ordersList);
  }

  return (
    <div className='offers--category offers--category-post'>
      <div className='offers--category_title'>
        <span>{ pageStaticText.pro.title }</span>
      </div>
      <div className='offers--category_list'>
        <ul>
          {
            categories.map((category, key) => {
              return <li className={!!!isCategorySelected(category) ? 'active': ''} onClick={() => checkCategory(category)} key={key}>{ category.name }</li>
            })
          }
        </ul>
      </div>
      { (tableData.length > 0 && offers.length === 0) && 
        <div className='offers--category_details'>
          <div className='offers--category_details_title'>
            <span>{ pageStaticText.pro.tableTitle }</span>
          </div>
          <div className='offers--category_table offer--table flex justify-content-start flex-wrap'>
            <Accordion activeIndex={0}>
              {
                tableData.map((table, key) => {
                  return <AccordionTab key={key} header={table?.name}>
                      <DataTable
                        value={ table?.list }
                        tableStyle={{ width: '100%' }}
                      >
                        <Column field='index' header={ pageStaticText.table.fields.number }></Column>
                        <Column field='title' style={{ minWidth: '60%', width: '60%' }} header={ pageStaticText.table.fields.name }></Column>
                        <Column field='documentLink' body={ tableDocumentIcon } header={ pageStaticText.table.fields.task }></Column>
                        <Column field='minimumOrderQuantity' header={ pageStaticText.table.fields.minCount }></Column>
                        <Column field='productionTime' editor={(options) => tableNumberChangeHTML(options, 1, 60)} header={ pageStaticText.table.fields.deadline }></Column>
                        <Column field='unitPrice'  editor={(options) => tableNumberChangeHTML(options, 1, null)}  header={ pageStaticText.table.fields.amount }></Column>
                      </DataTable>
                    </AccordionTab>
                })
              }
              </Accordion>
          </div>
        </div>
      }
      { (tableData.length > 0 && offers.length === 0) &&
        <div className='offers--category_sendbutton flex justify-content-end flex-wrap'>
          <button onClick={() => sendOfferData()}>{ pageStaticText.pro.sendButton }</button>
        </div>
      }
    </div>
  )
};