import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import { Button } from 'primereact/button';

import InputTest from 'components/common/InputTest';
import { AuthData } from 'components/Auth/types';

import { routesPath } from 'routes';

import { useAppSelector } from 'store';
import { registration } from 'store/auth/actions';
import { useAppDispatch } from 'store';

import staticData from 'common/static.json';
import { validations } from 'helpers/validations';

import { Check } from 'assets/icons';

export const RegistrationPage: FC = () => {
  const dispatch = useAppDispatch();

  //const { watch } = useFormContext();

  const navigate = useNavigate();
  
  const { id, token } = useAppSelector(state => state.auth);

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      phoneNumber: '',
      email: '',
      password: '',
      confirmPassword: '',
      tos: false,
    },
  });

  useEffect(() => {
    if (id && token) {
      navigate(routesPath.ordersList);
    }
  }, [id, token]);

  // const confirmPassword = {
  //   ...validations.password,
  //   validate: (value: string) =>
  //     value === watch('newPassword') || 'Пароли не совпадают',
  // };

  const onSubmit = (data: any) => {
    dispatch(
      registration({
        email: data.email,
        password: data.password,
        phoneNumber: data.phoneNumber.replace(/[^0-9.]/g, ''),
      })
    );
  };

  return (
    <div className='auth flex justify-content-center flex-wrap'>
      <FormProvider {...methods}>
        <form
          className='p-d-flex p-jc-center p-mt-2 w-8 auth-form'
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <div className='flex justify-content-center flex-wrap gap-2'>
            <span className='auth-form-title'>
              {staticData.auth.registration.title}
            </span>
          </div>
          <div className='flex flex-column gap-2'>
            <InputTest
              name='phoneNumber'
              validations={validations.phoneNumber}
              classNameInput='auth-form-input auth-form-registration'
              classNameLabel='auth-form-label'
              classNameError='input-error-info'
              classNameValid='input-valid-auth'
              placeholder={
                staticData.auth.registration.placeholders.phoneNumber
              }
              label={staticData.auth.registration.labels.phoneNumber}
              mask={'+7(999)999-99-99'}
            />
          </div>
          <div className='flex flex-column gap-2'>
            <InputTest
              name='email'
              validations={validations.email}
              classNameInput='auth-form-input auth-form-registration'
              classNameLabel='auth-form-label'
              classNameError='input-error-info'
              classNameValid='input-valid-auth'
              placeholder={staticData.auth.registration.placeholders.email}
              label={staticData.auth.registration.labels.email}
            />
          </div>
          <div className='flex flex-column gap-2'>
            <InputTest
              name='password'
              type='password'
              validations={validations.password}
              classNameInput='auth-form-input auth-form-registration'
              classNameLabel='auth-form-label'
              classNameValid='input-valid-auth'
              classNameError='input-error-auth'
              placeholder={staticData.auth.registration.placeholders.password}
              label={staticData.auth.registration.labels.password}
            />
          </div>
          <div className='flex flex-column gap-2'>
            <InputTest
              name='confirmPassword'
              type='password'
              validations={validations.password}
              classNameInput='auth-form-input auth-form-registration'
              classNameLabel='auth-form-label'
              classNameValid='input-valid-auth'
              classNameError='input-error-auth'
              placeholder={
                staticData.auth.registration.placeholders.confirmPassword
              }
              label={staticData.auth.registration.labels.confirmPassword}
            />
          </div>

          <label className='auth-form-agreement' for="tos">
            <input className='auth-form-agreement__input' name='tos' type='checkbox' id='tos' />
            <div className="auth-form-agreement__checkbox"><Check /></div>
            <span className='auth-form-agreement__text'>Согласие с&nbsp;
              <a onClick={ () => { navigate('/terms/tos') } } className='auth-form-agreement__link'>Пользовательским соглашением</a> и <a onClick={ () => { navigate('/terms/toa') } } className='auth-form-agreement__link'>Политикой обработки персональных данных</a>
            </span>
          </label>

          <div className='flex justify-content-center flex-wrap align-items-stretch flex-wrap'>
            <Button
              className='auth-form-registration-button flex align-items-center justify-content-center'
              label={staticData.auth.registration.button}
              type='submit'
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
