import { FC } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { routesPath } from 'routes';
import { Button } from 'primereact/button';
import { REPRESENTATIVE_ISSUE_ORDER } from 'store/partners/types';
import { useAppDispatch } from 'store';
import { getPartnerIssue } from 'store/orders/actions';
import {
  IOrderItemData
} from 'store/orders/types';

export const OrderRepresentative: FC<{ order:IOrderItemData }> = ({ order }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [orderData, setOrderData] = useState({});

  useEffect(() => {
    if (order?.payloadData) {
      setOrderData(JSON.parse(order?.payloadData));
    }
  }, [order]);

  const openOrder = async () => {
    await dispatch(getPartnerIssue(order?.id));
    navigate(`${routesPath.orderDetail.replace(':id', order?.orderId )}`, { state: {
      isPartnerOrder: true,
      partnerType: 'representative'
    }});
  }
  return (
    <div className='orders-list--list_item orders-list--list_item-role-representative'>
      <div className='flex justify-content-between flex-wrap w-full'>
        <div className='orders-list--list_item--title'>
          <span>Заказ { order?.publicId }</span><span>{ moment(order.timeCreated).format('L').replaceAll('/', '.') }</span>
        </div>
        <div className='orders-list--list_item--button'>
          <span>{ REPRESENTATIVE_ISSUE_ORDER[order.status] }</span>
        </div>
      </div>

      <div className='orders-list--list_item--info w-full project-name'>
        <span style={{fontSize: '24px'}}> { orderData?.projectName } </span>
      </div>
      <div className='orders-list--list_item--info w-full'>
        <div>
          <span>Представитель: </span>
          <span>{ orderData?.organizationName }</span>
        </div>
        <div>
          <span>Номер телефона представителя: </span>
          <span>{ orderData?.phoneNumber }</span>
        </div>
        <div>
          <span>Местоположение участка: </span>
          <span>{ orderData?.location }</span>
        </div>
        <div className='flex justify-content-end flex-wrap orders-list--list-order_button w-full'>
          <Button
            onClick={() => { openOrder() }}
            className='orders-list--list-order_button_btn'
            label='Перейти'
          />
        </div>
      </div>
    </div>
  )
}