import { FC } from 'react';
import { useAppSelector } from 'store';

import { MarketsListItem } from './MarketsListItem';

export const MarketsList: FC = () => {
  const { projects } = useAppSelector(state => state.projects);

  return (
    <div className='projects-list'>
      <div className="projects-list__wrapper">
        {
          projects?.data?.map((project, key) => {
            return <MarketsListItem key={key} project={ project }/>
          })
        }
      </div>
      {/* <div className="projects-list__more">Посмотреть еще</div> */}
    </div>
  );
};