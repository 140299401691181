import { createAsyncThunk } from '@reduxjs/toolkit';

import { documentsApi } from 'services/api/documentsApi';
import {
  DOCUMENTS_GET_LIST,
  DOCUMENTS_SAVE,
  DOCUMENTS_GET_FILE,
  DOCUMENTS_REMOVE_FILE,
  DOCUMENTS_FETCH_FILLED,
  DOCUMENTS_FETCH_PARTNER_FILE,
  DOCUMENTS_FETCH_ORDERS_FILE,
  DOCUMENTS_FETCH_ORDERS_FOR_CUSTOMER,
  DOCUMENTS_ADD_FOR_ORDER,
  DOCUMENTS_CHANGE_VISIBILITY_CUSTOMER,
  DOCUMENTS_CHANGE_VISIBILITY_PARTNER,
  DOCUMENTS_GET_PARTNERS_DOCUMENTS,
  INewDocument
} from './types';

import { IDocumentsParams } from 'services/api/documentsApi';

export const getDocuments = createAsyncThunk(
  DOCUMENTS_GET_LIST,
  async (params: any) => {
    const response = await documentsApi.getDocuments(params);
    return response;
  }
);

export const saveDocuments = createAsyncThunk(
  DOCUMENTS_SAVE,
  async (params: any) => {
    const response = await documentsApi.saveDocuments(params);
    return response;
  }
);

export const getDocumentFile = createAsyncThunk(
  DOCUMENTS_GET_FILE,
  async (id: any) => {
    const response = await documentsApi.getDocumentsFile(id);
    return response;
  }
);

export const removeDocumentTemplate = createAsyncThunk(
  DOCUMENTS_REMOVE_FILE,
  async (id: any) => {
    const response = await documentsApi.removeDocumentTemplate(id);
    return response;
  }
);

export const getFilledDocuments = createAsyncThunk(
  DOCUMENTS_FETCH_FILLED,
  async (params: any) => {
    const response = await documentsApi.getFilledDocuments(params);
    return response;
  }
);

export const getFilledDocumentId = createAsyncThunk(
  DOCUMENTS_FETCH_PARTNER_FILE,
  async (id: any) => {
    const response = await documentsApi.getFilledDocumentId(id);
    return response;
  }
);

export const getOrdersDocumentsById = createAsyncThunk(
  DOCUMENTS_FETCH_ORDERS_FILE,
  async (id: any) => {
    const response = await documentsApi.getOrdersDocumentsById(id);
    return response;
  }
);

export const getOrdersTemplateDocuments = createAsyncThunk(
  DOCUMENTS_FETCH_ORDERS_FOR_CUSTOMER,
  async (params) => {
    const response = await documentsApi.getOrdersTemplateDocuments(params);
    return response;
  }
);
export const addTemplatesForOrder = createAsyncThunk(
  DOCUMENTS_ADD_FOR_ORDER,
  async ({id, params}) => {
    const response = await documentsApi.addTemplatesForOrder(id, params);
    return response;
  }
);

export const changeVisibilityForCustomer = createAsyncThunk(
  DOCUMENTS_CHANGE_VISIBILITY_CUSTOMER,
  async (id) => {
    const response = await documentsApi.changeVisibilityForCustomer(id);
    return response;
  }
);

export const changeVisibilityForPartner = createAsyncThunk(
  DOCUMENTS_CHANGE_VISIBILITY_PARTNER,
  async (id) => {
    const response = await documentsApi.changeVisibilityForPartner(id);
    return response;
  }
);

export const getOrdersTemplateDocumentsForPartners = createAsyncThunk(
  DOCUMENTS_GET_PARTNERS_DOCUMENTS,
  async ({id, params }) => {
    const response = await documentsApi.getOrdersTemplateDocumentsForPartners(id, params);
    return response;
  }
);
