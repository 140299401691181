import { FC, useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch} from 'store';
import { Accordion, AccordionTab } from 'primereact/accordion';
import moment from 'moment';

import { RepresentativeInputs } from './Inputs';
import { paymentStatus } from 'store/orders/types';
import TempImageDetail from 'assets/images/temp-order-detail.png'
import TempImage from 'assets/images/temp-order.png'
import { ProjectDetail } from 'components/common/Order/ProjectDetail';

export const RepresentativeIssue:FC = () => {
  const dispatch = useAppDispatch();

  const { order } = useAppSelector(state => state.orders);

  const [accordionButton, setAccordionButton] = useState('Раскрыть');

  // useEffect(() => {
  //   dispatch(getExecutors({ orderExecutorTypes: Object.keys(PartnerTypes) }));
  // }, []);
  
  return (
    <div className='order'>
      <div className='order-detail flex align-items-start justify-content-center'>
        <div className='order-detail--wrapper'>
        <div className='order-detail_info flex flex-wrap'>
          <div className='order-detail_info--preview flex'> 
            <img src={ order?.order?.projectConfigImage?.fileUrl } />
          </div>
          <div className='order-detail_info--info flex'>
            <div>
              <div>
                <ProjectDetail
                  number={ `Заказ № ${order?.order?.number}` }
                  name={ order?.order?.projectName }
                  date={ moment(order?.order?.timeCreated).format('L').replaceAll('/', '.') }
                />
              </div>
              <div className='order-detail_info--trim'>
              {
                order?.order?.options.map((option, key) => {
                  return <div key={ key } className='order-detail_info--trim_wall'>
                    {
                      <span>{ option.option }: { option.variant }</span>
                    }
                  </div>
                })
              }
              </div>
              <div className='order-detail_info--price'>
                <div>{ order?.order?.amount } ₽</div>
              </div>
            </div>
          </div>
          <div className='order-detail_info--status'>
            <div>{ paymentStatus[order?.order?.paymentStatus] }</div>
          </div>
        </div>
        <div>
          { order?.order?.subOrderData.length > 0 &&
            <div className='order-detail_info--additional'>
              <Accordion
                activeIndex={1}
                onTabOpen={ () => { setAccordionButton('Свернуть') }}
                onTabClose={ () => { setAccordionButton('Раскрыть') }}
              >
                <AccordionTab
                  header={
                    <div className='order-detail_info--additional_accordion flex justify-content-between flex-wrap'>
                      <div className='order-detail_info--additional_accordion_title'>
                        <span>Дополнительная комплектация   </span>
                      </div>
                      <div className='order-detail_info--additional_accordion_img'>
                        <ul>
                          { order?.order?.subOrderData.map((subOrder, key) => {
                              return <li key={ key }><img src={ TempImage } /></li>
                            })
                          }
                        </ul>
                      </div>
                      <div className='order-detail_info--additional_accordion_ctrl'>
                        <span>{ accordionButton }</span>
                      </div>
                    </div>
                  }
                >
                  <p className='order-detail_info--additional_info m-0'>
                    <ul>
                      { order?.order?.subOrderData.map((subOrder, key) => {
                        return <li key={ key }>
                                <img src={ TempImage } />
                                <span >{ `${subOrder.projectName} ${subOrder.amount} ₽`  }</span>
                              </li>
                        })
                      }
                    </ul>
                  </p>
                </AccordionTab>
              </Accordion>
            </div>
          }
        </div>
        </div>
      <RepresentativeInputs />
      </div>
    </div>
  )
}
