import { AccountType } from 'store/profile/types';

export const UI_REGIONS = 'ui/regions';

export enum PartnerType {
  CONTRACTOR = 'CONTRACTOR',
  OTHER = '',
}

export interface IUIState {
  isGuest: boolean;
  isHideOverflow: boolean;
  partnerType: PartnerType;
  accountType: AccountType;
  regionList: Array<IRegion>;
  regionMap: Array<Object>;
  notifications: Array<INotification>;
  isProToogler: boolean;
}

export interface INotification {
  type: string;
  id: number;
  message: string;
  header: string;
}

export interface IRegion {
  region: string;
  value: string;
}
