import { Button } from 'primereact/button';
import { useAppDispatch } from 'store';
import { getUser, updateUser } from 'store/profile/actions';
import { profileSlice, setIsEditing } from 'store/profile/reducer';
import { AccountType } from 'store/profile/types';

const AccountTypeSelection: React.FC = () => {
  const dispatch = useAppDispatch();
  const { setAccountDialog, setIsEditing, setAccountType } =
    profileSlice.actions;

  const handleClose = () => {
    dispatch(setAccountDialog());
  };

  const handleSelect = (type: string) => {
    dispatch(setAccountType(type));
    dispatch(setAccountDialog());
    dispatch(setIsEditing());
  };

  return (
    <div className='profile w-full p-6 flex flex-column gap-6'>
      <div className='flex justify-content-center flex-wrap gap-2 auth-form-title'>
        <span>Заполнение данных</span>
      </div>
      <div className='p-d-flex p-jc-center gap-2'>
        <div className='flex justify-content-center flex-wrap align-items-stretch flex-wrap auth-form-description'>
          <span>Выберите тип аккаунта</span>
        </div>
        <div className='flex justify-content-around flex-wrap align-items-stretch flex-wrap gap-3'>
          <Button
            className='button-white'
            label='Физ. лицо'
            onClick={() => {
              handleSelect(AccountType.individualUser);
            }}
          />
          <Button
            className='button-white'
            label='Юр. лицо/ИП'
            onClick={() => {
              handleSelect(AccountType.legalUser);
            }}
          />
        </div>
      </div>
      <div className='flex justify-content-center flex-wrap align-items-stretch flex-wrap'>
        <Button
          className='button-green'
          label='Закрыть'
          onClick={handleClose}
        />
      </div>
    </div>
  );
};

export default AccountTypeSelection;
