import { useEffect } from 'react';
import { Button } from 'primereact/button';
import { useAppDispatch } from 'store';
import { getPartners, updateStatus } from 'store/partners/actions';
import { regions } from 'store/ui/actions';
import { IPartnerIssue, IStatus, Status } from 'store/partners/types';

import { PartnerTypes, WorkType } from 'store/partners/types';

import { useAppSelector } from 'store';

import staticData from 'common/static.json';

const text = staticData.partners;

interface Props {
  item: IPartnerIssue;
}

const PartnersItem: React.FC<Props> = ({ item }) => {
  const dispatch = useAppDispatch();

  const { regionMap } = useAppSelector(state => state.ui);

  useEffect(() => {
    dispatch(regions());
  }, [])
  
  const handleTag = () => {
    switch (item.issuePartnerStatus) {
      case 'INACTIVE':
        return <div className='partners-item-tag'>Новая</div>;
      case 'ACTIVE':
        return <div className='partners-item-tag'>Одобрена</div>;
      case 'DISABLED':
        return <div className='partners-item-tag'>Отклонена</div>;
      default:
        break;
    }
  };

  const handleButtons = () => {
    switch (item.issuePartnerStatus) {
      case 'INACTIVE':
        return (
          <div className='flex gap-3 flex-wrap'>
            <Button
              label={text.reject}
              className='button-white partners-filters__button'
              onClick={() => {
                handleStatus({ issuePartnerStatus: Status.disabled });
              }}
            />
            <Button
              label={text.approve}
              className='button-green partners-filters__button'
              onClick={() => {
                handleStatus({ issuePartnerStatus: Status.active });
              }}
            />
          </div>
        );
      case 'ACTIVE':
      case 'DISABLED':
        return (
          <Button
            label={text.changeStatus}
            className='button-green partners-filters__button'
            onClick={() => {
              handleStatus({ issuePartnerStatus: Status.inactive });
            }}
          />
        );
      default:
        break;
    }
  };

  const handleStatus = (data: IStatus) => {
    const id = item.id;
    dispatch(updateStatus({ id, data })).then(() => dispatch(getPartners()));
  };

  return (
    <div className='partners-item flex flex-column gap-2'>
      <div className='flex justify-content-between gap-3 flex-wrap'>
        <span>{`${text.number} ${item.number}`}</span>
        {handleTag()}
      </div>
      <div className='font-medium text-xl flex gap-2'>
        {PartnerTypes[item.partnerType] || item.partnerType}
        {item.workType && <span>{` - ${WorkType[item.workType] || item.workType}`}</span>}
      </div>
      <div className='flex flex-column gap-3'>
        <div className='flex gap-3 flex-wrap'>
          <div className='font-bold'>
            {`${text.orgName}: `}
            <span className='font-normal'>{item.organizationName}</span>
          </div>
          <div className='font-bold'>
            {`${text.phoneNumber}: `}
            <span className='font-normal'>{item.phoneNumber}</span>
          </div>
        </div>
        <div className='flex gap-3 flex-wrap'>
          <div className='font-bold'>
            {`${text.inn}: `}
            <span className='font-normal'>{item.inn}</span>
          </div>
          <div className='font-bold'>
            {`${text.kpp}: `}
            <span className='font-normal'>{item.kpp}</span>
          </div>
          <div className='font-bold'>
            {`${text.ogrn}: `}
            <span className='font-normal'>{item.ogrn}</span>
          </div>
        </div>
        <div className='flex justify-content-between gap-3 flex-wrap'>
          <div className='font-bold'>
            {`${text.region}: `}
            {
              item.regions.map((region) => {
                return <span className='font-normal'>{ regionMap[region] }</span>
              })
            }
          </div>
          {handleButtons()}
        </div>
      </div>
    </div>
  );
};

export default PartnersItem;
