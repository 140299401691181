import { FC, useState, useEffect } from 'react';

import { DocumentsHeader } from './DocumentsHeader';
import { DocumentsFilters } from 'components/Documents/DocumentsFilters';
import { DocumentsUserList } from './DocumentsUserList';

import { useAppDispatch } from 'store';
import { getDocuments, removeDocumentTemplate, getFilledDocuments } from 'store/documents/actions';
import { DocumentsCategory } from 'store/documents/types';

import { getPartnerDocuments } from 'store/users/actions';

import { DocumentsType, INewDocument } from 'store/documents/types';

import { useNavigate } from 'react-router';

import { routesPath } from 'routes';

import { getUserDocuments } from 'store/users/actions';

import { useAppSelector } from 'store';

import { ProfilePersonal, ProfileMoneyBag, ProfileDocuments } from 'assets/icons';
export const UserDocumentsPage: FC = () => {
  const dispatch = useAppDispatch();
  const [documentCategory, setDocumentCategory] = useState(DocumentsCategory.SYSTEM);
  const [currentFilters, setCurrentFilters] = useState({});
  const [documentStatus, setDocumentStatus] = useState(Object.keys(DocumentsType)[0]);

  const { role, legalUser } = useAppSelector(
    state => state.profile
  );

  const navigate = useNavigate();
  useEffect(() => {
    if (role === 'CUSTOMER') {
      dispatch(getUserDocuments({ pageNum: 0, pageSize: 100, proActive: false}));
    } else {
      dispatch(getFilledDocuments({
        pageNum: 0,
        pageSize: 100,
        executionIssuesIds: [legalUser?.partner?.authUserId]
      }))
    }
  }, []);

  const applyFilters = (filters: any) => {
    setCurrentFilters(filters);

    if (role === 'CUSTOMER') {
      dispatch(getUserDocuments({ pageNum: 0, pageSize: 100, proActive: false, ...filters}));
    } else {
      dispatch(getPartnerDocuments({ pageNum: 0, pageSize: 100, ...filters}));
    }
  }

  return (
    <div className='documents'>
       { role === 'CUSTOMER' &&
        <div className="profile-slider">
          <div className="profile-slider__item profile-slider-item" onClick={ () => navigate(routesPath.profile)}>
            <span className="profile-slider-item__text">Личные данные</span>
            <ProfilePersonal className="profile-slider-item__img profile-slider-item__img"/>
          </div>
          <div className="profile-slider__item profile-slider-item" onClick={ () => navigate(routesPath.referral)}>
            <span className="profile-slider-item__text">Реферальная программа</span>
            <ProfileMoneyBag  className="profile-slider-item__img"/>
          </div>
          <div className="profile-slider__item profile-slider-item">
            <span className="profile-slider-item__text">Документы</span>
            <ProfileDocuments  className="profile-slider-item__img profile-slider-item__img--active"/>
          </div>
        </div>
        }
      <DocumentsFilters
        applyFilters={ (filters) => { applyFilters(filters) } }
        documentCategory={ documentCategory }
        changeDocumentStatus={ () => { } }
        documentStatus={ documentStatus }
      />
      <DocumentsUserList />
    </div>
  )
};