import React, { FC, useEffect, useState } from 'react';

import { CrossIcon } from 'assets/icons';


export const ConfirmModalModal: FC<{
  confirmAction: () => void,
  onClose: () => void,
  confirmText: any
}> = ({ confirmAction, onClose, confirmText }) => {
 
  return (
    <div className="categories-modal-changes__wrapper">
      <CrossIcon className='categories-modal-changes__close' onClick={ () => { onClose() }} />
      <p className="categories-modal-changes__title">{ confirmText?.title }</p>
      <p className="categories-modal-changes__text">{ confirmText?.description }</p>
      <div className="categories-modal-changes__buttons categories-modal-changes-buttons">
        <button className="categories-modal-changes-buttons__button" onClick={ () => onClose() }>{ confirmText?.buttonCancel }</button>
        <button className="categories-modal-changes-buttons__button categories-modal-changes-buttons__button--active" onClick={ () => { confirmAction() }  }>{ confirmText?.buttonOk }</button>
      </div>
    </div>
  )
};