import { FC, useState, useEffect } from 'react';
import { FormProvider, useForm, useFormContext, useController } from 'react-hook-form';
import moment from 'moment';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';

import InputTest from 'components/common/InputTest';
import { validations } from 'helpers/validations';

export const AddressForm: FC<{address, submitAction}> = ({ address, submitAction }) => {
  // const [isShowCalendar, setShowCalendar] = useState(false);
  // const [date, setDate] = useState(false);

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      address: address?.address,
      arrivalDate: address?.arrivalDate,
      arrivalHourFrom: address?.arrivalHourFrom,
      arrivalHourTo: address?.arrivalHourTo,
    },
  });
  const { setValue } = useForm();

  const [arrivalTime, setArrivalTime] = useState([]);
  const [date, setDate] = useState(false);
  const arrivalTimes = [
    { name: 'с 9:30 до 12:00', value: ['9:30', '12:00'] },
    { name: 'с 12:30 до 18:00', value: ['12:30', '18:00'] },
    { name: 'с 18:00 до 20:00', value: ['18:00', '20:00'] },
];

  useEffect(() => {
    if (arrivalTime.length) {
      setValue('arrivalHourFrom', arrivalTime[0]);
      setValue('arrivalHourTo', arrivalTime[1]);
    }
  }, [arrivalTime]);

  useEffect(() => {
    if (date) {
      setValue('arrivalDate', moment(date).format('L').replaceAll('/', '.'));
    }
  }, [date]);

  const onSubmit = (data: any) => {
    const dateSplit = moment(date).format('L').split('.');
    submitAction({
      address: data?.address,
      arrivalDate: `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`,
      arrivalHourFrom: arrivalTime[0],
      arrivalHourTo:arrivalTime[1]
    });
  };

  return (
    <div className='flex justify-content-center flex-wrap'>
      <FormProvider {...methods}>
        <form
          className='order--form auth-form auth-form--login p-d-flex p-jc-center p-mt-2 w-8'
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <div className='flex justify-content-center flex-wrap gap-2'>
            <span className='auth-form-title'>
              Редактировать адреса
            </span>
          </div>
          <div className='flex flex-column gap-2'>
            <InputTest
              name='address'
              type='text'
              validations={validations.district}
              classNameInput='auth-form-input auth-form-login'
              classNameLabel='auth-form-label'
              classNameValid='input-valid-auth'
              classNameError='input-error-auth'
              placeholder='Адрес'
              label='Адрес'
            />
          </div>
          <div className='flex flex-column gap-2'>
            <label className=''>
              Дата прибытия
            </label>
            <Calendar value={date} onChange={(e) => setDate(e.value)} inline showWeek />
          </div>
          <div className='flex flex-column gap-2'>
            <label>
              Время прибытия
            </label>
            <Dropdown
              name='arrivalHourFrom'
              options={ arrivalTimes }
              optionLabel='name'
              optionValue='value'
              value={ arrivalTime }
              onChange={ e => { setArrivalTime(e.value) } }
              className='auth-form-input cart--completion_land-selection--dropdown w-full pr-0 flex align-items-center h-3rem w-min'
            />
          </div>
          <div className='flex justify-content-center flex-wrap align-items-stretch'>
            <Button
              className='auth-form-login-button flex align-items-center justify-content-center'
              label='Сохранить изменения'
              type='submit'
            />
          </div>
          
        </form>
      </FormProvider>
    </div>
  );
};
