import { FC, useState, useEffect } from 'react';
import { useSearchParams, NavLink } from 'react-router-dom';

import { PasswordRestoreIcon  } from 'assets/icons';
import { requestEmailConfirmation, emailConfirmation } from 'store/auth/actions';
import { getUser } from 'store/profile/actions';
import { useAppDispatch, useAppSelector } from 'store';
import { useNavigate } from 'react-router-dom';

import { EmailType } from 'store/profile/types';

import { routesPath } from 'routes';

import { CartConfirmation } from 'assets/icons';

import staticData from 'common/static.json';
const staticText = staticData.auth.passwordRestore;

export const ConfirmaCartPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [counter, setCounter] = useState(59);
  const [isWaiting, setWaitingStatus] = useState(true);
  const { id } = useAppSelector(state => state.auth);
  const { common } = useAppSelector(state => state.profile);

  const [searchParams] = useSearchParams();

  // // useEffect(() => {
  // //   const tempToken = searchParams.get('token');
  // //   if (tempToken) {
  // //     dispatch(emailConfirmation(tempToken));
  // //   }
  // // }, [searchParams]); 

  // // useEffect(() => {
  // //   if (id) {
  // //     dispatch(getUser());
  // //   }
  // // }, [id]);

  // // useEffect(() => {
  // //   if (common.emailConfirmationStatus === EmailType.CONFIRMED) {
  // //     navigate(routesPath.cart);
  // //   }
  // // }, [common]);

  // useEffect(() => {
  //   if (isWaiting) {
  //     startCountdown();
  //   }
  // }, [isWaiting]);

  // const secondsFormat = () => {
  //   if (counter > 9) {
  //     return `00:${counter}`;
  //   }

  //   return `00:0${counter}`;
  // };

  // const startCountdown = () => {
  //   let timerValue = 59;
  //   const intervalId = setInterval(() => {
  //     if (timerValue > 1) {
  //       timerValue -= 1;
  //       setCounter(timerValue);
  //     } else {
  //       clearInterval(intervalId);
  //       setWaitingStatus(false);
  //     }
  //   }, 1000);
  // };

  // const requestNewEmail = () => {
  //     dispatch(requestEmailConfirmation(id));
  //     setCounter(59);
  //     setWaitingStatus(true);
  // };

  return (
    <div className='flex justify-content-center flex-wrap'>
      <form
        className='auth-form cart--confirmation auth-form--restore p-d-flex p-jc-center p-mt-2 w-8 auth-form'
      >
        <div className='auth-form--restore-pending'>
          <div className='auth-form--restore-icon'>
            <CartConfirmation />
          </div>
          <div className='auth-form--restore-info'>
            <span>Заказ сформирован</span>
          </div>
          <div className='auth-form--restore-description'>
            <div>Отследить работу по заказу Вы можете в разделе Заказы</div>
          </div>
          <div className='auth-form--restore-timer'>
            <NavLink
              to={ routesPath.ordersList }
            >
              <button> Перейти </button>
            </NavLink>
          </div>
        </div>
      </form>
    </div>
  );
};
