import { getUser, updateUser } from './actions';
import { AccountType, IProfile, EmailType } from './types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: IProfile = {
  individualUser: {
    referalLink: '',
    passportSeries: '',
    passportNumber: '',
    passportIssuedBy: '',
    passportIssueDate: '',
    dateOfBirth: '',
    residenceAddress: '',
    inn: '',
    iian: '',
    region: '',
  },
  legalUser: {
    orgName: '',
    ogrn: '',
    inn: '',
    kpp: '',
    bankName: '',
    bankAccount: '',
    correspondentAccount: '',
    bic: '',
    regions: [],
    responsiblePersonName: '',
    authorityGround: '',
    legalAddress: ''
  },
  common: {
    name: '',
    phoneNumber: '',
    email: '',
    referralLink: '',
    roles: '',
    id: '',
    region: '',
    emailConfirmationStatus: EmailType.UNCONFIRMED,
    accountType: AccountType.uncertainUser,
  },
  accountDialog: false,
  warningDialog: false,
  isEditing: false,
  passwordEditing: false,
  role: ''
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setIsEditing(state) {
      state.isEditing = !state.isEditing;
    },
    setPasswordEditing(state) {
      state.passwordEditing = !state.passwordEditing;
    },
    setAccountDialog(state) {
      state.accountDialog = !state.accountDialog;
    },
    setWarningDialog(state) {
      state.warningDialog = !state.warningDialog;
    },
    setAccountType(state, action: PayloadAction<string>) {
      state.common.accountType = action.payload as AccountType;
    },
    clearStore(state) {
      state = initialState;
    }
  },
  extraReducers: builder => {
    builder.addCase(getUser.fulfilled, (state, action: PayloadAction<any>) => {
      const { commonUserData, individualUserData, legalEntityUserData } =
        action.payload;
      state.common = commonUserData;
      if (individualUserData) {
        state.individualUser = individualUserData;
      }
      if (legalEntityUserData) {
        state.legalUser = legalEntityUserData;
      }
      state.role = commonUserData?.roles;
      if (commonUserData?.roles === 'PARTNER') {
        state.role = legalEntityUserData?.partner?.partnerType;
      }
    }).addCase(updateUser.fulfilled, (state, action: PayloadAction<any>) => {
      const { commonUserData, individualUserData, legalEntityUserData } =
        action.payload;
      state.common = commonUserData;
      if (individualUserData) {
        state.individualUser = individualUserData;
      }
      if (legalEntityUserData) {
        state.legalUser = legalEntityUserData;
      }
      state.role = commonUserData?.roles;
      if (commonUserData?.roles === 'PARTNER') {
        state.role = legalEntityUserData?.partner?.partnerType;
      }
  });
},
});

export const { setIsEditing, clearStore, setAccountDialog } = profileSlice.actions;

export default profileSlice.reducer;
