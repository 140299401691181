import { useAppSelector } from 'store';
import { AccountType } from 'store/profile/types';
import CompleteIndividualData from './CompleteIndividualData';
import CompleteLegalData from './CompleteLegalData';

const CompleteData = () => {
  const { common } = useAppSelector(state => state.profile);

  const handleType = () => {
    switch (common.accountType) {
      case AccountType.individualUser:
        return <CompleteIndividualData />;
      case AccountType.legalUser:
        return <CompleteLegalData />;
      default:
        break;
    }
  };

  return <div className='flex justify-content-center'>{handleType()}</div>;
};

export default CompleteData;
