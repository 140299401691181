import { createAsyncThunk } from '@reduxjs/toolkit';

import { userApi } from 'services/api/userApi';
import { documentsApi } from 'services/api/documentsApi';

import { USER_CREATE_LEGAL, USER_GET, USER_GET_ALL, USER_FETCH_DOCUMENTS, USER_FETCH_DOCUMENTS_PARTNERS } from './types';

export const getUserById = createAsyncThunk(USER_GET, async (id: string) => {
  const response = await userApi.getUserById(id);
  return response;
});

export const getAllUsers = createAsyncThunk(
  USER_GET_ALL,
  async (params: object) => {
    const response = await userApi.getAllUsers(params);
    return response;
  }
);

export const createLegal = createAsyncThunk(
  USER_CREATE_LEGAL,
  async ({ id, data }: { id: string; data: any }) => {
    const response = await userApi.createLegal(id, data);
    return response;
  }
);

export const getUserDocuments = createAsyncThunk(
  USER_FETCH_DOCUMENTS,
  async (params: any) => {
    const response = await userApi.getUserDocuments(params);
    return response;
  }
);

export const getPartnerDocuments = createAsyncThunk(
  USER_FETCH_DOCUMENTS_PARTNERS,
  async (params: any) => {
    const response = await documentsApi.getFilledDocuments(params);
    return response;
  }
);