import { FC, useState, useEffect, ReactElement } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/scrollbar';

export const Slider: FC<{
  sliderAction: (tab:string) => void,
  slides: {
    label: string;
    value?: string;
    additional?: string;
    element: ReactElement;
    breakpointsCount?: number;
  }[],
  customClass: string
}> = ({ sliderAction, slides, customClass }) => {
  const [selected, setSelect] = useState(0);

  const selTab = (index: number) => {
    if (slides[index]?.additional) {
      return;
    }
    setSelect(index);
    sliderAction(slides[index]?.value)
  }

  return (
    <Swiper
      loop={true}
      scrollbar={{
        hide: true,
      }}
      breakpoints={{
        320: {
          slidesPerView: "auto",
          spaceBetween: 8
        },
        530: {
          slidesPerView: 2,
          spaceBetween: 8
        },
        744: {
          slidesPerView: 3,
          spaceBetween: 8,
        },
        1050: {
          slidesPerView: 4,
          spaceBetween: 8,
        },
        1280: {
          slidesPerView: 4,
          spaceBetween: 8,
        },
        1400: {
          slidesPerView: 4,
          spaceBetween: 48,
        }
      }}
      modules={[Scrollbar]}
      className={ `${customClass}__swiper ${customClass}-swiper`}
    >
      <div className={`${customClass}-swiper__wrapper swiper-wrapper`}>
    {
        slides.map((tab, key) => {
          return <SwiperSlide><div key={ key } onClick={ () => { selTab(key) } } className={`${customClass}-swiper__slide ${customClass}-swiper-item swiper-slide`}>
                  <div>
                    <div className={`${customClass}-swiper-slide__text`}>
                      <span>{ tab.label }</span>
                      {
                        tab?.additional &&
                        <span>{ tab?.additional }</span>
                      }
                    </div>
                    <div className={ key === selected ? `${customClass}-swiper-slide__img ${customClass}-swiper-slide__img--active ${customClass}-swiper-item__img--active` : `${customClass}-swiper-slide__img` }>
                      { tab.element }
                    </div>
                  </div>
                </div></SwiperSlide>
        })
      }
      </div>
    </Swiper>
  )
}