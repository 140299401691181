import { FC, useEffect } from 'react';

import { MarketsList } from './MarketsList'
import { MarketNavigation } from './MarketNavigation';
import { MarketsFilters } from './Filters';
import { fetchProjects } from 'store/projects/actions';

import { useAppDispatch } from 'store';

export const MarketsPage: FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchProjects({
      pageNum: 0,
      pageSize: 500
    }));
  }, []);

  return (
    <div className='projects'>
        <MarketNavigation />
        
      <div className="projects__wrapper">
        <MarketsFilters />
        <MarketsList />
      </div>
    </div>
  );
};