export const COMMERCIAL_GET_CATEGORIES = 'commercial/getCategory';
export const COMMERCIAL_CREATE_CATEGORY = 'commercial/createCategory';
export const COMMERCIAL_DELETE_CATEGORY = 'commercial/deleteCategory';
export const COMMERCIAL_GET_TEMPLATE = 'commercial/getTemplate';
export const COMMERCIAL_CREATE_OFFER = 'commercial/createOffer';
export const COMMERCIAL_GET_OFFERS = 'commercial/getOffers';
export const COMMERCIAL_GET_OFFER = 'commercial/getOffer';
export const COMMERCIAL_UPDATE_OFFER = 'commercial/updateOffer';
export const COMMERCIAL_GET_USER_OFFER = 'commercial/getUserOffer';
export const COMMERCIAL_ADD_NOMENCLATURE = 'commercial/addNomenclature';
export const COMMERCIAL_FETCH_NOMENCLATURE = 'commercial/fetchNomenclature';
export const COMMERCIAL_ALL_NOMENCLATURE_COLUMNS = 'commercial/getAllNomenclatureColumns';

export interface ICommercialData {
  id: string;
  partnerId: string;
  categoryName: string;
  organizationName: string;
  phoneNumber: string;
  cityName: string;
}

export interface IOfferData {
  id: string;
  productionTime: number;
  unitPrice: number;
  templateId: string;
  categoryId: string;
  documentLink: string;
  minimumOrderQuantity: string;
}

export interface ICommercialNewCategorie {
  name: string;
}

export interface ICommercialCategorie {
  id: string;
  name: string;
}

interface ICommercialNomenclatureColumnsDefinitions {
  id: string;
  title: string;
  type: string;
}

interface ICommercialNomenclatureRow {
  key: string;
  value: string;
}

export interface ICommercialNomenclature {
  columnsDefinitionPreviews: ICommercialNomenclatureColumnsDefinitions[],
  data: {
    id: string;
    categoryId: string;
    data: ICommercialNomenclatureRow[],
  }[]
}

export interface ICommercialTemplate {
  id: string;
  documentLink: string;
  title: string;
  minimumOrderQuantity: number;
}

export interface IMapOffer {
  categoryId: string;
  data: IOfferData[];
}

export interface ICommercialState {
  list: {
    data: ICommercialData[],
    size: Number
  },
  offers: IOfferData[],
  categories: ICommercialCategorie[],
  selectedCategories: ICommercialCategorie[],
  templates: {
    categoryId: string,
    data: ICommercialTemplate[]
  }[],
  userOffers: IOfferData[],
  mapOffer: IMapOffer[]
  nomenclature: ICommercialNomenclature,
  nomenclatureColumns: any
}