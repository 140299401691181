import { replace } from "connected-react-router";
import { request } from "./api";
import { API_ROUTES } from 'config'

export const commercialApi = {
  getCategory: () => request.get<void>(API_ROUTES.commercial.getCategory),
  createCategory: (data: any) => request.post<void>(API_ROUTES.commercial.createCategory, data),
  deleteCategory: (categoryId: string) => request.delete<void>(API_ROUTES.commercial.deleteCategory.replace('{categoryId}', categoryId)),
  getTemplate: (categoryId:string) => request.get<void>(API_ROUTES.commercial.getTemplate.replace('{categoryId}', categoryId)),
  createOffer: (data: any) => request.post<void>(API_ROUTES.commercial.createOffer, data),
  getOffers: (data: any) => request.get<void>(API_ROUTES.commercial.getOffers, data),
  getOffer: () => request.get<void>(API_ROUTES.commercial.getOffer),
  updateOffer: (data: any) => request.put<void>(API_ROUTES.commercial.updateOffer, data),
  getUserOffer: (categoryId: string, partnerId: string) => request.get<void>(API_ROUTES.commercial.getUserOffer.replace('{categoryId}', categoryId).replace('{partnerId}', partnerId)),
  fetchNomenclature: (categoryIds: any) => request.get<void>(API_ROUTES.commercial.fetchNomenclature, categoryIds),
  addNomenclature: (data: any) => request.post<void>(API_ROUTES.commercial.addNomenclature, data),
  getAllColumns: () => request.get<void>(API_ROUTES.commercial.getAllColumns)
};