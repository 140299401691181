import { createAsyncThunk } from '@reduxjs/toolkit';

import { ordersApi } from 'services/api/ordersApi';
import { partnersApi } from 'services/api/partnersApi';

import {
  ORDERS_LIST,
  ORDERS_ITEM,
  ORDERS_ARCHIVE,
  ORDERS_USERS_ORDER,
  ORDERS_PARTNER_ORDERS,
  ORDERS_EDIT_LAND,
  ORDERS_EDIT,
  ORDERS_ADD_DOCUMENTS,
  ORDERS_EDIT_DOCUMENTS,
  ORDERS_REMOVE_DOCUMENTS,
  ORDERS_CREATE_ORDER,
  ASSIGN_EXECUTORS,
  ORDERS_GET_PARTNER_ISSUE,
  ORDERS_UPDTE_PARTNER_ISSUE
} from './types'

export const fetchOrders = createAsyncThunk(
  ORDERS_LIST,
  async (params: object) => {
    const response = await ordersApi.list(params);
    return response;
  }
);

export const getById = createAsyncThunk(
  ORDERS_ITEM,
  async ( orderId: any ) => {
    const response = await ordersApi.getById(orderId);
    return response;
  }
);

export const getArchive = createAsyncThunk(
  ORDERS_ARCHIVE,
  async (params: object) => {
    const response = await ordersApi.archive(params);
    return response;
  }
);

export const getUsersOrder = createAsyncThunk(
  ORDERS_USERS_ORDER,
  async ({ id, params }) => {
    const response = await ordersApi.usersOrder(id, params);
    return response;
  }
);

export const fetchPartnerOrders = createAsyncThunk(
  ORDERS_PARTNER_ORDERS,
  async (params) => {
    const response = await partnersApi.getOrders(params);
    return response;
  }
);

export const editLandData = createAsyncThunk(
  ORDERS_EDIT_LAND,
  async ({id, params}) => {
    const response = await ordersApi.editLandData(id, params);
    return response;
  }
);

export const edit = createAsyncThunk(
  ORDERS_EDIT,
  async ({id, params}) => {
    const response = await ordersApi.edit(id, params);
    return response;
  }
);

export const addDocuments = createAsyncThunk(
  ORDERS_ADD_DOCUMENTS,
  async ({id, params}) => {
    const response = await ordersApi.addDocuments(id, params);
    return response;
  }
);

export const editDocuments = createAsyncThunk(
  ORDERS_EDIT_DOCUMENTS,
  async ({id, params}) => {
    const response = await ordersApi.editDocuments(id, params);
    return response;
  }
);

export const removeDocuments = createAsyncThunk(
  ORDERS_EDIT_DOCUMENTS,
  async ({orderId, documentId}) => {
    const response = await ordersApi.removeDocuments(orderId, documentId);
    return response;
  }
);

export const createOrder = createAsyncThunk(
  ORDERS_CREATE_ORDER,
  async (params) => {
    const response = await ordersApi.create(params);
    return response;
  }
);

export const assignExecutors = createAsyncThunk(
  ASSIGN_EXECUTORS,
  async ({id, params}) => {
    const response = await ordersApi.assignExecutors(id, params);
    return response;
  }
);

export const getPartnerIssue = createAsyncThunk(
  ORDERS_GET_PARTNER_ISSUE,
  async (orderExecutionIssueId) => {
    const response = await partnersApi.getOrderExecutionIssueId(orderExecutionIssueId);
    return response;
  }
);

export const updatePartnerIssue = createAsyncThunk(
  ORDERS_UPDTE_PARTNER_ISSUE,
  async (data) => {
    const response = await partnersApi.updatePartnerIssue(data);
    return response;
  }
);