import { useEffect } from 'react';
import { Button } from 'primereact/button';
import { useAppDispatch, useAppSelector } from 'store';
import { Status } from 'store/partners/types';
import { partnersSlice } from 'store/partners/reducer';
import { getPartners } from 'store/partners/actions';
import BackButton from 'components/common/BackButton';
import PartnersItem from './PartnerItem';
import staticData from 'common/static.json';

const text = staticData.partners;

const Partners: React.FC = () => {
  const { list, status } = useAppSelector(state => state.partners);
  const { setStatus } = partnersSlice.actions;
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getPartners());
  }, []);

  const handleFilter = (status: string) => {
    dispatch(setStatus(status));
  };

  return (
    <div className='p-4 flex flex-column gap-3'>
      <div className='flex'>
        <BackButton />
      </div>
      <div className='flex flex-wrap gap-3 partners-filters'>
        <Button
          className={`${
            status === Status.inactive ? 'button-green' : 'button-white'
          }`}
          label={text.inactive}
          onClick={() => {
            handleFilter('INACTIVE');
          }}
        />
        <Button
          className={`${
            status === Status.active ? 'button-green' : 'button-white'
          }`}
          label={text.active}
          onClick={() => {
            handleFilter('ACTIVE');
          }}
        />
        <Button
          className={`${
            status === Status.disabled ? 'button-green' : 'button-white'
          }`}
          label={text.disabled}
          onClick={() => {
            handleFilter('DISABLED');
          }}
        />
      </div>
      <div className='flex flex-column gap-3'>
        <span className='font-medium text-2xl'>{text.requests}</span>
        {list
          .filter(item => item.issuePartnerStatus === status)
          .map(item => (
            <PartnersItem item={item} key={item.id} />
          ))}
      </div>
    </div>
  );
};

export default Partners;
