import { createContext, useContext, useMemo, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { TOKEN_STORAGE_KEY, REFRESH_TOKEN_STORAGE_KEY } from 'common/constants';
import { useAppDispatch, useAppSelector } from 'store';
import { setToken } from 'store/auth/reducer';
import { getUser } from 'store/auth/actions';
import { userRoles } from 'store/auth/types';
import { getUser as profileUser } from 'store/profile/actions';
import { fetchCarts } from 'store/carts/actions';
import { routesPath } from 'routes';
import { getReferralInfo } from 'store/referral/actions';
import { Roles } from 'store/auth/types';
import { checkNullProperties } from 'helpers/services';
import { PartnerTypes } from 'store/partners/types';
import { getOffer } from 'store/commercial/actions';
import { putInCart } from 'store/carts/actions';

const AuthContext = createContext('');

export const AuthProvider = ({ children }) => {
  let location = useLocation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { token, refreshToken, id, role } = useAppSelector(state => state.auth);
  const { common, legalUser } = useAppSelector(state => state.profile);
  const [userToken, setUserToken] = useLocalStorage(TOKEN_STORAGE_KEY, '');
  const [userRefreshToken, setUserRefreshToken] = useLocalStorage(REFRESH_TOKEN_STORAGE_KEY, '');

  useEffect(() => {
    const { pathname } = location;
    const cartData = window.localStorage.getItem('CART_DATA');
    if (cartData) {
      window.localStorage.removeItem('CART_DATA');
      dispatch(putInCart(JSON.parse(cartData)));
      navigate(routesPath.cart);

    }

    if (token && id === null) {
      const fetchData = async () => {
        await dispatch(getUser());
        await dispatch(profileUser());
        await dispatch(getReferralInfo());
      }
      fetchData();

      if (pathname === routesPath.emailConfirmation) {
          
      }
      navigate(pathname);
    }
    if (
      (pathname === routesPath.root ||
        pathname === routesPath.login ||
        pathname === routesPath.registration ||
        pathname === routesPath.emailConfirmation) &&
      id
    ) {
      navigate(routesPath.ordersList);
    }
  }, [token, userToken, id]);

  useEffect(() => {
    setUserToken(token);
  }, [token]);

  useEffect(() => {
    setUserRefreshToken(refreshToken);
  }, [refreshToken]);

  useEffect(() => {
    const { pathname } = location;

    if (role === userRoles.CUSTOMER) {
      dispatch(fetchCarts());
    }
  }, [role]);

  useEffect(() => {
    const { pathname } = location;

    if (pathname === routesPath.cart) {
      return;
    }

    if (common.roles === Roles.PARTNER) {
      //remove in future
      let tempUser = structuredClone(legalUser);
      delete tempUser.legalAddress;

      if (checkNullProperties(tempUser)) {
        navigate(routesPath.completeData, { state: {
          partnerCheck: true
        }});
      } else {
        const makeRequest = async () => {
          return await dispatch(getOffer());
        }
  
        if (legalUser?.partner.partnerType === 'PROVIDER') {
          makeRequest().then((data) => {
            if (data?.payload?.data.length === 0 && pathname !== 'profile') {
              navigate(routesPath.offers);
            }
          });
        }
      }
    }
  }, [common]);

  // useEffect(() => {
  //   const { pathname } = location;
  //   const partnerType = legalUser?.partner?.partnerType;
  //   console.info(pathname);
  //   console.info(partnerType)
  //   if (partnerType === 'PROVIDER') {
  //     navigate(routesPath.offers);
  //   } else if (
  //     (pathname === routesPath.root ||
  //       pathname === routesPath.login ||
  //       pathname === routesPath.registration)
  //   ) {
  //     navigate(routesPath.cart);
  //   } else {
  //     console.info(token);
  //     console.info('to partner')
  //     navigate(pathname);
  //   }
  // }, [common.id]);


  useEffect(() => {
    const { pathname } = location;

    if (userToken) {
      dispatch(setToken({ token: userToken }));
    } else if (pathname === '/') {
      navigate(routesPath.login);
    }
  }, [userToken]);

  const value = useMemo(
    () => ({
      userToken,
    }),
    [setUserToken]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
