import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { useParams, useLocation } from 'react-router-dom';

import { PartnersDocumentsItem } from './PartnersDocumentsItem';

import { DocumentsPreview } from 'components/Documents/DocumentsPreview';

import { useAppSelector, useAppDispatch } from 'store';

import { getDocumentFile, getFilledDocumentId, getOrdersTemplateDocumentsForPartners } from 'store/documents/actions';

import { getFilledDocuments } from 'store/documents/actions';

export const PartnersDocuments: React.FC = () => {
  const dispatch = useAppDispatch();
  const { order } = useAppSelector(state => state.orders);
  const { documents } = useAppSelector(state => state.documents);
  const { partnerIssue } = useAppSelector(state => state.orders);
  useEffect(() => {
    console.info(partnerIssue);
    // dispatch(getOrdersTemplateDocumentsForPartners({id: partnerIssue?.issue?.executorData?.authUserId, params: {
    //   pageNum: 0,
    //   pageSize: 100
    // }}))
    //dispatch(getFilledDocuments({ pageNum: 0, pageSize: 100, proActive: true, orderNumberPart: order?.order?.id}));
  }, []);

  const [isShowDocument, setShowDocument] = useState(false);

  const showDocument = (id: string) => {
    //dispatch(getFilledDocumentId(id));
    setShowDocument(true);
  }

  return (
    <div className='documents'>
      <>
        <Dialog
          visible={ isShowDocument }
          onHide={ () => setShowDocument(false) }
          header=""
          className='documents-modal documents__modal'
        >
          <DocumentsPreview />
          <div className="documents-modal__button">Документ подписан</div>
        </Dialog>
        <div className='documents-list documents__list'>
          {
            documents?.data?.map((document, key) => {
              return <PartnersDocumentsItem
                        showDocument={ (id: string) => { showDocument(id) } }
                        removeFile={ () => {} }
                        key={ key }
                        document={ document }
                        documentStatus={ '' }
                      />
            })
          }
        </div>
      </>
    </div>
  )
};