import { MultiSelect } from 'primereact/multiselect';
import { useController } from 'react-hook-form';
import CityItem from './CityItem';
import { useAppSelector } from 'store';
import { defaultMessage } from 'helpers/validations';

const LegalCitySelect: React.FC = () => {
  const { isEditing, legalUser } = useAppSelector(state => state.profile);
  const { regionList } = useAppSelector(state => state.ui);
  const {
    field,
    fieldState: { error, isDirty },
  } = useController({ name: 'regions', rules: { required: defaultMessage } });

  return (
    <div>
      {isEditing ? (
        <div>
          <div className='profile-desktop'>
            <MultiSelect
              {...field}
              value={field.value}
              onChange={e => field.onChange(e.value)}
              className={`auth-form-input flex align-items-center pr-0 h-3rem w-min ${
                isDirty && !error && 'profile-valid-auth'
              } ${error && 'input-error-auth'}`}
              options={regionList}
              optionLabel='value'
              optionValue='region'
              showSelectAll={false}
              filter={true}
              filterInputAutoFocus={false}
              panelClassName='profile-info-select'
              maxSelectedLabels={2}
              selectedItemsLabel='Выбраны города:'
            />
          </div>
          <div className='profile-mobile'>
            <MultiSelect
              {...field}
              value={field.value}
              onChange={e => field.onChange(e.value)}
              className={`auth-form-input flex align-items-center pr-0 h-3rem w-full ${
                isDirty && !error && 'profile-valid-auth'
              } ${error && 'input-error-auth'}`}
              options={regionList}
              optionLabel='value'
              optionValue='region'
              showSelectAll={false}
              filter={true}
              filterInputAutoFocus={false}
              panelClassName='profile-info-select'
              maxSelectedLabels={2}
              selectedItemsLabel='Выбраны города:'
            />
          </div>
        </div>
      ) : (
        <ul className='flex gap-2 m-0 p-0 flex-wrap'>
          {Array.isArray(legalUser.regions) &&
            legalUser.regions.map((item: string) => (
              <CityItem city={item} key={item} />
            ))}
        </ul>
      )}
    </div>
  );
};

export default LegalCitySelect;
