import { createSlice } from '@reduxjs/toolkit';

import { IUsersState, IUserProfile } from './types';

import {
  getUserById,
  getAllUsers,
  getUserDocuments,
  getPartnerDocuments
} from './actions';

const initialUsers:IUsersState = {
  list: {
    data: [],
    size: 0
  },
  documents: {
    data: [],
    totalPages: 1,
    totalElements: 0,
    pageSize: 1, 
    pageNum: 1
  },
  listSize: 0,
  user: null as IUserProfile
}

const usersSlice = createSlice({
  name: 'users',
  initialState: initialUsers,
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserById.fulfilled, (state, { payload }) => {
        state.user = payload;
      })
      .addCase(getAllUsers.fulfilled, (state, { payload }) => {
        if (!payload.pageNum) {
          state.list.data = payload.data;
        } else {
          state.list.data = state.list.data.concat(payload.data);
        }
        state.list.size = payload.totalElements;
      })
      .addCase(getUserDocuments.fulfilled, (state, { payload }) => {
        const { data, pageNum, totalElements } = payload;
        if (pageNum === 0) {
          state.documents.data = data;
        } else {
          state.documents.data = state.documents.data.concat(data);
        }

        state.documents.totalElements = totalElements;
      })
      .addCase(getPartnerDocuments.fulfilled, (state, { payload }) => {
        const { data, pageNum, totalElements } = payload;
        if (pageNum === 0) {
          state.documents.data = data;
        } else {
          state.documents.data = state.documents.data.concat(data);
        }

        state.documents.totalElements = totalElements;
      })
  },
});

//export const {  } = usersSlice.actions;

export default usersSlice.reducer;