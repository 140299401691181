import { request } from "./api";

import { API_ROUTES } from 'config'

export const ordersApi = {
  list: (params: Object) => request.get<void>(API_ROUTES.orders.list, params),
  getById: (id: string) => request.get<void>(API_ROUTES.orders.item.replace('{orderId}', id)),
  archive: (params: any) => request.get<void>(API_ROUTES.orders.archive, params),
  usersOrder: (id, params) => request.get<void>(API_ROUTES.orders.usersOrder.replace('{userID}', id), params),
  editLandData: (id, params) => request.put<void>(API_ROUTES.orders.editLandData.replace('{orderId}', id), params),
  edit: (id, params) => request.put<void>(API_ROUTES.orders.edit.replace('{orderId}', id), params),
  addDocuments: (id, params) => request.post<void>(API_ROUTES.orders.addDocuments.replace('{orderId}', id), params),
  editDocuments: (id, params) => request.put<void>(API_ROUTES.orders.editDocuments.replace('{orderId}', id), params),
  removeDocuments: (orderId, documentId) => request.delete<void>(API_ROUTES.orders.removeDocuments.replace('{orderId}', orderId).replace('{documentId}', documentId)),
  create: (params: any) => request.post<void>(API_ROUTES.orders.create, params),
  assignExecutors: (id, params) => request.post<void>(API_ROUTES.orders.assignExecutors.replace('{orderId}', id), params),
};