import staticData from 'common/static.json';

export const Toogler = ({ disabled, handleToggle }) => {
  return (
    <div className="toogler">
      <label>
        <input
          onChange={ handleToggle }
          className="toogler-checkbox"
          type="checkbox"
          disabled={disabled}
        />
        <div className="toogler-button" />
        <div className="toogler-labels">
          <span>{ staticData.header.roles.lite }</span>
          <span>{ staticData.header.roles.pro }</span>
        </div>
      </label>
    </div>
  );
};