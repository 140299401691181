import { Controller, useController, useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { InputMask } from 'primereact/inputmask';
import { HideIcon, ShowIcon, ValidIcon } from 'assets/icons';
import ErrorTooltip from './ErrorTooltip';
import { defaultMessage } from 'helpers/validations';

interface Props {
  name: string;
  validations: any;
  icon?: string;
  label?: string;
  placeholder?: string;
  type?: string;
  mask?: string;
  disabled?: boolean;
  classNameInput?: string;
  classNameLabel?: string;
  classNameError?: string;
  classNameValid?: string;
  isRequired?: boolean;
}

const InputTest: React.FC<Props> = ({
  icon,
  label,
  placeholder,
  type,
  name,
  mask,
  validations,
  disabled,
  classNameInput,
  classNameLabel,
  classNameError,
  classNameValid,
  isRequired = true,
}) => {
  const handleRules = () => {
    switch (isRequired) {
      case true:
        return {
          ...validations,
          required: { value: true, message: defaultMessage },
        };
      case false:
        return { ...validations, required: false };
      default:
        break;
    }
  };

  const {
    field,
    fieldState: { error, isDirty },
  } = useController({
    name,
    rules: handleRules(),
    // rules: {},
    defaultValue: '',
  });

  const { setValue, control } = useFormContext();

  const trimInput = () => {
    field.value && setValue(name, field.value.replace(/\s+/g, ' ').trim());
  };

  const [isVisible, setIsVisible] = useState(false);
  const handleShow = () => {
    setIsVisible(!isVisible);
  };

  const isFullyFilled = (value: string) => {
    const requiredLength = mask?.replace(/\D/g, '').length;

    const numbers = value.match(/\d/g);
    return numbers && numbers.length === requiredLength;
  };

  return (
    <label className={classNameLabel}>
      {label}
      {type === 'password' ? (
        <div className='input-container'>
          <input
            {...field}
            className={`input input-password ${classNameInput} ${
              error && classNameError
            } ${isDirty && !error && classNameValid}`}
            type={`${isVisible ? 'text' : 'password'}`}
            placeholder={placeholder}
            onBlur={trimInput}
            disabled={disabled}
          />
          <div className='input-password-icon'>
            {error && !disabled && <ErrorTooltip error={error} />}
            {isDirty && !error && !disabled && <ValidIcon />}
            {isVisible ? (
              <HideIcon onClick={handleShow} />
            ) : (
              <ShowIcon onClick={handleShow} />
            )}
          </div>
        </div>
      ) : (
        <div className='input-container'>
          {mask ? (
            <Controller
              name={name}
              control={control}
              rules={{
                ...handleRules(),
                validate: {
                  fullyFilled: value =>
                    isFullyFilled(value) || 'Поле заполнено не полностью',
                },
              }}
              render={({ field }) => (
                <InputMask
                  disabled={disabled}
                  autoClear={false}
                  placeholder={placeholder}
                  className={`input ${classNameInput} ${
                    error && classNameError
                  } ${isDirty && !error && classNameValid}`}
                  mask={mask}
                  value={field.value}
                  onChange={e => field.onChange(e.value)}
                />
              )}
            />
          ) : (
            <input
              {...field}
              className={`input ${classNameInput} ${error && classNameError} ${
                isDirty && !error && classNameValid
              }`}
              placeholder={placeholder}
              disabled={disabled}
              onBlur={trimInput}
            />
          )}
          {error && !disabled && (
            <ErrorTooltip error={error} iconClassName='input-validation-icon' />
          )}
          {isDirty && !error && !disabled && (
            <ValidIcon className='input-validation-icon' />
          )}
          <i className={icon} />
        </div>
      )}
    </label>
  );
};

export default InputTest;
