import { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { FormProvider, useForm } from 'react-hook-form';

import { Button } from 'primereact/button';
import { routesPath } from 'routes';

import InputTest from 'components/common/InputTest';

import { login } from 'store/auth/actions';
import { getUser } from 'store/auth/actions';
import { getUser as profileUser } from 'store/profile/actions';
import { useAppDispatch, useAppSelector } from 'store';

import { TOKEN_STORAGE_KEY } from 'common/constants';
import { useLocalStorage } from 'hooks/useLocalStorage';

import staticData from 'common/static.json';
import { validations } from 'helpers/validations';

export const LoginPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { token, id } = useAppSelector(state => state.auth);
  const [user, setUser] = useLocalStorage(TOKEN_STORAGE_KEY, token);

  useEffect(() => {
    if (id) {
      dispatch(getUser());
      dispatch(profileUser());
    }
  }, [id]);

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = (data: any) => {
    //methods.reset();
    dispatch(login(data));
  };

  const registrationAction = () => {
    navigate('/registration');
  };

  const passwordRestore = () => {
    navigate('/password-restore');
  };

  return (
    <div className='flex justify-content-center flex-wrap'>
      <FormProvider {...methods}>
        <form
          className='auth-form auth-form--login p-d-flex p-jc-center p-mt-2 w-8'
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <div className='flex justify-content-center flex-wrap gap-2'>
            <span className='auth-form-title'>
              {staticData.auth.login.title}
            </span>
          </div>
          <div className='flex flex-column gap-2'>
            <InputTest
              name='email'
              type='email'
              validations={validations.email}
              classNameInput='auth-form-input auth-form-login'
              classNameLabel='auth-form-label'
              classNameValid='input-valid-auth'
              classNameError='input-error-auth'
              placeholder={staticData.auth.login.placeholders.email}
              label={staticData.auth.login.labels.email}
            />
          </div>
          <div className='flex flex-column gap-2'>
            <InputTest
              name='password'
              type='password'
              validations={validations.password}
              classNameInput='auth-form-input'
              classNameLabel='auth-form-label'
              classNameValid='input-valid-auth'
              classNameError='input-error-auth'
              placeholder={staticData.auth.login.placeholders.password}
              label={staticData.auth.login.labels.password}
            />
          </div>
          <div className='auth-form-forget flex align-content-start flex-wrap'>
            <span onClick={passwordRestore}>
              {staticData.auth.login.forget}
            </span>
          </div>
          <div className='flex justify-content-center flex-wrap align-items-stretch'>
            <Button
              className='auth-form-login-button flex align-items-center justify-content-center'
              label={staticData.auth.login.button}
              type='submit'
            />
          </div>
          <div className='flex justify-content-center flex-wrap align-items-stretch'>
            <span
              className='auth-form--registration-button'
              onClick={registrationAction}
            >
              {staticData.auth.login.registrationButton}
            </span>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
