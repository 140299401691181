import { FC, useState, useEffect } from 'react';
import { Arrow } from 'assets/icons';
import { Check } from 'assets/icons';

import { useOutsideClick } from 'hooks/useOutsideClick';

export const DropDownWithCheckBox: FC<{ options: any, setSelectedValues: any, initialValue: any }> = ({ options, setSelectedValues, initialValue = [] }) => {
  const [isShow, setShow] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState(initialValue);
  const checkOptions = (option) => {
    if (!isContain(option)) {
      setSelectedOptions([...selectedOptions.concat(option)]);
    } else {
      const tempOptions = selectedOptions.filter(selected => selected.value !== option.value);
      setSelectedOptions([...tempOptions]);
    }
  }

  const ref = useOutsideClick(() => {
    setShow(false);
  });

  useEffect(() => {
    setSelectedValues(selectedOptions);
  }, [selectedOptions]);

  const isContain = (option: any) => {
    const contain = selectedOptions.find((elem) => {
      return elem?.value === option.value;
    });

    return contain !== undefined;
  }

  const selectedValuesLabel = () => {
    const values = selectedOptions.map(select => select?.name);

    return values.join(', ')
  }

  return (
    <>
      <div className="select auth-select" ref={ref}>
        <div className={`select__header ${isShow ? 'select__header--active' : '' } `} onClick={() => setShow(!isShow)}>
          <span className="select__current">{ selectedValuesLabel()  }</span>
          <div className="select__icon"><Arrow /></div>
        </div>
        <div className={`select__body ${isShow ? 'select__body--visible' : '' } `} >
          {
            options.map((option: any) => {
              return <div
                className="select__item select__item--active checkbox-select-item checkbox-select-item--active"
                onClick={() => { checkOptions(option) }}
              >
                  <div className={`checkbox-select-item__icon ${isContain(option) ? 'checkbox-select-item__icon--active' : '' } `}>
                    {
                      isContain(option) && <Check />
                    }
                  </div>
                  <div className="checkbox-select-item__text">{ option.name }</div>
                </div>
            })
          }
        </div>
      </div>
    </>
  )
};