import { FC, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ListBox } from 'primereact/listbox';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { PartnerTypes } from 'store/partners/types';
import { useAppSelector } from 'store';

export const OrderExecutorsForm: FC<{ currentExecutors, executors, submitAction }> = ({ currentExecutors, executors, submitAction }) => {
  const [selectedExecutor, setSelectedExecutor] = useState({});
  const [newExecutors, setNewExecutors] = useState({});
  const [currentExecutor, setCurrentExecutor] = useState({});

  const { regionMap } = useAppSelector(state => state.ui);

  const onSubmit = () => {
    const partnersData = Object.keys(newExecutors).map((executor) => {
      return {
        executorType: executor,
        partnerId: newExecutors[executor]
      }
    });
    submitAction({ partnersData });
  };

  useEffect(() => {
    const tempObj = {}
    currentExecutors.map((executor) => {
      tempObj[executor.executorType] = executor.partnerId;
    })
    setNewExecutors(tempObj)
  }, []);


  const selectExecutor = ({ type, value }) => {
    let tempObj = structuredClone(newExecutors);

    if (tempObj[type]) {
      delete tempObj[type];
      setSelectedExecutor(value);
    } else {
      tempObj[type] = value?.id;
      setSelectedExecutor(value);
    }

    setNewExecutors(tempObj);
  }

  const executorTemplate = (option) => {
    return (
        <div className='order-detail_inputs--list_executor flex align-items-center'>
            <div className='order-detail_inputs--list_executor-select'>
              <div className='order-detail_inputs--list_executor-select_btn'>
          
              </div>
            </div>
            <div>
              <div className='order-detail_inputs--list_executor-name'>{ option.organizationName }</div>
              <div className='order-detail_inputs--list_executor-region'>{ regionMap[option.regions] }</div>
            </div>
        </div>
    );
};

  return (
    <div className='orders'>
      <div className='order'>
        <div className='order-detail executors-detail'>
          <div className='executors-detail--title'>
            <span>Редактировать исполнителей</span>
          </div>
          <div className='order-detail_inputs order--inputs flex justify-content-center flex-wrap'>
            <div className='order-detail_inputs--list col-12'>
              <Accordion>
                { executors.map((executor, key) => {
                  return <AccordionTab
                    header={PartnerTypes[executor.executorType] }
                    key={key}
                    className={`${newExecutors[executor.executorType] ? 'active-list': ''}`}
                    // onClick={(e) =>  {setCurrentExecutor(executor.executorType)} }
                    >
                      <ListBox
                        value={selectedExecutor}
                        itemTemplate={executorTemplate}
                        onChange={(e) =>  selectExecutor({type: executor.executorType, value: e.value }) }
                        options={executor.executorsData}
                        optionLabel='organizationName'
                        className='w-full md:w-14re'
                      />
                      </AccordionTab>
                  })
                }
                </Accordion>
              </div>
            </div>
          </div>
          <div className='flex justify-content-center flex-wrap align-items-stretch'>
            <button
              className='executor-button auth-form-login-button flex align-items-center justify-content-center'
              label='Сохранить изменения'
              type='submit'
              onClick={ onSubmit }
            >Сохранить изменения</button>
          </div>
        </div>
      </div>
  );
};
