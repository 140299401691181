import { createSlice } from '@reduxjs/toolkit';
import {
  fetchProjects,
  fetchProjectsWithOptions,
  fetchProjectTree,
  fetchProjectImages,
  fetchProjectById,
  fetchVillageHouse
} from './actions';

import { IProjectsState, } from './types';

const initialProjects:IProjectsState = {
  projects: {
    data: [],
    size: 0
  },
  project: {},
  currentImages: {
    EXTERIOR: '',
    PLAN: []
  },
  projectData: {

  },
  villageHouse: {}
}

const projectsSlice = createSlice({
  name: 'projects',
  initialState: initialProjects,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjects.fulfilled, (state,  { payload }) => {
        state.projects.data = payload?.data;
        state.projects.size = state.projects.data.length;
      })
      .addCase(fetchProjectsWithOptions.fulfilled, (state,  { payload }) => {
        state.project = payload;
      })
      .addCase(fetchProjectTree.fulfilled, (state,  { payload }) => {
        state.project = payload;
        state.currentImages['EXTERIOR'] = '';
        state.currentImages['PLAN'] = [];

        payload?.images?.map((img) => {
          if (img?.type === 'EXTERIOR') {
            state.currentImages[img?.type] = img?.fileInfo?.fileUrl  
          }

          if (img?.type === 'PLAN') {
            state.currentImages[img?.type].push(img?.fileInfo?.fileUrl)
          }
        })

      })
      .addCase(fetchProjectImages.fulfilled, (state,  { payload }) => {
        state.currentImages[payload?.type] = payload?.payload?.data;
      })
      .addCase(fetchProjectById.fulfilled, (state,  { payload }) => {
        state.projectData = payload;
      })
      .addCase(fetchVillageHouse.fulfilled, (state,  { payload }) => {
        state.villageHouse = payload;
      })
    }
});

export const { } = projectsSlice.actions;

export default projectsSlice.reducer;