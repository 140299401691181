import { Outlet } from 'react-router';
import { FC } from 'react';

import { MainFooter } from 'components/common/Footer';
import { TabBar } from 'components/common/Footer/TabBar';
import { MainHeader } from 'components/common/Header';
import Notifications from '../Notifications';

import {
  useAppSelector,
} from 'store';

const Layout: FC = () => {
  const { isHideOverflow } = useAppSelector(state => state.ui);
  const setOverflow = { overflow: isHideOverflow ? 'hidden' : 'initial' };

  return (
    <section className='main-wrapper' style={setOverflow}>
      <Notifications />
      <MainHeader />
      <div className='main'>
        <Outlet />
      </div>
      <MainFooter />
      <TabBar />
    </section>
  );
};

export default Layout;
