import { CrossIcon } from 'assets/icons';
import { useAppSelector } from 'store';

interface Props {
  city: string;
  onClick?: () => void;
}

const CityItem: React.FC<Props> = ({ city, onClick }) => {
  const { isEditing } = useAppSelector(state => state.profile);
  const { regionMap } = useAppSelector(state => state.ui);

  return (
    <li className='profile-info-chip' key={city}>
      {regionMap[city]}
      {isEditing && <CrossIcon onClick={onClick} />}
    </li>
  );
};

export default CityItem;
