import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'store';
import { getCategory, fetchNomenclature, addNomenclature, getAllColumns } from 'store/commercial/actions';
import { setCategory, clearNomenclature } from 'store/commercial/reducer';
import { Dialog } from 'primereact/dialog';

import { EditTable, CrossIcon } from 'assets/icons';

import { CategoryNewModal } from './CategoryNewModal';

import { Accordion, AccordionTab } from 'primereact/accordion';
import { routesPath } from 'routes';

import {
  createCategory,
  deleteCategory
} from 'store/commercial/actions';
import { ICommercialNewCategorie } from 'store/commercial/types';

import { CloseIcon } from 'assets/icons';

import { BackIcon } from 'assets/icons';
import { ConfirmModalModal } from './ConfirmModal';
import staticData from 'common/static.json';
const pageStaticText = {
  ...staticData.offer
};

const confirmTextStatic = {
  row: {
    title: 'Сохранить изменения?',
    description: 'Все введенные изменения отобразятся в смете',
    buttonCancel: 'Отменить',
    buttonOk: 'Сохранить'
  },
  category: {
    title: 'Удалить категорию?',
    description: 'Таблица по данной категории будет также удалена',
    buttonCancel: 'Назад',
    buttonOk: 'Удалить'
  }
}

export const NomenclatureNewPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [isValidTable, setValidTable] = useState(true);

  const [editValue, setEditValue] = useState({});

  const [nomenclatureTable, setNomenclatureTable] = useState({});
  const [isEditMode, setEditMode] = useState(false);

  const [deletedRow, setDeletedRow] = useState(null);
  const [editCell, setEditCell] = useState({});

  const [timerForEdit, setTimerForEdit] = useState(null);

  const [isAddNewCategoryModal, setAddNewCategoryModal] = useState(false);

  const [tempCategory, setTempCategory] = useState([]);

  const [removedColumns, setRemovedColumns] = useState([]);
  const [newColumns, setNewColumns] = useState([]);
  const [newRows, setNewRows] = useState({categoryId: '', data: []});
  const [removedRows, setRemovedRows] = useState([]);

  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [currentConfirmAction, setCurrentConfirmAction] = useState(false);
  const [confirmText, setConfirmText] = useState('');

  const [isButtonBlocked, setButtonBlocked] = useState(false);

  const {
    categories,
    selectedCategories,
    offers,
    nomenclature,
    nomenclatureColumns
  } = useAppSelector(state => state.commercial);

  useEffect(() => {
    dispatch(getCategory());
    dispatch(getAllColumns());
  }, []);

  useEffect(() => {
    if (Object.keys(nomenclature).length === 0) {
      return;
    }

    const nomenclatureTable = {
      header: nomenclature?.columnsDefinitionPreviews?.map(
        column => ({ title: column?.title, type: column?.type, id: column?.id, 
          immutable: column?.immutable })).reduce(
          (obj, item) => Object.assign(obj, { [item.title]: {
            type: item.type,
            id: item.id,
            immutable: item.immutable
        }}), {}),
      body: nomenclature?.data?.map((data) => {
        return {
        id: data?.id,
        data: data?.data
        }
      })
    }
    
    const headKeys = Object.keys(nomenclatureTable?.header);
    const newBody = nomenclatureTable.body?.map((body) => {
      let tempBody = [...body.data];
      let bodyKeys = body.data.map((elem) => {
        return elem?.key;
      });

      let difference = headKeys.filter(x => !bodyKeys.includes(x));

      difference.forEach((diff) => {
        tempBody.push({
          key: diff,
          value: ''
        })
      })

      return {
        id: body.id,
        data: tempBody
      }
    });

    nomenclatureTable.body = newBody;

    let tempEditMode = {};
    Object.keys(nomenclatureTable?.header).forEach(elem => {
      tempEditMode[elem] = {
        edit: false,
        show: false
      }
    });

    setEditValue(tempEditMode)
    setNomenclatureTable(nomenclatureTable)
  }, [nomenclature]);

  useEffect(() => {
    if (offers.length) {
      navigate(routesPath.profile);
    }
  }, [offers]);

  const checkValidation = () => {
    setValidTable(true);
    tableData.forEach((row) => {
      row?.list.forEach((attr) => {
        Object.keys(attr).forEach(elem => {
          if (attr[elem] === null || attr[elem] === '') {
            setValidTable(false);
          }
        })
      })
    });
  }

  useEffect(() => {
    dispatch(clearNomenclature());
  }, [selectedCategories]);

  useEffect(() => {
    checkValidation();
  }, [tableData]);

  const checkCategory = (category: any) => {
    let categoriesTemp = structuredClone(selectedCategories);
    // if (isCategorySelected(category)) {
    //   // if (selectedCategories.length) {
    //   //   if (selectedCategories[0]?.id !== category?.id) {
    //   //     return;
    //   //   }
    //   // }
    //   categoriesTemp.push(category);
    //   dispatch(setCategory(categoriesTemp))
    //   dispatch(fetchNomenclature({ categoryIds: category?.id }));
    // } else {
    //   categoriesTemp = categoriesTemp.filter(item => item.id !== category.id);
    //   dispatch(setCategory(categoriesTemp));
    // }

    if (selectedCategories.length && category.id === selectedCategories[0].id) {
      dispatch(setCategory([]))
      setNomenclatureTable({})
      return;
    }

    dispatch(setCategory([category]))
    if (category?.id) {
      dispatch(fetchNomenclature({ categoryIds: category?.id }));
    }
    setNomenclatureTable({})
  }
  
  const isCategorySelected = (category) => {
    const { id } = category;
    const isContains = selectedCategories.find(elem => elem.id === id);
    return isContains === undefined;
  }

  const removeCategory = async (id: string) => {
    await dispatch(deleteCategory(id));
    dispatch(getCategory());
  }

  const isShowAddColumnButton = () => {
    let headers = false;
    if (nomenclatureTable?.header !== undefined) {
      headers = Object.keys(nomenclatureTable?.header);
    }

    return headers;
  }

  const addColumn = async () => {
    let tables = structuredClone(nomenclatureTable);

    const columnName = ' '
    let tempNewColumns = newColumns;
    tempNewColumns.push(columnName);
    setNewColumns([...tempNewColumns]);

    tables.header[columnName] = {
      type: 'STRING',
      immutable: false,
      id: ''
    }

    tables?.body?.map((row) => {
      let newRow = row?.data;
      
      newRow.push({
        key: columnName,
        value: ''
      });

      return newRow
    });

    let tempEditMode = {};
    Object.keys(tables?.header).forEach(elem => {
      tempEditMode[elem] = {
        edit: false,
        show: false
      }
    });

    setEditValue(tempEditMode);
    setNomenclatureTable(tables);
  }

  const changeNomenclature = async (value: any, col, row) => {
    let tables = structuredClone(nomenclatureTable);
    tables.body[col].data[row].value = value;
    setNomenclatureTable(tables);
  }

  const addRow = async () => {
    let tables = structuredClone(nomenclatureTable);

    let newRow = Object.keys(nomenclatureTable?.header).map((key => {
      const { immutable, type } = nomenclatureTable?.header[key];

      return {
        key: key,
        value: type === 'STRING' ? '': '0'
      }
    }));

    

    tables?.body.push({
      id: '',
      data: newRow
    })

    let tempRows = newRows?.data;
    tempRows.push(newRow)
    setNewRows({
      categoryId: selectedCategories[0].id,
      data: tempRows
    })

    setNomenclatureTable(tables);
  }

  const removeColumn = async (index, key) => {
    let tables = structuredClone(nomenclatureTable);
    const deleteId = tables?.header[key].id;

    if (!deleteId) {
      let tempNewColumns = newColumns;

      const index = tempNewColumns.indexOf(key);
      if (index > -1) {
        tempNewColumns.splice(index, 1); 
      }

      setNewColumns([...tempNewColumns])
    } else {
      let tempColumns = removedColumns;
      tempColumns.push(deleteId);
      setRemovedColumns([...tempColumns]);
    }
    
    delete tables?.header[key];

    const tempBody = tables?.body.map((row) => {
      const temp = row?.data?.filter(line => line.key !== key);

      return {
        id: row?.id,
        data: temp
      }
    })
    tables.body = tempBody;
    let tempEditMode = {};
    Object.keys(tables?.header).forEach(elem => {
      tempEditMode[elem] = {
        edit: false,
        show: false
      }
    });

    setEditValue(tempEditMode);
    setNomenclatureTable(tables);
  }

  const removeRow = async (index: number) => {
    const rowId = nomenclatureTable?.body[index].id;

    if (rowId) {
      const tempRows = removedRows;
      tempRows.push(rowId);
      setRemovedRows(tempRows);
    }

    let tables = structuredClone(nomenclatureTable);
    tables?.body.splice(index, 1);

    setNomenclatureTable(tables);
  }

  const changeColumnName = async (key, value) => {
    let tempEditValue = structuredClone(editValue);
    delete tempEditValue[key]
    tempEditValue[value] = {
      edit: true,
      show: false
    }

    let tables = structuredClone(nomenclatureTable);
    tables.header[value] = tables?.header[key];
    delete tables?.header[key];

    tables.body = tables?.body.map((row) => {

      const test = row?.data?.map((item:any) => {
        if (item.key === key) {
          return {
            key: value,
            value: item.value,
          }
        } else {
          return item
        }
      });

      return {
        data: test,
        id: row?.id
      }
    });

    let tempEditMode = {};
    Object.keys(tables?.header).forEach(elem => {
      tempEditMode[elem] = {
        edit: false,
        show: false
      }
    });

    setEditValue(tempEditMode);

    setNomenclatureTable(tables);
  }

  const addDefaultRow = () => {
    let newRow = nomenclatureColumns.map((column => {
      const key = column?.title;
      if (column.type === 'STRING') {
        return {
          key: key,
          value: ''
        }
      }

      if (column.type === 'INT') {
        return {
          key: key,
          value: 10
        }
      }
    }));

    return newRow;
  }

  const sendData = async () => {
    //Новые столбцы
    let array1 = Object.keys(nomenclatureTable?.header);
    let array2 = nomenclatureColumns.map(column => column.title);
    let difference = array1.filter((element) => !array2.includes(element));

    const newRows = nomenclatureTable?.body.filter(body => body?.id === '');
    const oldRows = nomenclatureTable?.body.filter(body => body?.id !== '');
    
    await dispatch(addNomenclature({
      createColumnsNames: difference,
      deleteColumnsIds: removedColumns,
      createNomenclatureRequests:
        newRows.map((row) => {
          return {
            categoryId: selectedCategories[0].id,
            data: row?.data
          }
        }),
      updateNomenclatureRequests:
        oldRows.map((row) => {
          return {
            id: row?.id,
            categoryId: selectedCategories[0].id,
            data: row?.data
          }
        }),
      deleteNomenclatureIds: removedRows
    }));
    dispatch(fetchNomenclature({ categoryIds: selectedCategories[0].id }));
    dispatch(getAllColumns());
  }


  const createNewCategory = (categorie) => {
    setTempCategory([{ name: categorie[0]?.name, id: '' }]);
    dispatch(setCategory([{ name: categorie[0]?.name, id: '' }]))
    const nomenclatureTable = {
      header: nomenclatureColumns?.map(
        column => ({ title: column?.title, type: column?.type, id: column?.id, 
          immutable: column?.immutable })).reduce(
          (obj, item) => Object.assign(obj, { [item.title]: {
            type: item.type,
            id: item.id,
            immutable: item.immutable
        }}), {}),
      body: [{
        id: '',
        data: nomenclatureColumns?.map((column) => {
          return {
            key: column?.title,
            value: ''
          }
        })
      }]
    }

    let tempEditMode = {};
    Object.keys(nomenclatureTable?.header).forEach(elem => {
      tempEditMode[elem] = {
        edit: false,
        show: false
      }
    });

    setEditValue(tempEditMode)
    setNomenclatureTable(nomenclatureTable)
  }
  
  const sendCategory = async () => {
    let array1 = Object.keys(nomenclatureTable?.header);
    let array2 = nomenclatureColumns.map(column => column.title);
    let difference = array1.filter((element) => !array2.includes(element));

    const sendData = {
      categoryName: tempCategory[0]?.name,
      nomenclature: nomenclatureTable?.body.map(row => { return { data: row?.data }})
    }
    await dispatch(addNomenclature({
      createColumnsNames: difference,
      deleteNomenclatureIds: removedRows
    }));
    await dispatch(createCategory([sendData]));
    setTempCategory([]);
    dispatch(setCategory([]))
    dispatch(getCategory());
  }

  const checkTableValidation = () => {
    try {
      nomenclatureTable?.body?.forEach(element => {
        element?.data.forEach(row => {
          if (nomenclatureTable?.header[row?.key]?.type === 'STRING') {
            if (!/^[a-zA-Zа-яА-ЯёЁ0-9\\?!№%.,/: ]+$/.test(row?.value)) {
              throw 'Error';
            }
          }
        })
      });
    } catch (e) {

      return true;
    }

    try {
      Object.keys(nomenclatureTable?.header).forEach(key => {
        if (!/^[А-ЯЁ][а-яё\\d,\s]{2,}$/g.test(key)) {
          throw 'Error';
        }
      });
    } catch (e) {

      return true;
    }

    return false;
  }

  return (
    <div className='categories'>
      <Dialog
        visible={ isConfirmModal }
        onHide={() => setIsConfirmModal(false)}
        className='categories-modal-changes categories-modal__changes'>
          <ConfirmModalModal
            onClose={ () => setIsConfirmModal(false) }
            confirmAction={ currentConfirmAction }
            confirmText={ confirmTextStatic[confirmText] }
          />
      </Dialog>
{/* вынести отдельно */}
      <div className="nomenclature">
        <div className="nomenclature__header nomenclature-header">
          {/* <button className='button-back' >
            <BackIcon />
            Назад
          </button> */}
          <div className="nomenclature-header__info">
            <h1 className="nomenclature-header__title">Смета</h1>
            <button className="nomenclature-header__button" onClick={ () => { navigate(routesPath.offers) }} >Коммерческие предложения</button>
          </div>
        </div>

        {
          (!categories.length && !tempCategory.length) &&
          <div className="nomenclature__empty nomenclature-empty">
            <p className="nomenclature-empty__placeholder">Нет добавленных категорий.<br></br> Добавьте категории, нажав кнопку Редактировать</p>
            <button
              className="nomenclature-empty__button"
              onClick={ () => { setAddNewCategoryModal(true) } }
            >Редактировать</button>
          </div>
        }
      </div>
{/* вынести отдельно */}


      { (categories.length || tempCategory.length) &&

      <div className="categories__category categories-category">
        <h2 className='categories-category__title'>
          { pageStaticText.pro.title }
        </h2>
        { (categories.length > 0 || tempCategory.length > 0) &&
          <ul className='categories-category__list categories-category-list'>
            {
              categories.concat(tempCategory).map((category, key) => {
                return <li
                className={!!!isCategorySelected(category) ?
                  'categories-category-list__item categories-category-list__item--active categories-category-list-item categories-category-list-item--active':
                  selectedCategories.length ? 'categories-category-list__item categories-category-list-item': 'categories-category-list__item categories-category-list-item'
                } onClick={() => checkCategory(category)} key={key}> 
                  { category.name }
                  <div
                    onClick={ (e) => { e.stopPropagation(); setConfirmText('category'); setCurrentConfirmAction(() => () => { removeCategory(category?.id); setIsConfirmModal(false)  }); setIsConfirmModal(true) } }
                    className='categories-category-list-item__close'>
                      <CloseIcon />
                  </div>
                </li>
              })
            }
            <li
              className={selectedCategories.length ? 'categories-category-list__item categories-category-list__item--added disabled' :
                'categories-category-list__item categories-category-list__item--added'} onClick={() => {setAddNewCategoryModal(true)}}
            >
              <CloseIcon />
            </li>
          </ul>
        }
        { (nomenclature?.columnsDefinitionPreviews?.length || nomenclatureTable?.header !== undefined) && 
          <div className='categories-category__details categories-category-details'>
            
            <h2 className='categories-category-details__title'>
              { pageStaticText.pro.tableTitle }
            </h2>
            { selectedCategories.map((categorie) => {
              return <div className='categories-category-details__table'>
                <Accordion activeIndex={0}>
                  <AccordionTab header={ categorie.name } className='categories-category-details__accordion'>
                    <table className='category-table'>
                      <thead className='category-table__head'>
                        <tr className='category-table__header category-table-header'>
                          <th className='category-table-header__item category-table-header__item--number'>№</th>
                          {
                            nomenclatureTable?.header !== undefined &&
                            Object.keys(nomenclatureTable?.header)?.map((head, key) => {
                              return <>
                                  {
                                    nomenclatureTable?.header[head].type === '' &&
                                    <th
                                      key={key}
                                      className={ /^[А-ЯЁ][а-яё\\d,\s]{2,}$/g.test(head) ? 'category-table-header__item' : 'category-table-header__item--invalid' } 
                                    >{ head }</th>
                                  }
                                  {
                                    (nomenclatureTable?.header[head].type === 'INT' ||
                                    nomenclatureTable?.header[head].type === 'STRING')  &&
                                    <th
                                      key={key}
                                      className={ nomenclatureTable?.header[head].immutable ? 'category-table-header__item disabled' : /^[А-ЯЁ][а-яё\\d,\s]{2,}$/g.test(head) ? 'category-table-header__item' : 'category-table-header__item category-table-header__item--invalid' } 
                                      onMouseEnter={ () => { !nomenclatureTable?.header[head].immutable && setEditValue(prevState => {
                                        return {
                                          ...prevState,
                                          [head]: {
                                            edit: false,
                                            show: true
                                          }
                                        }
                                      }) } }
                                      onMouseLeave={ () => { setEditValue(prevState => {
                                        return {
                                          ...prevState,
                                          [head]: {
                                            edit: false,
                                            show: false
                                          }
                                        }
                                      }) } }
                                    >
                                      {
                                        editValue[head].show && !editValue[head].edit &&
                                        <>
                                          <div className='category-table-header__icon'>
                                            <EditTable onClick={ () => { setEditValue(prevState => {
                                              return {
                                                ...prevState,
                                                [head]: {
                                                  edit: true,
                                                  show: false
                                                }
                                              }
                                              })}}
                                            />
                                          </div>
                                          <div className='category-table-header__icon' onClick={  () => { setConfirmText('row'); setCurrentConfirmAction(() => () => { removeColumn(key, head); setIsConfirmModal(false)  }); setIsConfirmModal(true) } }><CrossIcon/></div>
                                        </>
                                      }
                                      {
                                        !editValue[head].show &&
                                          <textarea
                                            name=""
                                            id=""
                                            className='category-table-header__text'
                                            readonly={editValue[head].edit}
                                            disabled={ nomenclatureTable?.header[head].immutable }
                                            onChange={
                                              (e) => {
                                                changeColumnName(head, e.target.value)
                                              }
                                            }
                                          >
                                            { head }
                                          </textarea>
                                      }
                                    </th>
                                  }
                                </>
                            })
                          }
                        </tr>
                      </thead>
                      <tbody className='category-table__body'>
                        {
                          nomenclatureTable?.body?.map((row: any, key: number) => {
                            return <> 
                              <tr key={key} className={ deletedRow === key ? 'category-table-row deleted': 'category-table-row' } >
                                <td
                                  key={key}
                                  className='category-table-row__item category-table-row__item--number'
                                  onMouseEnter={ () => { setDeletedRow(key); } }
                                  onMouseLeave={ () => { setDeletedRow(null); } }
                                  onClick={ () => { setConfirmText('row'); setCurrentConfirmAction(() => () => { removeRow(key); setIsConfirmModal(false)  }); setIsConfirmModal(true) } }
                                >{ deletedRow === key ? <CrossIcon/> : key + 1 }</td>
                                {
                                    row?.data?.map((line: any, index: number) => {
                                      return <td
                                        key={index}
                                        className={
                                          nomenclatureColumns[index]?.type === 'INT' ? 
                                          'category-table-row__item' :
                                          /^[a-zA-Zа-яА-ЯёЁ0-9\\?!№%.,/: ]+$/.test(line?.value) ? 'category-table-row__item' : 'category-table-row__item category-table-row__item--invalid' 
                                        }
                                        onMouseEnter={ () => { setEditCell({ key, index }) } }
                                        onMouseLeave={ () => { setEditCell(null) } }
                                        >
                                          {
                                            (editCell?.key === key && editCell?.index === index) &&
                                            <EditTable onClick={() => { setEditCell(null)} } />
                                          }
                                          {
                                            (editCell?.key !== key || editCell?.index !== index) &&
                                            <input
                                              onChange={ (e) => { changeNomenclature(
                                                e.target.value,
                                                key,
                                                index
                                              )}}
                                              type={ nomenclatureColumns[index]?.type === 'INT' ? 'number': 'text' }
                                              value={line?.value}
                                              placeholder=''
                                              className='category-table-row__input'
                                            />
                                          }
                                      </td>
                                    })
                                }
                                { (key === 0 && isShowAddColumnButton()) &&
                                  <td rowSpan="0" className='category-table-row__item category-table-row__item--added'  onClick={ () => { addColumn() } }><span><CrossIcon className='category-table-row__item--added__img'/> Добавить столбец</span> </td>
                                }
                              </tr>
                            </>
                          })
                        }
                      </tbody>
                    </table>
                          <div className="category-added-material" onClick={ () => { addRow() } }>Добавить материал <CrossIcon className='category-added-material__img'/></div>
                        </AccordionTab>  
                </Accordion>
              </div>
              })
            }
          </div>
        }
        {/* { (tableData.length > 0 && offers.length === 0) &&
          <div className="categories-category-buttons">
            <button disabled={ !isValidTable } className='categories-category-buttons__sendbutton disabled' onClick={() => sendData()}>{ pageStaticText.pro.sendButton }</button>
          </div>
        } */}
        {
          tempCategory.length !== 0 && 
          <div className="categories-category-buttons">
            <button className='categories-category-buttons__sendbutton' onClick={() => sendCategory()}>Создать категорию</button>
          </div>  
        }
        {
          tempCategory.length === 0 && 
          <div className="categories-category-buttons">
            <button disabled={ checkTableValidation() } className={ checkTableValidation() ? 'categories-category-buttons__sendbutton disabled' : 'categories-category-buttons__sendbutton' } onClick={() => sendData()}>{ pageStaticText.pro.sendButton }</button>
          </div>  
        }
      </div>
      }
      <Dialog visible={isAddNewCategoryModal} onHide={() => setAddNewCategoryModal(false)} className='categories-modal'>
        <CategoryNewModal
          onClose={ () => { setAddNewCategoryModal(false) } }
          onSave={ async (categories: ICommercialNewCategorie[]) => {
            setAddNewCategoryModal(false);
            createNewCategory(categories);
          }}
        />
      </Dialog>
    </div>
  )
};