import { FC, useState, useEffect } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import moment from 'moment';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';

import InputTest from 'components/common/InputTest';
import { validations } from 'helpers/validations';

export const LogisticForm: FC<{logistic, submitAction}> = ({ logistic, submitAction }) => {
  const [isShowCalendar, setShowCalendar] = useState(false);
  const [date, setDate] = useState(false);

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      organizationName: logistic?.organizationName,
      phoneNumber: logistic?.phoneNumber,
      fullName: logistic?.fullName,
      carInfo: logistic?.carInfo,
      arrivalDate: logistic?.departurePoint?.arrivalDate
    },
  });
  const { setValue } = useForm();

  useEffect(() => {
    setValue('arrivalDate', moment(date).format('L').replaceAll('/', '.'));
  }, [date]);

  const onSubmit = (data: any) => {
    submitAction(data);
  };

  return (
    <div className='flex justify-content-center flex-wrap'>
      <FormProvider {...methods}>
        <form
          className='order--form auth-form auth-form--login p-d-flex p-jc-center p-mt-2 w-8'
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <div className='flex justify-content-center flex-wrap gap-2'>
            <span className='auth-form-title'>
              Редактировать логиста
            </span>
          </div>
          <div className='flex flex-column gap-2'>
            <InputTest
              name='organizationName'
              type='text'
              validations={validations.required}
              classNameInput='auth-form-input auth-form-login'
              classNameLabel='auth-form-label'
              classNameValid='input-valid-auth'
              classNameError='input-error-auth'
              placeholder='ООО «Джентельмены»'
              label='Грузоперевозчик'
            />
          </div>
          <div className='flex flex-column gap-2'>
            <InputTest
              name='phoneNumber'
              type='text'
              validations={validations.required}
              classNameInput='auth-form-input auth-form-login'
              classNameLabel='auth-form-label'
              classNameValid='input-valid-auth'
              classNameError='input-error-auth'
              placeholder='+ 7 (999) 348- 48-48'
              label='Номер телефона'
            />
          </div>
          <div className='flex flex-column gap-2'>
            <InputTest
              name='fullName'
              type='text'
              validations={validations.required}
              classNameInput='auth-form-input'
              classNameLabel='auth-form-label'
              classNameValid='input-valid-auth'
              classNameError='input-error-auth'
              placeholder='Свинтицкий Станислав Ст|'
              label='ФИО водителя'
            />
          </div>
          <div className='flex flex-column gap-2'>
            <InputTest
              name='brand'
              type='text'
              validations={validations.required}
              classNameInput='auth-form-input auth-form-login'
              classNameLabel='auth-form-label'
              classNameValid='input-valid-auth'
              classNameError='input-error-auth'
              placeholder='ГАЗ ГАЗель next 20'
              label='Марка машины'
            />
          </div>
          <div className='flex flex-column gap-2'>
            <InputTest
              name='model'
              type='text'
              validations={validations.required}
              classNameInput='auth-form-input auth-form-login'
              classNameLabel='auth-form-label'
              classNameValid='input-valid-auth'
              classNameError='input-error-auth'
              placeholder='ГАЗ ГАЗель next 20'
              label='Модель машины'
            />
          </div>
          <div className='flex flex-column gap-2'>
            <InputTest
              name='number'
              type='text'
              validations={validations.required}
              classNameInput='auth-form-input auth-form-login'
              classNameLabel='auth-form-label'
              classNameValid='input-valid-auth'
              classNameError='input-error-auth'
              placeholder='О123'
              label='Номер машины'
            />
          </div>
          {/* <div className='flex flex-column gap-2' onClick={ () => { setShowCalendar(true) } }>
            <InputTest
              name='arrivalDate'
              type='text'
              mask={'99.99.9999'}
              disabled={true}
              validations={validations.required}
              classNameInput='auth-form-input auth-form-login'
              classNameLabel='auth-form-label'
              classNameValid='input-valid-auth'
              classNameError='input-error-auth'
              placeholder='04.09.2020'
              label='Дата прибытия'
            />
          </div>
          { isShowCalendar &&
            <div className='flex flex-column gap-2'>
              <Calendar value={date} onChange={(e) => setDate(e.value)} inline showWeek />
            </div>
          } */}
          {/* <div className='flex flex-column gap-2'>
            <InputTest
              name='arrivalDate'
              type='text'
              validations={validations.required}
              classNameInput='p-form-input auth-form-login'
              classNameLabel='auth-form-label'
              classNameValid='input-valid-auth'
              classNameError='input-error-auth'
              placeholder='04.09.2020'
              label='Время прибытия'
            />
          </div> */}
          <div className='flex justify-content-center flex-wrap align-items-stretch'>
            <Button
              className='auth-form-login-button flex align-items-center justify-content-center'
              label='Сохранить изменения'
              type='submit'
            />
          </div>
          
        </form>
      </FormProvider>
    </div>
  );
};
