import { FC, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import ReactCardFlip from 'react-card-flip';
import { NavLink } from 'react-router-dom';
import { Button } from 'primereact/button';
import moment from 'moment';

import { useAppSelector } from 'store';
import {
  PaymentStatus,
  OrderStatus,
  IOrderItemData
} from 'store/orders/types';

import {
  HomeIcon,
  ParkIcon,
  SofaIcon,
  StepDocumentIcon
} from 'assets/icons';

export const OrderCustomer: FC<{ order:IOrderItemData }> = ({ order }) => {
  const [isFlipped, setFlipped] = useState(false);

  const getPercent = (orderStatus: OrderStatus) => {
    if (orderStatus === 'CANCELLED') {
      return 0;
    }
    const statusLength = Object.keys(OrderStatus).length;
    const statusIndex = Object.keys(OrderStatus).indexOf(orderStatus);
    return Math.round(100 / statusLength * statusIndex);
  }

  return (
  <div className={`orders-list--list_item orders-list--list_item-role-customer flex`}>
    <div className='grid flex justify-content-between flex-wrap orders-list--list_item__order w-full'>
      <div className='desktop col flex justify-content-between justify-content-center orders-list--list_left w-5'>

        <div className='orders-list--list-order_progress w-3'
          onMouseEnter={() => setFlipped(false)}
          onMouseLeave={() => setFlipped(false)}
        >
          <ReactCardFlip isFlipped={isFlipped} flipDirection='horizontal'>
            <div>
              <CircularProgressbar
                className='orders-list--list-order_progress--bar'
                strokeWidth={4}
                value={getPercent(order.status)}
                text={`${getPercent(order.status)}%`}
                styles={
                  buildStyles({
                    pathColor: `rgba(137, 165, 30, 1), ${getPercent(order.status) / 100})`,
                    textColor: '#353535',
                    trailColor: '#F5F5F5'
                  })
                }
              />
            </div>
            <div>
              <div className='orders-list--list-order_additional__icon'>
                <StepDocumentIcon />
              </div>
            </div>
          </ReactCardFlip>
        </div>

        <div className='orders-list--list-order_info w-9'>
          <div className='orders-list--list-order_number flex justify-content-start flex-wrap w-full'><span>Заказ № { order.number }</span></div>
          <div className='w-full'>
            <span className='orders-list--list-order_name'>{ order.projectName }</span>
            <span className='orders-list--list-order_date'>{ moment(order.timeCreated).format('L').replaceAll('/', '.') }</span>
          </div>
          {/* <div className='orders-list--list-order_additional flex justify-content-start flex-wrap w-full'>
            <div className='w-4'>
              <div className='orders-list--list-order_additional__icon'>
                <HomeIcon />
              </div>
            </div>
            <div className='w-4'>
              <div className='orders-list--list-order_additional__icon'>
                <ParkIcon />
              </div>
            </div>
            <div className='w-4'>
              <div className='orders-list--list-order_additional__icon'>
                <SofaIcon />
              </div>
            </div>
          </div> */}
        </div>
      </div>

      <div className='desktop col orders-list--list_right w-7'>
        <div className='flex justify-content-end flex-wrap w-full'>
          <div className='orders-list--list-order_price inline-block flex align-items-center justify-content-center'>
            <span>{ order.totalAmount ? (order?.totalAmount?.toFixed()).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ') : '' } ₽</span>
          </div>
          <div className='orders-list--list-order_payment_status inline-block flex align-items-center justify-content-center'><span> { PaymentStatus[order.paymentStatus as keyof typeof PaymentStatus] } </span></div>
          <div className='orders-list--list-order_current_status inline-block flex align-items-center justify-content-center'><span> { OrderStatus[order.status as keyof typeof OrderStatus] } </span></div>
        </div>
        <div className='flex justify-content-end flex-wrap orders-list--list-order_button w-full'>
          <NavLink
            to={`/order/${order.id}`}
          >
            <Button
              className='orders-list--list-order_button_btn'
              label='Перейти'
            />
          </NavLink>
        </div>
      </div>
    </div></div>
  )
}