import { FC, useState, useEffect } from 'react';
import { Button } from 'primereact/button';

import { CartInfo } from './CartInfo'
import { CartPrices  } from 'components/Cart/CartPrices';

import { useAppSelector, useAppDispatch } from 'store';
import { fetchProjects } from 'store/projects/actions';
import { ICartData } from 'store/carts/types';

import staticData from 'common/static.json';

export const CartCard: FC<ICartData> = (cart) => {
  const dispatch = useAppDispatch();
  // const [isOrdersExists, setOrders] = useState(false);

  // const {
  //   list
  // } = useAppSelector(state => state.carts);

  // useEffect(() => {
  //   dispatch(fetchProjects(cart?.projectId));
  // }, []);

  // useEffect(() => {
  //   if (list.length) {
  //     setOrders(true);
  //   } else {
  //     setOrders(false);
  //   }
  // }, [list]);

  return (
    <div className='cart-item'>
      <CartInfo { ...cart } />
    </div>
  )
};