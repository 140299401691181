import { FC, useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';

import { fetchOrders } from 'store/orders/actions';

import { useAppDispatch, useAppSelector } from 'store';

import { PaymentStatus, OrderStatusActive, OrderStatusArchive } from 'store/orders/types';

import staticData from 'common/static.json';

import { CalendarIcon } from 'assets/icons'

const pageSize = 5;

export const Filters:FC<{ orderType:string }> = ({orderType}) => {
  const dispatch = useAppDispatch();

  const [paymentFilter, setPaymentFilter] = useState('');
  const { token } = useAppSelector(state => state.auth);
  const [orderFilter, setOrderFilter] = useState('');
  const [dateFilters, setDateFilters] = useState(null);
  const [orderStatusFields, setOrderStatusFields] = useState(null);


  const [date, setDate] = useState(null);

  const filterChange = ( value:string, field:string ) => {
    if (field === 'payment') {
      setPaymentFilter(value);
    }

    if (field === 'order') {
      setOrderFilter(value);
    }
  }

  useEffect(() => {
    let tempArray = [];
    if (orderType === 'ACTIVE') {
      Object.keys(OrderStatusActive).map((item) => {
        tempArray.push({
          name: OrderStatusActive[item],
          value: item
        });
      });
    } 
    if (orderType === 'ARCHIVE') {
      Object.keys(OrderStatusArchive).map((item) => {
        tempArray.push({
          name: OrderStatusArchive[item],
          value: item
        });
      });
    }
    setOrderStatusFields([...tempArray]);
  }, [orderType])

  useEffect(() => {
    let params = {
      pageSize,
      pageNum: 0,
    };

    if (orderFilter) {
      params.orderStatuses = orderFilter;
    }

    if (paymentFilter) {
      params.paymentStatuses = paymentFilter;
    }
    if (token) {
      dispatch(fetchOrders(params));
    }
  }, [paymentFilter, orderFilter])

  let paymentStatusFields:any = [];

  Object.keys(PaymentStatus).map((item) => {
    paymentStatusFields.push({
      name: PaymentStatus[item],
      value: item
    });
  });

  return (
    <div className='flex align-content-start flex-wrap'>
      <div className='orders-list--filters_item'>
        <div className='orders-list--filters_item-label'>
          <span>Статус оплаты</span>
        </div>
        <Dropdown
          value={paymentFilter}
          onChange={(e) => filterChange(e.value, 'payment')}
          options={ paymentStatusFields }
          optionLabel='name' 
          placeholder='Статус оплаты'
          className='w-full md:w-14rem'
          showClear
        />
      </div>
      <div className='orders-list--filters_item'>
        <div className='orders-list--filters_item-label'>
          <span>Статус заказа</span>
        </div>
        <Dropdown
          value={orderFilter}
          onChange={(e) => filterChange(e.value, 'order')}
          options={ orderStatusFields }
          optionLabel='name' 
          placeholder='Статус заказа'
          className='w-full md:w-14rem'
          showClear
        />
      </div>
      {/* <div className='orders-list--filters_item'>
        <div className='orders-list--filters_item-label'>
          <span>Дата заказа</span>
        </div>
        <Calendar
          id="buttondisplay"
          value={ date }
          onChange={(e) => filterChange(e, 'date')}
          showIcon
          icon={ <CalendarIcon /> }
        />
      </div> */}
    </div>
  )
}
