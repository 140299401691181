import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import authReducer from 'store/auth/reducer';
import ordersReducer from 'store/orders/reducer';
import uiReducer from 'store/ui/reducer';
import profileReducer from 'store/profile/reducer';
import partnersReducer from 'store/partners/reducer';
import usersReducer from 'store/users/reducer';
import commercialReducer from 'store/commercial/reducer';
import cartsReducer from 'store/carts/reducer';
import projectsReducer from 'store/projects/reducer';
import referralReducer from 'store/referral/reducer';
import documentsReducer from 'store/documents/reducer';

const store = configureStore({
  reducer: {
    auth: authReducer,
    profile: profileReducer,
    orders: ordersReducer,
    ui: uiReducer,
    partners: partnersReducer,
    users: usersReducer,
    commercial: commercialReducer,
    carts: cartsReducer,
    projects: projectsReducer,
    referral: referralReducer,
    documents: documentsReducer
  },
});

export const useAppDispatch = () => useDispatch<typeof store.dispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type RootState = ReturnType<typeof store.getState>;

export default store;
