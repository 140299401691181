import { FC, useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { PasswordRestoreIcon  } from 'assets/icons';
import { requestEmailConfirmation, emailConfirmation } from 'store/auth/actions';
import { setEmailToken } from 'store/auth/reducer';
import { getUser } from 'store/profile/actions';
import { useAppDispatch, useAppSelector } from 'store';

import { EmailType } from 'store/profile/types';

import { routesPath } from 'routes';

import staticData from 'common/static.json';
const staticText = staticData.auth.passwordRestore;

export const EmailConfirmation: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [counter, setCounter] = useState(59);
  const [isWaiting, setWaitingStatus] = useState(true);
  //const [emailToken, setEmailToken] = useState('');
  const { id, emailToken } = useAppSelector(state => state.auth);
  const { common } = useAppSelector(state => state.profile);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (emailToken !== null) {
      dispatch(emailConfirmation(emailToken));
    }
  }, [emailToken]); 

  useEffect(() => {
    const tempToken = searchParams.get('token');
    if (tempToken !== null) {
      dispatch(setEmailToken(tempToken));
      if (id && tempToken) {
        dispatch(emailConfirmation(tempToken));
      }
    }
  }, [searchParams]); 

  useEffect(() => {
    if (id) {
      if (searchParams.get('token')) {
        dispatch(emailConfirmation(searchParams.get('token')));
      }
      dispatch(getUser());
    }
  }, [id]);

  useEffect(() => {
    if (common.emailConfirmationStatus === EmailType.CONFIRMED) {
      navigate(routesPath.ordersList);
    }
  }, [common]);

  useEffect(() => {
    if (isWaiting) {
      startCountdown();
    }
  }, [isWaiting]);

  const secondsFormat = () => {
    if (counter > 9) {
      return `00:${counter}`;
    }

    return `00:0${counter}`;
  };

  const startCountdown = () => {
    let timerValue = 59;
    const intervalId = setInterval(() => {
      if (timerValue > 1) {
        timerValue -= 1;
        setCounter(timerValue);
      } else {
        clearInterval(intervalId);
        setWaitingStatus(false);
      }
    }, 1000);
  };

  const requestNewEmail = () => {
      dispatch(requestEmailConfirmation(id));
      setCounter(59);
      setWaitingStatus(true);
  };

  return (
    <div className='flex justify-content-center flex-wrap'>
      <form
        className='auth-form auth-form--restore p-d-flex p-jc-center p-mt-2 w-8 auth-form'
      >
        <div className='auth-form--restore-pending'>
          <div className='auth-form--restore-icon'>
            <PasswordRestoreIcon />
          </div>
          <div className='auth-form--restore-info'>
            <span>{staticText.info}</span>
          </div>
          <div className='auth-form--restore-description'>
            <div>Мы отправили  подтверждение на ваш электронный ящик</div>
          </div>
          <div className='auth-form--restore-timer'>
            { isWaiting && (
              <span>
                {staticData.auth.passwordRestore.timer} {secondsFormat()}
              </span>
            )}
            { !isWaiting && (
              <span
                onClick={requestNewEmail}
                className='auth-form--restore-timer--link'
              >
                {staticData.auth.passwordRestore.timerRetry}{' '}
              </span>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};
