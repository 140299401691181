export enum FormatTo {
  server = 'server',
  client = 'client',
}

export const formatDate = (date: string, to: FormatTo) => {
  switch (to) {
    case FormatTo.client:
      return date.split('-').reverse().join('.');
    case FormatTo.server:
      return date.split('.').reverse().join('-');
    default:
      break;
  }
};
