import { FC, useState, useEffect } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Dialog } from 'primereact/dialog';

import moment from 'moment';

import { getById, editLandData, edit, addDocuments, editDocuments, removeDocuments, assignExecutors } from 'store/orders/actions';
import { getExecutors } from 'store/partners/actions';

import { orderStatus, paymentStatus } from 'store/orders/types';

import { partnersTypes, PartnerTypes, ExecutorsTypes } from 'store/partners/types';
import { updatePartnerIssue } from 'store/orders/actions';
import { StatusForm } from 'components/Order/Representative/forms/StatusForm';
import { LogisticForm } from 'components/Order/Representative/forms/LogisticForm';
import { AddressForm } from './forms/address';
import { useAppSelector, useAppDispatch} from 'store';

import { LogisticStatus } from 'store/orders/types';
import { DeadlineForm } from './forms/DeadlineForm';

import { changeVisibilityForPartner } from 'store/documents/actions';
import {
  DocumentsAwaitingSignature,
  CrossIcon,
  CheckMarkIcon,
  CheckMarkFilledIcon, 
  CheckMarkFilledIcon2,
  CheckMarkEmptyIcon,
  DocumentEyeShow,
  DocumentEyeNoshow,
  PdfDocumentIcon
} from 'assets/icons';

import { OrderDocumentsForm } from '../forms/OrderDocumentsForm';
import { getOrdersDocumentsById } from 'store/documents/actions';

import { DocumentsPreview } from 'components/Documents/DocumentsPreview';

export const LogisticInputs:FC = () => {
  const dispatch = useAppDispatch();
  const [orderData, setOrderData] = useState('{}');
  const { partnerIssue, order } = useAppSelector(state => state.orders);

  // const { executors } = useAppSelector(state => state.partners);

  const [isShowDialog, showDialog] = useState(false);
  const [currentDialog, setCurrentDialog] = useState(<></>);
  
  const {
    role
  } = useAppSelector(state => state.auth);

  useEffect(() => {
    if (partnerIssue?.issue?.payloadData) {
      setOrderData(partnerIssue?.issue?.payloadData);
    }
    
  }, [partnerIssue]);

  useEffect(() => {
    dispatch(getExecutors({ orderExecutorTypes: Object.keys(ExecutorsTypes) }));
  }, []);

  const isAdmin = role === 'ADMIN';

  // let defaultPayloadData = {
  //   fullName: null,
  //   representativeName: null,
  //   representativePhoneNumber: null,
  //   carInfo: {
  //     brand: null,
  //     model: null,
  //     number: null
  //   },
  //   pickUpPoint: {
  //     address: null,
  //     arrivalDate: null,
  //     arrivalHourFrom: null,
  //     arrivalHourTo: null,
  //     organizationName: null,
  //     phoneNumber: null
  //   },
  //   departurePoint: {
  //     address: null,
  //     arrivalDate: null,
  //     arrivalHourFrom: null,
  //     arrivalHourTo: null,
  //     organizationName: null,
  //     phoneNumber: null
  //   }
  // }

  const [defaultPayloadData, setdefaultPayloadData] = useState( {
    fullName: null,
    representativeName: null,
    representativePhoneNumber: null,
    carInfo: {
      brand: null,
      model: null,
      number: null
    },
    pickUpPoint: {
      address: null,
      arrivalDate: null,
      arrivalHourFrom: null,
      arrivalHourTo: null,
      organizationName: null,
      phoneNumber: null
    },
    departurePoint: {
      address: null,
      arrivalDate: null,
      arrivalHourFrom: null,
      arrivalHourTo: null,
      organizationName: null,
      phoneNumber: null
    }
  });

  const [isShowDocument, setShowDocument] = useState(false);
  const [currentDocument, setCurrentDocument] = useState();

  const showDocument = (document: any) => {
    setCurrentDocument(document);
    dispatch(getOrdersDocumentsById(document?.fileId));
    setShowDocument(true);
  }

  const changeDocuments = (data) => {
    showDialog(false);

    data.forEach(document => {
      switch(document.type) {
        case 'removed':
          dispatch(removeDocuments({
            orderId: order?.order?.id,
            documentId: document.id
          }));
          return;
        default:
          return;
      }
    })
  }


  const showEditForm = (type: string) => {
    showDialog(true);
    switch(type) {
      case 'status':
          setCurrentDialog(
            <StatusForm
              currentStatus={partnerIssue?.issue?.status}
              submitAction={ (data) => { editData('status', data) } }
            />
          );
          return;
      case 'addressA':
        setCurrentDialog(
          <AddressForm
            address={ JSON.parse(JSON.parse(JSON.stringify(orderData)))?.pickUpPoint }
            submitAction={ (data) => { updatePayloadData(data, 'pickUpPoint') }}
          />
        );
        return;
        case 'addressB':
          setCurrentDialog(
            <AddressForm
              address={ JSON.parse(JSON.parse(JSON.stringify(orderData)))?.departurePoint }
              submitAction={ (data) => { updatePayloadData(data, 'departurePoint') }}
            />
          );
          return;
      case 'logistic':
        setCurrentDialog(
          <LogisticForm
            logistic={ JSON.parse(JSON.parse(JSON.stringify(orderData))) }
            submitAction={ (data) => { updateLogisticData(data) }}
          />
        );
          return;
        case 'deadline':
          setCurrentDialog(
            <DeadlineForm
              deadline={ partnerIssue?.issue?.orderExecutionDeadline }
              submitAction={ (data) => { updateLogisticData(data) }}
            />
          );
            return;
        case 'documents':
          setCurrentDialog(
            <OrderDocumentsForm
              currentDocuments={order?.documents}
              submitAction={ (data) => { changeDocuments(data) } }
            />
          );
          return;
      default:
        setCurrentDialog(<></>);
        return;
    }
  }

  const editData = (field, data) => {
    showDialog(false);
    const params = {};
    params[field] = data;

    dispatch(updatePartnerIssue({
      id: partnerIssue?.issue?.id,
      ...params
    }))
  }

  const updatePayloadData = (data, point) => {
    showDialog(false);
    let temObject = structuredClone(defaultPayloadData);
    temObject[point] = {
      ...temObject[point],
      ...data
    }
    dispatch(updatePartnerIssue({
      id: partnerIssue?.issue?.id,
      updatedPayload: JSON.stringify(temObject)
    }))
  }


  const updateLogisticData = (data) => {
    showDialog(false);

    let temObject = structuredClone(defaultPayloadData);

    temObject.carInfo = {
      brand: data?.brand,
      model: data?.model,
      number: data?.number,
    }

    temObject.representativeName = data.organizationName;
    temObject.representativePhoneNumber = data.phoneNumber;
    temObject.fullName = data.fullName;

    setdefaultPayloadData(temObject)

    dispatch(updatePartnerIssue({
      id: partnerIssue?.issue?.id,
      status: partnerIssue?.issue?.status,
      updatedPayload: JSON.stringify(temObject)
    }))
  }

  const changeVisibility = async (id: string) => {
    await dispatch(changeVisibilityForPartner(id));
    dispatch(getById(order.order.id));
  }

  return (
    <div className='order-detail_inputs order-detail_inputs_block order--inputs flex align-items-center justify-content-center'>
      <Dialog visible={isShowDialog} style={{ width: '50vw' }} onHide={() => showDialog(false)} className='order--dialog'>
          { currentDialog }
      </Dialog>
      <>
        <Dialog
          visible={ isShowDocument }
          onHide={ () => setShowDocument(false) }
          header=""
          className='documents-modal documents__modal'
        >
          <DocumentsPreview />
          {
            currentDocument?.status === 'WAITING_FOR_SENDING' &&
            <div className="documents-modal__button">Ожидает отправки</div>
          }
          {
            currentDocument?.status === 'WAITING_FOR_SIGNING' &&
            <div className="documents-modal__button">Ожидает подписания</div>
          }
          {
            currentDocument?.status === 'FIRST_PARTICIPANT_SIGNED' &&
            <div className="documents-modal__button">1 подписант</div>
          }
          {
            currentDocument?.status === 'SECOND_PARTICIPANT_SIGNED' &&
            <div className="documents-modal__button">2 подписанта</div>
          }
        </Dialog>
      </>
      <div className='order-detail_inputs--list col-12'>
        <div className='order-detail_inputs--list__date'>
          Дата заказа {moment(partnerIssue?.issue?.timeCreated).format('L').replaceAll('/', '.')}
        </div>
        <Accordion activeIndex={0}>
          {/* <AccordionTab
            header={`Дата заказа ${moment(partnerIssue?.issue?.timeCreated).format('L').replaceAll('/', '.')}`}
          >
          </AccordionTab> */}
          <AccordionTab
            header='Статус заказа'
          >
            <ul>
              <li>Статус: { LogisticStatus[partnerIssue?.issue?.status] }</li>
            </ul>
            { isAdmin && 
              <div className='order-detail_inputs--buttons order--inputs_buttons'>
                <button onClick={ () => { showEditForm('status') }}>Редактировать</button>
              </div>
            }
          </AccordionTab>
          <AccordionTab
            header='Крайний срок исполнения заказа'
          >
            <ul>
              <li>Дата: { moment(partnerIssue?.issue?.orderExecutionDeadline).format('L').replaceAll('/', '.') }</li>
            </ul>
            { isAdmin &&
              <div className='order-detail_inputs--buttons order--inputs_buttons'>
                <button onClick={ () => { showEditForm('deadline') }}>Редактировать</button>
              </div>
            }
          </AccordionTab>
          <AccordionTab
            header='Адреса'
          >
            <ul>
              <li style={{color: '#353535', marginLeft: '5px'}}>Пункт А</li>
              <li style={{ marginLeft: '15px'}}>Адрес:{ JSON.parse(JSON.parse(JSON.stringify(orderData)))?.pickUpPoint?.address }</li>
              <li style={{ marginLeft: '15px'}}>Дата прибытия { JSON.parse(JSON.parse(JSON.stringify(orderData)))?.pickUpPoint?.arrivalDate }</li>
              <li style={{ marginLeft: '15px'}}>Временной промежуток: с { JSON.parse(JSON.parse(JSON.stringify(orderData)))?.pickUpPoint?.arrivalHourFrom } до { JSON.parse(JSON.parse(JSON.stringify(orderData)))?.pickUpPoint?.arrivalHourTo }</li>
            </ul>
            { isAdmin &&
              <div className='order-detail_inputs--buttons order--inputs_buttons'>
                <button onClick={ () => { showEditForm('addressA') }}>Редактировать</button>
              </div>
            }
            <ul>
              <li style={{color: '#353535', marginLeft: '5px'}}>Пункт Б</li>
              <li style={{ marginLeft: '15px'}}>Адрес:{ JSON.parse(JSON.parse(JSON.stringify(orderData)))?.departurePoint?.address }</li>
              <li style={{ marginLeft: '15px'}}>Дата прибытия { JSON.parse(JSON.parse(JSON.stringify(orderData)))?.departurePoint?.arrivalDate }</li>
              <li style={{ marginLeft: '15px'}}>Временной промежуток: с { JSON.parse(JSON.parse(JSON.stringify(orderData)))?.departurePoint?.arrivalHourFrom } до { JSON.parse(JSON.parse(JSON.stringify(orderData)))?.departurePoint?.arrivalHourTo }</li>
            </ul>
            { isAdmin &&
              <div className='order-detail_inputs--buttons order--inputs_buttons'>
                <button onClick={ () => { showEditForm('addressB') }}>Редактировать</button>
              </div>
            }
          </AccordionTab>
          <AccordionTab
            header='Контакты'
          >
            <ul>
              <li style={{color: '#353535', marginLeft: '5px'}}>Пункт А</li>
              <li style={{ marginLeft: '15px'}}>Наименование организации :{ JSON.parse(JSON.parse(JSON.stringify(orderData)))?.pickUpPoint?.organizationName }</li>
              <li style={{ marginLeft: '15px'}}>Номер телефона: { JSON.parse(JSON.parse(JSON.stringify(orderData)))?.pickUpPoint?.phoneNumber }</li>
            </ul>
            <ul>
              <li style={{color: '#353535', marginLeft: '5px'}}>Пункт Б</li>
              <li style={{ marginLeft: '15px'}}>Наименование организации :{ JSON.parse(JSON.parse(JSON.stringify(orderData)))?.departurePoint?.organizationName }</li>
              <li style={{ marginLeft: '15px'}}>Номер телефона: { JSON.parse(JSON.parse(JSON.stringify(orderData)))?.departurePoint?.phoneNumber }</li>
            </ul>
          </AccordionTab>
          <AccordionTab
            header='Исполнитель'
          >
            <ul>
              <li>Грузоперевозчик: { partnerIssue?.issue?.executorData?.organizationName }</li>
              <li>Номер телефона: { partnerIssue?.issue?.executorData?.phoneNumber }</li>
              <li>ФИО водителя: { JSON.parse(JSON.parse(JSON.stringify(orderData)))?.fullName }</li>
              <li>Марка и модель машины: { JSON.parse(JSON.parse(JSON.stringify(orderData)))?.carInfo?.brand },  &nbsp;
              { JSON.parse(JSON.parse(JSON.stringify(orderData)))?.carInfo?.model }</li>
              <li>Номер машины: { JSON.parse(JSON.parse(JSON.stringify(orderData)))?.carInfo?.number }</li>
            </ul>
            { isAdmin &&
              <div className='order-detail_inputs--buttons order--inputs_buttons'>
                <button onClick={ () => { showEditForm('logistic') }}>Редактировать</button>
              </div>
            }
          </AccordionTab>
        </Accordion>
        </div>
    </div>
  )
}
