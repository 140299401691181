import { useAppDispatch, useAppSelector } from 'store';
import staticData from 'common/static.json';
import InputTest from 'components/common/InputTest';
import { AccountType } from 'store/profile/types';
import IndividualCitySelect from './IndividualCitySelect';
import LegalCitySelect from './LegalCitySelect';

import photo from '../../assets/images/blank-avatar.png';
import { validations } from 'helpers/validations';
import { EmailConfirmIcon } from 'assets/icons';
import { Tooltip } from 'primereact/tooltip';
import { requestEmailConfirmation } from 'store/auth/actions';
import { useNavigate } from 'react-router';
import { routesPath } from 'routes';
import { EmailType } from 'store/profile/types';

const text = staticData.profile;
const placeholder = staticData.profile.placeholders.info;

const ProfileInfo: React.FC = () => {
  const { isEditing, common, legalUser } = useAppSelector(state => state.profile);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleCityType = () => {
    switch (common.accountType) {
      case AccountType.individualUser:
        return <IndividualCitySelect />;
      case AccountType.legalUser:
        return <LegalCitySelect />;
      default:
        break;
    }
  };

  const handleNameType = () => {
    switch (common.accountType) {
      case AccountType.individualUser:
      case AccountType.uncertainUser:
        return (
          <InputTest
            name='name'
            validations={validations.name}
            classNameInput='profile-form-input-info font-bold text-xl p-0'
            classNameError='input-error-info'
            disabled={!isEditing}
            placeholder={placeholder.name}
          />
        );
      case AccountType.legalUser:
        return (
          <InputTest
            name='orgName'
            validations={validations.orgName}
            classNameInput='profile-form-input-info font-bold text-xl'
            classNameError='input-error-info'
            disabled={!isEditing || (legalUser.orgName !== null && legalUser.orgName !== '') }
            placeholder={placeholder.orgName}
          />
        );
      default:
        break;
    }
  };

  const handleConfirmEmail = () => {
    dispatch(requestEmailConfirmation(common.id)).then(() => {
      navigate(routesPath.emailConfirmation);
    });
  };

  return (
    <div>
      <div className='profile-desktop flex flex-column'>
        <div className='profile-info grid flex align-items-start'>
          <div className='col-12 p-1'>{handleNameType()}</div>
          { common.accountType === AccountType.individualUser &&
            <>
              <div className='col-3 font-bold'>{`${text.date}:`}</div>
              <div className='col-9'>
                  <InputTest
                    mask={'99.99.9999'}
                    name='dateOfBirth'
                    validations={validations.mask}
                    classNameInput='profile-form-input-info'
                    classNameError='input-error-info'
                    disabled={!isEditing}
                  />
              </div>
            </>
          }
          <div className='col-3 font-bold'>{`${text.email}:`}</div>
          <div className='col-9 flex gap-1 align-items-center'>
          { common.emailConfirmationStatus !== EmailType.CONFIRMED &&
              <>
                <EmailConfirmIcon
                  className='confirm-icon cursor-pointer select-none'
                  data-pr-tooltip={text.emailConfirm}
                  data-pr-position='right'
                  onClick={handleConfirmEmail}
                />
                <Tooltip className='input-tooltip' target='.confirm-icon' />
              </>
            }
            <InputTest
              name='email'
              validations={validations.email}
              classNameInput='profile-form-input-info'
              classNameError='input-error-info'
              classNameLabel='profile-form-label-email'
              disabled={!isEditing}
            />
          </div>
          <div className='col-3 font-bold'>{`${text.phoneNumber}:`}</div>
          <div className='col-9'>
            <InputTest
              mask={'+7 (999) 999-99-99'}
              name='phoneNumber'
              validations={validations.mask}
              classNameInput='profile-form-input-info'
              classNameError='input-error-info'
              disabled={!isEditing}
            />
          </div>
          <div className='font-bold align-self-center col-3'>{`${text.region}:`}</div>
          <div className='col-9'>{handleCityType()}</div>
        </div>
      </div>

      <div className='profile-mobile'>
        <div className='grid'>
          <div className='col-12 grid'>
            <img
              className='profile-photo col-4'
              src={photo}
              alt='user avatar'
            />
            <div className='col p-3 flex flex-column gap-3'>
              {handleNameType()}
              { common.accountType === AccountType.individualUser &&
                <InputTest
                  name='dateOfBirth'
                  validations={validations.mask}
                  classNameInput='profile-form-input-info'
                  classNameError='input-error-info'
                  disabled={!isEditing}
                  mask={'99.99.9999'}
                  label={`${text.date}:`}
                  classNameLabel='font-bold p-0'
                />
              }
              <InputTest
                name='phoneNumber'
                validations={validations.mask}
                classNameInput='profile-form-input-info'
                classNameError='input-error-info'
                disabled={!isEditing}
                mask={'+7(999)999-99-99'}
                label={`${text.phoneNumber}:`}
                classNameLabel='font-bold p-0'
              />
            </div>
          </div>
          <div className='col-12 font-bold'>{`${text.email}:`}</div>
          <div className='flex gap-1 col-12 align-items-center'>
            <InputTest
              name='email'
              validations={validations.email}
              classNameInput='profile-form-input-info'
              classNameError='input-error-info'
              // disabled={!isEditing}
              disabled={true}
            />
            { common.emailConfirmationStatus !== EmailType.CONFIRMED &&
              <>
                <EmailConfirmIcon
                  className='confirm-icon cursor-pointer select-none'
                  data-pr-tooltip={text.emailConfirm}
                  data-pr-position='bottom'
                  onClick={handleConfirmEmail}
                />
                <Tooltip className='input-tooltip' target='.confirm-icon' />
              </>
            }
          </div>
          <div className='font-semibold align-self-center col-12'>{`${text.region}:`}</div>
          <div className='col-12'>{handleCityType()}</div>
        </div>
      </div>
    </div>
  );
};

export default ProfileInfo;
