import { createAsyncThunk } from '@reduxjs/toolkit';

import { commercialApi } from 'services/api/commercialApi';
import {
  COMMERCIAL_GET_CATEGORIES,
  COMMERCIAL_GET_TEMPLATE,
  COMMERCIAL_CREATE_OFFER,
  COMMERCIAL_GET_OFFERS,
  COMMERCIAL_GET_OFFER,
  COMMERCIAL_UPDATE_OFFER,
  COMMERCIAL_GET_USER_OFFER,
  COMMERCIAL_CREATE_CATEGORY,
  COMMERCIAL_DELETE_CATEGORY,
  COMMERCIAL_FETCH_NOMENCLATURE,
  COMMERCIAL_ADD_NOMENCLATURE,
  COMMERCIAL_ALL_NOMENCLATURE_COLUMNS
} from './types';

export const getCategory = createAsyncThunk(
  COMMERCIAL_GET_CATEGORIES,
  async () => {
    const response = await commercialApi.getCategory();
    return response;
  }
);

export const createCategory = createAsyncThunk(
  COMMERCIAL_CREATE_CATEGORY,
  async (data: any) => {
    const response = await commercialApi.createCategory(data);
    return response;
  }
);

export const deleteCategory = createAsyncThunk(
  COMMERCIAL_DELETE_CATEGORY,
  async (categoryId: string) => {
    const response = await commercialApi.deleteCategory(categoryId);
    return response;
  }
);

export const getTemplate = createAsyncThunk(
  COMMERCIAL_GET_TEMPLATE,
  async (categoryId: string) => {
    const { data } = await commercialApi.getTemplate(categoryId);
    return { data: data, categoryId };
  }
);

export const createOffer = createAsyncThunk(
  COMMERCIAL_CREATE_OFFER,
  async (data: Object) => {
    const response = await commercialApi.createOffer({request: data});
    return response
  }
);

export const getOffers = createAsyncThunk(
  COMMERCIAL_GET_OFFERS,
  async (data: Object) => {
    const response = await commercialApi.getOffers(data);
    return response
  }
);

export const getOffer = createAsyncThunk(
  COMMERCIAL_GET_OFFER,
  async () => {
    const response = await commercialApi.getOffer();
    return response
  }
);

export const updateOffer = createAsyncThunk(
  COMMERCIAL_UPDATE_OFFER,
  async (data) => {
    const response = await commercialApi.updateOffer({request : data });
    return response
  }
);

export const getUserOffer = createAsyncThunk(
  COMMERCIAL_GET_USER_OFFER,
  async ({ categoryId, partnerId }) => {
    const response = await commercialApi.getUserOffer(categoryId, partnerId);
    return response
  }
);

export const fetchNomenclature = createAsyncThunk(
  COMMERCIAL_FETCH_NOMENCLATURE,
  async (categoryIds: any) => {
    const response = await commercialApi.fetchNomenclature(categoryIds);
    return response
  }
);


export const addNomenclature = createAsyncThunk(
  COMMERCIAL_ADD_NOMENCLATURE,
  async (data: any) => {
    const response = await commercialApi.addNomenclature(data);
    return response
  }
);

export const getAllColumns = createAsyncThunk(
  COMMERCIAL_ALL_NOMENCLATURE_COLUMNS,
  async () => {
    const response = await commercialApi.getAllColumns();
    return response
  }
);
