import { BackIcon } from 'assets/icons';
import { useNavigate } from 'react-router';

const BackButton: React.FC = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(-1);
  };

  return (
    <button className='button-back' onClick={handleClick}>
      <BackIcon />
      Назад
    </button>
  );
};

export default BackButton;
