export const checkNullProperties = (obj:any) => {
  let isNull = false;

  for (var key in obj) {
      if (obj[key] === null || obj[key] === '') {
        isNull = true;
        break;
      }
  }
  return isNull;
}