import { API_ROUTES } from 'config';
import { request } from './api';

export const projectsApi = {
  getList: (params) => request.get<void>(API_ROUTES.projects.getList, params),
  getById: (projectId: string) => request.get<void>(API_ROUTES.projects.getById.replace('{projectId}', projectId)),
  getByIdWithOptions: (projectId: string) => request.get<void>(API_ROUTES.projects.getByIdWithOptions.replace('{projectId}', projectId)),
  getProjectTree: (data: any) => request.post<void>(API_ROUTES.projects.getTree, data),
  getImages: (id:string) => request.get<void>(API_ROUTES.projects.getImages.replace('{imageId}', id)),
  getVillageHouse: (name: number) => request.get<void>(API_ROUTES.projects.getVillageHouse.replace('{name}', name))
};
