import React, { useState, useEffect, FC } from 'react';
import {
  NavLink,
  useNavigate,
  useLocation
} from 'react-router-dom';
import { Button } from 'primereact/button';

import staticData from 'common/static.json';

import { useAppDispatch, useAppSelector } from 'store';
import { toogleUserRight } from 'store/auth/reducer';
import { guestToggle } from 'store/ui/reducer';

import { useAuth } from 'hooks/useAuth';

import { togglePro } from 'store/ui/reducer';

import { Toogler } from 'components/common/Toogler/Toogler';
import { Roles } from 'store/auth/types';

import { routesPath } from 'routes';

import {
  LogoIcon,
  NotificationIcon,
  MessageIcon,
  MapPickerIcon,
  ProfileIcon,
  CartIcon,
} from 'assets/icons';

export const MainHeader: FC = () => {
  const dispatch = useAppDispatch();
  const { role } = useAppSelector(state => state.auth);
  const { common } = useAppSelector(state => state.profile);
  const { isGuest, isProToogler } = useAppSelector(state => state.ui);
  const { count } = useAppSelector(state => state.carts);
  const [isPartner, setPartnerState] = useState(false);
  const [currentRoleHeader, setCurrentRoleHeader] = useState('guest');
  const navigate = useNavigate();
  useEffect(() => {
    if (common.roles === 'PARTNER') {
      setPartnerState(true);
    } else {
      setPartnerState(false);
    }
    setCurrentRoleHeader(getRoleHeader());
  }, [common, role]);

  useEffect(() => {
    setCurrentRoleHeader(getRoleHeader());
  }, [isProToogler]);

  const getRoleHeader = () => {
    if (common.roles === 'PARTNER') {
      if (isProToogler) {
        return 'customer'
      }

      return 'partner'
    }

    return role.toLowerCase();
  }

  return (
    <div className='main-header'>
      <div className='main-header--wrapper'>
        <div className='main-header-level-top flex justify-content-between flex-wrap'>
          <ul className='main-header-level-top--links flex align-items-center justify-content-center'>
            <li><a href='https://kalitka.moscow/partneram' target='_blank'><span className='font-light'>{ staticData.header.common.partnerLink }</span></a></li>
            <li><a href='https://kalitka.moscow/about' target='_blank'><span className='font-light'>{ staticData.header.common.aboutLink }</span></a></li>
            {/* <li className='main-header-level-top--links-city flex align-items-center'>
              <MapPickerIcon className='main-header-icon main-header-icon--mappicker flex align-items-center' />
              <span className='flex align-items-center'>{ staticData.header.common.city }</span> 
            </li> */}
          </ul>
          { true &&
            <ul className='main-header-level-top--user flex align-items-center flex-wrap'>
              { isPartner && 
                <li className='main-header--toggler flex align-items-center justify-content-center'>
                  <Toogler
                    isOn={ isProToogler }
                    //disabled={!isGuest}
                    handleToggle={ () => { dispatch(togglePro()) } }
                  />
                </li>
              }
              {/* <li><NotificationIcon className='main-header-icon main-header-icon--notification flex align-items-center justify-content-center' /></li> */}
              <li><MessageIcon onClick={() => { window.location.replace('https://t.me/kalitka_support') }} className='main-header-icon main-header-icon--message flex align-items-center justify-content-center' /></li>
              <NavLink
                  to={ routesPath.profile }
              >
                <li><ProfileIcon className='main-header-icon main-header-icon--profile flex align-items-center justify-content-center' /></li>
              </NavLink>
            </ul>
          }
        </div>
        <div className='main-header-level-bottom flex justify-content-between flex-wrap'>
          <div className='flex align-items-center justify-content-center main-header-level-bottom--wrap'>
            <div className='flex align-items-center justify-content-center justify-content-between main-header-level-bottom--info'>
              <NavLink
                to={ 'https://kalitka.moscow' }
              >
                <LogoIcon className='main-header-icon main-header-icon--logo flex align-items-center justify-content-center' />
              </NavLink>
              <div className='main-header--mobile-button'>
                <NavLink
                  to={ staticData.header[currentRoleHeader]?.button.route }
                >
                  <Button
                    className='main-header--button flex align-items-center justify-content-center'
                    label={ staticData.header[currentRoleHeader]?.button.label }
                  />
                </NavLink>
              </div>
              { true &&
                <ul className='main-header-level-bottom--user flex align-items-center justify-content-center'>
                  <li><NotificationIcon className='main-header-icon main-header-icon--notification' /></li>
                  <li><MessageIcon className='main-header-icon main-header-icon--message' /></li>
                  <li><ProfileIcon className='main-header-icon main-header-icon--profile' /></li>
                  { isPartner &&
                    <li className='main-header--toggler'>
                      <Toogler
                        isOn={ isProToogler }
                        //disabled={!isGuest}
                        handleToggle={ () => { dispatch(togglePro()) } }
                      />
                    </li>
                  }
                </ul>
              }
            </div>
            <div className='flex flex-wrap align-items-center justify-content-center'>
              {/* <ul className='main-header-level-bottom--user flex align-items-center justify-content-center'>
                <li><NotificationIcon className='main-header-icon main-header-icon--notification' /></li>
                <li><MessageIcon className='main-header-icon main-header-icon--message' /></li>
                { !isGuest && <li><ProfileIcon className='main-header-icon main-header-icon--profile' /></li> }
                <li className='main-header--toggler'>
                  <Toogler
                    isOn={ isUserType }
                    disabled={!isGuest}
                    handleToggle={ toogleRight }
                  />
                </li>
              </ul> */}
            </div>
          </div>
          <ul className='flex align-items-center justify-content-center main-header-level-bottom--links'>
            {
              staticData.header[currentRoleHeader]?.routes.map((link, key) => {
                return <li key={key} className='flex align-items-center justify-content-center'>
                  { link?.additional &&
                    <span
                      className={ link?.additional ? 'font-light': '' }
                      style={ link?.additional ? { cursor: 'auto', color: '#C2C2C2'} : {} }
                    >{ link.label }</span>
                  }
                  { !link?.additional &&
                    <NavLink to={ link.route }><span>{ link.label }</span></NavLink>
                  }
                  { link?.additional && <span className='main-header-level-bottom--links_additional flex align-items-center justify-content-center'>{ link?.additional }</span> }
                </li>;
              })
            }
            { !!staticData.header[currentRoleHeader]?.cart && <li className='main-header-level-bottom--links_cart'><NavLink to={ '/cart' }><div className='main-header-level-bottom--links_cart_count flex align-items-center justify-content-center flex-wrap'>{count}</div><CartIcon/></NavLink></li> }
            <li>
              <NavLink
                to={ staticData.header[currentRoleHeader]?.button.route }
              >
                <Button
                  className='main-header--button flex align-items-center justify-content-center'
                  label={ staticData.header[currentRoleHeader]?.button.label }
                />
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};