import React, { FC, useEffect, useState } from 'react';

import { CrossIcon } from 'assets/icons';

export const ConfirmDocumentsModal: FC<{
  confirmAction: () => void,
  onClose: () => void,
}> = ({ confirmAction, onClose, confirmText }) => {
 
  return (
    <div className="categories-modal-changes__wrapper">
      <CrossIcon className='categories-modal-changes__close' onClick={ () => { onClose() }} />
      <p className="categories-modal-changes__title">Удаление файла</p>
      <p className="categories-modal-changes__text">Вы точно хотите удалить файл</p>
      <div className="categories-modal-changes__buttons categories-modal-changes-buttons">
        <button className="categories-modal-changes-buttons__button" onClick={ () => onClose() }>Отмена</button>
        <button className="categories-modal-changes-buttons__button categories-modal-changes-buttons__button--active" onClick={ () => { confirmAction() }  }>Удалить</button>
      </div>
    </div>
  )
};