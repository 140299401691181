import { useEffect } from 'react';
import staticData from 'common/static.json';
import BackButton from 'components/common/BackButton';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';

import { getReferralInfo } from 'store/referral/actions';

import { useAppDispatch } from 'store';

import { useNavigate } from 'react-router';

import { ProfilePersonal, ProfileMoneyBag, ProfileDocuments } from 'assets/icons';

import { routesPath } from 'routes';

const text = staticData.referral;

const Referral: React.FC = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getReferralInfo());
  }, []);

  return (
    <div className='p-4 w-full grid'>
      <div className='col-12 flex flex-column gap-1'>
        <BackButton />
        <span className='font-bold text-2xl'>{text.referral}</span>
      </div>
      <div className='col-12 flex flex-column gap-6'>

        {/* для юр лица с типом заказчик */}
        <div className="profile-slider">
          <div className="profile-slider__item profile-slider-item" onClick={ () => navigate(routesPath.profile)}>
            <span className="profile-slider-item__text">Личные данные</span>
            <ProfilePersonal className="profile-slider-item__img profile-slider-item__img"/>
          </div>
          <div className="profile-slider__item profile-slider-item">
            <span className="profile-slider-item__text">Реферальная программа</span>
            <ProfileMoneyBag  className="profile-slider-item__img profile-slider-item__img--active profile-slider-item__img--referal"/>
          </div>
          <div className="profile-slider__item profile-slider-item" onClick={ () => navigate(routesPath.userDocuments)}>
            <span className="profile-slider-item__text">Документы</span>
            <ProfileDocuments  className="profile-slider-item__img"/>
          </div>
        </div>
        {/* для юр лица с типом заказчик */}

        <FirstStep />
        <SecondStep />
      </div>
    </div>
  );
};

export default Referral;
