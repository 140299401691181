import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import BackButton from 'components/common/BackButton';

import { useAppSelector, useAppDispatch } from 'store';
import { getCategory, getUserOffer } from 'store/commercial/actions';
import { getPartnerInfo } from 'store/partners/actions';
import { regions } from 'store/ui/actions';

// import photo from 'assets/images/profile-avatar.png';
// import { useAppSelector, useAppDispatch } from 'store';
// import { getUserById } from 'store/users/actions';
// import { Roles, AccountType } from 'store/users/types';

import {
  PdfDocumentIcon
} from 'assets/icons';

import staticData from 'common/static.json';

const pageStaticText = {
  ...staticData.offers.list,
  ...staticData.offer
}
export const OfferPage: FC = () => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const {
    categories,
    userOffers,
    mapOffer
  } = useAppSelector(state => state.commercial);

  const { partner } = useAppSelector(state => state.partners);
  const { regionMap } = useAppSelector(state => state.ui);

  useEffect(() => {
    dispatch(regions());
  }, []);

  useEffect(() => {
    const { categoryId, partnerId } = params;
    dispatch(getUserOffer({ partnerId, categoryId }));
    dispatch(getCategory());
    //dispatch(region());
    dispatch(getPartnerInfo(categoryId));
  }, [params]);

  useEffect(() => {
    
  }, [categories, userOffers]);

  const getCategoryName = (id) => {
    let category = [];
    category = categories.find(category => category.id === id);
    return category?.name || '';
  }

  const tableDocumentIcon = (e) => {
    return <div className='offer--table-documents'><a href={e.documentLink} target='_blank'><PdfDocumentIcon /></a></div>
  }

  return (
    <div className='offer'>
      <div className='flex'>
        <BackButton />
      </div>
      { mapOffer.map((offer, key) => {
        return <div key={key}>
          <div className='offer--header flex justify-content-between flex-wrap'>
            <div className='offer--header--title'>
              <span>{ getCategoryName(offer.categoryId) }</span>
            </div>
            {/* <button>
              Скачать
            </button> */}
          </div>
          <div className='offer--fields'>
            <div className='offer--fields-item'>
              <span className='offer--fields-item_label'>{ pageStaticText.organisationName } </span>
              <span className='offer--fields-item_value'>{ partner?.organizationName }</span>
            </div>
            <div className='offer--fields-item'>
              <span className='offer--fields-item_label'>{ pageStaticText.organisationNumber } </span>
              <span className='offer--fields-item_value'>{ partner?.phoneNumber }</span>
            </div>
            <div className='offer--fields-item flex justify-content-between flex-wrap'>
              <div>
                <span className='offer--fields-item_label'>{ pageStaticText.organisationLocation } </span>
                {
                  partner?.regions?.map((region, key) => {
                    return <span key={ key } className='offer--fields-item_value'>{ regionMap[region] }</span>
                  })
                }
              </div>
            </div>
          </div>
          <div className='offer--table flex justify-content-start flex-wrap'>
              <DataTable
                value={offer.data}
                tableStyle={{ width: '100%' }}
              >
                <Column field='id' header={ pageStaticText.table.fields.number }></Column>
                <Column field='title' style={{ minWidth: '60%', width: '60%' }} header={ pageStaticText.table.fields.name }></Column>
                <Column field='documentLink' body={ (e) => { return tableDocumentIcon(e) } } header={ pageStaticText.table.fields.task }></Column>
                <Column field='minimumOrderQuantity' header={ pageStaticText.table.fields.minCount }></Column>
                <Column field='productionTime' header={ pageStaticText.table.fields.deadline }></Column>
                <Column field='unitPrice' header={ pageStaticText.table.fields.amount }></Column>
              </DataTable>
            </div>
        </div>
      })
    }
    </div>
  )
};