import React, { FC, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment';

import { routesPath } from 'routes';
import photo from 'assets/images/blank-avatar.png';
import { useAppSelector, useAppDispatch } from 'store';
import { getUserById } from 'store/users/actions';
import { getUserReferral } from 'store/referral/actions';
import { Roles, AccountType } from 'store/users/types';
import { regions } from 'store/ui/actions';

import staticData from 'common/static.json';
import BackButton from 'components/common/BackButton';
import { PartnerTypes, WorkType } from 'store/partners/types';
import { FormatTo, formatDate } from 'utils/formatDate';
const pageStaticText = staticData.users.profile;

const roleName = {
  ADMIN: 'Админ',
  PARTNER: 'Партнер',
  CUSTOMER: 'Заказчик'
}

export const UserPage: FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(state => state.users);
  const { regionMap } = useAppSelector(state => state.ui);
  const { userReferral } = useAppSelector(state => state.referral);
  const [userRole, setUserRole] = useState('');
  const [userType, setUserType] = useState('');

  const authorityGround = {
    ARTICLES_OF_ASSOCIATION: 'Устав',
    POWER_OF_ATTORNEY: 'Доверенность'
  }
  
  const { id } = params;

  useEffect(() => {
    if (id) {
      dispatch(getUserById(id));
      dispatch(getUserReferral(id));
      dispatch(regions());
    }
  }, [id]);

  useEffect(() => {
    setUserRole(user?.commonUserData?.roles as Roles);
    setUserType(user?.commonUserData?.accountType as AccountType);
  }, [user]);

  const openOrder = (id: string) => {
    if (user?.commonUserData?.roles === 'CUSTOMER') {
      navigate(`${routesPath.orderDetail.replace(':id', id )}`);
    } else if (user?.commonUserData?.roles === 'PARTNER') {
      navigate(`${routesPath.orderDetail.replace(':id',order.id )}`, { state: {
        isPartnerOrder: true,
        partnerType: user?.legalEntityUserData?.partner?.partnerType.toLowerCase()
      }});    
    }
  }


  const tableButtonLink = (referral) => {
    return <div className='user-card--table-link'>
        <button onClick={() => { openOrder(referral.id) }}>Перейти</button>
      </div>
  };

  const tableTime = (data) => {
    return moment(data.timeCreated).format('L').replaceAll('/', '.');
  };

  const tableFooter = () => {
    return <div className='user-card--table-footer'>
      <div className='user-card--table-footer-item flex justify-content-between flex-wrap'>
        <div>
          <span>{ pageStaticText.table.footer.labels.orders }</span>
          <span className='user-card--table-footer-item_amount'>{ userReferral?.totalOrdersCount }</span>
        </div>
        <div>
          <span>{ pageStaticText.table.footer.labels.amount }</span>
          <span className='user-card--table-footer-item_amount'>{ userReferral?.totalOrdersAmount } ₽ </span>
        </div>
        <div>
          <span>{ pageStaticText.table.footer.labels.bonus }</span>
          <span  className='user-card--table-footer-item_amount'>{ userReferral?.totalIncome } ₽</span>
        </div>
      </div>
    </div>
  }

  return (
    <div className='user p-4 flex flex-column gap-3'>
      <div className='flex'>
        <BackButton />
      </div>
      <div className='user-card'>
        <div className='flex justify-content-between flex-wrap'>
          <div className='user-card--info flex justify-content-start flex-wrap'>
            <div className='user-card--info_photo'>
              <img className='profile-photo' src={photo} alt='user avatar' />
            </div>

            <div className='user-card--info_fields'>
              <div className="flex justify-content-start flex-wrap">
                  { user?.commonUserData?.accountType === 'LEGAL_ENTITY_USER' && 
                    <>
                    <div className='user-card--info_fields-item'>
                      <span>Наименование организации</span>
                      <span>{ user?.legalEntityUserData?.orgName }</span>
                    </div>
                    { userRole === 'PARTNER' &&
                      <div className='user-card--info_fields-item' style={{marginLeft: '40px'}}>
                        <span>Форма организации</span>
                        <span>Юр. лицо</span>
                      </div>
                    }
                    { userRole === 'PARTNER' &&
                      <div className='user-card--info_fields-item' style={{marginLeft: '40px'}}>
                        <span>Юридический адрес</span>
                        <span>{ user?.legalEntityUserData?.legalAddress }</span>
                      </div>
                    }
                    </>
                  }
                  { user?.legalEntityUserData === null && 
                    <>
                      <div className='user-card--info_fields-item'>
                        <span>{ pageStaticText.fields.name }</span>
                        <span>{ user?.commonUserData?.name }</span>
                      </div>
                      <div className='user-card--info_fields-item' style={{marginLeft: '40px'}}>
                        <span>Дата рождения</span>
                        <span>{ formatDate(user?.individualUserData?.dateOfBirth, FormatTo.server)  }</span>
                      </div>
                      <div className='user-card--info_fields-item' style={{marginLeft: '40px'}}>
                        <span>Адрес регистрации</span>
                        <span>{ user?.individualUserData?.residenceAddress }</span>
                      </div>
                    </>
                  }
              </div>

              <div className='flex justify-content-start flex-wrap' style={{marginTop: '28px'}}>
                <div className='user-card--info_fields-item'>
                  <span>{ pageStaticText.fields.phoneNumber }</span>
                  <span>{ user?.commonUserData?.phoneNumber }</span>
                </div>

                <div className='user-card--info_fields-item' style={{marginLeft: '40px'}}>
                  <span>{ pageStaticText.fields.email }</span>
                  <span>{ user?.commonUserData?.email }</span>
                </div>

                <div className='user-card--info_fields-item' style={{marginLeft: '40px'}}>
                  <span> Область </span>
                  { user?.legalEntityUserData?.regions?.map((region, key) => {
                      return <span key={key}>{ regionMap[region] } </span>
                    })
                  }
                  <span>{ regionMap[user?.individualUserData?.region] }</span>
                </div>

                { user?.legalEntityUserData !== null &&
                  <div className='user-card--info_fields-item' style={{marginLeft: '40px'}}>
                    <span>{ pageStaticText.fields.ogrn }</span>
                    <span>{ user?.legalEntityUserData?.ogrn }</span>
                  </div>
                }

                { user?.individualUserData !== null &&
                  <div className='user-card--info_fields-item' style={{marginLeft: '40px'}}>
                    <span>{ pageStaticText.fields.passportNumber }</span>
                    <span>{user?.individualUserData?.passportSeries} {user?.individualUserData?.passportNumber}</span>
                  </div>
                }
              </div>

              <div className='flex justify-content-start flex-wrap' style={{marginTop: '28px'}}>
                { user?.individualUserData !== null &&
                  <>
                    {/* <div className='user-card--info_fields-item'>
                      <span>{ pageStaticText.fields.passportNumber }</span>
                      <span>{user?.individualUserData?.passportSeries} {user?.individualUserData?.passportNumber}</span>
                    </div> */}
                    <div className='user-card--info_fields-item'>
                      <span>{ pageStaticText.fields.passportIssuedBy }</span>
                      <span>{ user?.individualUserData?.passportIssuedBy }</span>
                    </div>
                    <div className='user-card--info_fields-item' style={{marginLeft: '40px'}}>
                      <span>{ pageStaticText.fields.passportIssueDate }</span>
                      <span>{ moment(user?.individualUserData?.passportIssueDate).format('L').replaceAll('/', '.') }</span>
                    </div>
                    <div className='user-card--info_fields-item' style={{marginLeft: '40px'}}>
                      <span>ИНН</span>
                      <span>{ user?.individualUserData?.inn }</span>
                    </div>
                    <div className='user-card--info_fields-item' style={{marginLeft: '40px'}}>
                      <span>СНИЛС</span>
                      <span>{ user?.individualUserData?.iian }</span>
                    </div>
                  </>
                }

              { user?.legalEntityUserData !== null &&
                <>
                  {/* <div className='user-card--info_fields-item'>
                    <span>{ pageStaticText.fields.ogrn }</span>
                    <span>{ user?.legalEntityUserData?.ogrn }</span>
                  </div> */}
                  <div className='user-card--info_fields-item'>
                    <span>{ pageStaticText.fields.inn }</span>
                    <span>{ user?.legalEntityUserData?.inn }</span>
                  </div>
                  <div className='user-card--info_fields-item' style={{marginLeft: '40px'}}>
                    <span>{ pageStaticText.fields.kpp }</span>
                    <span>{ user?.legalEntityUserData?.kpp }</span>
                  </div>
                  <div className='user-card--info_fields-item' style={{marginLeft: '40px'}}>
                    <span>ФИО ответственного лица</span>
                    <span>{ user?.legalEntityUserData?.responsiblePersonName }</span>
                  </div>
                  <div className='user-card--info_fields-item' style={{marginLeft: '40px'}}>
                    <span>Основание полномочий</span>
                    <span>{ authorityGround[user?.legalEntityUserData?.authorityGround] }</span>
                  </div>
                </>
              }
              </div>
            </div>

          </div>
          <div className='user-card--orders'>
            {/* <button>
              Заказы
            </button> */}
          </div>
        </div>

        <div className='user-card--role flex justify-content-start align-items-end flex-wrap'>
          <button>
            { pageStaticText.fields.role } { user?.commonUserData?.roles === 'CUSTOMER' ? roleName[user?.commonUserData?.roles]: PartnerTypes[user?.legalEntityUserData?.partner?.partnerType] }
          </button>

          { user?.legalEntityUserData &&
            <div className='user-card--info_fields flex justify-content-start flex-wrap'>
              <div className='user-card--info_fields-item'>
                <span>{ pageStaticText.fields.bankName }</span>
                <span>{ user?.legalEntityUserData?.bankName }</span>
              </div>
              <div className='user-card--info_fields-item' style={{marginLeft: '40px'}}>
                <span>{ pageStaticText.fields.bic }</span>
                <span>{ user?.legalEntityUserData?.bic }</span>
              </div>
              <div className='user-card--info_fields-item' style={{marginLeft: '40px'}}>
                <span>{ pageStaticText.fields.bankAccount }</span>
                <span>{ user?.legalEntityUserData?.bankAccount }</span>
              </div>
              <div className='user-card--info_fields-item' style={{marginLeft: '40px'}}>
                <span>{ pageStaticText.fields.correspondentAccount }</span>
                <span>{ user?.legalEntityUserData?.correspondentAccount }</span>
              </div>
            </div>
          }
          { user?.individualUserData !== null &&
            <>
              <div className='user-card--info_fields flex justify-content-start flex-wrap'>
                <div className='user-card--info_fields-item'>
                  <span>{ pageStaticText.fields.bankName }</span>
                  <span>{ user?.legalEntityUserData?.bankName }</span>
                </div>
                <div className='user-card--info_fields-item' style={{marginLeft: '40px'}}>
                  <span>{ pageStaticText.fields.bic }</span>
                  <span>{ user?.legalEntityUserData?.bic }</span>
                </div>
                <div className='user-card--info_fields-item' style={{marginLeft: '40px'}}>
                  <span>{ pageStaticText.fields.bankAccount }</span>
                  <span>{ user?.legalEntityUserData?.bankAccount }</span>
                </div>
                <div className='user-card--info_fields-item' style={{marginLeft: '40px'}}>
                  <span>{ pageStaticText.fields.correspondentAccount }</span>
                  <span>{ user?.legalEntityUserData?.correspondentAccount }</span>
                </div>
              </div>
            </>
          }
        </div>

        <div className='user-card--referal flex justify-content-start flex-wrap'>
          <label>{ pageStaticText.fields.referal } <input disabled className='user-card--referal_link' type='text' value={ user?.commonUserData?.referralLink } /></label>
        </div>

        <div className='user-card--table flex justify-content-start flex-wrap'>
          <DataTable
            value={userReferral?.referralInfoWithOrderData}
            footer={tableFooter}
            tableStyle={{ width: '100%' }}
          >
            <Column field='number' header={ pageStaticText.table.fields.id }></Column>
            <Column field='date' body={ (e) => { return tableTime(e) } } header={ pageStaticText.table.fields.date }></Column>
            <Column field='amount' header={ pageStaticText.table.fields.amount }></Column>
            <Column field='income' header={ pageStaticText.table.fields.bonus }></Column>
            <Column header='' body={(e) => { return tableButtonLink(e) }}></Column>
          </DataTable>
        </div>
      </div>
    </div>
  )
};