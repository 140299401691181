import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { VillageOnGoing } from './VillageOnGoing';

import { BackIcon } from 'assets/icons';
import { VillageMyself } from 'assets/icons';
import { VillageRenta } from 'assets/icons';
import { VillageUp } from 'assets/icons';

import { navigationList } from './types';
import { Slider } from 'components/common/Slider';

import { fetchVillageHouse } from 'store/projects/actions';
import { useAppDispatch } from 'store';

export const VilagePage: FC = () => {
  const dispatch = useAppDispatch();
  const params = useParams();

  const { name } = params;

  useEffect(() => {
    dispatch(fetchVillageHouse(name));
  }, [name]);

  const back = () => {
    window.location.replace('https://kalitka.moscow/poselki');
  }
  return (
    <div className='village'>
      <div className="village__header">
        <button className='village__back village-back'>
          <div className="village-back__icon"><BackIcon /></div>
          <span className="village-back__text" onClick={() => { back() }}>Назад</span>
        </button>

        <div className="village__navigation village-navigation">
          <Slider
            sliderAction={ (value) => { changeType(value) } }
            slides={ navigationList }
            customClass={ 'project' }
          />
        </div>

      </div>

      <div className="village__wrapper">

        <VillageOnGoing />
        
        

        {/* <div className="village__program village-program">
          <h2 className="village-program__title">Программы покупки</h2>
          <p className="village-program__text">Выберите программу покупки Вашего будущего дома в соответствии с Вашими целями — Вы можете приобрести дом для себя и своей семьи или построить его в качестве инвестиции: в стройку для дальнейшей продажи или для сдачи в аренду.</p>
          <div className="village-program__cards village-program-cards">
            <div className="village-program-cards__item village-program-card">
              <p className="village-program-card__title">Для себя</p>
              <p className="village-program-card__text">Приобретение дома для&nbsp;Вашей семьи</p>
              <div className="village-program-card__img"><VillageMyself /></div>
            </div>
            <div className="village-program-cards__item village-program-card">
              <p className="village-program-card__title">На продажу</p>
              <p className="village-program-card__text">Приобретение дома в&nbsp;стройку для продажи</p>
              <div className="village-program-card__img"><VillageUp /></div>
            </div>
            <div className="village-program-cards__item village-program-card">
              <p className="village-program-card__title">Под аренду</p>
              <p className="village-program-card__text">Приобретение дома для&nbsp;сдачи в аренду</p>
              <div className="village-program-card__img"><VillageRenta /></div>
            </div>
          </div>
        </div>


        <div className="village__calculator village-calculator">
          <h2 className="village-calculator__title">Рассчитайте свою доходность*</h2>
          
          <div className="village-calculator-result__switch village-calculator-result__switch--mobile">
            <div className="village-calculator-result__button village-calculator-result__button--active">Собственные&nbsp;средства</div>
            <div className="village-calculator-result__button">Ипотека</div>
          </div>

          <div className="village-calculator__triggers village-calculator-triggers">
            <div className="village-calculator-triggers__item village-calculator-triggers__item--active">Для себя</div>
            <div className="village-calculator-triggers__item">На продажу</div>
            <div className="village-calculator-triggers__item">Под аренду</div>
          </div>

          <div className="village-calculator__blocks village-calculator-blocks">
            <div className="village-calculator-blocks__item village-calculator-blocks__price">
              <div className="village-calculator-blocks-input">
                <span className="village-calculator-blocks-input__lable">Стоимость недвижимости</span>
                <input type="text" className="village-calculator-blocks-input__item input--new" placeholder='5 000 000 ₽'/>
              </div>
              <div className="village-calculator-blocks-input village-calculator-blocks-input--disabled">
                <span className="village-calculator-blocks-input__lable">Стоимость продажи дома</span>
                <input type="text" className="village-calculator-blocks-input__item input--new" placeholder='—'/>
              </div>
              <div className="village-calculator-blocks-input village-calculator-blocks-input--disabled">
                <span className="village-calculator-blocks-input__lable">Комиссия за продажу</span>
                <input type="text" className="village-calculator-blocks-input__item input--new " placeholder='—'/>
              </div>
              <div className="village-calculator-blocks-input village-calculator-blocks-input--disabled">
                <span className="village-calculator-blocks-input__lable">Время перепродажи дома</span>
                <input type="text" className="village-calculator-blocks-input__item input--new" placeholder='—'/>
              </div>
              <div className="village-calculator-blocks-input village-calculator-blocks-input--disabled">
                <span className="village-calculator-blocks-input__lable">Время сдачи в аренду</span>
                <input type="text" className="village-calculator-blocks-input__item input--new" placeholder='—'/>
              </div>
            </div>
            <div className="village-calculator-blocks__item village-calculator-blocks__mortgage">
              <div className="village-calculator-blocks-input village-calculator-blocks-input--disabled">
                <span className="village-calculator-blocks-input__lable">Ипотечные программы</span>
                <input type="text" className="village-calculator-blocks-input__item input--new" placeholder='—'/>
              </div>
              <div className="village-calculator-blocks-input village-calculator-blocks-input--disabled">
                <span className="village-calculator-blocks-input__lable">Процентная ставка</span>
                <input type="text" className="village-calculator-blocks-input__item input--new" placeholder='—'/>
              </div>
              <div className="village-calculator-blocks-input village-calculator-blocks-input--disabled">
                <span className="village-calculator-blocks-input__lable">Ежемесячный платеж</span>
                <input type="text" className="village-calculator-blocks-input__item input--new " placeholder='—'/>
              </div>
              <div className="village-calculator-blocks-input village-calculator-blocks-input--disabled">
                <span className="village-calculator-blocks-input__lable">Первоначальный взнос</span>
                <input type="text" className="village-calculator-blocks-input__item input--new" placeholder='—'/>
              </div>
              <div className="village-calculator-blocks-input village-calculator-blocks-input--disabled">
                <span className="village-calculator-blocks-input__lable">Срок кредита</span>
                <input type="text" className="village-calculator-blocks-input__item input--new" placeholder='—'/>
              </div>
            </div>


            <div className="village-calculator-blocks__result village-calculator-result">
              <div className="village-calculator-result__switch">
                <div className="village-calculator-result__button village-calculator-result__button--active">Собственные&nbsp;средства</div>
                <div className="village-calculator-result__button">Ипотека</div>
              </div>
              <div className="village-calculator-result__card village-calculator-result__card--disabled village-calculator-result-card">
                <div className="village-calculator-result-card__info">
                  <span className="village-calculator-result-card__lable">Сумма вложений</span>
                  <span className="village-calculator-result-card__numbers">5 000 000 ₽</span>
                </div>
                <div className="village-calculator-result-card__info">
                  <span className="village-calculator-result-card__lable">Ежемесячный доход с аренды</span>
                  <span className="village-calculator-result-card__numbers">—</span>
                </div>
                <div className="village-calculator-result-card__info">
                  <span className="village-calculator-result-card__lable">Прибыль с проекта</span>
                  <span className="village-calculator-result-card__numbers">—</span>
                </div>
                <div className="village-calculator-result-card__info">
                  <span className="village-calculator-result-card__lable">Доходность с проекта</span>
                  <span className="village-calculator-result-card__numbers">—</span>
                </div>
              </div>
            </div>
          </div>
          <div className="village-calculator__annotation">* Все расчеты приведены исключительно в аналитических целях, не являются индивидуальной инвестиционной рекомендацией.</div>
        </div> */}
      </div>
    </div>
  );
};