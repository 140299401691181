import { createSlice } from '@reduxjs/toolkit';
import {
  registration,
  login,
  restorePassword,
  refreshToken,
  getUser
} from './actions';
import {
  Roles,
  IAuthState,
 } from './types'

const initialAuth:IAuthState = {
  role: Roles.GUEST,
  id: null,
  token: '',
  refreshToken: '',
  emailToken: null
}
const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuth,
  reducers: {
    toogleUserRight(state, { payload }) {
      state.role = payload;
    },
    setToken(state, { payload }) {
      state.token = payload.token;
    },
    dropState(state) {
      state = initialAuth;
    },
    setEmailToken(state, { payload }) {
      state.emailToken = payload;
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(registration.fulfilled, (state,  { payload }) => {
        state.token = payload.accessToken;
        state.refreshToken = payload.refreshToken;
        state.id = payload?.userResponse?.commonUserData.id;
        state.role = payload?.userResponse?.commonUserData?.roles;
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        state.token = payload.accessToken;
        state.refreshToken = payload.refreshToken;
        state.id = payload.userId;
      })
      .addCase(getUser.fulfilled, (state, { payload }) => {
        state.role = payload?.commonUserData?.roles;
        state.id = payload?.commonUserData?.id;
      })
      .addCase(refreshToken.fulfilled, (state, { payload }) => {
        state.token = payload?.accessToken;
        state.refreshToken = payload?.refreshToken;
      })
  },
});

export const {
  toogleUserRight,
  setToken,
  dropState,
  setEmailToken
} = authSlice.actions;
export default authSlice.reducer;