
import { FC, useState, useEffect } from 'react';

import staticData from 'common/static.json';

const staticText = staticData.buttons.loadMore;

export const LoadMoreButton: FC<{
  loadAction: Function,
  start: Number,
  step: Number,
  size: Number
  }> = ({ loadAction, start, step, size }) => {
  const [currentPosition, setPosition] = useState(start);

  const [isShow, showButton] = useState(!!size);

  const loadMore = () => {
    if ((currentPosition + 1) * step !== size) {
      setPosition(currentPosition + 1);
    }
  };

  useEffect(() => {
    loadAction({
      pageNum: currentPosition,
      pageSize: step,
    });

    if ((currentPosition + 1) * step === size) {
      showButton(false);
    }

  }, [currentPosition]);

  useEffect(() => {
    showButton(!!((currentPosition + 1) * step < size));
  }, [size]);

  useEffect(() => {
    showButton(!!((currentPosition + 1) * step < size));
  }, [currentPosition]);

  return (
    <div className='button--load-more flex justify-content-end flex-wrap'>
      { isShow &&
        <button onClick={loadMore}>
          { staticText.label }
        </button>
      }
    </div>
  );
};
