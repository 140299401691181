import { createSlice } from '@reduxjs/toolkit';

import {
  IDocumentsState,
  DocumentsCategory,
  DocumentsType,
  IDocumentData
} from './types';

import {
  getDocuments, 
  saveDocuments,
  getDocumentFile,
  removeDocumentTemplate,
  getFilledDocuments,
  getFilledDocumentId,
  getOrdersDocumentsById,
  getOrdersTemplateDocuments,
  addTemplatesForOrder,
  changeVisibilityForCustomer,
  getOrdersTemplateDocumentsForPartners
} from './actions';


//const tempSize = tempDocuments.length;

const initialDocuments:IDocumentsState = {
  documents: {
    data: [],
    totalPages: 1,
    totalElements: 0,
    pageSize: 1, 
    pageNum: 1
  },
  customerDocuments: [],
  currentDocument: {}
}

const documentsSlice = createSlice({
  name: 'documents',
  initialState: initialDocuments,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(getDocuments.fulfilled, (state,  { payload }) => {
      const { data, pageNum, totalElements } = payload;
      if (pageNum === 0) {
        state.documents.data = data;
      } else {
        state.documents.data = state.documents.data.concat(data);
      }

      state.documents.totalElements = totalElements;
    })
    .addCase(saveDocuments.fulfilled, (state,  { payload }) => {
      
    })
    .addCase(getDocumentFile.fulfilled, (state,  { payload }) => {
      state.currentDocument = payload;
    })
    .addCase(getFilledDocumentId.fulfilled, (state,  { payload }) => {
      state.currentDocument = payload;
    })
    .addCase(removeDocumentTemplate.fulfilled, (state,  { payload }) => {

    })
    .addCase(getFilledDocuments.fulfilled, (state,  { payload }) => {
      const { data, pageNum, totalElements } = payload;
      if (pageNum === 0) {
        state.documents.data = data;
      } else {
        state.documents.data = state.documents.data.concat(data);
      }

      state.documents.totalElements = totalElements;
    })
    .addCase(getOrdersDocumentsById.fulfilled, (state,  { payload }) => {
      state.currentDocument = payload;
    })
    .addCase(getOrdersTemplateDocuments.fulfilled, (state,  { payload }) => {
      state.customerDocuments = payload?.data;
    })
    .addCase(addTemplatesForOrder.fulfilled, (state,  { payload }) => {

    })
    .addCase(getOrdersTemplateDocumentsForPartners.fulfilled, (state,  { payload }) => {
      const { data, pageNum, totalElements } = payload;
      if (pageNum === 0) {
        state.documents.data = data;
      } else {
        state.documents.data = state.documents.data.concat(data);
      }

      state.documents.totalElements = totalElements;
    })
  },
});

export const {} = documentsSlice.actions;

export default documentsSlice.reducer;