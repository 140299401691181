import { createAsyncThunk } from '@reduxjs/toolkit';

import { projectsApi } from 'services/api/projectsApi';
import {
  PROJECTS_WITH_OPTIONS,
  PROJECTS_FETCH_MARKET_LIST,
  PROJECTS_GET_TREE,
  PROJECTS_IMAGES,
  PROJECT_BY_ID,
  PROJECTS_FETCH_VILLAGE_HOUSE
} from './types';

export const fetchProjects = createAsyncThunk(PROJECTS_FETCH_MARKET_LIST, async (params) => {
  const response = await projectsApi.getList(params);
  return response;
});

export const fetchProjectTree = createAsyncThunk(
  PROJECTS_GET_TREE,
  async (params) => {
    const response = await projectsApi.getProjectTree(params);
    return response;
  }
);

export const fetchProjectById = createAsyncThunk(
  PROJECT_BY_ID,
  async (id) => {
    const response = await projectsApi.getById(id);
    return response;
  }
);

export const fetchProjectsWithOptions = createAsyncThunk(
  PROJECTS_WITH_OPTIONS,
  async (id: string) => {
    const response = await projectsApi.getByIdWithOptions(id);
    return response;
  }
);

export const fetchProjectImages = createAsyncThunk(
  PROJECTS_IMAGES,
  async (image: any) => {
    const response = await projectsApi.getImages(image.fileId);
    return { payload: response, type: image.type };
  }
);

export const fetchVillageHouse = createAsyncThunk(
  PROJECTS_FETCH_VILLAGE_HOUSE,
  async (name: number) => {
    const response = await projectsApi.getVillageHouse(name);
    return response
  }
);
