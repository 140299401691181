import { FC, useState } from 'react';
import { useController } from 'react-hook-form';
import InputTest from 'components/common/InputTest';
import staticData from 'common/static.json';
import { useAppSelector } from 'store';
import { validations } from './validations';

import { Arrow } from 'assets/icons';
import { Check } from 'assets/icons';

import { DropDownWithCheckBox } from 'components/common/DropDownWithCheckBox';

const placeholder = staticData.partnersRequest.placeholders;
const text = staticData.partnersRequest;

const PartnerData: FC = () => {
  const { legalUser } = useAppSelector(state => state.profile);

  const [selectAuthorityGround, setSelectAuthorityGround] = useState([]);

  const [isShow, setIsShow] = useState(false);

  setTimeout(() => setIsShow(true), 700);

  const { field } = useController({ name: 'authorityGround' });

  const authorityGround = [{
    name: 'Устав',
    value: 'ARTICLES_OF_ASSOCIATION'
  }, {
    name: 'Доверенность',
    value: 'POWER_OF_ATTORNEY'
  }]

  return (
    <div className='flex flex-column gap-3'>
      <span className='text-center font-medium text-2xl'>
        {text.application}
      </span>
      <span className='text-center'>{text.orgDetails}</span>
      { isShow && 
        <div className='flex flex-column gap-3 justify-content-center'>
          <InputTest
            name='orgName'
            validations={validations.orgName}
            classNameInput='auth-form-input'
            classNameValid='input-valid-auth'
            classNameError='input-error-auth'
            classNameLabel='flex flex-column gap-2'
            placeholder={placeholder.orgName}
            label={text.orgName}
            disabled={legalUser.orgName ? true : false}
          />
          <InputTest
            name='ogrn'
            validations={validations.ogrn}
            classNameInput='auth-form-input'
            classNameValid='input-valid-auth'
            classNameError='input-error-auth'
            classNameLabel='flex flex-column gap-2'
            placeholder={placeholder.ogrn}
            label={text.ogrn}
            disabled={legalUser.ogrn ? true : false}
          />
          <div className='flex gap-3 justify-content-between align-items-end'>
            <InputTest
              name='inn'
              validations={validations.mask}
              mask='9999999999'
              classNameInput='auth-form-input profile-form-input'
              classNameValid='input-valid-auth'
              classNameError='input-error-auth'
              classNameLabel='flex flex-column gap-2'
              placeholder={placeholder.inn}
              label={text.inn}
              disabled={legalUser.inn ? true : false}
            />
            <InputTest
              name='kpp'
              validations={validations.kpp}
              classNameInput='auth-form-input profile-form-input'
              classNameValid='input-valid-auth'
              classNameError='input-error-auth'
              classNameLabel='flex flex-column gap-2'
              placeholder={placeholder.kpp}
              label={text.kpp}
              disabled={legalUser.kpp ? true : false}
            />
          </div>
          <InputTest
            name='phoneNumber'
            mask={'+7(999)999-99-99'}
            validations={validations.mask}
            classNameInput='auth-form-input profile-form-input'
            classNameValid='input-valid-auth'
            classNameError='input-error-auth'
            classNameLabel='flex flex-column gap-2'
            placeholder={placeholder.phoneNumber}
            label={text.phoneNumber}
          />

          <InputTest
            name='responsiblePersonName'
            validations={validations.orgName}
            classNameInput='auth-form-input profile-form-input'
            classNameValid='input-valid-auth'
            classNameError='input-error-auth'
            classNameLabel='flex flex-column gap-2'
            placeholder={'Иванов Иван Иванович'}
            label={'ФИО ответственного лица'}
          />

          <p>Основание полномочий</p>
          <DropDownWithCheckBox
            {...field}
            initialValue={ authorityGround?.filter((item) => {
              if (item?.value === legalUser?.authorityGround) {
                return item
              }
            }) }
            setSelectedValues={ values => { field.onChange(values.map(values => values.value)); setSelectAuthorityGround(values) } }
            options={ authorityGround }
          />
        </div>
      }
    </div>
  );
};

export default PartnerData;
