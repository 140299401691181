import { ROUTES } from 'common/constants';

export const routesPath = {
  root: ROUTES.ROOT,
  login: ROUTES.LOGIN,
  registration: ROUTES.REGISTRATION,
  passwordRestore: ROUTES.PASSWORD_RESTORE,
  emailConfirmation: ROUTES.EMAIL_CONFIRMATION,
  ordersList: ROUTES.ORDERS_LIST,
  orderDetail: ROUTES.ORDER_DETAIL,
  profile: ROUTES.PROFILE,
  cart: ROUTES.CART,
  cartConfirmation: ROUTES.CART_CONFIRMATION,
  partnerConfirmation: ROUTES.PARTNER_CONFIRMATION,
  partners: ROUTES.PARTNERS,
  referral: ROUTES.REFERRAL,
  completeData: ROUTES.COMPLETE_DATA,
  partnerIssue: ROUTES.PARTNER_ISSUE,
  users: ROUTES.USERS,
  user: ROUTES.USER,
  offers: ROUTES.OFFERS,
  offer: ROUTES.OFFER,
  nomenclature: ROUTES.NOMENCLATURE,
  notFound: ROUTES.NOT_FOUND,
  documents: ROUTES.DOCUMENTS,
  markets: ROUTES.MARKETS,
  market: ROUTES.MARKET,
  village: ROUTES.VILLAGE,
  terms: ROUTES.TERMS,
  userDocuments: ROUTES.DOCUMENTS_USER
};
