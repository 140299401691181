import { FC, useEffect } from 'react';

import { useAppSelector, useAppDispatch } from 'store';

import { clearCommercial } from 'store/commercial/reducer';

import { OffersList } from './OffersList';
import { OffersCategory } from './OffersCategory';

export const OffersPage: FC = () => {
  const dispatch = useAppDispatch();
  const { role } = useAppSelector(state => state.auth);

  useEffect(() => {
    dispatch(clearCommercial());
  }, []);

  return (
    <div className='offers'>
      {
        role === 'PARTNER' && <OffersCategory />
      }
      {
        role === 'ADMIN' && <OffersList />
      }
    </div>
  )
};