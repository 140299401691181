import { createAsyncThunk } from '@reduxjs/toolkit';
import { REFERRAL_GET_INFO, REFERRAL_SAVE_BANK_DETAILS, REFERRAL_USER } from './types';
import { referralApi } from 'services/api/referralApi';

export const getReferralInfo = createAsyncThunk(REFERRAL_GET_INFO, async () => {
  const response = await referralApi.getInfo();
  return response;
});

export const saveBankDetails = createAsyncThunk(
  REFERRAL_SAVE_BANK_DETAILS,
  async (data: any) => {
    const response = await referralApi.saveBankDetails(data);
    return response;
  }
);

export const getUserReferral = createAsyncThunk(
  REFERRAL_USER,
  async (id: string) => {
    const response = await referralApi.getUserReferral(id);
    return response;
  }
);
