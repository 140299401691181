import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IReferral } from './types';
import { getReferralInfo, getUserReferral } from './actions';

const initialState: IReferral = {
  info: {
    percent: 0,
    referralLink: '',
    referralsCount: 0,
    withdrawnAmount: 0,
    availableAmount: 0,
  },
  userReferral: []
};

export const referralSlice = createSlice({
  name: 'referral',
  initialState,
  reducers: {},
  extraReducers: builder =>
    builder.addCase(
      getReferralInfo.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.info = action.payload;
      }
    ).addCase(
      getUserReferral.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.userReferral = action.payload;
      }
    ),
});

export default referralSlice.reducer;
