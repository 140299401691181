import { FC, useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch} from 'store';
import { Accordion, AccordionTab } from 'primereact/accordion';
import moment from 'moment';

import { RealtorInputs } from './inputs';
import { paymentStatus } from 'store/orders/types';
import TempImageDetail from 'assets/images/temp-order-detail.png'
import TempImage from 'assets/images/temp-order.png'
import { ProjectDetail } from 'components/common/Order/ProjectDetail';

export const RealtorIssue:FC = () => {
  const dispatch = useAppDispatch();

  const { order } = useAppSelector(state => state.orders);

  // useEffect(() => {
  //   dispatch(getExecutors({ orderExecutorTypes: Object.keys(PartnerTypes) }));
  // }, []);
  
  return (
    <div className='order'>
      <div className='order-detail flex align-items-start justify-content-center'>
        <RealtorInputs />
      </div>
    </div>
  )
}
