import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { IProjectItem } from 'store/projects/types';
import { ProjectArea, ProjectFloor, ProjectFront } from 'assets/icons';

import { routesPath } from 'routes';

export const MarketsListItem: FC<{ project: IProjectItem }> = ({ project }) => {
  const navigate = useNavigate();
  return (
    <div className='projects-list-item' onClick={ () => { navigate(routesPath.market.replace(':id', project?.id)) } }>
      <div className="projects-list-item__wrapper">

        <img className='projects-list-item__img' src={ project?.fileInfo?.fileUrl } />

        <div className='projects-list-item__main projects-list-item-main'>
          <h2 className='projects-list-item-main__title'>
            { project?.model }
          </h2>
          <span className='projects-list-item-main__amount'>
            от { project?.projectSpecs?.baseCost ? (project?.projectSpecs?.baseCost?.toFixed()).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 '): '' } ₽
          </span>
        </div>

        <div className='projects-list-item__info projects-list-item-info'>
          <div className='projects-list-item-info__front'>
            <ProjectFront />
            <span>{ project?.projectSpecs?.baseLivingArea } м²</span>
          </div>
          <div className='projects-list-item-info__area'>
            <ProjectArea />
            <span>{ project?.projectSpecs?.roomsMinCount}-{ project?.projectSpecs?.roomsMaxCount } ком</span>
          </div>
          <div className='projects-list-item-info__floor'>
            <ProjectFloor />
            <span>{ project?.projectSpecs?.floorsCount } эт</span>
          </div>
        </div>

      </div>
    </div>
  );
};