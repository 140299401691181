import { Button } from 'primereact/button';
import { useNavigate } from 'react-router';

import staticData from 'common/static.json';
import { useAppSelector } from 'store';
const text = staticData.referral;

const SecondStep: React.FC = () => {
  const { info } = useAppSelector(state => state.referral);
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/complete-data');
  };

  return (
    <div className='referral-wrapper flex flex-wrap align-items-center justify-content-between gap-3'>
      <div className='flex flex-column gap-3'>
        <span>{text.secondStep}</span>
        <span className='text-xl font-medium'>Выводите бонусы</span>
      </div>
      <div className='flex flex-wrap gap-3'>
        <div className='flex gap-3 align-items-center'>
          {text.orders}
          <span className='referral-block border-round-md'>
            {info.referralsCount}
          </span>
        </div>
        <div className='flex gap-3 align-items-center'>
          {text.amount}
          <span className='referral-block border-round-md'>
            {`${info.withdrawnAmount} ₽`}
          </span>
        </div>
        <div className='flex gap-3 align-items-center'>
          {text.fundsAvailable}
          <span className='referral-block border-round-md'>
            {`${info.availableAmount} ₽`}
          </span>
        </div>
      </div>
      <Button
        className='button-green min-w-max px-6 py-3'
        label={text.cashout}
        onClick={handleNavigate}
      />
    </div>
  );
};

export default SecondStep;
