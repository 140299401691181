import { FC, useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from 'store';
import TempImage from 'assets/images/temp-project.png'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/scrollbar';

export const MarketGallery: FC = () => {
  const dispatch = useAppDispatch();
  const { currentImages } = useAppSelector(state => state.projects);
  const [currentPanel, setCurrentPanel] = useState('EXTERIOR');
  const { projectData } = useAppSelector(state => state.projects);

  return (
    <div className='project-gallery'>
      <div className="project-gallery__wrapper">
        {
          currentPanel === 'EXTERIOR' &&
          <img className='project-gallery__img' src={currentImages[currentPanel]} />
        }
        {
          currentPanel === 'PLAN' &&
            <Swiper
              loop={true}
              scrollbar={{
                hide: true,
              }}
              breakpoints={{
                320: {
                  slidesPerView: "auto",
                  spaceBetween: 8
                },
                530: {
                  slidesPerView: 1,
                  spaceBetween: 8
                },
                744: {
                  slidesPerView: 1,
                  spaceBetween: 8,
                },
                1050: {
                  slidesPerView: 1,
                  spaceBetween: 8,
                },
                1280: {
                  slidesPerView: 1,
                  spaceBetween: 8,
                },
                1400: {
                  slidesPerView: 1,
                  spaceBetween: 48,
                }
              }}
              modules={[Scrollbar]}
            >
              <div>
                {
                  currentImages[currentPanel].map((img) =>{ 
                    return <SwiperSlide style={{width: '100% !important'}}><img className='project-gallery__img' src={img} /></SwiperSlide>
                  })
                }
              </div>
            </Swiper>
          }
        <div className='project-gallery__buttons project-gallery-buttons'>
          <button
            className={`project-gallery-buttons__item ${currentPanel === 'EXTERIOR' ? 'project-gallery-buttons__item--active': ''}`}
            onClick={ () => { setCurrentPanel('EXTERIOR') } }
          >
            Фасад
          </button>
          <button
            className={`project-gallery-buttons__item ${currentPanel === 'PLAN' ? 'project-gallery-buttons__item--active': ''}`}
            onClick={ () => { setCurrentPanel('PLAN') } }
          >
            Планировка
          </button>
        </div>
      </div>
      <div className='project-gallery__description project-description'>
        <p className="project-description__text">
          { projectData?.description }
        </p>
      </div>
    </div>
  );
};