import React, { FC, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';

import { useAppSelector, useAppDispatch } from 'store';
import { getAllUsers } from 'store/users/actions';
import { LoadMoreButton } from 'components/common/LoadMoreButton';
import { regions } from 'store/ui/actions';
import staticData from 'common/static.json';

import { routesPath } from 'routes';

const users = staticData.users.list;

const roleName = {
  ADMIN: 'Админ',
  PARTNER: 'Партнер',
  CUSTOMER: 'Заказчик'
}

const pageSize = 5;

export const UsersPage: FC = () => {
  const dispatch = useAppDispatch();
  const { list } = useAppSelector(state => state.users);
  const { regionMap } = useAppSelector(state => state.ui);
  
  const [roleFilter, setRoleFilter] = useState(null);
  
  useEffect(() => {
    dispatch(regions());
  }, []);

  useEffect(() => {
    let params = {
      pageNum: 0,
      pageSize
    }
    if (roleFilter) {
      params.role = roleFilter;
    }
    if (roleFilter !== null) {
      dispatch(getAllUsers(params));
    }
  }, [roleFilter]);

  const loadMore = (params) => {
    if (roleFilter) {
      params.role = roleFilter;
    }
    dispatch(getAllUsers(params));
  }

  return (
    <div className='users'>
      <div className='users-list'>
        <div className='users-list--header flex justify-content-between flex-wrap'>
          <div className='users-list--header-title'>
            <span>{ users.title }</span>
          </div>
          <div className='users-list--header-request'>
            <span>
              <NavLink
                to={ routesPath.partners }
              >
                <button>{ users.requestButton }</button>
              </NavLink>
            </span>
          </div>
        </div>

        <div className='users-list--filters'>
          {
            users.filters.map((filter, key) => {
              return <div key={ key } className='users-list--filters-filter'>
                <div>
                  <span>{ filter.label }</span>
                </div>
                <Dropdown
                  value={ roleFilter }
                  onChange={(e) => setRoleFilter(e.value)}
                  options={ filter.roleFilters }
                  optionLabel='name' 
                  placeholder=''
                  className='w-full md:w-14rem'
                  showClear
                />
              </div>
            })
          }
        </div>
        {
          list.data.map((user, key) => {
            return <div key={key} className='users-list--item'>
            <div className='users-list--item_card'>
              <div className='users-list--item_card-id'>
                <span> { key + 1 }</span>
              </div>
              <div className='users-list--item_card-name'>
                <span>{ user.name }</span>
              </div>
              <div>
                <span>Email: { user?.email }</span>
              </div>
              <div>
                <span>Роль: { roleName[user.role] }</span>
              </div>
              <div>
                  <span> Область:  &nbsp;
                  {
                    user.regions.map((region, key) => {
                      return <span key={ key }>{ regionMap[region] } { key + 1 !== user.regions.length ? ',': '' }</span>
                    })
                  }
                  </span>
              </div>
              <div className='users-list--item_card-additional flex align-items-end justify-content-between flex-wrap'>
                <div>
                </div>
                <div>
                <NavLink
                  to={ `/user/${user.id}` }
                >
                  <button>
                    Перейти
                  </button>
                </NavLink>
                </div>
              </div>
            </div>
          </div>
          })
        }
    </div>
    <LoadMoreButton
      loadAction={ loadMore }
      start={ 0 }
      step={ pageSize }
      size={ list.size }
    />
  </div>
  )
};