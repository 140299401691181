import { Dropdown } from 'primereact/dropdown';
import { useController } from 'react-hook-form';
import { useAppSelector } from 'store';
import { defaultMessage } from 'helpers/validations';

const IndividualCitySelect = () => {
  const { individualUser, isEditing } = useAppSelector(state => state.profile);
  const { regionList, regionMap } = useAppSelector(state => state.ui);
  const {
    field,
    fieldState: { error, isDirty },
  } = useController({ name: 'region', rules: { required: defaultMessage } });

  return (
    <div>
      {isEditing ? (
        <div>
          <div className='profile-desktop'>
            <Dropdown
              {...field}
              options={regionList}
              optionLabel='value'
              optionValue='region'
              value={field.value}
              onChange={e => field.onChange(e.value)}
              panelClassName='profile-info-select'
              className={`auth-form-input pr-0 flex align-items-center h-3rem w-min ${
                error && 'input-error-auth'
              } ${isDirty && !error && 'profile-valid-auth'}`}
            />
          </div>
          <div className='profile-mobile'>
            <Dropdown
              {...field}
              options={regionList}
              optionLabel='value'
              optionValue='region'
              value={field.value}
              onChange={e => field.onChange(e.value)}
              panelClassName='profile-info-select'
              className={`auth-form-input pr-0 flex align-items-center h-3rem w-full ${
                error && 'input-error-auth'
              } ${isDirty && !error && 'profile-valid-auth'}`}
            />
          </div>
        </div>
      ) : (
        <span>{ regionMap[individualUser.region]}</span>
      )}
    </div>
  );
};

export default IndividualCitySelect;
