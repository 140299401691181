import React from 'react';
import { Provider } from 'react-redux';
import { ApplicationState } from 'store';
import { Store } from 'redux';
import { History } from 'history';
import moment from 'moment';
import 'moment/locale/ru';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'assets/index.scss';

import { RouterProvider } from 'react-router-dom';
import { router } from 'routes/routes';

interface MainProps {
    store: Store<ApplicationState>;
    history: History;
}

const App: React.FC<MainProps> = ({ store, history }) => {
    moment.locale('ru');
    return (
        <Provider store={store}>
            <RouterProvider router={router} />
        </Provider>
    );
};

export default App;
