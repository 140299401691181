import { API_ROUTES } from 'config';
import { request } from './api';
import { IStatus } from 'store/partners/types';

export const partnersApi = {
  getPartners: () => request.get<void>(API_ROUTES.partners.fetchList),
  updateStatus: (id: string, data: IStatus) =>
    request.put<void>(
      API_ROUTES.partners.updateStatus.replace('{issuePartnerId}', id),
      data
    ),
  createIssue: (data: any) =>
    request.post<void>(API_ROUTES.partners.createIssue, data),
  getOrders: (data: any) =>
    request.get<void>(API_ROUTES.partners.getOrders, data),
  getExecutors: (data: any) =>
    request.get<void>(API_ROUTES.partners.getExecutors, data),
  getOrderExecutionIssueId: (orderExecutionIssueId: string) => 
    request.get<void>(API_ROUTES.partners.getPartnerIssue.replace('{orderExecutionIssueId}', orderExecutionIssueId)),
  getPartnerInfo: (partnerId: string) => 
    request.get<void>(API_ROUTES.partners.getPartnerInfo.replace('{partnerId}', partnerId)),
  updatePartnerIssue: (data) => 
    request.put<void>(API_ROUTES.partners.updateIssue, data),
};
