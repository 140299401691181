import axios, { AxiosError, AxiosResponse } from 'axios';
import { TOKEN_STORAGE_KEY, REFRESH_TOKEN_STORAGE_KEY } from 'common/constants';
import { API_BASE_URL } from 'config';
import store from 'store';
import { uiSlice } from 'store/ui/reducer';
import staticData from 'common/static.json';
import { refreshToken } from 'store/auth/actions';

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}
export interface ISortDirection {
  ASC: SortDirection,
  DESC: SortDirection
}

const { setNotification } = uiSlice.actions;

const text = staticData.notifications.error;

const instance = axios.create({
  baseURL: API_BASE_URL,
});

instance.interceptors.request.use(config => {
  const token = localStorage.getItem(TOKEN_STORAGE_KEY);

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

const errorNotification = (message: any) => {
  return {
    id: Math.random(),
    // message: text.message,
    message: message,
    type: 'error',
    header: text.header,
  };
};

instance.interceptors.response.use(
  res => res,
  (error: AxiosError) => {
    const { data, status } = error.response!;

    switch (status) {
      case 400:
        //store.dispatch(setNotification(errorNotification(data.message)));
        break;
      case 401:
        console.error('unauthorised');
        window.localStorage.removeItem(TOKEN_STORAGE_KEY);
        store.dispatch(refreshToken(window.localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY)))
        //store.dispatch(setNotification(errorNotification(data.message)));
        break;
      case 403:
        console.error('forbidden');
        //store.dispatch(setNotification(errorNotification(data.message)));
        break;
      case 404:
        console.error('not-found');
        break;
      case 500:
        console.error('server-error');
        //store.dispatch(setNotification(errorNotification(data.message)));
        break;
    }
    return Promise.reject(error);
  }
);

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

export const request = {
  get: <T>(url: string, params?: Object) =>
    instance
      .get<T>(
        params
          ? `${url}?${Object.keys(params)
              .map(key => key + '=' + params[key])
              .join('&')}`
          : url
      )
      .then(responseBody),
  post: <T>(url: string, body: {}) =>
    instance.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body: {}) =>
    instance.put<T>(url, body).then(responseBody),
  delete: <T>(url: string) => instance.delete<T>(url).then(responseBody),
};
