import { FC, useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from 'store';
import { IProjectItem } from 'store/projects/types';
import { ProjectArea, ProjectFloor, ProjectFront } from 'assets/icons';
import { Slider } from 'primereact/slider';
import { InputText } from 'primereact/inputtext';

import { Dialog } from 'primereact/dialog';
import { DocumentsFilter } from 'assets/icons';

export const MarketsFilters: FC<{ project: IProjectItem }> = ({ project }) => {
  const dispatch = useAppDispatch();
  const [area, setArea] = useState([30,87]);
  const [price, setPrice] = useState([30, 90]);

  return (
    <div className='projects-filters'>

      {/* <div className='projects-filters__floors projects-filters-floors'>
        <span className='projects-filters__text'>Этажность</span>
        <div className="projects-filters-floors__buttons projects-filters-floors-buttons">
          <button className="projects-filters-floors-buttons__button">1</button>
          <button className="projects-filters-floors-buttons__button projects-filters-floors-buttons__button--active">2</button>
        </div>
      </div>

      <div className='projects-filters__slider projects-filters__slider--area projects-filters-slider'>
        <span className='projects-filters__text'>Площадь</span>
        <div className='projects-filters-slider__wrapper'>
          <InputText
            value={area[0]}
            // onChange={(e) => setValue(e.target.value)}
          />
          <InputText
            value={area[1]}
            // onChange={(e) => setValue(e.target.value)}
          />
          <Slider value={area} onChange={(e) => setArea(e.value)} range />
        </div>
      </div>

      <div className='projects-filters__slider projects-filters__slider--price projects-filters-slider'>
        <span className='projects-filters__text'>По цене</span>
        <div className='projects-filters-slider__wrapper'>
          <InputText
            value={price[0]}
            // onChange={(e) => setValue(e.target.value)}
          />
          <InputText
            value={price[1]}
            // onChange={(e) => setValue(e.target.value)}
          />
          <Slider value={price} onChange={(e) => setPrice(e.value)} range />
        </div>
      </div>

      <div className="projects-filters__search projects-filters-search input--new">
          <span className="projects-filters-search__text">Фильтр</span>
           <div className="projects-filters-search__icon">
            <DocumentsFilter />
           </div>
        </div>

        <Dialog visible={false} onHide={() => setDocumentMode(false)} header="Фильтр" className='projects-filters-modal'>
          <div className="projects-filters-modal__wrapper">

          <div className='projects-filters-modal__floors projects-filters-modal-floors'>
            <span className='projects-filters-modal__text'>Этажность</span>
            <div className="projects-filters-modal-floors__buttons projects-filters-modal-floors-buttons">
              <button className="projects-filters-modal-floors-buttons__button">1</button>
              <button className="projects-filters-modal-floors-buttons__button projects-filters-modal-floors-buttons__button--active">2</button>
            </div>
          </div>

          <div className='projects-filters-modal__slider projects-filters-modal__slider--area projects-filters-modal-slider'>
            <span className='projects-filters-modal__text'>Площадь</span>
            <div className='projects-filters-modal-slider__wrapper'>
              <InputText
                value={area[0]}
                // onChange={(e) => setValue(e.target.value)}
              />
              <InputText
                value={area[1]}
                // onChange={(e) => setValue(e.target.value)}
              />
              <Slider value={area} onChange={(e) => setArea(e.value)} range />
            </div>
          </div>

          <div className='projects-filters-modal__slider projects-filters-modal__slider--price projects-filters-modal-slider'>
            <span className='projects-filters-modal__text'>По цене</span>
            <div className='projects-filters-modal-slider__wrapper'>
              <InputText
                value={price[0]}
                // onChange={(e) => setValue(e.target.value)}
              />
              <InputText
                value={price[1]}
                // onChange={(e) => setValue(e.target.value)}
              />
              <Slider value={price} onChange={(e) => setPrice(e.value)} range />
            </div>
          </div>

            <button className="projects-filters-modal__button">Применить</button>
          </div>
        </Dialog> */}
    </div>
  );
};