import { FC } from 'react';

import { IDocumentData } from 'store/documents/types';
import { PdfDocumentIcon } from 'assets/icons';
import { DocumentsAwaitingSignature } from 'assets/icons';
import { CrossIcon, CheckMarkIcon, CheckMarkFilledIcon, CheckMarkFilledIcon2, CheckMarkEmptyIcon, ReadonlyMarkIcon } from 'assets/icons';

export const DocumentsUserItem: FC<{
  document:IDocumentData,
  showDocument: (id:string) => void,
  removeFile: (id:string) => void,
  documentStatus: string
}> = ({ document, showDocument, removeFile, documentStatus }) => {
  return (
    <a onClick={ () => { showDocument(document?.fileId) } } className='documents-list__link documents-list-link'>
      <div className='documents-list-link__img'>
        <PdfDocumentIcon />
      </div>
      <div className="documents-list-link__content documents-link-content">
        <div className="documents-link-content__info">
          <span className='documents-link-content__name'>
            { document?.name }
          </span>

            <div className="documents-link-content__status documents-link-content-status">
              <div className="documents-link-content-status__img">
                {
                  document?.status === 'WAITING_FOR_SENDING' &&
                  <DocumentsAwaitingSignature />
                }
                {
                  document?.status === 'ONLY_FOR_READING' &&
                  <ReadonlyMarkIcon />
                }
                {
                  document?.status === 'WAITING_FOR_SIGNING' &&
                  <CheckMarkEmptyIcon />
                }
                {
                  document?.status === 'FIRST_PARTICIPANT_SIGNED' &&
                  <CheckMarkFilledIcon />
                }
                {
                  document?.status === 'SECOND_PARTICIPANT_SIGNED' &&
                  <CheckMarkFilledIcon2 />
                }
              </div>
              {
                  document?.status === 'WAITING_FOR_SENDING' &&
                  <span className="documents-link-content-status__text">Ожидает отправки</span>
                }
                {
                  document?.status === 'ONLY_FOR_READING' &&
                  <span className="documents-link-content-status__text">Только для чтения</span>
                }
                {
                  document?.status === 'WAITING_FOR_SIGNING' &&
                  <span className="documents-link-content-status__text">Ожидает подписания</span>
                }
                {
                  document?.status === 'FIRST_PARTICIPANT_SIGNED' &&
                  <span className="documents-link-content-status__text">1 подписант</span>
                }
                {
                  document?.status === 'SECOND_PARTICIPANT_SIGNED' &&
                  <span className="documents-link-content-status__text">2 подписанта</span>
                }
            </div>
        </div>

            <span className="documents-link-content__number">Заказ { document?.orderPublicId }</span>
      </div>
    </a>
  )
};