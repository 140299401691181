import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { OrderInfo } from './OrderInfo';
import { RepresentativeIssue } from 'components/Order/Representative';
import { ContractorIssue } from 'components/Order/Contractor';
import { RealtorIssue } from 'components/Order/Realtor';
import { LogisticIssue } from 'components/Order/Logistic';
import { OrderPartnerHeader } from './OrderPartnerHeader';
import BackButton from 'components/common/BackButton';
import { getById } from 'store/orders/actions';

import { regions } from 'store/ui/actions';

import { getPartnerIssue } from 'store/orders/actions';

import { useAppSelector, useAppDispatch } from 'store';

import { getOrdersTemplateDocumentsForPartners } from 'store/documents/actions';

import { PartnersDocuments } from './PartnersDocuments';

import { getFilledDocuments } from 'store/documents/actions';

export const OrderPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const { state } = useLocation();
  const isPartnerOrder = state?.isPartnerOrder;
  const partnerType = state?.partnerType;

  const [currentParentTab, setCurrentParentTab] = useState('info');

  const { isProToogler } = useAppSelector(state => state.ui);
  
  const { token, role } = useAppSelector(state => state.auth);
  const { legalUser } = useAppSelector(state => state.profile);
  const { partnerIssue, order } = useAppSelector(state => state.orders);

  const isPartner = (role !== 'CUSTOMER' && role !== 'ADMIN');
  const isAdmin = role === 'ADMIN';
  const { id } = params;

  useEffect(() => {
    dispatch(regions());
    if (isAdmin && isPartnerOrder && id) {
      dispatch(getPartnerIssue(id));
    } else if (id) {
      dispatch(getById(id));
    }
  }, [token, id]);

  useEffect(() => {
    if (partnerIssue?.issue?.orderId) {
      dispatch(getById(partnerIssue?.issue?.orderId));
    }
  }, [partnerIssue]);

  useEffect(() => {
    if (currentParentTab === 'documents') {
      // dispatch(getOrdersTemplateDocumentsForPartners({id: legalUser?.partner?.authUserId, params: {
      //   pageNum: 0,
      //   pageSize: 100
      // }}))
      if (role === 'PARTNER') {
        console.info(order)
        dispatch(getFilledDocuments({
          pageNum: 0,
          pageSize: 100,
          executionIssuesIds: [partnerIssue?.issue?.id],
        }))
      }

      if (role === 'ADMIN') {
        dispatch(getFilledDocuments({
          pageNum: 0,
          pageSize: 100,
          executionIssuesIds: [partnerIssue?.issue?.id],
        }))
      }
    }
  }, [currentParentTab]);


  return (
    <div className='orders p-4 flex flex-column gap-3'>
      <div className='flex'>
        <BackButton />
      </div>
      { ((isPartner && !isProToogler) || (isAdmin && isPartnerOrder)) &&
        <OrderPartnerHeader
          changeTab={ (value) => { setCurrentParentTab(value) }}
        />
      }
      {
        isPartnerOrder && partnerType === 'representative' && currentParentTab === 'info' &&
          <RepresentativeIssue />
      }
      {
        isPartnerOrder && partnerType === 'contractor' && currentParentTab === 'info' &&
            <ContractorIssue />
      }
      {
        isPartnerOrder && partnerType === 'realtor' && currentParentTab === 'info' &&
            <RealtorIssue />
      }
      {
        isPartnerOrder && partnerType === 'logistic' && currentParentTab === 'info' &&
            <LogisticIssue />
      }
      {
        !isPartnerOrder && 
          <OrderInfo />
      }
      {
        currentParentTab === 'documents' &&
        <PartnersDocuments />
      }
  </div>
  )
};