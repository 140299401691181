import { useMultiStepForm } from 'hooks/useMultiStepForm';
import { Button } from 'primereact/button';
import { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { AccountType } from 'store/profile/types';
import { useAppDispatch, useAppSelector } from 'store';
import { FormatTo, formatDate } from 'utils/formatDate';
import { getUser, updateUser } from 'store/profile/actions';
import { createOrder } from 'store/orders/actions';

import { AccountTypeSelection } from './AccountTypeSelection';
import { LegalUserData } from './LegalUserData';
import { LegalUserFinalData } from './LegalUserFinalData';
import { IndividualUserData } from './IndividualUserData';
import { LandSelection } from './LandSelection';

import { routesPath } from 'routes';

export const CartCompletion: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { accountType } = useAppSelector(state => state.ui);
  const { legalUser, common, individualUser } = useAppSelector(state => state.profile);
  const { list } = useAppSelector(state => state.carts);
  const { id } = useAppSelector(state => state.auth);
  const [userData, setUserData] = useState({
    id: id,
    accountType: common?.accountType
  });
  const [orderIndex, setOrderIndex] = useState(0);

  const getLandForms = () => {
    return list.map((order, key) => {
      return <LandSelection projectName={ order.projectData.model } landIndex={ key } />;
    });
  }

  const [ordersWithLandData, setOrdersWithLandData] = useState([]);

  const sendData = async () => {
    await dispatch(createOrder({preparedOrdersData: ordersWithLandData, referralLink: userData.referral })).then((res) => {
      if (res?.error) {

      } else {
        navigate(routesPath.cartConfirmation);    
      }
    });
  }

  useEffect(() => {
    if (isLastStep || ordersWithLandData.length === getLandForms().length) {
      sendData();
    }
  }, [ordersWithLandData]);

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      accountType: common?.accountType,
      name: common?.name,
      passport: `${individualUser?.passportSeries} ${individualUser?.passportNumber}`,
      passportIssuedBy: individualUser?.passportIssuedBy,
      passportIssueDate: individualUser?.passportIssueDate,
      residenceAddress: individualUser?.residenceAddress,
      dateOfBirth: (individualUser.dateOfBirth &&
        formatDate(individualUser.dateOfBirth, FormatTo.server)) || null,
      orgName: legalUser?.orgName,
      ogrn: legalUser?.ogrn,
      inn: legalUser?.inn,
      kpp: legalUser?.kpp,
      bankName: legalUser?.bankName,
      bankAccount: legalUser?.bankAccount,
      correspondentAccount: legalUser?.correspondentAccount,
      bic: legalUser?.bic,
      responsiblePersonName: legalUser.responsiblePersonName || null,
      authorityGround: legalUser.authorityGround || null,
      legalAddress: legalUser.legalAddress || null,
      referral: ''
  }});
  
  const steps = () => {
    switch (userData.accountType as AccountType) {
      case AccountType.legalUser:
        return [<AccountTypeSelection />, <LegalUserData />, <LegalUserFinalData />].concat(getLandForms());
      case AccountType.individualUser:
        return [<AccountTypeSelection />, <IndividualUserData />].concat(getLandForms());
      default:
        return [<AccountTypeSelection />, <IndividualUserData />, <LegalUserData />, <LegalUserFinalData />].concat(getLandForms());
    }
    // if (common.accountType === AccountType.uncertainUser) {
    //   switch (userData.accountType as AccountType) {
    //     case AccountType.legalUser:
    //       return [<AccountTypeSelection />, <LegalUserData />, <LegalUserFinalData />].concat(getLandForms());
    //     default:
    //       return [<AccountTypeSelection />, <IndividualUserData />,].concat(getLandForms());
    //   }
    // } else {
    //   return getLandForms();
    // }
  };

  const { Step, next, back, goTo, isFirstStep, isLastStep, currentStep } = useMultiStepForm(
    steps(),
    common?.accountType !== AccountType.uncertainUser ? 1: 0
  );

  const handleFinish = (e: any) => {
    e.preventDefault();
  };

  const onSubmit = (formData: any) => {
    setUserData(prevState => {
      return {
        ...prevState,
        ...formData
      }
    });

    const saveUserData = () => {
      let data = {
        commonUserData: {
          email: common.email,
          phoneNumber: common.phoneNumber,
          roles: common.roles || null,
          name: formData.name || null,
          referralLink: null || null,
          accountType: formData.accountType || common.accountType,
        },
        individualUserData: null,
        legalEntityUserData: null
      }

      if (formData.accountType === AccountType.individualUser) {
        data.individualUserData = {
          passportSeries:
            (formData.passport && formData.passport.split(' ')[0]) || null,
          passportNumber:
            (formData.passport && formData.passport.split(' ')[1]) || null,
          passportIssueDate:
            (formData.passportIssueDate &&
              formatDate(formData.passportIssueDate, FormatTo.server)) ||
            null,
          dateOfBirth:
            (formData.dateOfBirth &&
              formatDate(formData.dateOfBirth, FormatTo.server)) ||
            null,
          passportIssuedBy: formData.passportIssuedBy || null,
          residenceAddress: formData.residenceAddress || null,
          region: individualUser?.region || null
        }
      }

      if (formData.accountType === AccountType.legalUser) {
        data.legalEntityUserData = {
          orgName: formData.orgName || null,
          inn: formData.inn || null,
          kpp: formData.kpp || null,
          ogrn: formData.ogrn || null,
          bankName: formData.bankName || null,
          bankAccount: formData.bankAccount || null,
          correspondentAccount: formData.correspondentAccount || null,
          bic: formData.bic || null,
          regions: legalUser?.regions || null,
          responsiblePersonName: formData.responsiblePersonName || legalUser?.responsiblePersonName || null,
          authorityGround: formData.authorityGround[0] || legalUser?.authorityGround || null,
          legalAddress: formData.legalAddress || legalUser?.legalAddress || null,
        }
      }

      dispatch(updateUser({ id, data })).then(() => {
        dispatch(getUser());
      });
    }

    if (formData.accountType === AccountType.legalUser && currentStep === 2) {
      saveUserData();
    } else if (formData.accountType === AccountType.individualUser && currentStep === 1) {
      saveUserData();
    } 

    if (!!formData[`region${ordersWithLandData.length}`] &&
    !!formData[`district${ordersWithLandData.length}`] &&
    !!formData[`locality${ordersWithLandData.length}`] &&
    !!formData[`cadastralNumber${ordersWithLandData.length}`]) {
      setOrdersWithLandData(prevState => {
        return prevState.concat([{
          orderData: {
            cartElementId: list[ordersWithLandData.length].id,
            paymentMethod: formData[`paymentMethod${ordersWithLandData.length}`]
          },
          landData: {
            region: formData[`region${ordersWithLandData.length}`],
            district: formData[`district${ordersWithLandData.length}`],
            locality: formData[`locality${ordersWithLandData.length}`] ,
            cadastralNumber: formData[`cadastralNumber${ordersWithLandData.length}`],
          }
        }]);
      });
      methods.reset();
    }

    next();

    // if (isLastStep && ordersWithLandData.length === getLandForms().length) {
    //   dispatch(createOrder({preparedOrdersData: ordersWithLandData, referralLink: userData.referral })).then((res) => {
    //     if (res?.error) {

    //     } else {
    //       navigate(routesPath.cartConfirmation);    
    //     }
    //   });
    // }
  };

  const alignButton = () => {
    if (isFirstStep) {
      return 'center';
    }

    return 'between'
  }

  const isBlockBackButton = () => {
    if (isFirstStep) {
      return true;
    }

    if (currentStep === 1 && common?.accountType !== AccountType.uncertainUser) {
      return true;
    }

    return false;
  }

  return (
    <div className='flex justify-content-center cart--completion'>
      <FormProvider {...methods}>
        <form
          className='card-form cart--completion-form flex flex-column gap-3 justify-content-center mb-0'
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          {Step}
          <div className={`flex justify-content-${alignButton()} gap-3 mt-3 flex-wrap`}>
            { !isFirstStep &&
              <Button
                className='button-white'
                label='Назад'
                disabled={isBlockBackButton()}
                onClick={(e) => { e.preventDefault(); back(); }}
              />
            }
            <Button
              className='button-green'
              onClick={() => (isLastStep || isFirstStep ? handleFinish : null)}
              label={isLastStep ? 'Отправить' : 'Далее'}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
