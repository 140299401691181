import { FC, useEffect } from 'react';
import { CrossIcon } from 'assets/icons';
import { useAppDispatch } from 'store';
import { uiSlice } from 'store/ui/reducer';

interface Props {
  id: number;
  header: string;
  message: string;
}

const NotificationItem: FC<Props> = ({ id, header, message }) => {
  const dispatch = useAppDispatch();
  const { removeNotification } = uiSlice.actions;

  useEffect(() => {
    setTimeout(() => {
      dispatch(removeNotification(id));
    }, 15000);
  }, []);

  const handleClose = () => {
    dispatch(removeNotification(id));
  };

  return (
    <div className='notifications-item p-3 flex justify-content-between gap-1 animation-duration-400 fadeinup select-none'>
      <div className='flex flex-column gap-3'>
        <span className='font-medium text-xl'>{header}</span>
        <span>{message}</span>
      </div>
      <div className='notifications-item-close'>
        <CrossIcon className='cursor-pointer' onClick={handleClose} />
      </div>
    </div>
  );
};

export default NotificationItem;
