import { FC, useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from 'store';
import { IProjectItem } from 'store/projects/types';
import { useParams, useNavigate } from 'react-router-dom';
import { ProjectArea, ProjectFloor, ProjectFront } from 'assets/icons';

import { fetchProjectTree, fetchProjectImages } from 'store/projects/actions';

import { putInCart } from 'store/carts/actions';

import { routesPath } from 'routes';

export const VillageOptions: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { name } = params;
  const { villageHouse } = useAppSelector(state => state.projects);
  const { token } = useAppSelector(state => state.auth);

  const [isShowOptions, setShowOptions] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({});
  const project = { }
  const projectData = []
  
  const inCart = async () => {
    const optionsToRequest = villageHouse?.projectConfiguration?.options?.map((option) => {
      return {
        option: option.title,
        variant: option?.options?.filter((item) => {
          return item?.selected === true;
        })[0]?.title
      }
    });

    const sendData = {
      count: 1,
      projectId: villageHouse?.projectData?.id,
      pricePerSingleProject: villageHouse?.price,
      options: optionsToRequest
    };

    if (token) {
      await dispatch(putInCart(sendData));

      navigate(routesPath.cart);
    } else {
      window.localStorage.setItem('CART_DATA', JSON.stringify(sendData));
      navigate(routesPath.cart);
    }

    await dispatch(putInCart({
      count: 1,
      projectId: villageHouse?.projectData?.id,
      pricePerSingleProject: project?.price,
      options: optionsToRequest
    }))

    navigate(routesPath.cart);
  }


  return (
    <div className='village-options'>
      <div className="village-options__wrapper">
        <div className='village-options__header village-options-header'>
          <h1 className='village-options-header__title'>
            <span className="village-options-header__title--subtitle">Орловъ № {name}</span>
            { villageHouse?.projectData?.model }
          </h1>
          <span className='village-options-header__amount'>{ villageHouse?.price ? (villageHouse?.price?.toFixed()).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 '): '' } ₽ </span>
        </div>
        <h2 className='village-options__subtitle'>Комплектация</h2>


        <div className='village-options__list village-options-list'>
          {
            villageHouse?.projectConfiguration?.options?.map((option, key) => {
              return <div className='village-options-list__item list-item' key={ key }>
                      { !isShowOptions && key < 2 &&
                        <>
                          <span className='list-item__name'>{ option.title }</span>
                          <div className='list-item__options list-item-options'>
                            {
                              option?.options?.map((opt, index) => {
                                return <button
                                  disabled={ !opt?.available }
                                  className={ opt.selected === true ? 'list-item-options__button list-item-options__button--active': 'list-item-options__button' }
                                  >{ opt?.title }</button>
                              })
                            }
                          </div>
                        </>
                      }
                      {
                        !isShowOptions && key === 2 &&
                          <span className='list-item__showmore' onClick={ () => { setShowOptions(true) } }>Развернуть</span>
                      }
                      {
                        isShowOptions &&
                          <>
                          <span className='list-item__name'>{ option.title }</span>
                          <div className='list-item__options list-item-options'>
                            {
                              option?.options?.map((opt, index) => {
                                return <button
                                  disabled={ !opt?.available }
                                  className={ opt.selected === true ? 'list-item-options__button list-item-options__button--active': 'list-item-options__button' }
                                >{ opt?.title }</button>
                              })
                            }
                          </div>
                          </>
                      }
                      {
                        isShowOptions && key === villageHouse?.projectConfiguration?.options.length - 1 &&
                          <span className='list-item__showmore list-item__showmore--close' onClick={ () => { setShowOptions(false) } }>Свернуть</span>
                      }
                    </div>
            })
          }
            
        </div>

        <div className='village-options__summary village-options-summary'>
          <h3 className='village-options-summary__title'>Краткие характеристики</h3>
          <div className='village-options-summary__item village-options-summary-item'>
            <span className='village-options-summary-item__label'>Площадь дома, включая террасы и балконы</span>
            <span className='village-options-summary-item__value'>{ villageHouse?.projectConfiguration?.specs?.totalArea }&nbsp;м²</span>
          </div>
          <div className='village-options-summary__item village-options-summary-item'>
            <span className='village-options-summary-item__label'>Жилая площадь</span>
            <span className='village-options-summary-item__value'>{ villageHouse?.projectConfiguration?.specs?.livingArea }&nbsp;м²</span>
          </div>
          <div className='village-options-summary__item village-options-summary-item'>
            <span className='village-options-summary-item__label'>Количество комнат (в зависимости от выбранной планировки)</span>
            <span className='village-options-summary-item__value'>{ villageHouse?.projectConfiguration?.specs?.roomsCount }</span>
          </div>
          <div className='village-options-summary__item village-options-summary-item'>
            <span className='village-options-summary-item__label'>Этажей</span>
            <span className='village-options-summary-item__value'>{ villageHouse?.projectData?.projectSpecs?.floorsCount }</span>
          </div>
        </div>
      </div>
      {
        villageHouse?.disabled &&
        <div className="village-options__button" onClick={ () => { inCart() } }>Купить</div>
      }
    </div>
  );
};