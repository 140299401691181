import { FC, useEffect, useState } from 'react';

import { 
  RoadMapCreditCardIcon,
  RoadMapUnionIcon,
  RoadMapDeliveryIcon,
  RoadMapDocumentIcon,
  RoadMapHomeOutlinedIcon,
  RoadMapMarkIcon
} from 'assets/icons';

import { orderStatus } from 'store/orders/types';
import { useAppSelector } from 'store';

export const OrderRoadMap:FC = () => {

  const { order } = useAppSelector(state => state.orders);
  const [ordersStatusList, setOrdersStatusList] = useState([]);

  useEffect(() => {
    const listOrder = [{
      icon: <RoadMapCreditCardIcon className='order-road-map--item_icon' />,
      value: orderStatus.CREATED,
      key: 'CREATED',
      status: false,
      current: false
    }, {
      icon: <RoadMapUnionIcon className='order-road-map--item_icon' />,
      value: orderStatus.ORDER_CONFIRMATION,
      key: 'ORDER_CONFIRMATION',
      status: false,
      current: false
    }, {
      icon: <RoadMapDeliveryIcon className='order-road-map--item_icon' />,
      value: orderStatus.SIGNING_OF_DOCUMENTS,
      key: 'SIGNING_OF_DOCUMENTS',
      status: false,
      current: false
    }, {
      icon: <RoadMapDocumentIcon className='order-road-map--item_icon' />,
      value: orderStatus.PREPAYMENT,
      key: 'PREPAYMENT',
      status: false,
      current: false
    }, {
      icon: <RoadMapDeliveryIcon className='order-road-map--item_icon' />,
      value: orderStatus.CHECKING_THE_LAND_PLOT,
      key: 'CHECKING_THE_LAND_PLOT',
      status: false,
      current: false
    }, {
      icon: <RoadMapUnionIcon className='order-road-map--item_icon' />,
      value: orderStatus.GETTING_A_MORTGAGE,
      key: 'GETTING_A_MORTGAGE',
      status: false,
      current: false
    }, {
      icon: <RoadMapHomeOutlinedIcon className='order-road-map--item_icon' />,
      value: orderStatus.PAYMENT,
      key: 'PAYMENT',
      status: false,
      current: false
    }];

    
    listOrder.every((status, index) => {
      if (status.key === order?.order?.status) {
        listOrder[index].status = true;
        listOrder[index].current = true;
        return false;
      }

      listOrder[index].status = true;
      return true;
    });

    setOrdersStatusList([...listOrder]);
  }, [order]);

  return (
    <div className='order-road-map flex align-items-start justify-content-between align-items-center flex-wrap'>
      {
        ordersStatusList.map((status, key) => {
          return <>
            <div className={'order-road-map--item ' + (status.status && !status.current ? 'order-road-map--item_completed' :status.status && status.current ? 'order-road-map--item_active' :'order-road-map--item_pending')}>
              <div>{ status.icon }</div>
              { status.status && status.current &&
                <span>{ status.value }</span>
              }
              { status.status && !status.current &&
                <span><RoadMapMarkIcon /></span>
              }
            </div>
            { key + 1 !== ordersStatusList.length &&
              <div className='order-road-map--separator'></div>
            }
          </>
        })
      }
    </div>
  )
}
