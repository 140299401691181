import { FC, useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch} from 'store';
import { LogisticInputs } from './inputs';

export const LogisticIssue:FC = () => {
  const dispatch = useAppDispatch();

  const { order } = useAppSelector(state => state.orders);
  
  return (
    <div className='order-detail flex align-items-start justify-content-center'>
      <LogisticInputs />
    </div>
  )
}
