import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  IStatus,
  PARTNERS_CREATE_ISSUE,
  PARTNERS_GET_PARTNERS,
  PARTNERS_UPDATE_STATUS,
  PARTNERS_GET_EXECUTORS,
  PARTNERS_GET_INFO
} from './types';
import { partnersApi } from 'services/api/partnersApi';

export const getPartners = createAsyncThunk(PARTNERS_GET_PARTNERS, async () => {
  const response = await partnersApi.getPartners();
  return response;
});

export const updateStatus = createAsyncThunk(
  PARTNERS_UPDATE_STATUS,
  async ({ id, data }: { id: string; data: IStatus }) => {
    const response = await partnersApi.updateStatus(id, data);
    return response;
  }
);

export const createIssue = createAsyncThunk(
  PARTNERS_CREATE_ISSUE,
  async (data: any) => {
    const response = await partnersApi.createIssue(data);
    return response;
  }
);

export const getExecutors = createAsyncThunk(
  PARTNERS_GET_EXECUTORS,
  async (data: any) => {
    const response = await partnersApi.getExecutors(data);
    return response;
  }
);

export const getPartnerInfo = createAsyncThunk(
  PARTNERS_GET_INFO,
  async (id: any) => {
    const response = await partnersApi.getPartnerInfo(id);
    return response;
  }
);
