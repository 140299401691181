import staticData from 'common/static.json';
import InputTest from 'components/common/InputTest';
import { validations } from '../validations';
import { useAppSelector } from 'store';

const text = staticData.profile;
const placeholder = staticData.profile.placeholders;

const CompleteLegalDataFirstStep = () => {
  const { legalUser } = useAppSelector(state => state.profile);
  return (
    <div className='flex flex-column gap-3'>
      <span className='text-center'>{text.orgData}</span>
      <div className='flex flex-column gap-3 justify-content-center'>
        <InputTest
          name='orgName'
          validations={validations.orgName}
          classNameInput='auth-form-input'
          classNameValid='input-valid-auth'
          classNameError='input-error-auth'
          classNameLabel='flex flex-column gap-2'
          placeholder={placeholder.legalName}
          label={text.legalName}
          disabled={!!legalUser.orgName}
        />
        <InputTest
          name='ogrn'
          validations={validations.ogrn}
          classNameInput='auth-form-input'
          classNameValid='input-valid-auth'
          classNameError='input-error-auth'
          classNameLabel='flex flex-column gap-2'
          placeholder={placeholder.ogrn}
          label={text.ogrn}
          disabled={!!legalUser.ogrn}
        />
        <div className='flex gap-4 justify-content-between align-items-end'>
          <InputTest
            name='inn'
            validations={validations.mask}
            mask='9999999999'
            classNameInput='auth-form-input profile-form-input'
            classNameValid='input-valid-auth'
            classNameError='input-error-auth'
            classNameLabel='flex flex-column gap-2'
            placeholder={placeholder.legalInn}
            label={text.inn}
            disabled={!!legalUser.inn}
          />
          <InputTest
            name='kpp'
            validations={validations.kpp}
            classNameInput='auth-form-input'
            classNameValid='input-valid-auth'
            classNameError='input-error-auth'
            classNameLabel='flex flex-column gap-2'
            placeholder={placeholder.kpp}
            label={`${text.kpp}*`}
            disabled={!!legalUser.kpp}
          />
        </div>
      </div>
    </div>
  );
};

export default CompleteLegalDataFirstStep;
