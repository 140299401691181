import { FC } from 'react';

import { TermsOfUse } from './TermsOfUse';
import { TermsPersonal } from './PersonalDataProcessing';
import { TermsAdvertising } from './AdvertisingInformational';
import { TermsPartnership } from './PartnershipAgreement';

import { useParams } from 'react-router';

import { BackIcon } from 'assets/icons';

export const TermsPage: FC = () => {
  const params = useParams();
  const { type } = params;
  return (
    <div className='terms'>
      <div className="terms__header">
        {/* <button className='project__back project-back'>
          <div className="project-back__icon"><BackIcon /></div>
          <span className="project-back__text">Назад</span>
        </button> */}
      </div>
      <div className="terms__wrapper">
        {
          type === 'tos' &&
          <TermsOfUse />
        }
        {
          type === 'personal' &&
          <TermsPersonal />
        }
        {
          type === 'toa' &&
          <TermsAdvertising />
        }
        {
          type === 'partnership' &&
          <TermsPartnership />
        }
      </div>
    </div>
  );
};