import { useState } from 'react';

export const useLocalStorage = (storageKey: string, storageValue: any) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(storageKey);
      if (value?.length) {
        return value;
      } else if (storageValue !== '' && storageValue !== null) {
          window.localStorage.setItem(storageKey, storageValue);
          return storageValue;
      }
    } catch (err) {
      return storageKey;
    }
  });
  const setValue = (newValue: string) => {
    // if (newValue === '' || newValue === null) {
    //   try {
    //     window.localStorage.removeItem(storageKey);
    //     setStoredValue(newValue);
    //     return;
    //   } catch (err) {
    //     console.log(err);
    //   }  
    // }

    try {
      if (newValue !== '' && newValue !== null) {
        window.localStorage.setItem(storageKey, newValue);
      }
    } catch (err) {
      console.log(err);
    }
    setStoredValue(newValue);
  };
  return [storedValue, setValue];
};