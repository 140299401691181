import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ICartsState } from './types';

import {
  fetchCarts,
  changeCountCarts,
  removeCarts,
  putInCart
} from './actions';

const initialCarts:ICartsState = {
  list: [],
  count: 0
}

const cartsSlice = createSlice({
  name: 'carts',
  initialState: initialCarts,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCarts.fulfilled, (state, action: PayloadAction<any>) => {
        state.list = action?.payload?.data;
        state.count = action?.payload?.data.length;
      })
      .addCase(changeCountCarts.fulfilled, (state,  action: PayloadAction<any>) => {
        state.list = action.payload?.data;
        state.count = action?.payload?.data.length;
      })
      .addCase(removeCarts.fulfilled, (state,  action: PayloadAction<any>) => {
        state.list = action?.payload?.data;
        state.count = action?.payload?.data.length;
      })
      .addCase(putInCart.fulfilled, (state,  action: PayloadAction<any>) => {

      })
    }
});

export default cartsSlice.reducer;