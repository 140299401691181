import { SelectButton } from 'primereact/selectbutton';
import { FC, useEffect } from 'react';
import { useController } from 'react-hook-form';
import { validations } from './validations';
import staticData from 'common/static.json';
import { uiSlice } from 'store/ui/reducer';
import { useAppDispatch } from 'store';
import { PartnerType } from 'store/ui/types';

const text = staticData.partnersRequest;

const PartnerTypeSelect: FC = () => {
  const { setPartnerType } = uiSlice.actions;
  const dispatch = useAppDispatch();
  const { field } = useController({
    name: 'partnerType',
    rules: validations.partnerType,
  });
  const options = [
    { label: 'Логист', value: 'LOGISTIC' },
    { label: 'Поставщик', value: 'PROVIDER' },
    { label: 'Подрядчик', value: 'CONTRACTOR' },
    { label: 'Риелтор', value: 'REALTOR' },
    { label: 'Представитель', value: 'REPRESENTATIVE' },
  ];

  useEffect(() => {
    field.value === PartnerType.CONTRACTOR
      ? dispatch(setPartnerType(PartnerType.CONTRACTOR))
      : dispatch(setPartnerType(PartnerType.OTHER));
  }, [field]);

  return (
    <div className='flex flex-column gap-3'>
      <span className='text-center font-medium text-2xl'>
        {text.partnerType}
      </span>
      <SelectButton options={options} {...field} className='partners-select' />
    </div>
  );
};

export default PartnerTypeSelect;
