import { IRegion } from 'store/ui/types';

export const PROFILE_GET_USER = 'profile/getUser';
export const PROFILE_UPDATE_USER = ' profile/updateUser';
export const PROFILE_UPDATE_PASSWORD = 'profile/updatePassword';

export interface IIndividualUser {
  referalLink: string;
  passportSeries: string;
  passportNumber: string;
  passportIssuedBy: string;
  passportIssueDate: string;
  residenceAddress: string;
  dateOfBirth: string;
  inn: string;
  iian: string;
  region: string;
}

export interface ILegalUser {
  orgName: string;
  ogrn: string;
  inn: string;
  kpp: string;
  bankName: string;
  bankAccount: string;
  correspondentAccount: string;
  bic: string;
  regions: Array<string>;
  responsiblePersonName: string;
  authorityGround: string;
  legalAddress: string;
}

export interface ICommon {
  name: string;
  phoneNumber: string;
  email: string;
  region: string[] | string;
  referralLink: string;
  roles: string[] | string;
  id: string;
  emailConfirmationStatus: EmailType;
  accountType: AccountType;
}

export interface IProfile {
  individualUser: IIndividualUser;
  legalUser: ILegalUser;
  common: ICommon;
  accountDialog: boolean;
  warningDialog: boolean;
  isEditing: boolean;
  passwordEditing: boolean;
  role: string;
}

export enum AccountType {
  individualUser = 'INDIVIDUAL_USER',
  legalUser = 'LEGAL_ENTITY_USER',
  uncertainUser = 'UNCERTAIN',
}

export enum EmailType {
  CONFIRMED = 'CONFIRMED',
  UNCONFIRMED = 'UNCONFIRMED',
}
