export const DOCUMENTS_GET_LIST = 'documents/getDocumentsList';
export const DOCUMENTS_SAVE = 'documents/saveDocuments';
export const DOCUMENTS_GET_FILE = 'documents/getDocumentFile';
export const DOCUMENTS_REMOVE_FILE = 'documents/removeDocumentFile';
export const DOCUMENTS_FETCH_FILLED = 'documents/fetchFilledDocuments';
export const DOCUMENTS_FETCH_PARTNER_FILE = 'documents/fetchPartnerDocumentId';
export const DOCUMENTS_FETCH_ORDERS_FILE = 'documents/fetchOrdersDocumentId';
export const DOCUMENTS_FETCH_ORDERS_FOR_CUSTOMER = 'documents/fetchOrdersForCustomer';
export const DOCUMENTS_ADD_FOR_ORDER = 'documents/addDocuemntsForOrder';
export const DOCUMENTS_CHANGE_VISIBILITY_CUSTOMER = 'documents/documentsChangedVisibilityCustomer';
export const DOCUMENTS_CHANGE_VISIBILITY_PARTNER = 'documents/documentsChangedVisibilityPartner';
export const DOCUMENTS_GET_PARTNERS_DOCUMENTS = 'documents/documentsGetPartnersDocuments';

export enum DocumentsType {
  TYPICAL = 'Типовые',
  FILLED = 'Заполненые'
}

export enum SignerType {
  CUSTOMER = 'CUSTOMER',
  REPRESENTATIVE = 'REPRESENTATIVE',
  REALTOR = 'REALTOR',
  LOGISTIC = 'LOGISTIC',
  PROVIDER = 'PROVIDER',
  CONTRACTOR = 'CONTRACTOR',
  SYSTEM = 'SYSTEM',
  ALL = 'ALL'
}

export enum DocumentsCategory {
  SYSTEM = 'SYSTEM',
  EMPLOYMENT = 'EMPLOYMENT',
  PARTICIPANTS = 'PARTICIPANTS',
  PROGRAMS = 'PROGRAMS',
  MORTGAGE = 'MORTGAGE'
}

export const documentsCategory = {
  SYSTEM: 'Системные',
  EMPLOYMENT: 'Кадровые',
  PARTICIPANTS: 'С участниками',
  PROGRAMS: 'Программы покупки',
  MORTGAGE: 'Ипотечные'
}

export const signerType = {
  CUSTOMER: 'Заказчик',
  REPRESENTATIVE: 'Представитель',
  REALTOR: 'Риелтор',
  LOGISTIC: 'Логист',
  PROVIDER: 'Поставщик',
  CONTRACTOR: 'Подрядчик',
  SYSTEM: 'Калитка',
  ALL: 'Все'
}

export const documentsType = {
  EMPLOYMENT: 'Трудоустройство',
  TERMS_AND_AGREEMENTS: 'Условия и соглашения',
  SUPPORTING: 'Сопроводительные',
  FINANCE: 'Финансы',
  OTHERS: 'Текущие'
}

export interface IDocumentData {
  id: string;
  name: string;
  category: DocumentsCategory;
  type: DocumentsType
}

export interface INewDocument {
  name: string;
  category: DocumentsCategory;
  type: DocumentsType;
  firstSigner: SignerType;
  secondSigner: SignerType;
}

export interface IDocumentsState {
  documents: {
    data: IDocumentData[],
    totalPages: Number,
    totalElements: Number,
    pageSize: Number, 
    pageNum: Number
  },
  customerDocuments: [],
  currentDocument: any
}