import { FC } from 'react';

import { Action } from '@reduxjs/toolkit';

import { navigationList } from './types';
import { Slider } from 'components/common/Slider';

export const MarketNavigation: FC<{ changeType: Action }> = ({ changeType }) => {
  return (
    <div className='projects-navigation'>
      <Slider
        sliderAction={ (value) => { changeType(value) } }
        slides={ navigationList }
        customClass={ 'projects-navigation' }
      />
    </div>
  )
}