import { FC, useState, useEffect } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { getById, editLandData, edit, addDocuments, editDocuments, removeDocuments, assignExecutors } from 'store/orders/actions';
import { getExecutors } from 'store/partners/actions';

import { OrderLandForm } from './forms/OrderLandForm';
import { OrderPaymentForm } from './forms/OrderPaymentForm';
import { OrderStatusForm } from './forms/OrderStatusForm';
import { OrderDocumentsForm } from './forms/OrderDocumentsForm';
import { OrderExecutorsForm } from './forms/OrderExecutorsForm';
import { DocumentsPreview } from 'components/Documents/DocumentsPreview';
import { orderStatus, paymentStatus } from 'store/orders/types';

import {
  DocumentsAwaitingSignature,
  CrossIcon,
  CheckMarkIcon,
  CheckMarkFilledIcon, 
  CheckMarkFilledIcon2,
  ReadonlyMarkIcon,
  CheckMarkEmptyIcon,
  DocumentEyeShow,
  DocumentEyeNoshow,
  PdfDocumentIcon
} from 'assets/icons';

import { partnersTypes, PartnerTypes, ExecutorsTypes } from 'store/partners/types';
import { getOrdersDocumentsById, changeVisibilityForCustomer } from 'store/documents/actions';

import { useAppSelector, useAppDispatch} from 'store';

export const OrderInputs:FC = () => {
  const dispatch = useAppDispatch();

  const { order } = useAppSelector(state => state.orders);
  const { executors } = useAppSelector(state => state.partners);

  const [isShowDialog, showDialog] = useState(false);
  const [currentDialog, setCurrentDialog] = useState(<></>);

  const [isShowDocument, setShowDocument] = useState(false);
  const [currentDocument, setCurrentDocument] = useState();

  const showDocument = async (document: any) => {
    setCurrentDocument(document);
    await dispatch(getOrdersDocumentsById(document?.fileId));
    setShowDocument(true);
  }
  
  const {
    role
  } = useAppSelector(state => state.auth);

  useEffect(() => {
    dispatch(getExecutors({ orderExecutorTypes: Object.keys(ExecutorsTypes) }));
  }, []);

  const isAdmin = role === 'ADMIN';

  const showEditForm = (type: string) => {
    showDialog(true);
    switch(type) {
      case 'paymentStatus':
        setCurrentDialog(
          <OrderPaymentForm
            currentStatus={order?.order?.paymentStatus}
            submitAction={ (data) => { editData('paymentStatus', data) } }
          />
        );
        return;
      case 'status':
          setCurrentDialog(
            <OrderStatusForm
              currentStatus={order?.order?.paymentStatus}
              submitAction={ (data) => { editData('status', data) } }
            />
          );
          return;
      case 'land':
        setCurrentDialog(
          <OrderLandForm
            land={order.land}
            submitAction={ editLand }
          />
        );
        return;
      case 'documents':
        setCurrentDialog(
          <OrderDocumentsForm
            currentDocuments={order?.documents}
            submitAction={ (data) => { changeDocuments(data) } }
          />
        );
        return;
      case 'executors':
        setCurrentDialog(
          <OrderExecutorsForm
            currentExecutors={order?.executors}
            executors={ executors }
            submitAction={ (data) => { 
              showDialog(false);
              dispatch(assignExecutors({ id:order?.order.id, params: data }));
              setTimeout(() => {
                dispatch(getById(order.order.id));
              }, 1500);
            } }
          />
        );
        return;
      default:
        setCurrentDialog(<></>);
        return;
    }
  }

  const editLand = (newLand) => {
    showDialog(false);
    dispatch(editLandData({id: order.order.id, params: newLand}));
  }

  const editData = (field, data) => {
    showDialog(false);
    const params = {};
    params[field] = data;

    dispatch(edit({
      id: order.order.id,
      params
    }))
  }

  const changeDocuments = async (data) => {
    showDialog(false);

    data.forEach(async document => {
      switch(document.type) {
        case 'edit':
          dispatch(editDocuments({
            id: order?.order?.id,
            params: {
              id: document.id,
              name: document.name,
              link: document.link
            }
          }));
          return;
        case 'new':
          dispatch(addDocuments({
            id: order?.order?.id,
            params: {
              name: document.name,
              link: document.link
            }
          }));
          return;
        case 'removed':
          await dispatch(removeDocuments({
            orderId: order?.order?.id,
            documentId: document.id
          }));
          dispatch(getById(order?.order?.id));
          return;
        default:
          return;
      }
    })

    setTimeout(() => {
      dispatch(getById(order.order.id));
    }, 1500);
  }

  const changeVisibility = async (id: string) => {
    await dispatch(changeVisibilityForCustomer(id));
    dispatch(getById(order.order.id));
  }

  const isShowVisibilityButton = (document: any) => {
    if (document?.status === 'WAITING_FOR_SENDING' ||
      document?.status === 'WAITING_FOR_SIGNING'
    ) {
      return true;
    }
    return false;
  }

  return (
    <div className='order-detail_inputs order-detail_inputs_block order--inputs flex align-items-center justify-content-center'>
      <Dialog visible={isShowDialog} style={{ width: '50vw' }} onHide={() => showDialog(false)} className='order--dialog'>
          { currentDialog }
      </Dialog>
      <>
        <Dialog
          visible={ isShowDocument }
          onHide={ () => setShowDocument(false) }
          header=""
          className='documents-modal documents__modal'
        >
          <DocumentsPreview />
          {
            currentDocument?.status === 'WAITING_FOR_SENDING' &&
            <div className="documents-modal__button">Ожидает отправки</div>
          }
          {
            currentDocument?.status === 'ONLY_FOR_READING' &&
            <div className="documents-modal__button">Только для чтения</div>
          }
          {
            currentDocument?.status === 'WAITING_FOR_SIGNING' &&
            <div className="documents-modal__button">Ожидает подписания</div>
          }
          {
            currentDocument?.status === 'FIRST_PARTICIPANT_SIGNED' &&
            <div className="documents-modal__button">1 подписант</div>
          }
          {
            currentDocument?.status === 'SECOND_PARTICIPANT_SIGNED' &&
            <div className="documents-modal__button">2 подписанта</div>
          }
        </Dialog>
      </>
      <div className='order-detail_inputs--list col-12'>
        <Accordion activeIndex={0}>
            { isAdmin &&
              <AccordionTab
                header='Статус оплаты'
              >
                <ul>
                  <li>Статус: { paymentStatus[order?.order?.paymentStatus] }</li>
                </ul>
                <div className='order-detail_inputs--buttons order--inputs_buttons'>
                  <button onClick={ () => { showEditForm('paymentStatus') }}>Редактировать</button>
                </div>
              </AccordionTab>
            }
            { isAdmin &&
              <AccordionTab
                header='Статус заказа'
              >
                <ul>
                  <li>Статус: { orderStatus[order?.order?.status] }</li>
                </ul>
                <div className='order-detail_inputs--buttons order--inputs_buttons'>
                  <button onClick={ () => { showEditForm('status') } }>Редактировать</button>
                </div>
              </AccordionTab>
            }
            <AccordionTab
              header='Участок'
            >
              <ul>
                <li>Регион: { order?.land?.region }</li>
                <li>Населенный пункт: { order?.land?.locality }  </li>
                <li>Район: { order?.land?.district }</li>
                <li>Кадастровый номер: { order?.land?.cadastralNumber } </li>
              </ul>
              { isAdmin &&
                <div className='order-detail_inputs--buttons order--inputs_buttons'>
                  <button onClick={ () => { showEditForm('land') }}>Редактировать</button>
                </div>
              }
            </AccordionTab>
            <AccordionTab
              header='Документы'
            >
              <ul className='order-detail_inputs--list_documents'>
                {
                  order?.documents?.map((document, key) => {
                    return  <li className='order-detail_inputs--list_documents-item flex align-items-center flex-wrap' key={key}><PdfDocumentIcon />
                      <span onClick={ () => { showDocument(document) } }>
                        <a href={document.link} target='_blank'> { document.name }</a>
                        
                        {
                          document?.status === 'WAITING_FOR_SENDING' &&
                          <p><DocumentsAwaitingSignature /> Ожидает отправки </p>
                        }
                        {
                          document?.status === 'WAITING_FOR_SIGNING' &&
                          <p><CheckMarkEmptyIcon /> Ожидает подписания </p>
                        }
                        {
                          document?.status === 'ONLY_FOR_READING' &&
                          <p><ReadonlyMarkIcon /> Только для чтения </p>
                        }
                        {
                          document?.status === 'FIRST_PARTICIPANT_SIGNED' &&
                          <p><CheckMarkFilledIcon /> 1 подписант </p>
                        }
                        {
                          document?.status === 'SECOND_PARTICIPANT_SIGNED' &&
                          <p><DocumentsAwaitingSignature /> 2 подписанта </p>
                        }
                      </span>
                      { (isAdmin && isShowVisibilityButton(document)) &&
                        <div
                          className='order-detail_inputs--list_documents-eye'
                          onClick={ () => { changeVisibility(document?.fileId) } }
                        >
                          {
                            document?.visibleForOwner &&
                            <DocumentEyeShow />
                          }
                          {
                            !document?.visibleForOwner &&
                            <DocumentEyeNoshow />
                          }
                        </div>
                      }
                    </li>;
                  })
                }
              </ul>
              { isAdmin &&
                <div className='order-detail_inputs--buttons order--inputs_buttons'>
                  <button onClick={ () => { showEditForm('documents') }}>Редактировать</button>
                </div>
              }
            </AccordionTab>
            {
              isAdmin &&
              <AccordionTab
                header='Заказчик'
              >
                <ul>
                  <li>Имя: { order?.customer?.name }</li>
                  <li>Телефон: { order?.customer?.phoneNumber } </li>
                </ul>
              </AccordionTab>
            }
            {
              isAdmin &&
              <AccordionTab
                header='Менеджер'
              >
                <ul>
                  <li>Имя: { order?.manager?.name }</li>
                  <li>Телефон: { order?.manager?.phoneNumber } </li>
                </ul>
              </AccordionTab>
            }
            {
              isAdmin &&
              <AccordionTab
                header='Исполнители'
              >
                <ul>
                  {
                    order?.executors?.map((executor, key) => {
                      return <li key={key}><span>{ PartnerTypes[executor.executorType] }: </span> { executor.orgName } </li>
                    })
                  }
                </ul>
                <div className='order-detail_inputs--buttons order--inputs_buttons'>
                  <button onClick={ () => { showEditForm('executors') }}>Редактировать</button>
                </div>
              </AccordionTab>
            }
            { !isAdmin &&
              <AccordionTab
                header='Контакты'
              >
                <div className='order-detail_inputs--list col-12'>
                  <ul className='order-detail_inputs--list_contact'>
                    <li><span>Застройщик:</span> </li>
                    <li><span>Менеджер</span></li>
                    <li>Имя: { order?.manager?.name }</li>
                    <li>Номер телефона: { order?.manager?.phoneNumber }</li>
                    <li>
                      <Button
                        className='order-detail_inputs--list_contact__button'
                        label="Перейти в чат"
                      />
                    </li>
                  </ul>
                </div>
              </AccordionTab>
            }
          </Accordion>
      </div>
    </div>
  )
}
