import React, { FC, useEffect, useState } from 'react';

import staticData from 'common/static.json';

import { ICommercialNewCategorie } from 'store/commercial/types';

import { CloseIcon } from 'assets/icons';

export const CategoryNewModal: FC<{
  onSave: (categories: ICommercialNewCategorie[]) => void,
  onClose: () => void}> = ({ onSave, onClose }) => {
  const [newCategories, addNewCategories] = useState([{ name: '' }]);

  const changeCategoryName = (
    index: number,
    value: string
  ) => {
    const tempArray = newCategories;
    tempArray[index].name = value;
    addNewCategories([...tempArray]);
  }

  const defaultData = [{
    key: 'Наименование',
    value: 'Наименование'
  }, {
    key: 'Тех. задание',
    value: 'Тех. задание'
  }, {
    key: 'Минимальное кратное кол-во в заказе',
    value: '10'
  },{
    key: 'Срок изготовления, дней',
    value: '10'
  },{
    key: 'Максимальная цена за единицу товара, ₽',
    value: '10'
  }];

  return (
    <div className="categories-modal__body">
      <h2 className="categories-modal__title">
        Категория
      </h2>
      <form className='categories-modal__form categories-modal-form' onSubmit={()=>{}}>
        {
          newCategories.map((category, key) => {
            return <label key={ key } className='categories-modal-form__lable categories-modal-form-lable'>
                      Наименование категории
                      <input
                        value={category.name}
                        type='text'
                        className='categories-modal-form__input'
                        onChange={e => changeCategoryName(key, e?.target?.value)}
                      ></input>
                      { key !== 0 &&
                        <div
                          className='categories-modal-form-lable__close'
                          onClick={ () => { addNewCategories([...newCategories.splice(key, 1)])} }
                        >
                          <CloseIcon />
                        </div>
                      }
                    </label>
          })
        }
      </form>

        {/* <p onClick={ () => { addNewCategories([...newCategories.concat({ name: '' })]) }} className="categories-modal__text categories-modal__text--underline">
          Добавить категорию
        </p> */}

        <div className='categories-modal-buttons'>
          <button onClick={ () => onClose() } className='categories-modal-buttons__button'>Отменить</button>
          <button onClick={ () => {
            onSave(newCategories);
          } }className='categories-modal-buttons__button categories-modal-buttons__button--filled'>Далее</button>
        </div>
      </div>
  )
};