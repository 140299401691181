import { createAsyncThunk } from '@reduxjs/toolkit';

import type { IUser } from 'models/user-auth.model';
import { authApi } from 'services/api/authApi';
import { userApi } from 'services/api/userApi';

import {
  AUTH_REGISTRATION,
  AUTH_LOGIN,
  AUTH_USER,
  AUTH_PASSWORD_RESTORE,
  AUTH_SET_PASSWORD,
  AUTH_REQUEST_EMAIL_CONFIRMATION,
  AUTH_EMAIL_CONFIRMATION,
  AUTH_REFRESH_TOKEN,
  ILoginData
} from './types'

export const registration = createAsyncThunk(
  AUTH_REGISTRATION,
  async (user:IUser) => {
    const response = await authApi.registration(user);
    return response;
  }
);

export const login = createAsyncThunk(
  AUTH_LOGIN,
  async (user:ILoginData) => {
    const response = await authApi.login(user);
    return response;
  }
);

export const getUser = createAsyncThunk(
  AUTH_USER,
  async () => {
    const response = await userApi.getUser();
    return response;
  }
);

export const restorePassword = createAsyncThunk(
  AUTH_PASSWORD_RESTORE,
  async (email:any) => {
    const response = await authApi.passwordRestore(email);
    return response;
  }
);

export const setPassword = createAsyncThunk(
  AUTH_SET_PASSWORD,
  async (data:any) => {
    const response = await authApi.setPassword(data);
    return response;
  }
);

export const requestEmailConfirmation = createAsyncThunk(
  AUTH_REQUEST_EMAIL_CONFIRMATION,
  async (id) => {
    const response = await authApi.requestEmailConfirmation(id);
    return response;
  }
);

export const emailConfirmation = createAsyncThunk(
  AUTH_EMAIL_CONFIRMATION,
  async (token) => {
    const response = await authApi.emailConfirmation({ token });
    return response;
  }
);

export const refreshToken = createAsyncThunk(
  AUTH_REFRESH_TOKEN,
  async (refreshToken: string) => {
    const response = await authApi.refreshToken(refreshToken);
    return response;
  }
);