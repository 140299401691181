import { Button } from 'primereact/button';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { AccountType } from 'store/profile/types';
import { getUser, updateUser } from 'store/profile/actions';
import { dropState } from 'store/auth/reducer';
import { profileSlice, clearStore } from 'store/profile/reducer';
import { useAppDispatch, useAppSelector } from 'store';
import ProfileHeader from './ProfileHeader';
import ProfileInfo from './ProfileInfo';
import IndividualData from './IndividualData';
import LegalData from './LegalData';
import BlankData from './BlankData';

import photo from '../../assets/images/blank-avatar.png';
import { FormatTo, formatDate } from 'utils/formatDate';
import { Dialog } from 'primereact/dialog';
import WarningDialog from 'components/common/WarningDialog';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { TOKEN_STORAGE_KEY, REFRESH_TOKEN_STORAGE_KEY } from 'common/constants';
import { useNavigate } from 'react-router';
import { regions } from 'store/ui/actions';

import { setAccountDialog } from 'store/profile/reducer';

import { routesPath } from 'routes';

const Profile: React.FC = () => {
  const [token, setValue] = useLocalStorage(TOKEN_STORAGE_KEY, '');

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { setIsEditing, setWarningDialog } = profileSlice.actions;
  const {
    isEditing,
    passwordEditing,
    common,
    individualUser,
    legalUser,
    warningDialog,
    accountDialog,
  } = useAppSelector(state => state.profile);
  const { id } = useAppSelector(state => state.auth);

  const handleDataType = () => {
    switch (common.accountType) {
      case AccountType.individualUser:
        return <IndividualData />;
      case AccountType.legalUser:
        return <LegalData />;
      case AccountType.uncertainUser:
        return <BlankData />;
    }
  };

  const commonValues = {
    name: common.name || '',
    email: common.email || '',
    region: individualUser.region || '',
    phoneNumber: common.phoneNumber || '',
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const legalValues = {
    orgName: legalUser.orgName || '',
    ogrn: legalUser.ogrn || '',
    inn: legalUser.inn || '',
    kpp: legalUser.kpp || '',
    bankName: legalUser.bankName || '',
    bankAccount: legalUser.bankAccount || '',
    bic: legalUser.bic || '',
    correspondentAccount: legalUser.correspondentAccount || '',
    regions: legalUser.regions || [],
    responsiblePersonName: legalUser.responsiblePersonName || '',
    authorityGround: legalUser.authorityGround || '',
    legalAddress: legalUser.legalAddress || '',
  };

  const individualValues = {
    passport:
      `${individualUser.passportSeries} ${individualUser.passportNumber}` || '',
    passportIssuedBy: individualUser.passportIssuedBy || '',
    passportIssueDate:
      formatDate(individualUser.passportIssueDate, FormatTo.client) || '',
    residenceAddress: individualUser.residenceAddress || '',
    dateOfBirth: formatDate(individualUser?.dateOfBirth, FormatTo.client) || ''
  };

  const methods = useForm({
    mode: 'onChange',
  });
  const {
    formState: { isValid },
  } = methods;

  useEffect(() => {
    switch (common.accountType) {
      case AccountType.individualUser:
        methods.reset({ ...commonValues, ...individualValues });
        break;
      case AccountType.legalUser:
        methods.reset({ ...commonValues, ...legalValues });
        break;
      case AccountType.uncertainUser:
        methods.reset({ ...commonValues });
        break;
      default:
        break;
    }
    // methods.trigger();
  }, [accountDialog, common, individualUser, legalUser]);

  useEffect(() => {
    dispatch(clearStore());
    dispatch(regions());
    dispatch(getUser());
  }, []);

  const onSubmit = (formData: any) => {
    const data = {
      commonUserData: {
        email: formData.email || null,
        phoneNumber: formData.phoneNumber || null,
        roles: common.roles || null,
        name: formData.name || null,
        referralLink: null || null,
        accountType: common.accountType,
      },
      individualUserData: {
        passportSeries:
          (formData.passport && formData.passport.split(' ')[0]) || null,
        passportNumber:
          (formData.passport && formData.passport.split(' ')[1]) || null,
        passportIssueDate:
          (formData.passportIssueDate &&
            formatDate(formData.passportIssueDate, FormatTo.server)) ||
          null,
        passportIssuedBy: formData.passportIssuedBy || null,
        residenceAddress: formData.residenceAddress || null,
        region: formData.region || null,
        dateOfBirth: (formData.dateOfBirth &&
          formatDate(formData.dateOfBirth, FormatTo.server)) || null
      },
      legalEntityUserData: {
        orgName: formData.orgName || null,
        inn: formData.inn || null,
        kpp: formData.kpp || null,
        ogrn: formData.ogrn || null,
        bankName: formData.bankName || null,
        bankAccount: formData.bankAccount || null,
        correspondentAccount: formData.correspondentAccount || null,
        bic: formData.bic || null,
        regions: formData.regions || null,
        responsiblePersonName: formData.responsiblePersonName || null,
        authorityGround: formData.authorityGround ? formData.authorityGround : null,
        legalAddress: formData.legalAddress || null,
      },
    };

    dispatch(updateUser({ id, data })).then(() => {
      dispatch(getUser());
    });
    methods.reset();
    dispatch(setIsEditing());
    dispatch(setWarningDialog());
  };

  const handleCancel = () => {
    methods.reset();
    dispatch(setIsEditing());
  };

  const handleEdit = () => {
    common.accountType === AccountType.uncertainUser
      ? dispatch(setAccountDialog())
      : dispatch(setIsEditing());
  };

  const handleWarning = (e: any) => {
    e.preventDefault();
    methods.trigger();
    isValid && dispatch(setWarningDialog());
  };

  const handleLogOut = async () => {
    await dispatch(dropState());
    //setValue(null);
    window.localStorage.removeItem(TOKEN_STORAGE_KEY);
    window.localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
    window.location.replace(window.location.origin);
  };

  return (
    <div>
      <Dialog
        showHeader={false}
        visible={warningDialog}
        onHide={() => {}}
        className='profile-dialog'
      >
        <WarningDialog
          confirm={methods.handleSubmit(onSubmit)}
          close={() => {
            dispatch(setWarningDialog());
          }}
        />
      </Dialog>
      <div className='profile profile-desktop p-4 w-full flex flex-column'>
        <ProfileHeader />
        <FormProvider {...methods}>
          <form className='profile-form pt-3 flex gap-6 w-full align-items-start'>
            <img className='profile-photo' src={photo} alt='user avatar' />
            <div className='profile flex flex-column w-full gap-2'>
              <ProfileInfo />
              {handleDataType()}
              {isEditing && (
                <div className='m-3 align-self-end flex gap-5 '>
                  <Button
                    className='button-white'
                    label='Отменить'
                    onClick={handleCancel}
                  />
                  <Button
                    className='button-white'
                    label='Сохранить'
                    disabled={!methods.formState.isDirty}
                    onClick={handleWarning}
                  />
                </div>
              )}
            </div>
          </form>
        </FormProvider>
        {!isEditing && !passwordEditing && (
          <div
            className='profile-button__edit'
            onClick={handleEdit}
          >Редактировать</div>
          )}

        {!isEditing && !passwordEditing && (
          <Button
            className='button-white mt-7 align-self-end'
            label='Выйти'
            onClick={handleLogOut}
          />
        )}
      </div>

      <div className='profile-mobile'>
        <div className='profile flex flex-column gap-3 p-4'>
          <ProfileHeader />
          <FormProvider {...methods}>
            <form className='flex flex-column'>
              <ProfileInfo />
              {handleDataType()}
              {isEditing && (
                <div className='m-3 align-self-end flex gap-5 '>
                  <Button
                    className='button-white'
                    label='Отменить'
                    onClick={handleCancel}
                  />
                  <Button
                    className='button-white'
                    label='Сохранить'
                    onClick={handleWarning}
                  />
                </div>
              )}
            </form>
          </FormProvider>
        </div>
        {!isEditing && !passwordEditing && (
          <Button
            className='button-white mt-5 align-self-end'
            label='Выйти'
            onClick={handleLogOut}
          />
        )}
      </div>
    </div>
  );
};

export default Profile;
