import { TabPanel, TabView } from 'primereact/tabview';
import { Dialog } from 'primereact/dialog';
import { useAppSelector } from 'store';
import ChangePassword from './ChangePassword';
import staticData from 'common/static.json';
import AccountTypeSelection from 'components/common/AccountTypeSelection';

const text = staticData.profile;

function BlankData() {
  const { accountDialog } = useAppSelector(state => state.profile);

  return (
    <>
      <TabView className='profile-tabs'>
        <TabPanel header={text.password} headerClassName='profile-tabs-header'>
          <ChangePassword />
        </TabPanel>
      </TabView>
      <Dialog
        className='profile-dialog'
        showHeader={false}
        visible={accountDialog}
        onHide={() => {}}
      >
        <AccountTypeSelection />
      </Dialog>
    </>
  );
}

export default BlankData;
