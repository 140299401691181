import { FC, useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from 'store';
import { IProjectItem } from 'store/projects/types';
import { useParams } from 'react-router-dom';
import { ProjectArea, ProjectFloor, ProjectFront } from 'assets/icons';
import { MarketDetail } from './MarketDetail'; 
import { MarketCalculator } from './MarketCalculator'; 
import { InvestmentCalculator } from './InvestmentCalculator'; 
import { MarketProgramSlider } from './MarketProgramSlider';

import { BackIcon } from 'assets/icons';

import { useNavigate } from 'react-router-dom';

import { Slider } from 'components/common/Slider';
import { navigationList } from 'components/Market/types';

import { fetchProjectTree, fetchProjectById } from 'store/projects/actions';

import { routesPath } from 'routes';

export const MarketPage: FC = () => {
  const dispatch = useAppDispatch();
  const params = useParams();

  const navigate = useNavigate();

  const { id } = params;

  useEffect(() => {
    dispatch(fetchProjectTree({ 
      projectId: id,
      selectedOptions: []
    }));
    dispatch(fetchProjectById(id))
  }, [id]);

  return (
    <div className='project'>
      <div className="project__header">
        <button className='project__back project-back'>
          <div className="project-back__icon"><BackIcon /></div>
          <span className="project-back__text" onClick={ () => { navigate(routesPath.markets) } }>Назад</span>
        </button>

        <Slider
          sliderAction={ (value) => { changeType(value) } }
          slides={ navigationList }
          customClass={ 'project' }
        />
      </div>
      
      <div className="project__wrapper">

        <MarketDetail />

        <MarketProgramSlider />

        <MarketCalculator />

        {/* <InvestmentCalculator /> */}

      </div>
    </div>
  );
};