import { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useAppDispatch, useAppSelector } from 'store';
import { PencilIcon } from 'assets/icons';
import { profileSlice } from '../../store/profile/reducer';
import { AccountType } from 'store/profile/types';
import { useNavigate } from 'react-router';
import { Dialog } from 'primereact/dialog';
import { getOffer } from 'store/commercial/actions';
import { OffersSelf } from 'components/Offers/OfferSelf'

import { routesPath } from 'routes';

import { ProfilePersonal, ProfileMoneyBag, ProfileDocuments } from 'assets/icons';

const ProfileHeader: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { setIsEditing, setAccountDialog } = profileSlice.actions;
  const { isEditing, passwordEditing, common, role } = useAppSelector(
    state => state.profile
  );

  const { offers } = useAppSelector(
    state => state.commercial
  );
  const { isProToogler } = useAppSelector(
    state => state.ui
  );
  const [isShowOffer, setShowOffers] = useState(false);

  useEffect(() => {
    if (role === 'PROVIDER') {
      dispatch(getOffer());
    }
  }, []);

  const handleEdit = () => {
    common.accountType === AccountType.uncertainUser
      ? dispatch(setAccountDialog())
      : dispatch(setIsEditing());
  };

  const handleNavigate = () => {
    navigate('/referral');
  };

  const handleNavigateOffers = () => {
    if (offers.length) {
      setShowOffers(true)
    } else {
      navigate('/offers');
    }
  }

  const isShowRefferalLink = () => {
    let isShow = true;

    if (role === 'PROVIDER' && !isProToogler) {
      isShow = false;
      return isShow;
    }

    if (common.accountType === AccountType.uncertainUser) {
      isShow = false;
      return isShow
    }

    if (passwordEditing) {
      isShow = false;
      return isShow;
    }

    if (isEditing) {
      isShow = false;
      return isShow;
    }

    return isShow;
  }

  return (
    <div>
      <div className='profile-desktop'>
        <Dialog className='offers--dialog' visible={isShowOffer} style={{ width: '100% !important', height: '100% !important', left: '0 !important' }} onHide={() => setShowOffers(false)}>
          <OffersSelf closeAction={ () => { setShowOffers(false) }} />
        </Dialog>
        <div className='profile flex flex-row justify-content-between align-items-center'>
          {/* <div className='flex flex-row gap-2 align-items-center'>
            <span className='font-bold text-2xl'>Личные данные</span>
            {!isEditing && !passwordEditing && (
              <PencilIcon
                className='profile-header-pencil'
                onClick={handleEdit}
              />
            )}
          </div>
          { isShowRefferalLink() && (
              <Button
                label='Реферальная программа'
                className='button-green'
                onClick={handleNavigate}
              />
            )} */}

            {/* для юр лица с типом заказчик */}
            { role === 'CUSTOMER' &&
              <div className="profile-slider">
                <div className="profile-slider__item profile-slider-item">
                  <span className="profile-slider-item__text">Личные данные</span>
                  <ProfilePersonal className="profile-slider-item__img profile-slider-item__img--active"/>
                </div>
                <div className="profile-slider__item profile-slider-item" onClick={ () => navigate(routesPath.referral)}>
                  <span className="profile-slider-item__text">Реферальная программа</span>
                  <ProfileMoneyBag  className="profile-slider-item__img"/>
                </div>
                <div className="profile-slider__item profile-slider-item" onClick={ () => navigate(routesPath.userDocuments)}>
                  <span className="profile-slider-item__text">Документы</span>
                  <ProfileDocuments  className="profile-slider-item__img"/>
                </div>
              </div>
            }
            {/* для юр лица с типом заказчик */}

            {role === 'PROVIDER' && !isProToogler && (
              <Button
                label='Коммерческое предложение'
                className='button-green'
                onClick={ () => { setShowOffers(true) } }
              />
            )}
        </div>
      </div>
      <div className='profile-mobile'>
        <div className='profile flex flex-row justify-content-between align-items-center'>
          <div className='flex flex-row gap-2 align-items-center'>
            <span className='font-bold text-2xl'>Личные данные</span>
            {!isEditing && !passwordEditing && (
              <PencilIcon
                className='profile-header-pencil'
                onClick={handleEdit}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;
