import { FC, useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from 'store';
import { getOffers, getCategory, getOffer } from 'store/commercial/actions';
import { regions } from 'store/ui/actions';

import { LoadMoreButton } from 'components/common/LoadMoreButton';

import { routesPath } from 'routes';

import staticData from 'common/static.json';
const pageStaticText = staticData.offers.list;
const pageSize = 5;

export const OffersList: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { list, categories } = useAppSelector(state => state.commercial);
  const { regionList, regionMap } = useAppSelector(state => state.ui);

  const [currentCategoryFilter, setCurrentCategoryFilter] = useState();
  const [currentRegionFilter, setCurrentRegionFilter] = useState();

  useEffect(() => {
    dispatch(getCategory());
    dispatch(regions());
  }, []);
 
  const loadMore = (params) => {
    if (currentCategoryFilter) {
      params.categoryId = currentCategoryFilter;
    }
    if (currentRegionFilter) {
      params.region = currentRegionFilter
    }
    dispatch(getOffers(params));
  }

  useEffect(() => {
    let params = {
      pageNum: 0,
      pageSize,
    }
    if (currentCategoryFilter) {
      params.categoryId = currentCategoryFilter;
    }
    if (currentRegionFilter) {
      params.region = currentRegionFilter
    }
    dispatch(getOffers(params));
  }, [currentCategoryFilter, currentRegionFilter]);

  const openOffer = ({ categoryId, partnerId }) => {
    navigate(`/offer/${partnerId}/${categoryId}`);
  }

  return (
    <div className='offers--list'>
      <div className='offers--title'>
        <span>{ pageStaticText.title }</span>
      </div>

      <div className='offers--filters flex align-content-start flex-wrap'>
        {
          <div className='offers--filters-filter'>
            <div>
              <span>{ pageStaticText.filters[0].label }</span>
            </div>
            <Dropdown
              value={currentCategoryFilter}
              onChange={(e) => setCurrentCategoryFilter(e.value)}
              options={ categories }
              optionValue='id'
              optionLabel='name' 
              placeholder='Все'
              className='w-full md:w-14rem'
              showClear
            />
          </div>
        }
        {
          <div className='offers--filters-filter'>
            <div>
              <span>{ pageStaticText.filters[1].label }</span>
            </div>
            <Dropdown
              value={currentRegionFilter}
              onChange={(e) => setCurrentRegionFilter(e.value)}
              options={ regionList }
              optionValue='region'
              optionLabel='value'
              placeholder='Все'
              className='w-full md:w-14rem'
              showClear
            />
          </div>
        }
      </div>

      {
        list.data.map((card, key) => {
          return <div key={key} className='offers--card'>
                    <div className='offers--card_title'>
                      <span>{ card.categoryName }</span>
                    </div>
            
                    <div className='offers--card_fields'>
                      <div className='offers--card_fields-item'>
                        <span className='offers--card_fields-item_label'>{ pageStaticText.organisationName } </span>
                        <span className='offers--card_fields-item_value'>{ card.organizationName }</span>
                      </div>
                      <div className='offers--card_fields-item'>
                        <span className='offers--card_fields-item_label'>{ pageStaticText.organisationNumber } </span>
                        <span className='offers--card_fields-item_value'>{ card.phoneNumber }</span>
                      </div>
                      <div className='offers--card_fields-item flex justify-content-between flex-wrap'>
                        <div>
                          <span className='offers--card_fields-item_label'>{ pageStaticText.organisationLocation } </span>
                          <span className='offers--card_fields-item_value'>{ card.cityName }</span>
                          {
                            card?.regions.map((region, key) => {
                              return <span key={ key } className='offers--card_fields-item_value'>{ regionMap[region] }</span>
                            })
                          }
                        </div>
                        <button onClick={ () => { openOffer({ partnerId: card.partnerId, categoryId: card?.categoryId }) }}>
                          { pageStaticText.buttonText }
                        </button>
                      </div>
                    </div>
                  </div>
        })
      }
      <LoadMoreButton
        loadAction={ loadMore }
        start={ 0 }
        step={ 5 }
        size={ list.size }
      />
    </div>
  )
};