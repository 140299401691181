import { FC, useEffect, useState } from 'react';

interface IProps {
  count: number;
  buttonAction: (value: number) => void;
}

export const QuantityButton: FC<IProps> = ({ count, buttonAction }) => {
  const minusAction = () => {
    if (count > 1) {
      buttonAction(count - 1);
    }
  }

  const plusAction = () => {
    buttonAction(count + 1);
  }

  return (
    <div className='button--quantity flex justify-content-between flex-wrap'>
      <span className='button--quantity_minus' onClick={() => { minusAction() }}> - </span>
      <span className='button--quantity_count'>{ count }</span>
      <span className='button--quantity_plus' onClick={() => { plusAction() } }> + </span>
    </div>
  )
};