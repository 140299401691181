import { FC, useState, useEffect } from 'react';
import FileViewer from 'react-file-viewer';

import { useAppSelector } from 'store';

export const DocumentsPreview: FC = () => {
  const [currentFile, setCurrentFile] = useState(null);

  const { currentDocument } = useAppSelector(state => state.documents);

  useEffect(() => {
    setCurrentFile(null)
  }, []);

  const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
      
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }
  
  const contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

  const downloadFile = () => {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = currentFile;
    a.download = '';
    a.click();
    window.URL.revokeObjectURL(currentFile);
  }

  useEffect(() => {
    if (currentDocument?.data) {
      const blob = b64toBlob(currentDocument?.data, contentType);
      const blobUrl = URL.createObjectURL(blob);
      setCurrentFile(blobUrl);
    }
  }, [currentDocument]);
  return (
    <>
    { currentFile &&
      <div className="documents-modal__preview">
        <div onClick={ () => { downloadFile() } } className='documents-modal__download'>Скачать файл</div>
        <FileViewer
          fileType={ 'docx' }
          filePath={currentFile}
        />
      </div>
    }
    </>
  )
};