import { BrowserRouter as Router, createBrowserRouter } from 'react-router-dom';

import { routesPath } from 'routes';

import { AuthProvider } from 'hooks/useAuth';

import { ProtectedRoute } from 'components/common/ProtectedRoute';
import { RegistrationPage } from 'components/Registration';
import { LoginPage } from 'components/Login';
import { OrdersPage } from 'components/Orders';
import { OrderPage } from 'components/Order';
import { CartPage } from 'components/Cart';
import { PasswordRestorePage } from 'components/PasswordRestore';
import { EmailConfirmation } from 'components/EmailConfirmation';
import Layout from 'components/common/Layout';
import Profile from 'components/Profile';
import Partners from 'components/Partners';
import Referral from 'components/Referral';
import CompleteData from 'components/CompleteData';
import PartnerIssue from 'components/PartnerIssue';
import { UsersPage } from 'components/Users';
import { UserPage } from 'components/User';
import { OffersPage } from 'components/Offers';
import { OfferPage } from 'components/Offer';
import { NotFoundPage } from 'components/common/NotFound';
import { ConfirmaCartPage } from 'components/Cart/ConfirmCart';
import { PartnerConfirmationPage } from 'components/PartnerConfirmation';
import { DocumentsPage } from 'components/Documents';
import { MarketsPage } from 'components/Markets';
import { MarketPage } from 'components/Market';
import { NomenclatureNewPage } from 'components/Nomenclature';  
import { VilagePage } from 'components/Village';
import { TermsPage } from 'components/Terms';

import { UserDocumentsPage } from 'components/Documents/UserDocuments'; 

export const router = createBrowserRouter([
  {
    path: '',
    element: (
      <AuthProvider>
        <Layout />
      </AuthProvider>
    ),
    children: [
      { path: routesPath.registration, element: <RegistrationPage /> },
      { path: routesPath.login, element: <LoginPage /> },
      { path: routesPath.passwordRestore, element: <PasswordRestorePage /> },
      {
        path: routesPath.ordersList,
        element: (
          <ProtectedRoute>
            <OrdersPage />
          </ProtectedRoute>
        ),
      },
      {
        path: routesPath.orderDetail,
        element: (
          <ProtectedRoute>
            <OrderPage path=':id' />
          </ProtectedRoute>
        ),
      },
      {
        path: routesPath.cartConfirmation,
        element: (
          <ProtectedRoute>
            <ConfirmaCartPage />
          </ProtectedRoute>
        ),
      },
      {
        path: routesPath.cart,
        element: (
          <ProtectedRoute>
            <CartPage />
          </ProtectedRoute>
        ),
      },
      {
        path: routesPath.partners,
        element: (
          <ProtectedRoute>
            <Partners />
          </ProtectedRoute>
        ),
      },
      {
        path: routesPath.profile,
        element: (
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        ),
      },
      {
        path: routesPath.referral,
        element: (
          <ProtectedRoute>
            <Referral />
          </ProtectedRoute>
        ),
      },
      {
        path: routesPath.partnerConfirmation,
        element: (
          <ProtectedRoute>
            <PartnerConfirmationPage />
          </ProtectedRoute>
        ),
      },
      {
        path: routesPath.completeData,
        element: (
          <ProtectedRoute>
            <CompleteData />
          </ProtectedRoute>
        ),
      },
      {
        path: routesPath.partnerIssue,
        element: (
          <ProtectedRoute>
            <PartnerIssue />
          </ProtectedRoute>
        ),
      },
      {
        path: routesPath.users,
        element: (
          <ProtectedRoute>
            <UsersPage />
          </ProtectedRoute>
        ),
      },
      {
        path: routesPath.user,
        element: (
          <ProtectedRoute>
            <UserPage />
          </ProtectedRoute>
        ),
      },
      {
        path: routesPath.offers,
        element: (
          <ProtectedRoute>
            <OffersPage />
          </ProtectedRoute>
        ),
      },
      {
        path: routesPath.emailConfirmation,
        element: (
          <ProtectedRoute>
            <EmailConfirmation />
          </ProtectedRoute>
        ),
      },
      {
        path: routesPath.offer,
        element: (
          <ProtectedRoute>
            <OfferPage />
          </ProtectedRoute>
        ),
      },
      {
        path: routesPath.documents,
        element: (
          <ProtectedRoute>
            <DocumentsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: routesPath.userDocuments,
        element: (
          <ProtectedRoute>
            <UserDocumentsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: routesPath.markets,
        element: <MarketsPage />
      },
      {
        path: routesPath.market,
        element: <MarketPage />
      },
      {
        path: routesPath.village,
        element: <VilagePage />
      },
      {
        path: routesPath.terms,
        element: <TermsPage />
      },
      {
        path: routesPath.nomenclature,
        element: (
          <ProtectedRoute>
            <NomenclatureNewPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
]);