import { FC } from 'react';

import staticData from 'common/static.json';
import InputTest from 'components/common/InputTest';
import { validations } from 'helpers/validations';

const text = staticData.profile;
const placeholder = staticData.profile.placeholders;

export const IndividualUserData: FC = () => {
  return (
    <div className='flex flex-column gap-3'>
      <div className='cart--completion-title'>
        <span className='text-center'>Оформление заказа</span>
      </div>
      <div className='flex flex-column gap-3 justify-content-center'>
        <InputTest
          name='name'
          validations={validations.name}
          classNameInput='auth-form-input'
          classNameValid='input-valid-auth'
          classNameError='input-error-auth'
          classNameLabel='flex flex-column gap-2'
          placeholder={placeholder.name}
          label={text.fullName}
        />
        <InputTest
          name='passport'
          mask={'9999 999999'}
          validations={validations.mask}
          classNameInput='auth-form-input'
          classNameValid='input-valid-auth'
          classNameError='input-error-auth'
          classNameLabel='flex flex-column gap-2'
          placeholder={placeholder.passport}
          label={text.passport}
        />
        <InputTest
          name='passportIssuedBy'
          validations={validations.passportIssue}
          classNameInput='auth-form-input'
          classNameValid='input-valid-auth'
          classNameError='input-error-auth'
          classNameLabel='flex flex-column gap-2'
          placeholder={placeholder.passportIssuedBy}
          label={text.passportIssuedBy}
        />
        <InputTest
          name='passportIssueDate'
          mask={'99.99.9999'}
          validations={validations.mask}
          classNameInput='auth-form-input'
          classNameValid='input-valid-auth'
          classNameError='input-error-auth'
          classNameLabel='flex flex-column gap-2'
          placeholder={placeholder.passportIssueDate}
          label={text.passportIssueDate}
        />
        <InputTest
          name='residenceAddress'
          validations={validations.registrationAddr}
          classNameInput='auth-form-input'
          classNameValid='input-valid-auth'
          classNameError='input-error-auth'
          classNameLabel='flex flex-column gap-2'
          placeholder={placeholder.residenceAddress}
          label={text.residenceAddress}
        />
        <InputTest
          name='dateOfBirth'
          mask={'99.99.9999'}
          validations={validations.passportIsssuedBy}
          classNameInput='auth-form-input'
          classNameValid='input-valid-auth'
          classNameError='input-error-auth'
          classNameLabel='flex flex-column gap-2'
          placeholder={'10.10.1990'}
          label={'Дата рождения'}
        />
        <InputTest
          name='referral'
          isRequired={false}
          validations={''}
          classNameInput='auth-form-input'
          classNameValid='input-valid-auth'
          classNameError='input-error-auth'
          classNameLabel='flex flex-column gap-2'
          placeholder='Ссылка'
          label='Реферальная ссылка'
        />
        {/* <InputTest
          name='snils'
          mask='99999999999'
          validations={validations.mask}
          classNameInput='auth-form-input'
          classNameValid='input-valid-auth'
          classNameError='input-error-auth'
          classNameLabel='flex flex-column gap-2'
          placeholder={placeholder.snils}
          label={text.snils}
        />
        <InputTest
          name='inn'
          mask='9999 999999'
          validations={validations.mask}
          classNameInput='auth-form-input'
          classNameValid='input-valid-auth'
          classNameError='input-error-auth'
          classNameLabel='flex flex-column gap-2'
          placeholder={placeholder.inn}
          label={text.inn}
        /> */}
      </div>
    </div>
  );
};

