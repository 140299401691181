import { createAsyncThunk } from '@reduxjs/toolkit';

import { cartsApi } from 'services/api/cartsApi';
import {
  CARTS_LIST,
  CARTS_CHANGE,
  CARTS_REMOVE,
  CARTS_ADD
} from './types';

export const fetchCarts = createAsyncThunk(
  CARTS_LIST,
  async () => {
    const response = await cartsApi.fetchList();
    return response;
  }
);

export const changeCountCarts = createAsyncThunk(
  CARTS_CHANGE,
  async (params) => {
    const response = await cartsApi.changeCount(params);
    return response;
  }
);

export const removeCarts = createAsyncThunk(
  CARTS_REMOVE,
  async (cartElemntsId: string) => {
    const response = await cartsApi.remove(cartElemntsId);
    return response;
  }
);

export const putInCart = createAsyncThunk(
  CARTS_ADD,
  async (params: string) => {
    const response = await cartsApi.putInCart(params);
    return response;
  }
);