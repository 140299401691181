import {
  ProjectHome,
  ProjectFurniture,
  ProjectLandscape,
  ProjectAdditional
} from 'assets/icons';

export const navigationList = [{
  label: 'Дома',
  element: <ProjectHome /> 
}, {
  label: 'Мебель',
  additional: 'Скоро',
  element: <ProjectFurniture /> 
}, {
  label: 'Ландшафт',
  additional: 'Скоро',
  element: <ProjectLandscape />
}, {
  label: 'Дополнительные постройки',
  additional: 'Скоро',
  element: <ProjectAdditional />
}];