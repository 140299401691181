import { FC } from 'react';

import { useAppSelector } from 'store';
import { MarketOptions } from './MarketOptions';
import { MarketGallery } from './MarketGallery';


export const MarketDetail: FC = () => {

  return (
    <div className='project-main'>
      <MarketGallery />
      <MarketOptions />
      
    </div>
  );
};