import { FC } from 'react';

import { IDocumentData } from 'store/documents/types';
import { PdfDocumentIcon } from 'assets/icons';
import { DocumentsAwaitingSignature } from 'assets/icons';
import { CrossIcon, CheckMarkIcon, CheckMarkFilledIcon, CheckMarkFilledIcon2, CheckMarkEmptyIcon } from 'assets/icons';

export const PartnersDocumentsItem: FC<{
  document:IDocumentData,
  showDocument: (id:string) => void,
  removeFile: (id:string) => void,
  documentStatus: string
}> = ({ document, showDocument, removeFile, documentStatus }) => {
  return (
    <a onClick={ () => { showDocument(document?.fileId) } } className='documents-list__link documents-list-link'>
      <div className='documents-list-link__img'>
        <PdfDocumentIcon />
      </div>
      <div className="documents-list-link__content documents-link-content">
        <div className="documents-link-content__info">
          <span className='documents-link-content__name'>
            { document?.name }
          </span>
        </div>
      </div>
    </a>
  )
};