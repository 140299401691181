import { FC, useState, useEffect } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import moment from 'moment';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';

import InputTest from 'components/common/InputTest';
import { validations } from 'helpers/validations';

export const AmountForm: FC<{amount, submitAction}> = ({ amount, submitAction }) => {
  const [isShowCalendar, setShowCalendar] = useState(false);
  const [date, setDate] = useState(false);

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      amount: amount,
    },
  });
  const { setValue } = useForm();

  const onSubmit = (data: any) => {
    submitAction(data);
  };

  return (
    <div className='flex justify-content-center flex-wrap'>
      <FormProvider {...methods}>
        <form
          className='order--form auth-form auth-form--login p-d-flex p-jc-center p-mt-2 w-8'
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <div className='flex justify-content-center flex-wrap gap-2'>
            <span className='auth-form-title'>
              Редактировать доход с заказа
            </span>
          </div>
          <div className='flex flex-column gap-2'>
            <InputTest
              name='amount'
              type='text'
              validations={validations.required}
              classNameInput='auth-form-input auth-form-login'
              classNameLabel='auth-form-label'
              classNameValid='input-valid-auth'
              classNameError='input-error-auth'
              placeholder='1  500 00 ₽'
              label='Сумма'
            />
          </div>
          
          <div className='flex justify-content-center flex-wrap align-items-stretch'>
            <Button
              className='auth-form-login-button flex align-items-center justify-content-center'
              label='Сохранить изменения'
              type='submit'
            />
          </div>
          
        </form>
      </FormProvider>
    </div>
  );
};
