  import moment from 'moment';
  import { useNavigate } from 'react-router-dom';
  import { FC, useState, useEffect } from 'react';
  import { Button } from 'primereact/button';
  import { routesPath } from 'routes';
  
  import {
    IOrderItemData
  } from 'store/orders/types';

  export const OrderContractor: FC<{ order:IOrderItemData }> = ({ order }) => {
    const navigate = useNavigate();
    const [orderData, setOrderData] = useState({});

    const options = {
      LOGISTIC: 'Логист',
      PROVIDER: 'Поставщик',
      CONTRACTOR: 'Подрядчик',
      REALTOR: 'Риелтор',
      REPRESENTATIVE: 'Представитель',
    };

    const workType = {
      GEOLOGY: 'Геология',
      CONSTRUCTION: 'Строительство',
      FURNITURE: 'Мебель',
      REPAIR: 'Ремонт',
      TECHNICAL_SUPERVISION: 'Технадзор',
      SERVICE: 'Сервисное обслуживание'
    }

    useEffect(() => {
      if (order?.payloadData) {
        setOrderData(JSON.parse(order?.payloadData));
      }
    }, [order]);

    const openOrder = () => {
      navigate(`${routesPath.orderDetail.replace(':id',order.id )}`, { state: {
        isPartnerOrder: true,
        partnerType: 'contractor'
      }});
    }

    return (
      <div className='orders-list--list_item orders-list--list_item-role-contractor w-full'>
        <div className='flex justify-content-between flex-wrap w-full'>
          <div className='orders-list--list_item--title'>
            <span>Заказ № { order.publicId } </span><span>{ moment(order.timeCreated).format('L').replaceAll('/', '.') }</span>
          </div>
          <div className='flex justify-content-end flex-wrap'>
            <div className='orders-list--list_item--price'>
              <span> { order?.amount } ₽ </span>
            </div>
            <div className='orders-list--list_item--button'>
              <span>{ order?.status }</span>
            </div>
          </div>
        </div>

        <div className='orders-list--list_item--info orders-list-wrapper__item'>
          <div>
            <span>Заказчик: </span>
            <span>{ orderData?.customerOrganizationName }</span>
          </div>
          <div>
            <span>Номер телефона заказчика: </span>
            <span>{ orderData?.customerPhoneNumber }</span>
          </div>
          <div>
            <span>Наименование проекта: </span>
            <span>{ orderData?.projectName }</span>
          </div>
          <div className='flex justify-content-between flex-wrap'>
            <div>
              <span>Местоположение участка: </span>
              <span>{ orderData?.location }</span>
            </div>
          </div>
          <button onClick={ () => { openOrder() } } className='orders-list__button'>
            Перейти
          </button>
        </div>
      </div>
    )
  }