import { request } from './api';
import { API_ROUTES } from 'config';

export const userApi = {
  updateUser: (id: string, data: any) =>
    request.put<void>(API_ROUTES.user.update.replace('{userId}', id), data),
  updatePassword: (id: string, data: any) =>
    request.put<void>(
      API_ROUTES.user.updatePassword.replace('{userId}', id),
      data
    ),
  getUser: () => request.get<void>(API_ROUTES.user.fetch),
  getUserById: (id: string) =>
    request.get<void>(API_ROUTES.user.byId.replace('{userId}', id)),
  getAllUsers: (params: object) =>
    request.get<void>(API_ROUTES.user.all, params),
  createLegal: (id: string, data: any) =>
    request.post<void>(
      API_ROUTES.user.createLegal.replace('{userId}', id),
      data
    ),
  getUserDocuments: (params: any) => request.get<void>(API_ROUTES.documents.getAllUserDocuments, params),
};
