const defaultMessage = 'Это поле обязательно для заполнения';

export const validations = {
  name: {
    required: defaultMessage,
    minLength: {
      value: 5,
      message: 'Поле должно содержать как минимум 5 символов',
    },
    pattern: {
      value: /^[а-яА-Я\s-]+$/,
      message: 'Это поле может содержать только кириллицу, тире и пробелы',
    },
  },
  passportIssue: {
    required: defaultMessage,
    pattern: {
      value: /^[а-яё\d\s\-\.,'"]+$/i,
      message:
        'Это поле может содержать только кириллицу, цифры и символы "".,-',
    },
    minLength: {
      value: 10,
      message: 'Поле должно содержать как минимум 10 символов',
    },
  },
  bankName: {
    required: defaultMessage,
    pattern: {
      value: /^[а-яё\d\s\-\.,'"]+$/i,
      message:
        'Это поле может содержать только кириллицу, цифры и символы "".,-',
    },
    minLength: {
      value: 5,
      message: 'Поле должно содержать как минимум 5 символов',
    },
  },
  orgName: {
    required: defaultMessage,
    minLength: {
      value: 5,
      message: 'Поле должно содержать как минимум 5 символов',
    },
    pattern: {
      value: /^(?=.*[а-яё])[а-яё\d\p{P}\s]+$/iu,
      message: 'Это поле может содержать только кириллицу, цифры и спецсимволы',
    },
  },
  ogrn: {
    required: defaultMessage,
    pattern: {
      value: /^\d{13}$|^\d{15}$/,
      message: 'Поле может содержать только цифры, 13 либо 15 штук',
    },
  },
  kpp: {
    required: defaultMessage,
    pattern: {
      value: /^\d{9}$|^0$/,
      message: 'Поле должно содержать 9 цифр (для ИП введите 0)'
    }
  },
  mask: {
    required: defaultMessage,
  },
};
