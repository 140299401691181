import { FC, useEffect, useState } from 'react';

import TempImage from 'assets/images/temp-project.png'

import { VillageOptions } from './VillageOptions';

import { useAppSelector } from 'store';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/scrollbar';

export const VillageOnGoing: FC = () => {
  const { villageHouse } = useAppSelector(state => state.projects);
  const { token } = useAppSelector(state => state.auth);

  const [villageImages, setVillageImages] = useState([]);

  useEffect(() => {
    const images = villageHouse?.projectConfiguration?.images?.map((img) => {
      return img?.fileInfo?.fileUrl;
    })
    setVillageImages(images);
  }, [villageHouse]);
  return (
    <div className='village-main'>
      <div className='village-gallery'>
        <div className="village-gallery__wrapper">
          <span className="village-gallery__lable">{ villageHouse?.finished ? 'Построен' : 'Строится' } </span>
          <Swiper
              loop={true}
              scrollbar={{
                hide: true,
              }}
              breakpoints={{
                320: {
                  slidesPerView: "auto",
                  spaceBetween: 8
                },
                530: {
                  slidesPerView: 1,
                  spaceBetween: 8
                },
                744: {
                  slidesPerView: 1,
                  spaceBetween: 8,
                },
                1050: {
                  slidesPerView: 1,
                  spaceBetween: 8,
                },
                1280: {
                  slidesPerView: 1,
                  spaceBetween: 8,
                },
                1400: {
                  slidesPerView: 1,
                  spaceBetween: 48,
                }
              }}
              modules={[Scrollbar]}
            >
              <div>
                {
                  villageImages?.map((img) =>{ 
                    return <SwiperSlide style={{width: '100% !important'}}>
                      <img className='village-gallery__img' src={ img } />
                    </SwiperSlide>
                  })
                }
              </div>
            </Swiper>
          {/* <div className='village-gallery__buttons village-gallery-buttons'>
            <button className='village-gallery-buttons__item village-gallery-buttons__item--active'>Планировка</button>
            <button className='village-gallery-buttons__item'>Фасад</button>
          </div> */}
        </div>
        <div className='village-main__description village-description'>
            <p className="village-description__text">
              { villageHouse?.projectData?.description }
            </p>
          </div>
      </div>

      <VillageOptions />
    </div>
  );
};