import { FC } from 'react';


export const TermsAdvertising: FC = () => {
  return (
    <div className='terms-advertising'>
      <h1 className="terms-advertising__title">Согласие на обработку персональных данных</h1>
      <p className="terms-advertising__desc">
        В соответствии с Федеральным законом от 13.03.2006 № 38-ФЗ «О рекламе» и Федеральным законом от 07.07.2003 г. № 126-ФЗ «О связи», настоящим Я, действуя по своей воли и в своем интересе, даю свое согласие Обществу с ограниченной ответственностью «КАЛИТКА» (Российская Федерация, 630004, Новосибирская область, г. Новосибирск, пр-ктДимитрова, д. 4/1; ОГРН 1245400015190; ИНН 5406840176) (далее - Общество) на направление мне на указанные мной на сайте https://kalitka.moscow, контактные данные (номер телефона и/или электронную почту) сообщений в информационных, рекламно-информационных целях об услугах (сервисах) Общества, а именно: рассылок о мероприятиях, контенте, акциях и др. информационного и рекламного характера, в виде sms-сообщений, и/или электронных писем, и/или сообщений в мессенджерах, и/или push-уведомлений, и/или посредством телефонных звонков.
      </p>

      <p className="terms-advertising__desc">
        Я согласен(а) с тем, что текст данного мной по собственной воле и в моих интересах согласия хранится в электронном виде в базе данных и беру на себя ответственность за достоверность предоставленных данных.
      </p>

      <p className="terms-advertising__desc">
        Я подтверждаю, что владею информацией о том, что в любой момент в течение всего срока действия настоящего согласия, я вправе отозвать согласие и отписаться от получения рассылок путем направления отзыва согласия по адресу: Российская Федерация, 630004, Новосибирская область, г.Новосибирск, пр-кт Димитрова, д. 4/1, или по электронной почте info@kalitka.moscow, или путем изменения настроек в личном кабинете, или путем нажатия ссылки «отписаться» в конце электронного письма, содержащего информацию рекламного характера, направленного на указанный мной адрес электронной почты.
      </p>

      <p className="terms-advertising__desc">
        Также я информирован(-а), что при возникновении вопросов относительно отказа от рассылки, я могу обратиться за помощью, отправив письмо в службу технической поддержки Общества (support@kalitka.moscow).
      </p>

      <p className="terms-advertising__desc">
        Я также подтверждаю, что предоставленные мною в адрес Общества номер телефона и/или адрес электронной почты принадлежат мне, а направляемые на них рекламно-информационные сообщения предназначены только для меня и не будет получаться третьими лицами, не давшими Обществу предварительного согласия на получение таких сообщений.
      </p>

      <p className="terms-advertising__desc">
        Настоящее согласие действует до момента отзыва в порядке, указанном в согласии, либо до момента отзыва моего согласия на обработку персональных данных Обществом.
      </p>

    </div>
  );
};