import { FC } from 'react';

import staticData from 'common/static.json';
import InputTest from 'components/common/InputTest';

import { validations } from 'helpers/validations';

const text = staticData.profile;
const placeholder = staticData.profile.placeholders;

export const LegalUserFinalData:FC = () => {
  return (
    <div className='flex flex-column gap-3'>
      <div className='cart--completion-title'>
        <span className='text-center'>Оформление заказа</span>
      </div>
      <div className='flex flex-column gap-3 justify-content-center'>
        <InputTest
          name='bankName'
          validations={validations.bankName}
          classNameInput='auth-form-input'
          classNameValid='input-valid-auth'
          classNameError='input-error-auth'
          classNameLabel='flex flex-column gap-2'
          placeholder={placeholder.bankName}
          label={text.bankName}
        />
        <InputTest
          name='bankAccount'
          mask='99999999999999999999'
          validations={validations.mask}
          classNameInput='auth-form-input'
          classNameValid='input-valid-auth'
          classNameError='input-error-auth'
          classNameLabel='flex flex-column gap-2'
          placeholder={placeholder.bankAccount}
          label={text.bankAccount}
        />
        <InputTest
          name='correspondentAccount'
          mask='30101999999999999999'
          validations={validations.mask}
          classNameInput='auth-form-input'
          classNameValid='input-valid-auth'
          classNameError='input-error-auth'
          classNameLabel='flex flex-column gap-2'
          placeholder={placeholder.correspondentAccount}
          label={text.correspondentAccount}
        />
        <InputTest
          name='bic'
          mask='999999999'
          validations={validations.mask}
          classNameInput='auth-form-input'
          classNameValid='input-valid-auth'
          classNameError='input-error-auth'
          classNameLabel='flex flex-column gap-2'
          placeholder={placeholder.bic}
          label={text.bic}
        />
         <InputTest
          name='referral'
          validations={''}
          isRequired={false}
          classNameInput='auth-form-input'
          classNameValid='input-valid-auth'
          classNameError='input-error-auth'
          classNameLabel='flex flex-column gap-2'
          placeholder='Ссылка'
          label='Реферальная ссылка'
        />
      </div>
    </div>
  );
};
