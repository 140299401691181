import { FC } from 'react';


export const TermsOfUse: FC = () => {
  return (
    <div className='terms-use'>
      <h1 className="terms-use__title">Пользовательское соглашение</h1>
      <p className="terms-use__desc">
        Настоящее Пользовательское соглашение (далее по тексту — «Соглашение») 
        регламентирует отношения между Общество с ограниченной ответственностью «КАЛИТКА» 
        (далее по тексту — «Компания») — создателем и правообладателем информационного сайта, 
        и любым лицом (далее по тексту — «Пользователь») по использованию сайта, находящегося 
        по адресу https://kalitka.moscow (далее — Сайт).
      </p>
      <p className="terms-use__desc">
        Настоящее Соглашение является публичной офертой Компании. Используя Сайт, в том числе, 
        путем просмотра страниц и материалов Сайта, Пользователь подтверждает, что он ознакомлен 
        и согласен с Правовой информацией Сайта и настоящим Пользовательским соглашением и означает 
        присоединение Пользователя к настоящему Соглашению и безоговорочное принятие его условий.
      </p>

      <div className="terms-use__info terms-use-info">
        <p className="terms-use-info__title">Общие положения</p>
        <ol className="terms-use-info__list terms-use-info-list">
          <li className="terms-use-info-list__item">Настоящее пользовательское Соглашение распространяется на всех Пользователей Сайта, независимо от цели использования Сайта, и представляет собой публичную оферту в соответствии со ст. 437 Гражданского кодекса РФ. Действие пользовательского соглашения распространяется на Пользователей с момента ввода Имени пользователя (логина) и Пароля. Нажатие кнопки «Зарегистрироваться» обозначает полное и безоговорочное согласие Пользователя с настоящим пользовательским Соглашением, (акцепт оферты) в соответствии со ст. 438 Гражданского кодекса РФ. В случае несогласия с пользовательским Соглашением, Пользователь обязуется прекратить просмотр или пользование услугами Сайта, а также удалить любые материалы, размещенные им на Сайте.</li>
          <li className="terms-use-info-list__item">Действующая редакция пользовательского Соглашения постоянно размещена по URL-адресу: https://kalitka.moscow/dokumenti/. Компания оставляет за собой право изменять или дополнять настоящее пользовательское Соглашение в любой момент без предварительного или последующего уведомления. Пользователь самостоятельно отслеживает изменения Соглашения и знакомится с действующей редакцией пользовательского соглашения. Продолжение использования Сайта Пользователем после внесения изменений и/или дополнений в настоящее Соглашение означает принятие и согласие Пользователя с такими изменениями и/или дополнениями.</li>
          <li className="terms-use-info-list__item">При регистрации Пользователь обязан сообщить Компании и проверить точность своих данных: фамилию, имя и отчество, e-mail, номер телефона. В случае ошибок или несоответствия части данных следует обратиться в Службу технической поддержки Компании по адресу электронной почты support@kalitka.moscow и/или изменить их самостоятельно в личном кабинете пользователя.</li>
          <li className="terms-use-info-list__item">Принятием настоящего Соглашения Пользователь дает согласие Компании на обработку своих персональных данных, переданных им Компании, в соответствии с условиями настоящего Соглашения.</li>
          <li className="terms-use-info-list__item">Принятием настоящего Соглашения Пользователь дает согласие Компании на получение рекламно – информационных материалов.</li>
          <li className="terms-use-info-list__item">При регистрации Пользователь самостоятельно вводит Имя пользователя (логин) и выбранный Пароль в зашифрованном виде. Пользователь несет полную ответственность за безопасность выбранных Имени пользователя (логина) и Пароля, в частности за отсутствие к ним доступа у третьих лиц.</li>
        </ol>
      </div>

      <div className="terms-use__info terms-use-info">
        <p className="terms-use-info__title">Принимая настоящее Соглашение Пользователь:</p>
        <ol className="terms-use-info__list terms-use-info-list">
          <li className="terms-use-info-list__item">Подтверждает, что ознакомился с условиями настоящего Соглашения.</li>
          <li className="terms-use-info-list__item">Осознает, что Сайт может содержать отдельные материалы, предназначенные только для Пользователей, достигших 18 лет.</li>
          <li className="terms-use-info-list__item">Обязуется использовать Сайт в соответствии с действующими и применимыми в данной области законами.</li>
          <li className="terms-use-info-list__item">Обязуется не публиковать в открытом доступе адреса электронной почты, почтовые адреса, номера телефонов, ссылки и другие контакты.</li>
          <li className="terms-use-info-list__item">Обязуется не публиковать оскорбления, клевету, нецензурные выражения, порнографические или иные аморальные материалы; материалы, демонстрирующие или пропагандирующие жестокость, террор или насилие; материалы, оскорбляющие человеческое достоинство; а также иные материалы, не соответствующие закону.</li>
          <li className="terms-use-info-list__item">Обязуется не вводить в заблуждение других Пользователей, а также не преследовать их и не досаждать им; не вступать в контакт с другими Пользователями в случае совершения ими действий, явно свидетельствующих о нежелании контакта с Пользователем; не заниматься сводничеством, а также совершать иные действия, нарушающие требования действующего законодательства РФ.</li>
          <li className="terms-use-info-list__item">Пользователь соглашается с правом Компании ограничивать или блокировать доступ к Системе или принимать иные меры в отношении Пользователя, нарушившего условия Соглашения, либо нормы действующего законодательства, либо охраняемые законом права третьих лиц в случае поступления от них мотивированной жалобы.</li>
          <li className="terms-use-info-list__item">Пользователю запрещается копирование, скачивание, воспроизводство, распространение и перевод на другие языки любого компонента Сайта без письменного разрешения Компании. Пользователь признает и соглашается с тем, что любой материал и все необходимые программы, связанные с работой Сайта, защищены законами об интеллектуальной собственности РФ и прочими международными законами, а контент, предоставляемый Пользователю в процессе использования Сайта, защищен авторскими правами, торговыми марками, патентами и прочими соответствующими законами.</li>
          <li className="terms-use-info-list__item">Пользователь не имеет право модифицировать, продавать, распространять материал Сайта и программы, целиком либо по частям. Компания предоставляет Пользователю личное неисключительное и непередаваемое право использовать контент в соответствии с настоящим Соглашением, при условии, что ни Пользователь, ни любые иные лица при содействии со стороны Пользователя не будут копировать или изменять контент и программное обеспечение; создавать программы, производные от программного обеспечения; проникать в программное обеспечение с целью получения кодов программ, а также модифицировать сервис, в том числе с целью получения несанкционированного доступа к нему.</li>
          <li className="terms-use-info-list__item">Пользователю запрещено скачивать, копировать, распространять материал, размещенный другими пользователями на своей персональной странице Сайте (в Блоге и/или Анкете).</li>
        </ol>
      </div>

      <div className="terms-use__info terms-use-info">
        <p className="terms-use-info__title">Компания оставляет за собой право:</p>
        <ol className="terms-use-info__list terms-use-info-list">
          <li className="terms-use-info-list__item">Модифицировать Сайт по своему усмотрению.</li>
          <li className="terms-use-info-list__item">Вносить изменения в данное Соглашение в одностороннем порядке без какого-либо специального уведомления. Новая редакция Соглашения вступает в силу с момента ее размещения на Сайте.</li>
          <li className="terms-use-info-list__item">Редактировать или удалять материалы, опубликованные Пользователем на Сайте, если они не соответствуют условиям данного Соглашения, наносят вред Компании или третьим лицам, а также нарушают требования действующего законодательства РФ.</li>
          <li className="terms-use-info-list__item">Отказывать Пользователю в доступе к Сайту в случае нарушения последним условий настоящего Соглашения.</li>
          <li className="terms-use-info-list__item">Использовать материалы, созданные Пользователем на Сайте и находящиеся в открытом доступе, в частности, в целях разработки рекламных материалов, размещения материалов на сайтах партнеров Компании и в других целях.</li>
          <li className="terms-use-info-list__item">Передавать права, полученные от Пользователя по настоящему Соглашению, третьим лицам в целях исполнения настоящего Соглашения без дополнительного согласия Пользователя.</li>
          <li className="terms-use-info-list__item">Компания сохраняет за собой право проводить модерацию всех материалов, размещённых Пользователями на Сайте, то есть следить за содержанием и качеством этих материалов и, в случае необходимости, по собственному усмотрению, без предупреждения и объяснения причин удалять или перемещать их.</li>
          <li className="terms-use-info-list__item">В случае нарушения условий Соглашения Компания имеет право предпринимать меры, необходимые для защиты этих условий и своих интересов вне зависимости от срока давности этих нарушений. Таким образом, бездействие со стороны Компании при нарушении Пользователями условий Соглашения не лишает Компанию права предпринять необходимые для защиты своих интересов действия позднее и не говорит об отказе Компании от своих прав при совершении подобных нарушений в будущем.</li>
        </ol>
      </div>

      <div className="terms-use__info terms-use-info">
        <p className="terms-use-info__title">Компания не несет ответственности:</p>
        <ol className="terms-use-info__list terms-use-info-list">
          <li className="terms-use-info-list__item">За содержание, достоверность и точность материалов, опубликованных Пользователем.</li>
          <li className="terms-use-info-list__item">За причинение вреда, ущерба, потерю информации или за причинение любых других убытков любым лицам, которые возникли при пользовании сервисом Сайта, в том числе с использованием мобильных средств связи и иных средств телекоммуникаций.</li>
          <li className="terms-use-info-list__item">За нарушение Пользователем авторских и иных прав третьих лиц путем опубликования материалов, не соответствующих действующему законодательству (в том числе авторскому), добавленных Пользователем на Сайт или переданных им Компании иным способом.</li>
        </ol>
      </div>

      <div className="terms-use__info terms-use-info">
        <p className="terms-use-info__title">Защита данных, не подлежащих оглашению, защита прав интеллектуальной собственности</p>
        <ol className="terms-use-info__list terms-use-info-list">
          <li className="terms-use-info-list__item">Стороны настоящего соглашения соглашаются с тем, что безопасность и защита данных пользователей относятся к важнейшим задачам Компании. В силу чего, Компания обязуется соблюдать предписания закона относительно защиты данных, не подлежащих оглашению.</li>
          <li className="terms-use-info-list__item">Пользователь уведомлен и согласен с тем, что в соответствии с данным Соглашением Компанией могут быть получены персональные данные — например, возраст, пол, почтовый индекс, номер телефона, результаты анализа теста личности, фотографии — которые затем сохраняются, обрабатываются и используются Компанией. Персональными данными являются такие, которые содержат сведения о личных и деловых отношениях определенного или определимого пользователя.</li>
          <li className="terms-use-info-list__item">Кроме того, сохраняются личные настройки пользования, которые Пользователь вводит при использовании Компанией. Этим гарантируется, что каждый Пользователь при следующем посещении (новая сессия — каждый новый вход в систему) может снова найти свои личные настройки.</li>
          <li className="terms-use-info-list__item">Пользователь разрешает использование Компанией его персональных данных в целях консультирования, рекламы, изучения рынка, исследований и анализа с целью улучшения Компанией и преобразования спектра услуг в соответствии с определенными потребностями.</li>
          <li className="terms-use-info-list__item">Пользователь дает разрешение на прием электронных писем с новостями от Компании.</li>
          <li className="terms-use-info-list__item">Компания обращает внимание пользователей на то, что сегодняшнее состояние техники не позволяет обеспечить при передаче данных в открытых сетях, как Интернет, полноценную защиту данных, не подлежащих оглашению. Поэтому пользователь сам отвечает за безопасность передаваемых им в Интернете данных.</li>
          <li className="terms-use-info-list__item">Своей регистрацией Пользователь выражает согласие с описанными выше положениями относительно использования его данных.</li>
          <li className="terms-use-info-list__item">При использовании Сайта запрещена загрузка, передача между Пользователями, изменение или размещение информации, данных или изображений, которые нарушают исключительные права третьих лиц, в частности авторские и смежные с ними права, а также исключительные права на изобретение, полезную модель, промышленный образец или товарный знак.</li>
          <li className="terms-use-info-list__item">Пользователь несет ответственность в случае нарушения им интеллектуальных прав третьих лиц, в частности авторских и смежных с ними прав, а также исключительных права на изобретение, полезную модель, промышленный образец или товарный знак.</li>
          <li className="terms-use-info-list__item">На страницах Сайта Пользователь вправе публиковать только те комментарии (включая изображение профиля, текст комментария и прикладываемые фотографии), которые не нарушают действующее законодательство и/или права третьих лиц. В случае если такое произведение является объектом авторского права или включает его, Пользователь гарантирует, что имеет право на такое использование. Публикуя указанные объектыПользователь предоставляет Компании право использовать их без ограничения по территории и сроку путем воспроизведения, распространения, перевода, публичного исполнения, публичного показа, сообщения в эфир и по кабелю, доведения до всеобщего сведения.</li>
          <li className="terms-use-info-list__item">Компания оставляет за собой право блокировать любую информацию, размещенную Пользователем, нарушающую интеллектуальные и/или исключительные права третьих лиц, в том числе авторские и смежные с ними права, по требованию правообладателя либо без такового.</li>
        </ol>
      </div>

      <div className="terms-use__info terms-use-info">
        <p className="terms-use-info__title">Заключительные положения</p>
        <ol className="terms-use-info__list terms-use-info-list">
          <li className="terms-use-info-list__item">Компания оставляет за собой право незамедлительно без предварительного предупреждения ограничить или блокировать доступ к Сайту или принимать иные меры в отношении Пользователя, нарушившего условия настоящего Соглашения. Кроме того, в случае нарушения Пользователем настоящего соглашения Компания обязуется активно содействовать представителям органов охраны правопорядка в сборе и предоставлении информации о Пользователе, нарушившем настоящее пользовательское соглашение, а также законодательство Российской Федерации.</li>
          <li className="terms-use-info-list__item">Регистрируясь, Пользователь подтверждает своё безоговорочное согласие со всеми положениями Соглашения, а также своё соответствие всем условиям, отражённым в формах регистрации и необходимыми для успешной регистрации. В определенных Компанией случаях, настоящее Соглашение вступает в силу при акцептации его пользователем на Сайте путем выбора и нажатия Пользователем опции «согласен» (или иной аналогичной опции. пользователь соглашается, что выбор им вышеуказанной опции означает, что он предварительно и в полном объеме ознакомился с условиями настоящего Соглашения и принимает их без каких-либо исключений.</li>
          <li className="terms-use-info-list__item">Соглашение заключается на неопределенный срок.</li>
          <li className="terms-use-info-list__item">При регистрации Пользователь получает информацию для доступа (логин и пароль) на сайт и принимает на себя всю ответственность за её сохранность, в том числе за соблюдение всех мер для обеспечения безопасности своих логина и Пароля, а также за все действия, совершённые с использованием логина и пароля Пользователя.</li>
          <li className="terms-use-info-list__item">Отношения сторон по настоящему Соглашению регулируются законодательством Российской Федерации.</li>
        </ol>
      </div>
    </div>
  );
};