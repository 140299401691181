import { request } from './api';
import { API_ROUTES } from 'config'
import { DocumentsCategory, INewDocument } from 'store/documents/types';

import { ISortDirection } from './api';

export interface IDocumentsParams {
  category?: DocumentsCategory
  name?: string;
  direction?: ISortDirection
  // type (DocumentType)
  // signers (array SignerType)
  // pageSize (int)
  // pageNum (int)
  // order(name, timeCreated)
  // direction(ASC, DESC)
}

export const documentsApi = {
  getDocuments: (params: any) => request.get<void>(API_ROUTES.documents.getDocuments, params),
  saveDocuments: (params: any) => request.post<void>(API_ROUTES.documents.saveDocuments, params),
  getDocumentsFile: (fileId: any) => request.get<void>(API_ROUTES.documents.getDocumentsFile.replace('{fileId}', fileId)),
  removeDocumentTemplate: (fileId: any) => request.delete<void>(API_ROUTES.documents.removeDocumentTemplate.replace('{fileId}', fileId)),
  getFilledDocuments: (params: any) => request.get<void>(API_ROUTES.documents.getFilledDocuments, params),
  getFilledDocumentId: (fileId: string) => request.get<void>(API_ROUTES.documents.getFilledDocumentId.replace('{fileId}', fileId)),
  getOrdersDocumentsById: (fileId: string) => request.get<void>(API_ROUTES.documents.getOrdersDocumentsById.replace('{fileId}', fileId)),
  getOrdersTemplateDocuments: (params: any) => request.get<void>(API_ROUTES.documents.getOrdersTemplateDocuments, params),
  addTemplatesForOrder: (id:string, params: any) => request.post<void>(API_ROUTES.documents.addTemplatesForOrder.replace('{orderId}', id), params),
  getOrdersTemplateDocumentsForPartners: (id:string, params) => request.get<void>(API_ROUTES.documents.getOrdersTemplateDocumentsForPartners.replace('{authUserId}', id), params),
  changeVisibilityForCustomer: (id:string) => request.post<void>(API_ROUTES.documents.changeVisibilityForCustomer.replace('{fileId}', id)),
  changeVisibilityForPartner: (id:string) => request.post<void>(API_ROUTES.documents.changeVisibilityForPartner.replace('{fileId}', id)),
};