import { useAppDispatch, useAppSelector } from 'store';
import { useFormContext } from 'react-hook-form';
import { Button } from 'primereact/button';
import { profileSlice } from 'store/profile/reducer';
import staticData from 'common/static.json';
import InputTest from 'components/common/InputTest';
import { validations } from 'helpers/validations';
import { updatePassword } from 'store/profile/actions';

const text = staticData.profile;

const ChangePassword: React.FC = () => {
  const { passwordEditing, isEditing, common } = useAppSelector(
    state => state.profile
  );
  const { setPasswordEditing } = profileSlice.actions;
  const dispatch = useAppDispatch();

  const { reset, handleSubmit, watch, formState, unregister, getValues } =
    useFormContext();

  const confirmPassword = {
    ...validations.password,
    validate: (value: string) =>
      value === watch('newPassword') || 'Пароли не совпадают',
  };

  const matchPassword = {
    ...validations.password,
    validate: (value: string) =>
      value != watch('oldPassword') || 'Новый пароль совпадает со старым',
  };

  const handleEdit = () => {
    unregister('region');
    dispatch(setPasswordEditing());
  };

  const handleCancel = () => {
    reset();
    dispatch(setPasswordEditing());
  };

  const onSubmit = () => {
    const data = {
      oldPassword: getValues('oldPassword'),
      newPassword: getValues('newPassword'),
    };
    dispatch(updatePassword({ id: common.id, data }));
    dispatch(setPasswordEditing());
  };

  return (
    <div>
      <div className='profile-desktop'>
        {passwordEditing ? (
          <div className='flex flex-column gap-2'>
            <div className='grid align-items-end'>
              <InputTest
                name='oldPassword'
                type='password'
                validations={validations.password}
                classNameInput='auth-form-input profile-form-input'
                classNameLabel='flex flex-column gap-2 col-4'
                classNameValid='input-valid-auth'
                classNameError='input-error-auth'
                label={text.currentPassword}
                disabled={!passwordEditing}
              />
              <InputTest
                name='newPassword'
                type='password'
                validations={matchPassword}
                classNameInput='auth-form-input profile-form-input'
                classNameLabel='flex flex-column gap-2 col-4'
                classNameValid='input-valid-auth'
                classNameError='input-error-auth'
                label={text.newPassword}
                disabled={!passwordEditing}
              />
              <InputTest
                name='confirmPassword'
                type='password'
                validations={confirmPassword}
                classNameInput='auth-form-input profile-form-input'
                classNameValid='input-valid-auth'
                classNameLabel='flex flex-column gap-2 col-4'
                classNameError='input-error-auth'
                label={text.confirmPassword}
                disabled={!passwordEditing}
              />
            </div>
            <div className='m-3 align-self-end flex gap-5'>
              <Button
                className='button-white'
                label='Отменить'
                onClick={handleCancel}
              />
              <Button
                type="button"
                className='button-white'
                label='Сохранить пароль'
                disabled={!formState.isDirty}
                onClick={() => onSubmit()}
              />
            </div>
          </div>
        ) : (
          <div className='flex flex-column gap-1 justify-content-start'>
            <InputTest
              type='password'
              name='oldPassword'
              validations={''}
              classNameInput='auth-form-input profile-form-input'
              classNameLabel='flex flex-column gap-2 col-4'
              label={text.currentPassword}
              disabled={!passwordEditing}
            />
            {!isEditing && (
              <span
                className='cursor-pointer col-4 font-semibold underline select-none'
                onClick={handleEdit}
              >
                Редактировать пароль
              </span>
            )}
          </div>
        )}
      </div>

      <div className='profile-mobile'>
        {passwordEditing ? (
          <div className='flex flex-column gap-2'>
            <div className='flex flex-column gap-3'>
              <InputTest
                name='oldPassword'
                type='password'
                validations={validations.password}
                classNameInput='auth-form-input profile-form-input'
                classNameLabel='flex flex-column gap-2'
                classNameValid='input-valid-auth'
                classNameError='input-error-auth'
                label={text.currentPassword}
                disabled={!passwordEditing}
              />
              <InputTest
                name='newPassword'
                type='password'
                validations={matchPassword}
                classNameInput='auth-form-input profile-form-input'
                classNameLabel='flex flex-column gap-2'
                classNameValid='input-valid-auth'
                classNameError='input-error-auth'
                label={text.newPassword}
                disabled={!passwordEditing}
              />
              <InputTest
                name='confirmPassword'
                type='password'
                validations={confirmPassword}
                classNameInput='auth-form-input profile-form-input'
                classNameValid='input-valid-auth'
                classNameLabel='flex flex-column gap-2'
                classNameError='input-error-auth'
                label={text.confirmPassword}
                disabled={!passwordEditing}
              />
            </div>
            <div className='m-3 align-self-end flex gap-5'>
              <Button
                className='button-white'
                label='Отменить'
                onClick={handleCancel}
              />
              <Button
                type="button"
                className='button-white'
                label='Сохранить пароль'
                disabled={!formState.isDirty}
                onClick={handleSubmit(onSubmit)}
              />
            </div>
          </div>
        ) : (
          <div className='flex flex-column gap-1 justify-content-start'>
            <InputTest
              type='password'
              name='oldPassword'
              validations={''}
              classNameInput='auth-form-input profile-form-input'
              classNameLabel='flex flex-column gap-2'
              label={text.currentPassword}
              disabled={!passwordEditing}
            />
            {!isEditing && (
              <span
                className='cursor-pointer col-4 font-semibold underline select-none'
                onClick={handleEdit}
              >
                Редактировать пароль
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChangePassword;
