import { FC, useState, useEffect } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Dialog } from 'primereact/dialog';
import moment from 'moment';
import { REPRESENTATIVE_ISSUE_ORDER } from 'store/partners/types';

import { getExecutors } from 'store/partners/actions';

import { PartnerTypes, ExecutorsTypes } from 'store/partners/types';

import { StatusForm } from './forms/StatusForm';
import { DeadlineForm } from './forms/DeadlineForm';
import { LogisticForm } from './forms/LogisticForm';

import { updatePartnerIssue } from 'store/orders/actions';
import { getOrdersDocumentsById } from 'store/documents/actions';

import { useAppSelector, useAppDispatch} from 'store';
import { getById } from 'store/orders/actions';
import { changeVisibilityForPartner } from 'store/documents/actions';
import {
  DocumentsAwaitingSignature,
  CrossIcon,
  CheckMarkIcon,
  CheckMarkFilledIcon, 
  CheckMarkFilledIcon2,
  CheckMarkEmptyIcon,
  DocumentEyeShow,
  DocumentEyeNoshow,
  PdfDocumentIcon
} from 'assets/icons';

import { OrderDocumentsForm } from '../forms/OrderDocumentsForm';
import { removeDocuments } from 'store/orders/actions';
import { DocumentsPreview } from 'components/Documents/DocumentsPreview';

export const ContractorInputs:FC = () => {
  const dispatch = useAppDispatch();

  const { partnerIssue, order } = useAppSelector(state => state.orders);

  const { executors } = useAppSelector(state => state.partners);

  const [isShowDialog, showDialog] = useState(false);
  const [currentDialog, setCurrentDialog] = useState(<></>);
  
  const {
    role
  } = useAppSelector(state => state.auth);

  useEffect(() => {
    dispatch(getExecutors({ orderExecutorTypes: Object.keys(ExecutorsTypes) }));
  }, []);

  const [isShowDocument, setShowDocument] = useState(false);
  const [currentDocument, setCurrentDocument] = useState();

  const showDocument = (document: any) => {
    setCurrentDocument(document);
    dispatch(getOrdersDocumentsById(document?.fileId));
    setShowDocument(true);
  }

  const isAdmin = role === 'ADMIN';

  const changeDocuments = (data) => {
    showDialog(false);

    data.forEach(document => {
      switch(document.type) {
        case 'removed':
          dispatch(removeDocuments({
            orderId: order?.order?.id,
            documentId: document.id
          }));
          return;
        default:
          return;
      }
    })
  }

  const showEditForm = (type: string) => {
    showDialog(true);
    switch(type) {
      case 'status':
          setCurrentDialog(
            <StatusForm
              currentStatus={partnerIssue?.issue?.status}
              submitAction={ (data) => { editData('status', data) } }
            />
          );
          return;
      case 'deadline':
        setCurrentDialog(
          <DeadlineForm
            currentDate={ partnerIssue?.issue?.orderExecutionDeadline }
            submitAction={ (data) => { editData('orderExecutionDeadline', data)} }
          />
        );
        return;
      case 'logistic':
        setCurrentDialog(
          <LogisticForm
            logistic={ partnerIssue?.logistic }
            //submitAction={}
          />
        );
          return;
      case 'documents':
        setCurrentDialog(
          <OrderDocumentsForm
            currentDocuments={order?.documents}
            submitAction={ (data) => { changeDocuments(data) } }
          />
        );
        return;
      // case 'documents':
      //   setCurrentDialog(
      //     <OrderDocumentsForm
      //       currentDocuments={order?.documents}
      //       submitAction={ (data) => { changeDocuments(data) } }
      //     />
      //   );
      //   return;
      // case 'executors':
      //   setCurrentDialog(
      //     <OrderExecutorsForm
      //       currentExecutors={order?.executors}
      //       executors={ executors }
      //       submitAction={ (data) => { 
      //         showDialog(false);
      //         dispatch(assignExecutors({ id:order?.order.id, params: data }));
      //         setTimeout(() => {
      //           dispatch(getById(order.order.id));
      //         }, 1500);
      //       } }
      //     />
      //   );
      //   return;
      default:
        setCurrentDialog(<></>);
        return;
    }
  }


  const editData = (field, data) => {
    showDialog(false);
    const params = { id: partnerIssue?.issue?.id };
    params[field] = data;
    dispatch(updatePartnerIssue(params));
  }

  const getExecutor = (type) => {
    const executor = order?.executors?.filter((executor) => {
      if (executor?.executorType === type) {
        return executor;
      }
    })

    if (executor !== undefined) {
      return executor[0];
    }

    return {};
  }

  const changeVisibility = async (id: string) => {
    await dispatch(changeVisibilityForPartner(id));
    dispatch(getById(order.order.id));
  }

  return (
    <div className='order-detail_inputs order-detail_inputs_block order--inputs flex align-items-center justify-content-center'>
      <Dialog visible={isShowDialog} style={{ width: '50vw' }} onHide={() => showDialog(false)} className='order--dialog'>
          { currentDialog }
      </Dialog>
      <>
        <Dialog
          visible={ isShowDocument }
          onHide={ () => setShowDocument(false) }
          header=""
          className='documents-modal documents__modal'
        >
          <DocumentsPreview />
          {
            currentDocument?.status === 'WAITING_FOR_SENDING' &&
            <div className="documents-modal__button">Ожидает отправки</div>
          }
          {
            currentDocument?.status === 'WAITING_FOR_SIGNING' &&
            <div className="documents-modal__button">Ожидает подписания</div>
          }
          {
            currentDocument?.status === 'FIRST_PARTICIPANT_SIGNED' &&
            <div className="documents-modal__button">1 подписант</div>
          }
          {
            currentDocument?.status === 'SECOND_PARTICIPANT_SIGNED' &&
            <div className="documents-modal__button">2 подписанта</div>
          }
        </Dialog>
      </>
      <div className='order-detail_inputs--list col-12'>
        <div className='order-detail_inputs--list__date'>
          Дата заказа {moment(partnerIssue?.issue?.timeCreated).format('L').replaceAll('/', '.')}
        </div>
        <Accordion activeIndex={0}>
          {/* <AccordionTab
            header={`Дата заказа ${moment(partnerIssue?.issue?.timeCreated).format('L').replaceAll('/', '.')}`}
          >
          </AccordionTab> */}
          <AccordionTab
            header='Статус заказа'
          >
            <ul>
              <li>Статус: { REPRESENTATIVE_ISSUE_ORDER[partnerIssue?.issue?.status] }</li>
            </ul>
            { isAdmin &&
              <div className='order-detail_inputs--buttons order--inputs_buttons'>
                <button onClick={ () => { showEditForm('status') }}>Редактировать</button>
              </div>
            }
          </AccordionTab>
          <AccordionTab
            header='Крайний срок исполнения заказа'
          >
            <ul>
              <li>Дата: { moment(partnerIssue?.issue?.orderExecutionDeadline).format('L').replaceAll('/', '.') }</li>
            </ul>
            { isAdmin &&
              <div className='order-detail_inputs--buttons order--inputs_buttons'>
                <button onClick={ () => { showEditForm('deadline') }}>Редактировать</button>
              </div>
            }
          </AccordionTab>
          <AccordionTab
            header='Участок'
          >
            <ul>
              <li>Регион: { order?.land?.region }</li>
              <li>Населенный пункт: { order?.land?.locality }  </li>
              <li>Район: { order?.land?.district }</li>
              <li>Кадастровый номер: { order?.land?.cadastralNumber } </li>
            </ul>
          </AccordionTab>
          <AccordionTab
            header='Заказчик'
          >
            <ul>
              <li>ФИО: { order?.customer?.name }</li>
              <li>Номер телефона: { order?.customer?.phoneNumber }</li>
            </ul>
          </AccordionTab>
          <AccordionTab
            header='Представитель'
          >
            <ul>
              <li>Наименование: { getExecutor('REPRESENTATIVE')?.orgName }</li>
            </ul>
          </AccordionTab>
          <AccordionTab
            header='Логистика'
          >
            <ul>
              <li>Грузоперевозчик: { partnerIssue?.logistic?.organizationName }</li>
              <li>Номер телефона: { partnerIssue?.logistic?.phoneNumber }</li>
              <li>ФИО водителя: { partnerIssue?.logistic?.fullName }</li>
              <li>Марка и модель машины:
                { partnerIssue?.logistic?.carInfo?.brand }
                { partnerIssue?.logistic?.carInfo?.model }
              </li>
              <li>Номер машины: { partnerIssue?.logistic?.carInfo?.number }</li>
              <li>Дата прибытия: { partnerIssue?.logistic?.departurePoint?.arrivalDate }</li>
              <li>Время прибытия: с { partnerIssue?.logistic?.departurePoint?.arrivalHourFrom } до &nbsp;
                { partnerIssue?.logistic?.departurePoint?.arrivalHourTo }</li>
            </ul>
          </AccordionTab>
        </Accordion>
        </div>
    </div>
  )
}
