import { FC, useState } from 'react';

import {
  PartnerOrderInfo,
  PartnerOrderImplementation,
  PartnerOrderDocuments,
  PartnerOrderFinance
} from 'assets/icons';
import { Action } from '@reduxjs/toolkit';

import { Slider } from 'components/common/Slider';

export const OrderPartnerHeader: FC<{ changeTab: Action }> = ({ changeTab }) => {

  const [selected, setSelect] = useState(0);

  const tabList = [{
    label: 'Общая информация',
    value: 'info',
    element: <PartnerOrderInfo />
  }, {
    label: 'Реализация',
    additional: 'Скоро',
    element: <PartnerOrderImplementation /> 
  }, {
    label: 'Документы',
    value: 'documents',
    element: <PartnerOrderDocuments />
  }, {
    label: 'Финансы',
    additional: 'Скоро',
    element: <PartnerOrderFinance />
  }];

  const selTab = (index: number) => {
    setSelect(index);
    changeTab(tabList[index].value);
  }

  return (
    <div className='orders--role-switcher orders--partners-header'>
        {
          // tabList.map((tab, key) => {
          //   return <div key={ key } onClick={ () => { selTab(key) } } className='orders--role-switcher_item orders--partners-header_item'>
          //           <div>
          //             <div className='orders--role-switcher_item-label'>
          //               <span>{ tab.label }</span>{ tab?.additional ? <span className='orders--role-switcher_item-label_additional'>{ tab?.additional }</span>: <></> }
          //             </div>
          //             <div className={ key === selected ? 'orders--role-switcher_item-img active' : 'orders--role-switcher_item-img' }>
          //               { tab.element }
          //             </div>
          //           </div>
          //         </div>
          // })
          <Slider
          sliderAction={ (value) => { changeTab(value) } }
          slides={ tabList }
          customClass={ 'orders-role' }
          />
        }
    </div>
  )
}