import { FC, useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from 'store';
import { getCategory, getTemplate, updateOffer, getOffer, createOffer } from 'store/commercial/actions';
import { setCategory, clearTemplates, clearCommercial } from 'store/commercial/reducer';

import { Accordion, AccordionTab } from 'primereact/accordion';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { CrossPlusIcon } from 'assets/icons';

import {
  PdfDocumentIcon
} from 'assets/icons';

import staticData from 'common/static.json';
import { Action } from '@reduxjs/toolkit';
const pageStaticText = {
  ...staticData.offer
};

export const OffersSelf: FC<{ closeAction:Action }> = ({closeAction}) => {
  const dispatch = useAppDispatch();
  const [tableData, setTableData] = useState([]);
  const [newTableData, setNewTableData] = useState([]);

  const [fillCategorie, setFillCategorie] = useState([]);
  const [newCategorie, setNewCategorie] = useState([]);
  const [isEditMode, setEditMode] = useState(false);
  const [isShowAllCategory, setShowAllCategory] = useState(false);

  const {
    categories,
    selectedCategories,
    templates,
    offers,
    mapOffer
  } = useAppSelector(state => state.commercial);

  useEffect(() => {
    dispatch(clearCommercial());
    dispatch(getCategory());
    dispatch(getOffer());
  }, []);
  
  const getCategorieName = (id) => {
    if (categories.length) {
      return categories?.find(elem => elem.id === id)?.name;
    } 

    return '';
  }

  // useEffect(() => {
  //   if (isEditMode === false && isShowAllCategory === false) {
  //     setNewCategorie([]);
  //     setNewTableData([]);
  //   }
  // }, [isEditMode, isShowAllCategory]);

  useEffect(() => {
    if (tableData.length) {
      return;
    }

    const newTemplates = mapOffer.map(offer => {
      return {
        categoryId: offer.categoryId, 
        name: getCategorieName(offer.categoryId),
        list: offer?.data.map((data, index) => {
          return {
            index: index + 1,
            ...data,
          }
        })
      }
    }) || [];

    setTableData([...newTemplates]);
  }, [offers, categories]);

  useEffect(() => {
    if (fillCategorie.length) {
      return;
    }
    categories.map((categorie) => {
      const { id } = categorie;
      const contains = offers.find(elem => elem.categoryId === id);
      if (!!contains) {
        const tempArray = fillCategorie;
        tempArray.push(categorie);
        setFillCategorie([...tempArray])
      }
    })
  }, [categories, offers]);

  useEffect(() => {
    if (newCategorie.length) {
      return;
    }
    categories.map((categorie) => {
      const { id } = categorie;
      const contains = offers.find(elem => elem.categoryId === id);
      if (!!!contains) {
        const tempArray = newCategorie;
        tempArray.push(categorie);
        setNewCategorie([...tempArray])
      }
    })
  }, [categories, offers]);


  useEffect(() => {
    const newTemplates = templates.map(template => {
      return {
        id: template.categoryId,
        name: selectedCategories.find(elem => elem.id === template.categoryId)?.name,
        list: template.data.map((data, index) => {
          return {
            index: index + 1,
            ...data,
            productionTime: '',
            unitPrice: ''
          }
        })
      }
    }) || [];

    setNewTableData(newTemplates);
  }, [templates]);

  const checkCategory = (category) => {
    let categoriesTemp = structuredClone(selectedCategories);
    if (isCategorySelected(category)) {
      categoriesTemp.push(category);
      dispatch(setCategory(categoriesTemp))
      dispatch(getTemplate(category.id));
    } else {
      categoriesTemp = categoriesTemp.filter(item => item.id !== category.id);
      dispatch(setCategory(categoriesTemp));
      dispatch(clearTemplates(category.id));
    }
  }
  
  const isCategorySelected = (category) => {
    const { id } = category;
    const isContains = selectedCategories.find(elem => elem.id === id);
    return isContains === undefined;
  }

  const tableDocumentIcon = () => {
    return <div className='offer--table-documents' ><PdfDocumentIcon /></div>
  }

  const tableNumberChangeHTML = (options, minValue, maxValue) => {
    return <div className='offers--category_table-input'><input value={options.value} onChange={(e) => options.editorCallback(e.target.value)}  type='number' min={minValue} max={maxValue} /></div>
  }

  const sendOfferData = () => {
    let sendingData = [];
    if (tableData.length) {
      tableData.map(data => {
        data?.list.map(item => {
          sendingData.push({
            unitPrice: Number(item.unitPrice),
            id: item?.id,
          })
        });
      });

      dispatch(updateOffer(sendingData));
    }

    if (newTableData.length) {
      const sendData = newTableData.map(data => {
        const temp = data.list.map(item => {
          return {
            productionTime: Number(item.productionTime),
            unitPrice: Number(item.unitPrice),
            nomenclatureId: item.id,
            categoryId: data.id,
          }
        });
  
        return temp;
      })

      dispatch(createOffer(sendData[0]));
    }
    setEditMode(false);
    closeAction();
    //navigate(routesPath.ordersList);
  }

  return (
    <div className='offers--category offers--category--self'>
      <div className='offers--category_title'>
        <span>Коммерческое предложение</span>
      </div>
      <div className='offers--category_list'>
        <ul>
          {
            fillCategorie.map((category, key) => {
              return <li className='offers--category_list_item-fill' key={key}>{ category.name }</li>
            })
          }
        </ul>
        { isEditMode && !isShowAllCategory &&
          <ul className='flex align-items-center flex-wrap'>
            <li onClick={ () => { setShowAllCategory(true) } } className='flex align-items-center flex-wrap'><span style={{ marginRight: '10px' }}>Добавить категорию</span><CrossPlusIcon /></li>
          </ul>
        }
        <ul>
          {isShowAllCategory &&
              newCategorie.map((category, key) => {
                return <li className={!!!isCategorySelected(category) ? 'active': ''} onClick={() => checkCategory(category)} key={key}>{ category.name }</li>
              })
          }
        </ul>
      </div>
      { tableData.length > 0 && 
        <div className='offers--category_details'>
          <div className='offers--category_table offer--table flex justify-content-start flex-wrap'>
            <Accordion activeIndex={0}>
              { !isEditMode &&
                tableData.map((table, key) => {
                  return <AccordionTab key={key} header={ getCategorieName(table?.categoryId) }>
                      <DataTable
                        value={ table?.list }
                        tableStyle={{ width: '100%' }}
                      >
                        <Column field='index' header={ pageStaticText.table.fields.number }></Column>
                        <Column field='title' style={{ minWidth: '60%', width: '60%' }} header={ pageStaticText.table.fields.name }></Column>
                        <Column field='documentLink' body={ tableDocumentIcon } header={ pageStaticText.table.fields.task }></Column>
                        <Column field='minimumOrderQuantity' header={ pageStaticText.table.fields.minCount }></Column>
                        <Column field='productionTime' header={ pageStaticText.table.fields.deadline }></Column>
                        <Column field='unitPrice' header={ pageStaticText.table.fields.amount }></Column>
                      </DataTable>
                    </AccordionTab>
                })
              }
              { isEditMode && 
                tableData.map((table, key) => {
                  return <AccordionTab key={key} header={ getCategorieName(table?.categoryId)}>
                      <DataTable
                        value={ table?.list }
                        tableStyle={{ width: '100%' }}
                      >
                        <Column field='index' header={ pageStaticText.table.fields.number }></Column>
                        <Column field='title' style={{ minWidth: '60%', width: '60%' }} header={ pageStaticText.table.fields.name }></Column>
                        <Column field='documentLink' body={ tableDocumentIcon } header={ pageStaticText.table.fields.task }></Column>
                        <Column field='minimumOrderQuantity' header={ pageStaticText.table.fields.minCount }></Column>
                        <Column field='productionTime' header={ pageStaticText.table.fields.deadline }></Column>
                        <Column field='unitPrice' editor={(options) => tableNumberChangeHTML(options, 1, null)} header={ pageStaticText.table.fields.amount }></Column>
                      </DataTable>
                    </AccordionTab>
                })
              }
              {
                newTableData.map((table, key) => {
                  return <AccordionTab key={key} header={ table?.name}>
                      <DataTable
                        value={ table?.list }
                        tableStyle={{ width: '100%' }}
                      >
                        <Column field='index' header={ pageStaticText.table.fields.number }></Column>
                        <Column field='title' style={{ minWidth: '60%', width: '60%' }} header={ pageStaticText.table.fields.name }></Column>
                        <Column field='documentLink' body={ tableDocumentIcon } header={ pageStaticText.table.fields.task }></Column>
                        <Column field='minimumOrderQuantity' header={ pageStaticText.table.fields.minCount }></Column>
                        <Column field='productionTime' editor={(options) => tableNumberChangeHTML(options, 1, 60)} header={ pageStaticText.table.fields.deadline }></Column>
                        <Column field='unitPrice' editor={(options) => tableNumberChangeHTML(options, 1, null)} header={ pageStaticText.table.fields.amount }></Column>
                      </DataTable>
                    </AccordionTab>
                })
              }
              </Accordion>
          </div>
        </div>
      }
      { !isEditMode &&
        <div className='offers--category_sendbutton flex justify-content-end flex-wrap'>
          <button onClick={() => { setEditMode(true)}}>Редактировать</button>
        </div>
      }
      { isEditMode &&
        <div className='offers--category_sendbutton flex justify-content-end flex-wrap'>
          {/* <button onClick={() => { setEditMode(false); setShowAllCategory(false) }}>Отменить</button> */}
          <button onClick={() => sendOfferData()}>Сохранить</button>
        </div>
      }
    </div>
  )
};