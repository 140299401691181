import React from 'react';
import ReactDOM from 'react-dom';
import { PrimeReactProvider } from 'primereact/api';

//import "./index.css";
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createBrowserHistory } from 'history';
import store from 'store';
const history = createBrowserHistory();

ReactDOM.render(
  <PrimeReactProvider>
    <App store={store} history={history} />
  </PrimeReactProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
