import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AccountType } from 'store/profile/types';
import { INotification, IUIState, PartnerType } from './types';
import { regions } from './actions';

const initialUI: IUIState = {
  isGuest: true,
  isProToogler: false,
  isHideOverflow: false,
  regionList: [],
  regionMap: [],
  notifications: [],
  partnerType: PartnerType.OTHER,
  accountType: AccountType.uncertainUser,
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState: initialUI,
  reducers: {
    guestToggle(state) {
      state.isGuest = !state.isGuest;
    },
    toggleOverflow(state) {
      state.isHideOverflow = !state.isHideOverflow;
    },
    togglePro(state) {
      state.isProToogler = !state.isProToogler;
    },
    setNotification(state, action: PayloadAction<INotification>) {
      state.notifications.push(action.payload);
    },
    removeNotification(state, action: PayloadAction<number>) {
      state.notifications = state.notifications.filter(
        item => item.id !== action.payload
      );
    },
    setPartnerType(state, action: PayloadAction<string>) {
      state.partnerType = action.payload as PartnerType;
    },
    setAccountType(state, action: PayloadAction<string>) {
      state.accountType = action.payload as AccountType;
    },
  },
  extraReducers: builder => {
    builder.addCase(regions.fulfilled, (state, action: PayloadAction<any>) => {
      state.regionList = action.payload.data;
      let regionMap = {}
      action.payload.data.map((regions) => {
        regionMap[regions.region] = regions.value;
      })
      state.regionMap = regionMap;
    });
  },
});

export const { guestToggle, toggleOverflow, setAccountType, togglePro, setNotification } = uiSlice.actions;

export default uiSlice.reducer;
